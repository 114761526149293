import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const PageTagTypes = {
	PAGES: "pages",
	PAGE_TITLE: "page_title",
	PAGE_RULE: "page_rule",
};

export const pagesApiSlice = createApi({
	reducerPath: "api/pages",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().sessionData.token;
			if (token) {
				headers.set("token", token);
			}
			return headers;
		},
	}),
	tagTypes: Object.values(PageTagTypes),
	endpoints: (builder) => ({
		getAllPages: builder.query({
			query: (initialPost) => "page/" + initialPost.companyId,
			providesTags: [PageTagTypes.PAGES],
		}),
		getPageTitle: builder.query({
			query: (initialPost) => "page/title/" + initialPost.pageId,
			providesTags: [PageTagTypes.PAGE_TITLE],
		}),
		insertNewPage: builder.mutation({
			query: (initialPost) => ({
				url: `page/${initialPost.salesRepId}/${initialPost.companyId}`,
				method: "POST",
				body: {
					title: initialPost.title,
					type: initialPost.type,
					date_created: initialPost.dateCreated,
					date_modified: initialPost.dateModified,
				},
			}),
			invalidatesTags: [PageTagTypes.PAGES],
		}),
		copyPage: builder.mutation({
			query: (initialPost) => ({
				url: `page/copy`,
				method: "POST",
				body: {
					clone_from_id: initialPost.cloneFromId,
					owner_id: initialPost.salesRepId,
					company_id: initialPost.companyId,
					title: initialPost.title,
					type: initialPost.type,
					date_created: initialPost.dateCreated,
					date_modified: initialPost.dateModified,
				},
			}),
			invalidatesTags: [PageTagTypes.PAGES],
		}),
		updatePageTitle: builder.mutation({
			query: (initialPost) => ({
				url: `page/title`,
				method: "PUT",
				body: {
					pageId: initialPost.pageId,
					title: initialPost.title,
				},
			}),
			invalidatesTags: [PageTagTypes.PAGES, PageTagTypes.PAGE_TITLE],
		}),
		updatePageVisibility: builder.mutation({
			query: (initialPost) => ({
				url: `page/${initialPost.pageId}/${initialPost.companyId}`,
				method: "PUT",
				body: {
					live: initialPost.live,
				},
			}),
			invalidatesTags: [PageTagTypes.PAGES],
		}),
		deletePage: builder.mutation({
			query: (initialPost) => ({
				url: "page/" + initialPost.pageId,
				method: "DELETE",
			}),
			invalidatesTags: [PageTagTypes.PAGES],
		}),
		getPageRules: builder.query({
			query: (initialPost) =>
				`page/rule/${initialPost.salesRepId}/${initialPost.companyId}`,
			providesTags: [PageTagTypes.PAGE_RULE],
		}),
		updateNewVariable: builder.mutation({
			query: (initialPost) => ({
				url: `page/rule/variable/${initialPost.salesRepId}/${initialPost.companyId}`,
				method: "POST",
				body: {
					variables: initialPost.variables,
				},
			}),
			invalidatesTags: [PageTagTypes.PAGE_RULE],
		}),
		updateNewRule: builder.mutation({
			query: (initialPost) => ({
				url: `page/rule/${initialPost.salesRepId}/${initialPost.companyId}`,
				method: "POST",
				body: {
					rules: initialPost.rules,
				},
			}),
			invalidatesTags: [PageTagTypes.PAGE_RULE],
		}),
	}),
});

export const {
	useGetAllPagesQuery,
	useGetPageTitleQuery,
	useInsertNewPageMutation,
	useCopyPageMutation,
	useUpdatePageTitleMutation,
	useUpdatePageVisibilityMutation,
	useDeletePageMutation,
	useGetPageRulesQuery,
	useUpdateNewVariableMutation,
	useUpdateNewRuleMutation,
} = pagesApiSlice;

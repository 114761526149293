import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const RecordingTagTypes = {
  RECORDING: "recording",
  SINGLE_RECORDING: "single-recording",
};

export const recordingApiSlice = createApi({
  reducerPath: "api/recording",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER}/web`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().sessionData.token;
      if (token) {
        headers.set("token", token);
      }
      return headers;
    },
  }),
  tagTypes: Object.values(RecordingTagTypes),
  endpoints: (builder) => ({
    getRecording: builder.query({
      query: (initialPost) => "/recording/" + initialPost.companyId + `?limit=${initialPost.limit}&pageNo=${initialPost.pageNo.length==0?1:initialPost.pageNo}`,
      providesTags: [RecordingTagTypes.RECORDING],
    }),
    getRecordingById: builder.query({
      query: (initialPost) => "/recording/id/" + initialPost.id,
      providesTags: [RecordingTagTypes.SINGLE_RECORDING],
    }),
    createRecording: builder.mutation({
      query: (initialPost) => ({
        url: "/recording",
        method: "POST",
        body: initialPost,
      }),
      invalidatesTags: [RecordingTagTypes.RECORDING],
    }),
    createRecordingCopy: builder.mutation({
      query: (initialPost) => ({
        url: "/recording/copy",
        method: "POST",
        body: initialPost,
      }),
      invalidatesTags: [RecordingTagTypes.RECORDING],
    }),
    updateRecording: builder.mutation({
      query: (initialPost) => ({
        url: "/recording/" + initialPost.recordingId,
        method: "PUT",
        body: {
          title: initialPost.title,
          description: initialPost.description,
          coordinates: initialPost.coordinates,
          date_modified: initialPost.dateModified,
        },
      }),
      invalidatesTags: [
        RecordingTagTypes.RECORDING,
        RecordingTagTypes.SINGLE_RECORDING,
      ],
    }),
    deleteRecording: builder.mutation({
      query: (initialPost) => ({
        url: "/recording/" + initialPost.recordingId,
        method: "DELETE",
      }),
      invalidatesTags: [RecordingTagTypes.RECORDING],
    }),
  }),
});

export const {
  useGetRecordingQuery,
  useGetRecordingByIdQuery,
  useCreateRecordingMutation,
  useCreateRecordingCopyMutation,
  useUpdateRecordingMutation,
  useDeleteRecordingMutation,
} = recordingApiSlice;

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {VideoTagTypes} from "./videoApiSlice";

export const loginApiSlice = createApi({
	reducerPath: "api/session",
	baseQuery: fetchBaseQuery({baseUrl: `${process.env.REACT_APP_SERVER}/web`}),
	endpoints: (build) => ({
		login: build.mutation({
			query: (initialPost) => ({
				url: "/login",
				method: "POST",
				body: {
					username: initialPost.username,
					password: initialPost.password,
				},
			}),
		}),
		checkToken: build.query({
			query: (initialPost) => ("/login/" + initialPost.token)
		}),
		sendResetCode: build.mutation({
			query: (initialPost) => ({
				url: "/login/reset/mail",
				method: "POST",
				body: {
					email: initialPost.email,
					code: initialPost.code,
				},
			}),
		}),
		resetPassword: build.mutation({
			query: (initialPost) => ({
				url: "/login/reset/password",
				method: "POST",
				body: {
					email: initialPost.email,
					password: initialPost.password,
					code: initialPost.code,
				},
			}),
		}),
		signUp: build.mutation({
			query: (initialPost) => ({
				url: "/login/signup",
				method: "POST",
				body: {
					email: initialPost.email,
					password: initialPost.password,
					fullName: initialPost.fullName,
					companyName: initialPost.companyName,
					method: initialPost.method
				},
			}),
		}),
		getUserInfo: build.query({
			query: (initialPost) => ("/login/matrix/" + initialPost.token)
		}),
	}),
})


export const {
	useCheckTokenQuery,
	useLoginMutation,
	useSendResetCodeMutation,
	useResetPasswordMutation,
	useSignUpMutation,
	useGetUserInfoQuery,
} = loginApiSlice;

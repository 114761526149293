import React, { useEffect, useState, useRef } from "react";
import NewWindow from "react-new-window";
import ReactPlayer from "react-player";
// import AnimatedCursor from "react-animated-cursor";
import pointer from "../../../assets/pointer.png";
import Hotspot from "../../top-level/Hotspot";

const PopupWindow = (props) => {
  const [hotspotList, setHotspotList] = useState([]);
  const [selectedHotspot, setSelectedHotspot] = useState();
  const [showHotspot, setShowHotspot] = useState(false);
  const vidRef = useRef();
  useEffect(() => {
    if (props.coordinates) {
      const hotspots = props.coordinates.hotspots.map((el) => ({ ...el }));
      setHotspotList(hotspots);
      setShowHotspot(false);
    } else {
      setShowHotspot(false);
      setHotspotList([]);
    }
  }, [props.url]);

  const onHotspotClickHandler = () => {
    props.setPlaying(true);
    setShowHotspot(false);
  };

  const onEndedHandler = () => {
    const hotspots = props.coordinates.hotspots.map((el) => ({ ...el }));
    setHotspotList(hotspots);
    props.setPlaying(false);
  };

  const onProgressHandler = (e) => {
    let idxNewHotspot = -1;
    let newHotspotList = hotspotList.map((el) => ({ ...el }));
    /* Logic for displaying hotspots in realtime. */
    for (let i = 0; i < hotspotList.length; i++) {
      if (
        !hotspotList[i].shown &&
        e.playedSeconds >= hotspotList[i].appearAt - 0.5
      ) {
        if (e.playedSeconds - hotspotList[i].appearAt > 2) break;
        idxNewHotspot = i;
        newHotspotList = newHotspotList.map((el, ind) => {
          if (ind === i) {
            return { ...el, shown: true };
          }
          return { ...el };
        });
        // console.log(newHotspotList);
        setHotspotList(newHotspotList);
        break;
      }
    }
    if (idxNewHotspot !== -1) {
      setSelectedHotspot(idxNewHotspot);
      setShowHotspot(true);
      props.setPlaying(false);
    }
  };

  const onReadyHandler = () => {
    const vid = vidRef.current.getInternalPlayer();
    if (vid.duration === Infinity) {
      vid.currentTime = 1e101;
      vid.ontimeupdate = () => {
        vid.ontimeupdate = () => {
          return;
        };
        vid.currentTime = 0;
      };
    }
    // Sync volume change and mute button
    if (props.setIsMuted) {
      vid.addEventListener("volumechange", () => {
        if (!vid.volume) {
          props.setIsMuted(true);
        } else {
          if (vid.muted) {
            props.setIsMuted(true);
          } else {
            props.setIsMuted(false);
          }
        }
      });
    }
  };

  return (
    <NewWindow
      center="screen"
      name="Zerofied Demo"
      title="Zerofied Demo"
      features={{
        width:
          props.features.height > props.features.width
            ? props.features.width * 0.4
            : props.features.width,
        height:
          props.features.height > props.features.width
            ? props.features.height * 0.4
            : props.features.height,
      }}
      onUnload={props.onUnloadWindow}
    >
      <div
        className="flex flex-grow h-full"
        style={
          props.isToggledPointer
            ? { cursor: `url(${pointer}), auto` }
            : { cursor: "auto" }
        }
      >
        <ReactPlayer
          ref={vidRef}
          playbackRate={props.playbackRate}
          playsinline
          controls={true}
          playing={props.playing}
          onProgress={props.coordinates ? (e) => onProgressHandler(e) : null}
          onPause={() => props.setPlaying(false)}
          onPlay={() => props.setPlaying(true)}
          onReady={onReadyHandler}
          loop={props.coordinates ? false : true}
          width={"100%"}
          height={"100%"}
          muted={props.isMuted}
          onEnded={onEndedHandler}
          url={props.url}
        />
        {showHotspot && (
          <Hotspot
            tooltip={hotspotList[selectedHotspot].caption}
            tooltipColor={hotspotList[selectedHotspot].captionColor}
            hotspotColor={hotspotList[selectedHotspot].hotspotColor}
            percentageTop={hotspotList[selectedHotspot].percentageFromTop}
            percentageLeft={hotspotList[selectedHotspot].percentageFromLeft}
            onHotspotClickHandler={onHotspotClickHandler}
          />
        )}
        {/* {props.isToggledPointer && (<div className="relative"><AnimatedCursor
            innerSize={8}
            outerSize={8}
            color='193, 11, 111'
            outerAlpha={0.2}
            innerScale={0.7}
            outerScale={5}
          /></div>
        )} */}
      </div>
    </NewWindow>
  );
};

export default PopupWindow;

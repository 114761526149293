import React from "react";
import { Gating } from "./Gating";
export const Settings = ({
	verifyIdentity,
	setVerifyIdentity,
	customVerification,
	setCustomVerification,
	isOutbound,
	setIsOutbound,
	isRequired,
	setIsRequired,
	checkBlocklist,
	setCheckBlocklist,
	checkDomain,
	setCheckDomain,
	checkIntended,
	setCheckIntended,
	checkTwoFactor,
	setCheckTwoFactor,
}) => {
	return (
		<>
			<div className="flex flex-col gap-y-[32px] mt-[32px]">
				<Gating
					verifyIdentity={verifyIdentity}
					setVerifyIdentity={setVerifyIdentity}
					customVerification={customVerification}
					setCustomVerification={setCustomVerification}
					isRequired={isRequired}
					isOutbound={isOutbound}
					setIsOutbound={setIsOutbound}
					setIsRequired={setIsRequired}
					checkBlocklist={checkBlocklist}
					setCheckBlocklist={setCheckBlocklist}
					checkDomain={checkDomain}
					setCheckDomain={setCheckDomain}
					checkIntended={checkIntended}
					setCheckIntended={setCheckIntended}
					checkTwoFactor={checkTwoFactor}
					setCheckTwoFactor={setCheckTwoFactor}
				/>
			</div>
		</>
	);
};

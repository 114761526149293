import queryString from 'query-string';
import {useHistory, useLocation} from "react-router-dom";
import {PageSpinner} from "../top-level/PageSpinner";
import {useGetUserInfoQuery} from "../../features/api/loginApiSlice";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setSessionData} from "../../features/state/session/sessionDataSlice";
import {setFlags, setQuestionLibrary, setWebsiteStyle} from "../../features/state/user-settings/userSettingSlice";

export const MigrateLogin = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const parsed = queryString.parse(location.search);
	const {data, isFetching, isError} = useGetUserInfoQuery({token: parsed?.token})

	useEffect(() => {
		if (!isFetching) {
			if (data.status === "error") {
				return history.push("/login");
			}
			const sessionData = {
				sellerId: data.payload.id,
				companyId: data.payload.companyId,
				token: data.payload.token,
				name: data.payload.name,
				companyName: data.payload.companyName,
				userName: data.payload.userName,
			};
			dispatch(setSessionData(sessionData));
			dispatch(
				setQuestionLibrary({
					questionLibraryLink: data.payload.companyQuestionLibraryLink,
				})
			);
			dispatch(
				setFlags({
					flags: data.payload.flags,
				})
			);
			dispatch(
				setWebsiteStyle({
					websiteStyle: data.payload.companyWebsiteStyle,
				})
			);
			history.push("/");
		}
	}, [data, isFetching])
	if (!parsed?.token || isError) history.push("/login");

	return <PageSpinner/>
}

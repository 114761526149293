import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import pauseicon from "../../../icons/pause-icon.svg";
import playicon from "../../../icons/play-icon.svg";
import dividericon from "../../../icons/divider-icon.svg";
import pointericon from "../../../icons/pointer-icon.svg";
import pointercuticon from "../../../icons/pointercut-icon.svg";
import muteicon from "../../../icons/mute-icon.svg";
import unmuteicon from "../../../icons/unmute-icon.svg";
import closecrossicon from "../../../icons/closecross-icon.svg";
import ReactTooltip from "react-tooltip";
import ControlsModal from "../../controls-modal/ControlsModal";
import { DEMO } from "../../../data/constants";
import {embedSelect} from "../../../utils/reactSelectStyles";

const ControlPanel = ({
  open,
  onClose,
  setPlaybackRate,
  playing,
  setPlaying,
  popupId,
  setPopupUrl,
  recordings,
  videos,
  isMuted,
  setIsMuted,
  isToggledPointer,
  setIsToggledPointer,
}) => {
  const playback = [1, 1.5, 2, 0.5, 0.75];
  const [currentPlayback, setCurrentPlayback] = useState(1);
  const [i, setI] = useState(0);
  const options = [];

  const handlePlayPause = () => {
    setPlaying((prevPlaying) => !prevPlaying);
  };
  recordings.map((it, i) => {
    options.push({
      key: it.id,
      value: i,
      location: it.location,
      label: it.title,
      coordinates: it.coordinates,
    });
  });
  // videos.map((it, i) => {
  //   options.push({ key: it.id, value: i, location: it.url, label: it.title });
  // });
  const playbackClickHandler = () => {
    const n = playback.length;
    const val = playback[(((i + 1) % n) + n) % n];
    setCurrentPlayback(val);
    setPlaybackRate(val);
    setI((i) => i + 1);
  };
  return (
    <ControlsModal open={open} onClose={onClose}>
      <ReactTooltip effect="solid" place="bottom" />
      <div className="flex flex-row items-center justify-between mx-5">
        <button
          onClick={handlePlayPause}
          data-tip={playing ? "Pause" : "Play"}
          className="border border-white hover:bg-[#F8F8F8] hover:rounded-md hover:border hover:border-[#D2D2D2]"
        >
          <img
              src={playing ? pauseicon : playicon}
              alt="pause/play"
              className="w-[2.5rem] h-[2.5rem]"
          />
        </button>
        <img src={dividericon} alt="|" className="w-[2rem] h-[2rem]" />
        <button
          onClick={playbackClickHandler}
          className="border border-white hover:bg-[#F8F8F8] text-lg hover:rounded-md hover:border hover:border-[#D2D2D2] w-[3rem] h-[2.5rem]"
        >
          {`${currentPlayback}x`}
        </button>
        <img src={dividericon} alt="|" className="w-[2rem] h-[2rem]" />
        <button
          onClick={() => setIsToggledPointer(!isToggledPointer)}
          data-tip={isToggledPointer ? "Show mouse" : "Hide mouse"}
          className="border border-white hover:bg-[#F8F8F8] hover:rounded-md hover:border hover:border-[#D2D2D2]"
        >
          <img
              src={isToggledPointer ? pointercuticon : pointericon}
              alt="Cursor Icon"
              className="w-[2.5rem] h-[2.5rem]"
          />
        </button>
        <img src={dividericon} alt="|" className="w-[2rem] h-[2rem]" />
        <button
          onClick={() => setIsMuted((isMuted) => !isMuted)}
          data-tip={isMuted ? "Unmute" : "Mute"}
          className="border border-white hover:bg-[#F8F8F8] hover:rounded-md hover:border hover:border-[#D2D2D2]"
        >
          <img src={isMuted ? muteicon : unmuteicon} alt="Mute/Unmute" className="w-[2.5rem] h-[2.5rem]"/>
        </button>
        <img src={dividericon} alt="|" className="w-[2rem] h-[2rem]" />
        <Select
          className="w-64"
          styles={embedSelect}
          components={makeAnimated()}
          options={options}
          placeholder={`Select ${DEMO}`}
          value={options.filter((option) => option.key === popupId)}
          onChange={(el) => setPopupUrl(el.location, el.key, el.coordinates)}
          isSearchable
        />
        <img src={dividericon} alt="|" className="w-[2rem] h-[2rem]" />
        <button onClick={onClose}>
          <img
            src={closecrossicon}
            alt="Close"
            className="w-[2.6rem] h-[2.6rem]"
          />
        </button>
      </div>
    </ControlsModal>
  );
};

export default ControlPanel;

import {Chart, LinearScale, Tooltip} from "chart.js";
import {Flow, SankeyController} from "chartjs-chart-sankey";
import React, {useEffect, useRef, useState} from "react";
import {Chart as ChartJS} from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

export const Sankey = ({id, cyoaData, sankeyData, sankeyColumn, sendAmplitudeEvent}) => {
    Chart.register(LinearScale, SankeyController, Flow, Tooltip, zoomPlugin);
    const [adventureName] = useState(cyoaData.filter(it => it.id === id)[0].name);
    const chartRef = useRef(null);
    const chartOptions = {
        responsive: true,
        plugins: {
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy'
                },
            },
					tooltip: {
						backgroundColor: "#FFF",
						borderColor: "#D9D9D9",
						borderWidth: 1,
						bodyColor: "#737373",
						bodyFont: {
							size: 15,
							weight: 400,
							lineHeight: "15px",
							family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
						},
						titleColor: "#444444",
						titleMarginBottom: 8,
						titleFont: {
							weight: 600,
							size: 15,
							lineHeight: "15px",
							family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
						},
						cornerRadius: 11,
						padding: 12,
						displayColors: false,
						callbacks: {
							title() {
								return '';
							},
							label(context) {
								const item = context.dataset.data[context.dataIndex];
								return [item.from, ' -> ' + item.to, item.flow];
							}
						},
					}
        },
    }

    const colors = [
        '#1F77B4',
        '#FF7F0E',
        '#2CA02C',
        '#D62728',
        '#9467BD',
        '#8C564B',
        '#E377C2',
        '#7F7F7F',
        '#BCBD22'
    ];

    const catColors = {};

    const getColor = (item) => {
        if (!catColors[item]) {
            catColors[item] = colors[sankeyColumn[item] % 9];
        }
        return catColors[item];
    };

    const zoomIn = () => {
        chartRef.current.zoom(1.1)
        sendAmplitudeEvent("AnalyticsSankeyZoomInClicked");
    }

    const zoomOut = () => {
        chartRef.current.zoom(0.9)
        sendAmplitudeEvent("AnalyticsSankeyZoomOutClicked");
    }

    const resetZoom = () => {
        chartRef.current.resetZoom()
        sendAmplitudeEvent("AnalyticsSankeyZoomResetClicked");
    }

    return (
        <>
            <div className="flex flex-col gap-y-4">
                <div className="flex flex-row w-full h-[3rem] items-center justify-around border-b border-[#D7D7D7]">
                    <div className="flex flex-row items-center gap-x-[0.75rem] flex-grow">
                        <p className="ml-[1.13rem] font-medium">
                            Customer Journey
                        </p>
                        <div className="rounded-full bg-[#A3A3A3] w-[0.25rem] h-[0.25rem]"/>
                        <p className="text-[#255CA4]">
                            {adventureName}
                        </p>
                    </div>
                    <div
                        className={sankeyData.length !== 0 ? "flex flex-row w-[5.81rem] h-[1.69rem] mr-[2.5rem] font-roboto" : "hidden"}>
                        <button
                            className="flex justify-center items-center text-[#1D60B5] bg-[#F1F7FF] p-[6px] rounded-bl-xl rounded-tl-xl border border-[#63A4FA]"
                            onClick={() => zoomIn()}>+
                        </button>
                        <button
                            className="flex justify-center items-center text-[#1D60B5] text-[13px] bg-[#F1F7FF] border-[#63A4FA] border-t border-b p-[6px]"
                            onClick={() => resetZoom()}>Reset
                        </button>
                        <button
                            className="flex justify-center items-center text-[#1D60B5] bg-[#F1F7FF] p-[6px] rounded-br-xl rounded-tr-xl border border-[#63A4FA]"
                            onClick={() => zoomOut()}>-
                        </button>
                    </div>
                </div>
                <div className="flex justify-center items-center w-full">
                    {
                        (sankeyData.length !== 0)
                            ?
                            <div className="flex-grow">
                                <ChartJS
                                    ref={chartRef}
                                    type={"sankey"}
                                    data={
                                        {
                                            datasets: [
                                                {
                                                    data: sankeyData,
                                                    colorFrom: (c) => getColor(c.dataset.data[c.dataIndex].from),
                                                    colorTo: (c) => getColor(c.dataset.data[c.dataIndex].to),
                                                    colorMode: "gradient",
                                                    borderWidth: 0,
                                                    size: "max",
                                                    column: sankeyColumn,
                                                }
                                            ]
                                        }
                                    }
                                    options={chartOptions}/>
                            </div>
                            :
                            <div className="flex justify-center items-center w-[98%] my-16">
                                <p className="font-semibold">No Data To Display</p>
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

import {configureStore} from "@reduxjs/toolkit";
import reduxCookiesMiddleware from "redux-cookies-middleware";

// Slices
import {formsApiSlice} from "./features/api/formsApiSlice";
import {relationshipApiSlice} from "./features/api/relationshipApiSlice";
import {loginApiSlice} from "./features/api/loginApiSlice";
import {mappingApiSlice} from "./features/api/mappingApiSlice";
import {videoApiSlice} from "./features/api/videoApiSlice";
import {videoAnalyticsApiSlice} from "./features/api/videoAnalyticsApiSlice";
import {demoApiSlice} from "./features/api/demoApiSlice";
import {analyticApiSlice} from "./features/api/analyticApiSlice";
import {settingsApiSlice} from "./features/api/settingsApiSlice";
import {pagesApiSlice} from "./features/api/pagesApiSlice";
import {recordingApiSlice} from "./features/api/recordingsApiSlice";
import {heroApiSlice} from "./features/api/heroApiSlice";
import {soloApiSlice} from "./features/api/soloApiSlice";
import {sneakpeekApiSlice} from "./features/api/sneakpeekApiSlice";
import {cyoaApiSlice} from "./features/api/cyoaApiSlice";
import {previewApiSlice} from "./features/api/previewApiSlice";
import {contactsApiSlice} from "./features/api/contactsApiSlice";
import sessionDataReducer from "./features/state/session/sessionDataSlice";
import userSettingReducer from "./features/state/user-settings/userSettingSlice";

// Cookies
import {paths} from "./features/cookies-paths/paths";
import {dashboardApiSlice} from "./features/api/dashboardApiSlice";
import {linkedInApiSlice} from "./features/api/linkedInApiSlice";

export default configureStore({
	reducer: {
		[formsApiSlice.reducerPath]: formsApiSlice.reducer,
		[mappingApiSlice.reducerPath]: mappingApiSlice.reducer,
		[videoApiSlice.reducerPath]: videoApiSlice.reducer,
		[relationshipApiSlice.reducerPath]: relationshipApiSlice.reducer,
		[loginApiSlice.reducerPath]: loginApiSlice.reducer,
		[videoAnalyticsApiSlice.reducerPath]: videoAnalyticsApiSlice.reducer,
		[demoApiSlice.reducerPath]: demoApiSlice.reducer,
		[analyticApiSlice.reducerPath]: analyticApiSlice.reducer,
		[settingsApiSlice.reducerPath]: settingsApiSlice.reducer,
		[pagesApiSlice.reducerPath]: pagesApiSlice.reducer,
		[recordingApiSlice.reducerPath]: recordingApiSlice.reducer,
		[heroApiSlice.reducerPath]: heroApiSlice.reducer,
		[soloApiSlice.reducerPath]: soloApiSlice.reducer,
		[sneakpeekApiSlice.reducerPath]: sneakpeekApiSlice.reducer,
		[cyoaApiSlice.reducerPath]: cyoaApiSlice.reducer,
		[previewApiSlice.reducerPath]: previewApiSlice.reducer,
		[contactsApiSlice.reducerPath]: contactsApiSlice.reducer,
		[dashboardApiSlice.reducerPath]: dashboardApiSlice.reducer,
		[linkedInApiSlice.reducerPath]: linkedInApiSlice.reducer,
		sessionData: sessionDataReducer,
		userSetting: userSettingReducer,
	},
	devTools: process.env.NODE_ENV === 'development',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(formsApiSlice.middleware)
			.concat(mappingApiSlice.middleware)
			.concat(videoApiSlice.middleware)
			.concat(relationshipApiSlice.middleware)
			.concat(loginApiSlice.middleware)
			.concat(videoAnalyticsApiSlice.middleware)
			.concat(demoApiSlice.middleware)
			.concat(analyticApiSlice.middleware)
			.concat(settingsApiSlice.middleware)
			.concat(pagesApiSlice.middleware)
			.concat(recordingApiSlice.middleware)
			.concat(heroApiSlice.middleware)
			.concat(soloApiSlice.middleware)
			.concat(sneakpeekApiSlice.middleware)
			.concat(cyoaApiSlice.middleware)
			.concat(previewApiSlice.middleware)
			.concat(contactsApiSlice.middleware)
			.concat(dashboardApiSlice.middleware)
			.concat(linkedInApiSlice.middleware)
			.concat(reduxCookiesMiddleware(paths)),
});

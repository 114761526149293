import React, {
	useEffect,
	useState,
} from "react";
import {toast} from "react-toastify";
import Select from "react-select";
import cyaDeleteIcon from "../../../icons/cya-delete-icon.svg";
import externalLinkIcon from "../../../icons/external-link.svg";
import crossIcon from "../../../icons/cross-icon.svg";
import checkBoxIcon from "../../../icons/question-checkbox-icon.svg";
import radioIcon from "../../../icons/question-radio-icon.svg";
import uuid from "react-uuid";
import {useSelector} from "react-redux";
import {embedSelect} from "../../../utils/reactSelectStyles";
import {toastError} from "../../custom-toast/toasts";

export const QuestionCard = ({index, fetchedQuestion, updateQuestionList, deleteEpisode}) => {
	const AnswerTypes = [
		{key: 0, label: "Checkbox", value: "checkbox", image: checkBoxIcon},
		{key: 1, label: "Multiple Choice", value: "radiogroup", image: radioIcon},
		// {label: "Dropdown", value: "dropdown"},
	];
	const questionLibraryLink = useSelector(
		(state) => state.userSetting.questionLibraryLink
	);
	const [type, setType] = useState({
		label: "Checkbox",
		value: "checkbox",
		image: checkBoxIcon,
	});
	const [newChoice, setNewChoice] = useState("");
	const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

	useEffect(() => {
		if (fetchedQuestion.type !== undefined && fetchedQuestion.type !== null) {
			const filtered = AnswerTypes.filter(it => it.value === fetchedQuestion.type);
			if (filtered.length !== 0) setType(filtered[0])
		}
	}, [fetchedQuestion]);

	const addNewChoice = () => {
		if (newChoice === "") {
			toastError("Please enter a choice");
			return;
		}

		if (fetchedQuestion.choices?.some((it) => it === newChoice)) {
			toastError("This choice already exists");
			return;
		}

		updateQuestionList(index, "choice", (fetchedQuestion.choices !== undefined) ? [...fetchedQuestion.choices, newChoice] : [newChoice]);
		setNewChoice("");
	};

	const removeChoice = (content) => {
		updateQuestionList(index, "choice", fetchedQuestion.choices.filter((item) => item !== content));
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			addNewChoice();
		}
	};

	let currIndex = -1;
	const externalLinkLogo = <img src={externalLinkIcon} alt="link"/>;
	return (
		<div
			className={
				`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px]`
			}
		>
			<div
				className={
					"flex items-center justify-between h-[2.38rem] bg-transparent border-[#DEE8ED] border-b"
				}
			>
				<p className="text-[#255CA4] text-[18px] leading-[18px] mx-[3.938rem]">
					Ask Question
				</p>
				<button
					className="flex items-center justify-center mr-[2.063rem]"
					onClick={() => deleteEpisode(index)}
				>
					<img src={cyaDeleteIcon} alt="delete" className="w-5 h-5"/>
				</button>
			</div>
			<div
				className={`flex flex-row bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px]`}
			>
				{/*<div className={showIndex ? "flex items-center w-[50px]" : "hidden"}>*/}
				{/*	{!isLive ? (*/}
				{/*		<img src={dragHandle} alt="drag" className="ml-[9px]"/>*/}
				{/*	) : null}*/}
				{/*</div>*/}
				<div className={"flex items-center my-[1.25rem] ml-[3.125rem]"}>
					<div className="flex flex-col space-y-[18px] justify-center">
						<div className="flex gap-x-4 items-center">
							<input
								type="text"
								className="flex text-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] leading-[15px]"
								value={fetchedQuestion.name}
								onChange={(e) => updateQuestionList(index, "name", e.target.value)}
								placeholder="Type your question"
							/>
							{questionLibraryLink?.length > 0 && (
								<p
									className="flex gap-x-2 items-center text-sm text-[#686868] cursor-pointer hover:underline"
									onClick={() =>
										window.open(`${questionLibraryLink}`, "_blank").focus()
									}
								>
									<span> What should I ask?</span>
									<span>{externalLinkLogo}</span>
								</p>
							)}
						</div>
						<Select
							className="w-[262px] h-[38px]"
							options={AnswerTypes}
							placeholder="Select Answer Type"
							value={type}
							onChange={(chosen) =>
							{
								setType(chosen);
								updateQuestionList(index, "type", chosen.value);
							}}
							styles={embedSelect}
							isSearchable
							formatOptionLabel={(option) => (
								<div className="gap-x-[18px] flex flex-row">
									<img src={option.image} alt="icons"/>
									<span>{option.label}</span>
								</div>
							)}
						/>
						<div className="flex flex-col space-y-[8px]">
							{fetchedQuestion.choices?.map((content, i) => {
								currIndex = i;
								return (
									<div
										key={i}
										className="flex flex-row gap-x-[0.5rem] items-center content-center"
									>
										<div className="flex justify-center items-center w-[13px] h-[14px]">
											<p className="text-[14px] leading-[14px] text-[#7D7D7D]">
												{`${alphabets[i]}.`}
											</p>
										</div>
										<input
											className="flex text-[13px] leading-[13px] text-[#828282] px-[12px] rounded-[4px] border border-[#BFCACF] w-[20.88rem] h-[1.88rem]"
											placeholder="Press Enter to add a new choice"
											value={content}
											disabled={true}
											onChange={(e) => setNewChoice(e.target.value)}
											onKeyDown={handleKeyDown}
										/>
										<button
											className="flex items-center ml-[24px]"
											onClick={() => removeChoice(content)}
										>
											<img src={crossIcon} alt="cross" className="w-5 h-5"/>
										</button>
									</div>
								);
							})}
							{type !== "text" && (
								<div className="flex flex-row gap-x-[0.5rem] items-center content-center">
									<div className="flex justify-center items-center w-[13px] h-[14px]">
										<p className="text-[14px] leading-[14px] text-[#7D7D7D]">
											{`${alphabets[currIndex === -1 ? 0 : currIndex + 1]}.`}
										</p>
									</div>
									<input
										className="flex text-[13px] leading-[13px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[20.88rem] h-[1.88rem] focus:outline-none focus:border-[#222222]"
										placeholder="Press Enter to add a new choice"
										value={newChoice}
										onChange={(e) => setNewChoice(e.target.value)}
										onKeyDown={handleKeyDown}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import React, { useState, useEffect } from "react";
import { EmbedBrowserCard } from "../EmbedBrowserCard";
import Modal from "react-modal";
import StyledButton from "../../../top-level/StyledButton";
import closecrossicon from "../../../../icons/closecross-icon.svg";
import copyIcon from "../../../../icons/copy-icon.svg";
import { EMBED } from "../../../../data/constants";
import { customStyles } from "../../../../utils/modalStyles";

export const EmbedBrowserCardContainer = ({
	solo,
	hero,
	cyoa,
	copyWidget,
	deleteSolo,
	deleteHero,
	deleteCyoa,
}) => {
	const [openSoloPopup, setOpenSoloPopup] = useState(false);
	const [soloCode, setSoloCode] = useState("");
	const [openHeroPopup, setOpenHeroPopup] = useState(false);
	const [heroCode, setHeroCode] = useState("");
	const [cyoaCode, setCyoaCode] = useState("");
	const [openCyoaPopup, setOpenCyoaPopup] = useState(false);
	const [copyMessage, setCopyMessage] = useState("Click to copy this code");
	const [embeds, setEmbeds] = useState([]);

	useEffect(() => {
		if (hero && solo && cyoa) {
			const sortedArr = [
				...hero.map((element) => ({
					...element,
					jsx: (
						<EmbedBrowserCard
							data={element}
							embedType={"Hero"}
							openEmbed={openHeroEmbed}
							copyWidget={copyWidget}
							deleteWidget={deleteHero}
						/>
					),
				})),
				...solo.map((element) => ({
					...element,
					jsx: (
						<EmbedBrowserCard
							data={element}
							embedType={"Solo"}
							openEmbed={openSoloEmbed}
							copyWidget={copyWidget}
							deleteWidget={deleteSolo}
						/>
					),
				})),
				...cyoa.map((element) => ({
					...element,
					jsx: (
						<EmbedBrowserCard
							data={element}
							embedType={"Choose Your Adventure"}
							openEmbed={openCyoaEmbed}
							copyWidget={copyWidget}
							deleteWidget={deleteCyoa}
						/>
					),
				})),
			].sort((a, b) => {
				return new Date(b.date_created) - new Date(a.date_created);
			});
			setEmbeds(sortedArr);
		}
	}, [solo, hero, cyoa]);

	const openSoloEmbed = (element) => {
		setOpenSoloPopup(true);
		setSoloCode(
			`<div id="zeros-sw-${element.id}"${
				JSON.stringify(element.is_sneak) === "1"
					? ' style="display: inline-block"'
					: ""
			}><script>(function (w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "sw${
				element.id
			}", "${process.env.REACT_APP_SOLO}/widget.js"); sw${
				element.id
			}("init",{element: document.getElementById('zeros-sw-${
				element.id
			}'), solo: ${element.id}, debug: false, mode: "light"});</script></div>`
		);
	};

	const openCyoaEmbed = (element) => {
		setOpenCyoaPopup(true);
		setCyoaCode(
			`<div id="zeros-cyoaw-${element.id}"${
				JSON.parse(element.settings).isSneak
					? ' style="display: inline-block"'
					: ""
			}><script>(function (w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "cyoaw${
				element.id
			}", "${process.env.REACT_APP_CYOA}/widget.js"); cyoaw${
				element.id
			}("init",{element: document.getElementById('zeros-cyoaw-${
				element.id
			}'), cyoa: ${element.id}, debug: false, mode: "dark"});</script></div>`
		);
	};

	const openHeroEmbed = (id) => {
		setOpenHeroPopup(true);
		setHeroCode(
			`<div id="zeros-hw-${id}"><script>(function (w, d, s, o, f, js, fjs){w[o]=w[o] || function (){(w[o].q=w[o].q || []).push(arguments);}; (js=d.createElement(s)), (fjs=d.getElementsByTagName(s)[0]); js.id=o; js.src=f; js.async=1; fjs.parentNode.insertBefore(js, fjs);})(window, document, "script", "hw${id}", "${process.env.REACT_APP_HERO}/widget.js"); hw${id}("init",{element: document.getElementById('zeros-hw-${id}'), hero: ${id}, debug: false, mode: "light", headline:{font: "Verdana", fontSize: 22, color: "#000000"}, description:{font: "Verdana", fontSize: 16, color: "#000000"}, action:{font: "Verdana", fontSize: 14, color: "#0000ff"}, styles: {background: "#f7f7f7", classNameContainer: "", classNameLeftHalf: "", classNameHeadline: "", classNameDescription: "", classNameAction: "" }});</script></div>`
		);
	};

	return (
		<>
			<div className="flex flex-row grid grid-cols-4 gap-x-[1.5rem] gap-y-[1.5rem] justify-center">
				{embeds.map((element, i) => {
					return <div key={i}>{element.jsx}</div>;
				})}
			</div>

			<Modal
				isOpen={openSoloPopup}
				onRequestClose={() => {
					setOpenSoloPopup(false);
					setCopyMessage("Click to copy this code");
				}}
				style={customStyles}
			>
				<div className="w-[43.5rem]">
					<form className="flex flex-col mx-[4.5rem] mt-[2.25rem] mb-[4.5rem]">
						<div className="flex flex-row-reverse">
							<button
								onClick={() => {
									setOpenSoloPopup(false);
									setCopyMessage("Click to copy this code");
								}}
							>
								<img
									src={closecrossicon}
									alt="Close"
									className="w-[2.6rem] h-[2.6rem]"
								/>
							</button>
						</div>
						<div
							className="flex flex-col justify-center mt-[2px] gap-y-[1.125rem]"
							controlId="formBasicEmail"
						>
							<p className="flex justify-center text-[28px] font-medium">
								Copy this {EMBED} Code
							</p>
							<div
								className="relative font-inter w-[34.38rem] px-[1.44rem] py-[1.38rem] rounded-[0.5rem] border-2 font-normal text-xs group"
								onClick={(e) => {
									e.preventDefault();
									setCopyMessage("Code Copied!");
									navigator.clipboard.writeText(soloCode);
								}}
							>
								<p>{soloCode}</p>
								<div className="absolute inset-0 z-10 bg-white text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
									<div className="flex flex-row gap-x-[1rem]">
										<img src={copyIcon} alt="copy" className="w-6 h-6" />
										<h1 className="text-sm text-[#7D7D7D] font-medium font-inter blur-0">
											{copyMessage}
										</h1>
									</div>
								</div>
							</div>
							<div className="bg-[#FDECD2] rounded-[0.5rem]">
								<p className="text-xs leading-[20.4px] font-normal mx-[0.375rem] my-[0.5rem] font-inter">
									The {EMBED.toLowerCase()} expands to fill in the given space,
									you may bound its parent container at your own discretion.
								</p>
							</div>
						</div>
					</form>
				</div>
			</Modal>
			<Modal
				isOpen={openHeroPopup}
				onRequestClose={() => {
					setOpenHeroPopup(false);
					setCopyMessage("Click to copy this code");
				}}
				style={customStyles}
			>
				<div className="w-[43.5rem]">
					<form className="flex flex-col mx-[4.5rem] mt-[2.25rem] mb-[4.5rem]">
						<div className="flex flex-row-reverse">
							<button
								onClick={() => {
									setOpenHeroPopup(false);
									setCopyMessage("Click to copy this code");
								}}
							>
								<img
									src={closecrossicon}
									alt="Close"
									className="w-[2.6rem] h-[2.6rem]"
								/>
							</button>
						</div>
						<div
							className="flex flex-col justify-center mt-[2px] gap-y-[1.125rem]"
							controlId="formBasicEmail"
						>
							<p className="flex justify-center text-[28px] font-medium">
								Copy this {EMBED} Code
							</p>
							<div
								className="relative font-inter w-[34.38rem] px-[1.44rem] py-[1.38rem] rounded-[0.5rem] border-2 font-normal text-xs group"
								onClick={(e) => {
									e.preventDefault();
									setCopyMessage("Code Copied!");
									navigator.clipboard.writeText(heroCode);
								}}
							>
								<p>{heroCode}</p>
								<div className="absolute inset-0 z-10 bg-white text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
									<div className="flex flex-row gap-x-[1rem]">
										<img src={copyIcon} alt="copy" className="w-6 h-6" />
										<h1 className="text-sm text-[#7D7D7D] font-medium font-inter blur-0">
											{copyMessage}
										</h1>
									</div>
								</div>
							</div>
							<div className="bg-[#FDECD2] rounded-[0.5rem]">
								<p className="text-xs leading-[20.4px] font-normal mx-[0.375rem] my-[0.5rem] font-inter">
									The {EMBED.toLowerCase()} expands to fill in the given space,
									you may bound its parent container at your own discretion.
								</p>
							</div>
						</div>
					</form>
				</div>
			</Modal>
			<Modal
				isOpen={openCyoaPopup}
				onRequestClose={() => {
					setOpenCyoaPopup(false);
					setCopyMessage("Click to copy this code");
				}}
				style={customStyles}
			>
				<div className="w-[43.5rem]">
					<form className="flex flex-col mx-[4.5rem] mt-[2.25rem] mb-[4.5rem]">
						<div className="flex flex-row-reverse">
							<button
								onClick={() => {
									setOpenCyoaPopup(false);
									setCopyMessage("Click to copy this code");
								}}
							>
								<img
									src={closecrossicon}
									alt="Close"
									className="w-[2.6rem] h-[2.6rem]"
								/>
							</button>
						</div>
						<div
							className="flex flex-col justify-center mt-[2px] gap-y-[1.125rem]"
							controlId="formBasicEmail"
						>
							<p className="flex justify-center text-[28px] font-medium">
								Copy this {EMBED} Code
							</p>
							<div
								className="relative font-inter w-[34.38rem] px-[1.44rem] py-[1.38rem] rounded-[0.5rem] border-2 font-normal text-xs group"
								onClick={(e) => {
									e.preventDefault();
									setCopyMessage("Code Copied!");
									navigator.clipboard.writeText(cyoaCode);
								}}
							>
								<p>{cyoaCode}</p>
								<div className="absolute inset-0 z-10 bg-white text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
									<div className="flex flex-row gap-x-[1rem]">
										<img src={copyIcon} alt="copy" className="w-6 h-6" />
										<h1 className="text-sm text-[#7D7D7D] font-medium font-inter blur-0">
											{copyMessage}
										</h1>
									</div>
								</div>
							</div>
							<div className="bg-[#FDECD2] rounded-[0.5rem]">
								<p className="text-xs leading-[20.4px] font-normal mx-[0.375rem] my-[0.5rem] font-inter">
									The {EMBED.toLowerCase()} expands to fill in the given space,
									you may bound its parent container at your own discretion.
								</p>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		</>
	);
};

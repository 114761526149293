import React, {useEffect, useRef, useState} from "react";
import {CYARuleCard} from "../../embed/components/editor/components/CYAComponents/CYARuleCard";
import {Spinner} from "../../top-level/Spinner";
import {toast} from "react-toastify";

export const Mapping = ({mapping, setMapping, questionList, demoOptions, mappingRef, isShown, setIsShown, triggerRefresh}) => {
	const [questions, setQuestions] = useState([]);
	const [answers, setAnswers] = useState([]);

	useEffect(() => {
		const qList = [];
		const aList = [];
		if (questionList !== null) {
			questionList.forEach(it => {
				if (it.data?.choices === undefined) return
				qList.push({index: it.id, data: it.data.name, type: it.data.type});
				aList.push({index: it.id, answers: it.data.choices});
			})
			setQuestions(qList);
			setAnswers(aList);
		}
	}, [questionList])

	const addRule = () => {
		if (!mapping) return setMapping([{ id: 1, data: null}]);
		const mappingList = [...mapping];
		const sortedMappingList = [...mappingList];
		sortedMappingList.sort((a, b) => a.id - b.id);
		const id =
			sortedMappingList.length !== 0
				? sortedMappingList[sortedMappingList.length - 1].id + 1
				: 1;
		mappingList.push({ id: id, data: null});
		setMapping(mappingList);
	}

	const deleteRule = (id) => {
		const filteredMappingList = mapping.filter(it => it.id !== id)
		setMapping(filteredMappingList);
	}

	useEffect(() => {
		setTimeout(() => setIsShown(true), 500);
	}, [triggerRefresh])

	return (
		<div className="h-full w-full">
			{
				isShown ?
					<div className="flex flex-col gap-y-[32px] my-[32px]">
						{
							mapping?.map(it => {
								return (
									<div key={it.id}>
										<CYARuleCard
											ref={(el) =>
												(mappingRef.current[it.id] = el)
											}
											index={it.id}
											questionList={questions}
											answerList={answers}
											demoOptions={demoOptions}
											rule={it.data}
											ruleToDelete={deleteRule}
											isLive={true}
											isPage={true}
										/>
									</div>
								)
							})
						}
						<div className={"flex items-center justify-center"} onClick={() => addRule()}>
							<button className="border border-[#63A4FA] bg-[#F1F7FF] text-[#1D60B5] text-[14px] leading-[14px] w-[130px] h-[32px] rounded-[10px]">
								+ Add Rule
							</button>
						</div>
					</div>
					:
					<div className="flex h-full w-full justify-center items-center">
						<Spinner/>
					</div>
			}
		</div>
	)
}

import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {PageEditStandardCustomized} from "./PageEditStandardCustomized";
import {PageSpinner} from "../../top-level/PageSpinner";
import {PageEditEmbed} from "./PageEditEmbed";

export const PageTypeRouter = () => {
	const {id, live, type} = useParams();
	let renderPageEditor = <PageSpinner/>;
	switch (type) {
		case "customized":
			renderPageEditor = <PageEditStandardCustomized type={type} id={id} live={live}/>
			break;
		case "standard":
			renderPageEditor = <PageEditStandardCustomized type={type} id={id} live={live}/>
			break;
		case "embed":
			renderPageEditor = <PageEditEmbed type={type} id={id} live={live}/>
			break;
	}
	return (
		<>
			{
				renderPageEditor
			}
		</>
		)
}

import {MaterialSwitch} from "../../../../../custom/MaterialSwitch";
import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {embedSelect} from "../../../../../../utils/reactSelectStyles";
import crossIcon from "../../../../../../icons/cross-icon.svg";

export const CYARecommendationsCard = ({showRecommendations, setShowRecommendations, recommendationsList, setRecommendationsList, demoOptions}) => {
	let currentIndex = 0;

	const setNewRecommendation = (index, value) => {
		const copyList = JSON.parse(JSON.stringify(recommendationsList));
		copyList[index] = value;
		setRecommendationsList(copyList)
	}

	const addNewRecommendation = () => {
		const keys = Object.keys(recommendationsList);
		const copyList = JSON.parse(JSON.stringify(recommendationsList));
		copyList[keys[keys.length - 1] + 1] = null;
		setRecommendationsList(copyList)
	}

	const recommendationToDelete = (index) => {
		const newObject = {}
		Object.values(recommendationsList).forEach((it,i) => {
			if (i !== index) {
				newObject[i] = it;
			}
		})
		setRecommendationsList(newObject)
	}

	return (
		<div className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px]`}>
			<div className={"flex items-center justify-start h-[2.38rem] bg-transparent border-[#DEE8ED] border-b"}>
				<p className="text-[#255CA4] text-[18px] leading-[18px] mx-[3.938rem]">
					Wrap-up Screen
				</p>
			</div>
			<div className={`flex flex-row bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px]`}>
				<div className="flex flex-col mx-[50px] gap-y-[18px] my-[20px]">
					<div className="flex flex-row items-center gap-x-[0.5rem]">
						<MaterialSwitch color={"primary"} checked={showRecommendations} onClick={() => setShowRecommendations(!showRecommendations)}/>
						<p className={`font-light text-[#222222] text-[15px] ml-[12px]`}>
							Show recommendations at the end
						</p>
					</div>
					<div className="flex flex-col gap-y-[18px] mx-[62px]">
						{
							Object.values(recommendationsList).map((it, i) => {
								const keys = Object.keys(recommendationsList);
								currentIndex = i;
									return (
										<div key={i} className="flex flex-row gap-x-[19px]">
											<Select
												className="w-[316.5px]"
												value={it}
												components={makeAnimated()}
												options={demoOptions}
												styles={embedSelect}
												placeholder={`Select demo ${i + 1}`}
												onChange={(e) => setNewRecommendation(keys[i], e)}
												isDisabled={!showRecommendations}
												autoFocus
												isSearchable
											/>
											<button className={(i > 0) ? "flex justify-center items-center" : "hidden"} onClick={() => recommendationToDelete(i)}>
												<img src={crossIcon} alt="close" className="w-5 h-5"/>
											</button>
										</div>
									)
								}
							)
						}
					</div>
					<div className={(currentIndex < 3) ? "flex mx-[82px]" : "hidden"}>
						<button className={`${showRecommendations ? "text-[#1D60B5]" : "text-[#828282]"} text-[14px] hover:underline`}
										disabled={!showRecommendations}
										onClick={() => addNewRecommendation()}>
							+ Add more demo
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

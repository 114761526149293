import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import "react-tabs/style/react-tabs.css";
import { useGetRecordingQuery } from "../../../../../features/api/recordingsApiSlice";
import {
	useCreateHeroMutation,
	useGetHeroByIdQuery,
	useUpdateHeroMutation,
} from "../../../../../features/api/heroApiSlice";
import { PageSpinner } from "../../../../top-level/PageSpinner";
import { DEMO, EMBED } from "../../../../../data/constants";
import HeroForm from "./components/HeroForm";
import {
	toastError,
	toastLoading,
	toastSuccess,
} from "../../../../custom-toast/toasts";

const HeroEditor = ({ id, isNew, setId, setIsNew }) => {
	const sellerName = useSelector((state) => state.sessionData.name);
	const sellerId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const flags = useSelector((state) => JSON.parse(state.userSetting.flags));
	const [blob, setBlob] = useState();
	const [blobUrl, setBlobUrl] = useState();

	let initialValues = {
		name: "",
		headline: "",
		description: "",
		label: "",
		link: "",
		show_preview: "0",
		show_custom_thumbnail: "0",
		custom_thumbnail: "",
		is_muted: "0",
		show_controls: "1",
		is_gated: flags.gating.checked ? "1" : "0",
		blinker_style: "PLAY",
		launch_triangle_color: "#FFFFFF",
		launch_circle_color: "#1E41AF",
		launch_hover_color: "1E3B8A",
		launch_hotspot_color: "#1E41AF",
		demo: "",
		redirect_to_restricted_domain: flags.gating.embed.verify.blocklist.redirect
			.checked
			? "1"
			: "0",
		live: "0",
		custom_verification: flags.gating.embed.verify.checked ? "1" : "0",
		email_required: flags.gating.embed.required.checked ? "1" : "0",
		check_blocklist: flags.gating.embed.verify.blocklist.checked ? "1" : "0",
		check_domain: flags.gating.embed.verify.domain.checked ? "1" : "0",
		check_twofactor: flags.gating.embed.verify.twofactor.checked ? "1" : "0",
		verification_content: {
			title: "Enter your email address to continue",
			description: "Enter your email address below to access the content",
			placeholder: "",
		},
		restricted_redirect: {
			restrictType: "demo",
			demo: null,
			message: {
				title: "",
				description: "",
				primaryButton: false,
				buttonLabel: "",
				URL: "",
			},
		},
	};

	const history = useHistory();
	const [createHero] = useCreateHeroMutation();

	const { data, isLoading, isFetching } = useGetHeroByIdQuery({
		id: id,
	});
	const {
		data: recData,
		isLoading: recIsLoading,
		isFetching: recIsFetching,
	} = useGetRecordingQuery({
		companyId: companyId,
		limit:1000,
		pageNo:1
	});

	const [updateHero] = useUpdateHeroMutation();

	if (isLoading || isFetching || recIsFetching || recIsLoading)
		return <PageSpinner text="Loading Hero.." />;

	if (!isNew) {
		initialValues = {
			name: data.payload[0].name,
			description: data.payload[0].description,
			headline: data.payload[0].headline,
			label: data.payload[0].action_label,
			link: data.payload[0].action_link,
			show_preview: JSON.stringify(data.payload[0].show_preview),
			show_custom_thumbnail: JSON.stringify(
				data.payload[0].show_custom_thumbnail
			),
			custom_thumbnail: data.payload[0].custom_thumbnail,
			show_controls: JSON.stringify(data.payload[0].show_controls),
			is_muted: JSON.stringify(data.payload[0].is_muted),
			is_gated: JSON.stringify(data.payload[0].is_gated),
			redirect_to_restricted_domain: JSON.stringify(
				data.payload[0].redirect_to_restricted_domain
			),
			blinker_style: data.payload[0].blinker_style,
			launch_triangle_color:
				JSON.parse(data.payload[0].launch_button)?.triangle_color ?? "#FFFFFF",
			launch_circle_color:
				JSON.parse(data.payload[0].launch_button)?.circle_color ?? "#1E41AF",
			launch_hover_color:
				JSON.parse(data.payload[0].launch_button)?.hover_color ?? "#1E3B8A",
			launch_hotspot_color:
				JSON.parse(data.payload[0].launch_button)?.hotspot_color ?? "#1E41AF",
			demo: data.payload[0].recording_id,
			live: JSON.stringify(data.payload[0].live),
			custom_verification: JSON.stringify(data.payload[0].custom_verification),
			email_required: JSON.stringify(data.payload[0].email_required),
			check_blocklist: JSON.stringify(data.payload[0].check_blocklist),
			check_domain: JSON.stringify(data.payload[0].check_domain),
			check_twofactor: JSON.stringify(data.payload[0].check_twofactor),
			verification_content: JSON.parse(data.payload[0].verification_content)
				? JSON.parse(data.payload[0].verification_content)
				: {
						title: "Enter your email address to continue",
						description: "Enter your email address below to access the content",
						placeholder: "",
				  },
			restricted_redirect: JSON.parse(data.payload[0].restricted_redirect)
				? JSON.parse(data.payload[0].restricted_redirect)
				: {
						restrictType: "demo",
						demo: null,
						message: {
							title: "",
							description: "",
							primaryButton: false,
							buttonLabel: "",
							URL: "",
						},
				  },
		};
	}

	const heroSchema = Yup.object().shape({
		name: Yup.string().required("Title is required"),
		headline: Yup.string()
			.required("Headline is required")
			.max(40, "Cannot be more than 40 charactres"),
		label: Yup.string()
			.required("Label is required")
			.max(50, "Cannot be more than 50 characters"),
		link: Yup.string().required("URL is required"),
		show_preview: Yup.boolean(),
		show_controls: Yup.boolean(),
		is_muted: Yup.boolean(),
		is_gated: Yup.boolean(),
		description: Yup.string()
			.required("Description is required")
			.max(500, "Cannot be more than 500 characters"),
	});

	const addNewHero = async (values) => {
		if (
			values.name === "" ||
			values.description === "" ||
			values.headline === "" ||
			values.label === "" ||
			values.link === "" ||
			values.demo === ""
		) {
			toastError("Incomplete Data");
		} else {
			const alert = toastLoading("Please wait...");
			const heroData = {
				salesrep_id: salesRepId,
				company_id: companyId,
				recording_id: +values.demo,
				name: values.name,
				headline: values.headline,
				description: values.description,
				action_label: values.label,
				action_link: values.link,
				show_preview: values.show_preview,
				show_custom_thumbnail: values.show_custom_thumbnail,
				show_controls: values.show_controls,
				is_muted: values.is_muted,
				is_gated: values.is_gated,
				blinker_style: values.blinker_style,
				launch_button: JSON.stringify({
					triangle_color: values.launch_triangle_color,
					circle_color: values.launch_circle_color,
					hover_color: values.launch_hover_color,
					hotspot_color: values.launch_hotspot_color,
				}),
				date_created: new Date().toISOString().slice(0, 19).replace("T", " "),
				date_modified: new Date().toISOString().slice(0, 19).replace("T", " "),
				live: values.live,
				verification_content: JSON.stringify(values.verification_content),
				custom_verification: values.custom_verification,
				email_required: values.email_required,
				check_blocklist: values.check_blocklist,
				check_domain: values.check_domain,
				check_twofactor: values.check_twofactor,
				redirect_to_restricted_domain: values.redirect_to_restricted_domain,
				restricted_redirect: JSON.stringify(values.restricted_redirect),
			};
			const fd = new FormData();
			fd.append("heroData", JSON.stringify(heroData));
			if (blob && values.show_custom_thumbnail) {
				fd.append("image", blob);
			}
			const status = await createHero({ fd }).unwrap();
			if (status.status === "success") {
				setId(status.payload);
				toast.dismiss(alert);
				toastSuccess(`Hero ${EMBED} Added`);
				setIsNew(false);
				// history.goBack();
			} else {
				toast.dismiss(alert);
				toastError(`Error adding hero ${EMBED.toLowerCase()}`);
			}
		}
	};

	const updateExistingHero = async (values) => {
		if (
			values.name === "" ||
			values.description === "" ||
			values.headline === "" ||
			values.label === "" ||
			values.link === "" ||
			values.demo === ""
		) {
			toastError("Incomplete Data");
		} else {
			const alert = toastLoading("Saving changes");
			const heroData = {
				name: values.name,
				headline: values.headline,
				description: values.description,
				action_label: values.label,
				action_link: values.link,
				show_preview: values.show_preview,
				show_custom_thumbnail: values.show_custom_thumbnail,
				show_controls: values.show_controls,
				is_muted: values.is_muted,
				is_gated: values.is_gated,
				blinker_style: values.blinker_style,
				launch_button: JSON.stringify({
					triangle_color: values.launch_triangle_color,
					circle_color: values.launch_circle_color,
					hover_color: values.launch_hover_color,
					hotspot_color: values.launch_hotspot_color,
				}),
				heroId: +id,
				recording_id: +values.demo,
				date_modified: new Date().toISOString().slice(0, 19).replace("T", " "),
				live: values.live,
				verification_content: JSON.stringify(values.verification_content),
				custom_verification: values.custom_verification,
				email_required: values.email_required,
				check_blocklist: values.check_blocklist,
				check_domain: values.check_domain,
				check_twofactor: values.check_twofactor,
				redirect_to_restricted_domain: values.redirect_to_restricted_domain,
				restricted_redirect: JSON.stringify(values.restricted_redirect),
			};
			const fd = new FormData();
			fd.append("heroData", JSON.stringify(heroData));
			if (blob && values.show_custom_thumbnail) {
				fd.append("image", blob);
			}
			const status = await updateHero({ heroId: +id, fd }).unwrap();
			if (status.status === "success") {
				toast.dismiss(alert);
				toastSuccess(`Changes saved`);
				// history.goBack();
			} else {
				toast.dismiss(alert);
				toastError(`Error updating hero ${EMBED.toLowerCase()}`);
			}
		}
	};

	const cancelHandler = (e) => {
		e.preventDefault();
		history.goBack();
	};

	return (
		<div className="flex flex-col justify-center items-center">
			<ReactTooltip effect="solid" place="right" />
			<Formik
				initialValues={initialValues}
				validationSchema={heroSchema}
				onSubmit={
					!isNew
						? (values) => updateExistingHero(values)
						: (values) => addNewHero(values)
				}
				children={(props) => (
					<HeroForm
						{...props}
						isNew={isNew}
						data={data}
						recDataList={[...recData.payload].sort(
							(a, b) => new Date(b.date_created) - new Date(a.date_created)
						)}
						sellerName={sellerName}
						sellerId={sellerId}
						setBlob={setBlob}
						blobUrl={blobUrl}
						setBlobUrl={setBlobUrl}
					/>
				)}
			/>
		</div>
	);
};

export default HeroEditor;

import { createSlice } from "@reduxjs/toolkit";

const sessionDataSlice = createSlice({
  name: "session/data",
  initialState: {
    sellerId: null,
    companyId: null,
    token: null,
    name: "",
    companyName: "",
		userName: "",
    expandedNavbar: true,
  },
  reducers: {
    setSessionData: (state, action) => {
      state.sellerId = action.payload.sellerId;
      state.companyId = action.payload.companyId;
      state.token = action.payload.token;
      state.name = action.payload.name;
      state.companyName = action.payload.companyName;
			state.userName = action.payload.userName;
    },
    setExpandedNavbar: (state, action) => {
      state.expandedNavbar = action.payload.expandedNavbar;
    },
  },
});

const { actions, reducer } = sessionDataSlice;
export const { setSessionData, setExpandedNavbar } = actions;
export default reducer;

//General Utility Functions

//This is the third step in our journey
//We create a relation between our current node (episode) and the episodes that can be displayed after it (refer to second step)
const returnNode = (startNode, episode, analyticsData, currIndex, nextIndex) => {
	switch (episode.episode) {
		case "demo":
			return {
				from: `${startNode} (${currIndex + 1})`,
				to: `${episode.demo.label} (${nextIndex + 1})`,
				flow: returnDemoWeights(analyticsData, currIndex, nextIndex)
			}
		case "question":
			return {
				from: `${startNode} (${currIndex + 1})`,
				to: `${episode.question} (${nextIndex + 1})`,
				flow: returnQuestionWeights(analyticsData, currIndex, nextIndex)
			}
		case "modal":
			return {
				from: `${startNode} (${currIndex + 1})`,
				to: `${episode.title} (${nextIndex + 1})`,
				flow: returnModalWeights(analyticsData, currIndex, nextIndex)
			}
		case "pdf":
			return {
				from: `${startNode} (${currIndex + 1})`,
				to: `${episode.pdf.title} (${nextIndex + 1})`,
				flow: returnPdfWeights(analyticsData, currIndex, nextIndex)
			}
		default:
			console.log("Unknown Node Type", episode.episode);
			return null;
	}
}

const returnAnswerNode = (startNode, question, episode, analyticsData, currIndex, nextIndex) => {
	switch (episode.episode) {
		case "demo":
			return {
				from: startNode,
				to: episode.demo.label,
				flow: returnAnswerWeights(analyticsData, episode.episode, question, startNode, currIndex, nextIndex)
			};
		case "question":
			return {
				from: startNode,
				to: episode.question,
				flow: returnAnswerWeights(analyticsData, episode.episode, question, startNode, currIndex, nextIndex)
			}
		case "modal":
			return {
				from: startNode,
				to: episode.title,
				flow: returnAnswerWeights(analyticsData, episode.episode, question, startNode, currIndex, nextIndex)
			}
		default:
			console.log("Unknown Answer Node Type", episode.episode);
			return null;
	}
}

//This is the secondary step (and a very important step) in our journey
//We determine if the next episode is a rule
//If it is a rule, we save a reference to it and move on
//Once we either reach the end of the episodes or an episode which is not a rule,
//We stop and return the rules list along with the non-rule episode
const returnNextRuleList = (episodeData, index) => {
	const ruleList = [];
	const nonRuleEpisode = [];
	for (let i = index + 1; i < episodeData.length; i++) {
		if (episodeData[i].episode === "rule") {
			ruleList.push(episodeData[i].result);
		} else {
			nonRuleEpisode.push(episodeData[i]);
			break;
		}
	}
	return {rules: ruleList, nonRule: nonRuleEpisode}
}

//Weight Calculation Functions

//This is the fourth step in our journey
//We filter out all the events related to the episode
//We then check how many people went from this node (episode) to the next node (episode)
//That value becomes the weight for that node edge
//We can either filter on load events or unload events but not both at the same time
//I use load for our purpose here
const returnDemoWeights = (analyticsData, currIndex, nextIndex) => {
	const demoEvents = analyticsData.filter(it => it.episode === "demo" && JSON.parse(it.event).type === "load");
	let weight = 0;
	demoEvents.forEach((it) => {
		const parsedEvent = JSON.parse(it.event);
		if (parsedEvent.prevIndex === currIndex && parsedEvent.currIndex === nextIndex) {
			weight += 1;
		}
	})
	return weight;
}

const returnQuestionWeights = (analyticsData, currIndex, nextIndex) => {
	const questionEvents = analyticsData.filter(it => it.episode === "question" && JSON.parse(it.event).type === "load");
	let weight = 0;
	questionEvents.forEach((it) => {
		const parsedEvent = JSON.parse(it.event);
		if (parsedEvent.prevIndex === currIndex && parsedEvent.currIndex === nextIndex) {
			weight += 1;
		}
	})
	return weight;
}

const returnAnswerWeights = (analyticsData, type, question, choice, currIndex, nextIndex) => {
	let weight = 0;
	const answerEvents = analyticsData.filter(it => it.episode === type && JSON.parse(it.event).type === "load");
	answerEvents.forEach((it) => {
		const parsedEvent = JSON.parse(it.event);
		if (parsedEvent.prevIndex === currIndex && parsedEvent.currIndex === nextIndex) {
			const prevChoice = parsedEvent.allPrevChoices[question];
			if (prevChoice !== undefined) {
				if (prevChoice.includes(choice)) {
					weight += 1;
				}
			}
		}
	})
	return weight;
}

const returnPdfWeights = (analyticsData, currIndex, nextIndex) => {
	const pdfEvents = analyticsData.filter(it => it.episode === "pdf" && JSON.parse(it.event).type === "load");
	let weight = 0;
	pdfEvents.forEach((it) => {
		const parsedEvent = JSON.parse(it.event);
		if (parsedEvent.prevIndex === currIndex && parsedEvent.currIndex === nextIndex) {
			weight += 1;
		}
	})
	return weight;
}

const returnQuestionAnswerWeights = (analyticsData, choice) => {
	const questionEvents = analyticsData.filter(it => it.episode === "question" && JSON.parse(it.event).type === "unload");
	const choiceEvents = questionEvents.filter(it => JSON.parse(it.event).selectedChoices.includes(choice));
	return choiceEvents.length;
}

const returnModalWeights = (analyticsData, currIndex, nextIndex) => {
	const modalEvents = analyticsData.filter(it => it.episode === "modal" && JSON.parse(it.event).type === "load");
	let weight = 0;
	modalEvents.forEach((it) => {
		const parsedEvent = JSON.parse(it.event);
		if (parsedEvent.prevIndex === currIndex && parsedEvent.currIndex === nextIndex) {
			weight += 1;
		}
	})
	return weight;
}

const returnModalCTAWeights = (analyticsData, title, CTA) => {
	const modalEvents = analyticsData.filter(it => it.episode === "modal" && JSON.parse(it.event).type === "click");
	let weight = 0;
	modalEvents.forEach((it) => {
		const parsedEvent = JSON.parse(it.event);
		if (parsedEvent.title === title && parsedEvent.ctaLabel === CTA) {
			weight += 1;
		}
	})
	return weight;
}

const returnExitWeights = (analyticsData, index) => {
	let weight = 0;
	const exitEvents = analyticsData.filter(it => JSON.parse(it.event).type === "exit");
	exitEvents.forEach((it) => {
		const parsedEvent = JSON.parse(it.event);
		if (parsedEvent.currIndex === index) {
			weight += 1;
		}
	})
	return weight;
}

const returnWrapUpWeights = (analyticsData) => {
	return analyticsData.filter(it => it.episode === "wrapup" && JSON.parse(it.event).type === "load").length;
}

//Generate Episode nodes and edges
const demoEpisode = (episode, episodeData, analyticsData, index) => {
	const startNode = episode.demo.label;
	const nextNodes = returnNextRuleList(episodeData, index);
	const result = [];
	if (nextNodes.rules.length !== 0) {
		nextNodes.rules.forEach((it, i) => {
			const ruleNode = returnNode(startNode, it, analyticsData, index, (index + i + 1));
			if (ruleNode !== null) {
				result.push(ruleNode);
			}
		});
	}
	if (nextNodes.nonRule.length !== 0) {
		const node = returnNode(startNode, nextNodes.nonRule[0], analyticsData, index, (index + nextNodes.rules.length + 1));
		if (node !== null) {
			result.push(node);
		}
	}
	if (nextNodes.rules.length === 0 && nextNodes.nonRule.length === 0) {
		result.push({from: `${startNode} (${index + 1})`, to: "Wrap up screen", flow: returnWrapUpWeights(analyticsData)});
	}
	// result.push({from: startNode, to: "Exit", flow: returnExitWeights(analyticsData, index)});d
	return result;
}

const questionEpisode = (episode, episodeData, analyticsData, index) => {
	const startNode = episode.question;
	const nextNodes = returnNextRuleList(episodeData, index);
	const result = [];
	if (nextNodes.rules.length !== 0) {
		nextNodes.rules.forEach((it, i) => {
			const ruleNode = returnNode(startNode, it, analyticsData, index, (index + i + 1));
			if (ruleNode !== null) {
				result.push(ruleNode);
			}
		});
	}
	if (nextNodes.nonRule.length !== 0) {
		const node = returnNode(startNode, nextNodes.nonRule[0], analyticsData, index, (index + nextNodes.rules.length + 1));
		if (node !== null) {
			result.push(node);
		}
	}
	if (nextNodes.rules.length === 0 && nextNodes.nonRule.length === 0) {
		result.push({from: `${startNode} (${index + 1})`, to: "Wrap up screen", flow: returnWrapUpWeights(analyticsData)});
	}
	// result.push({from: startNode, to: "Exit", flow: returnExitWeights(analyticsData, index)});
	return result;
}

const modalEpisode = (episode, episodeData, analyticsData, index) => {
	const startNode = episode.title;
	const CTA = [{label: episode.firstCTA.label, action: episode.firstCTA.action}, {
		label: episode.secondCTA.label,
		action: episode.secondCTA.action
	}];
	const nextNodes = returnNextRuleList(episodeData, index);
	const result = []
	CTA.forEach((it) => {
		if (it.label === "") return
		result.push({
			from: `${startNode} (${index + 1})`,
			to: `${it.label} (${index + 1})`,
			flow: returnModalCTAWeights(analyticsData, startNode, it.label)
		});
	});
	if (nextNodes.rules.length !== 0) {
		nextNodes.rules.forEach((it, i) => {
			CTA.forEach((el) => {
				if (el.label !== "" && el.action === "next") {
					const ruleNode = returnNode(el.label, it, analyticsData, index, (index + i + 1));
					if (ruleNode !== null) {
						result.push(ruleNode);
					}
				}
			})
		});
	}
	if (nextNodes.nonRule.length !== 0) {
		CTA.forEach((el) => {
			if (el.label !== "" && el.action === "next") {
				const node = returnNode(el.label, nextNodes.nonRule[0], analyticsData, index, (index + nextNodes.rules.length + 1));
				if (node !== null) {
					result.push(node);
				}
			}
		})
	}
	if (nextNodes.rules.length === 0 && nextNodes.nonRule.length === 0) {
		result.push({from: `${startNode} (${index + 1})`, to: "Wrap up screen", flow: returnWrapUpWeights(analyticsData)});
	}
	// result.push({from: startNode, to: "Exit", flow: returnExitWeights(analyticsData, index)});
	//Deferred to later when we have CTA exit events
	// CTA.forEach((el) => {
	//     result.push([el, "Exit", returnWeights()]);
	// })
	return result;
}

const pdfEpisode = (episode, episodeData, analyticsData, index) => {
	const startNode = episode.pdf.title;
	const nextNodes = returnNextRuleList(episodeData, index);
	const result = [];
	if (nextNodes.rules.length !== 0) {
		nextNodes.rules.forEach((it, i) => {
			const ruleNode = returnNode(startNode, it, analyticsData, index, (index + i + 1));
			if (ruleNode !== null) {
				result.push(ruleNode);
			}
		});
	}
	if (nextNodes.nonRule.length !== 0) {
		const node = returnNode(startNode, nextNodes.nonRule[0], analyticsData, index, (index + nextNodes.rules.length + 1));
		if (node !== null) {
			result.push(node);
		}
	}
	if (nextNodes.rules.length === 0 && nextNodes.nonRule.length === 0) {
		result.push({from: `${startNode} (${index + 1})`, to: "Wrap up screen", flow: returnWrapUpWeights(analyticsData)});
	}
	// result.push({from: startNode, to: "Exit", flow: returnExitWeights(analyticsData, index)});d
	return result;
}

const ruleEpisode = (episode, episodeData, analyticsData, index) => {
	switch (episode.result.episode) {
		case "demo":
			return demoEpisode(episode.result, episodeData, analyticsData, index);
		case "question":
			return questionEpisode(episode.result, episodeData, analyticsData, index);
		case "modal":
			return modalEpisode(episode.result, episodeData, analyticsData, index);
		case "pdf":
			return pdfEpisode(episode.result, episodeData, analyticsData, index);
		default:
			console.log("Unknown Episode Type");
			return [];
	}
}

//Our journey starts here
//We determine the episode type and send data to its respective function
export const findEpisodeType = (episode, episodeData, analyticsData, index) => {
	switch (episode.episode) {
		case "demo":
			return demoEpisode(episode, episodeData, analyticsData, index);
		case "question":
			return questionEpisode(episode, episodeData, analyticsData, index);
		case "rule":
			return ruleEpisode(episode, episodeData, analyticsData, index);
		case "modal":
			return modalEpisode(episode, episodeData, analyticsData, index);
		case "pdf":
			return pdfEpisode(episode, episodeData, analyticsData, index);
		default:
			console.log("Unknown Episode Type");
			return [];
	}
}

//Our journey ends here
//We determine the order (x-axis value) at which the nodes are to be shown
export const findColumnIndex = (episode, index, iteration) => {
	switch (episode.episode) {
		case "demo":
			return [{[`${episode.demo.label} (${iteration + 1})`]: index}, index + 1]
		case "question":
			return [{[`${episode.question} (${iteration + 1})`]: index}, index + 1]
		case "rule":
			return findColumnIndex(episode.result, index, iteration);
		case "modal":
			return [{
				[`${episode.title} (${iteration + 1})`]: index,
				[(episode.firstCTA.label !== "") ? `${episode.firstCTA.label} (${iteration + 1})` : ""]: index + 1,
				[(episode.secondCTA.label !== "") ? `${episode.secondCTA.label} (${iteration + 1})` : ""]: index + 1
			}, index + 2]
		case "pdf":
			return [{[`${episode.pdf.title} (${iteration + 1})`]: index}, index + 1]
		default:
			console.log("Unknown Episode Type");
			return [];
	}
}

//Old variant of question episode which also catered answers
// const questionEpisode = (episode, episodeData, analyticsData, index) => {
//     const startNode = episode.question;
//     const choices = episode.choices;
//     const nextNodes = returnNextRuleList(episodeData, index);
//     const result = []
//     choices.forEach((it) => {
//         result.push({from: startNode, to: it, flow: returnQuestionAnswerWeights(analyticsData, it)});
//     });
//     if (nextNodes.rules.length !== 0) {
//         nextNodes.rules.forEach((it, i) => {
//             choices.forEach((el) => {
//                 const ruleNode = returnAnswerNode(el, startNode, it, analyticsData, index, (index + i + 1));
//                 if (ruleNode !== null) {
//                     result.push(ruleNode);
//                 }
//             })
//         });
//     }
//     if (nextNodes.nonRule.length !== 0) {
//         choices.forEach((el) => {
//             const node = returnAnswerNode(el, startNode, nextNodes.nonRule[0], analyticsData, index, (index + nextNodes.rules.length + 1));
//             if (node !== null) {
//                 result.push(node);
//             }
//         })
//     }
//     result.push({from: startNode, to: "Exit", flow: returnExitWeights(analyticsData, index)});
//     return result;
// }

import {Sankey} from "../sankey/Sankey";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {Bar} from "react-chartjs-2";
import React from "react";
import {graphSelectionStyles} from "../../../../../utils/reactSelectStyles";
import {toastError, toastLoading} from "../../../../custom-toast/toasts";
import Papa from "papaparse";
import {toast} from "react-toastify";

export const CyaAnalytics = ({
															 selectedWidgets,
															 selectedProspect,
															 cyoaData,
															 sankeyData,
															 sankeyColumn,
															 selectedCyoaDemo,
															 setSelectedCyoaDemo,
															 demoDurationData,
															 demoDurationLabels,
															 selectedCyoaResponse,
															 cyoaResponseOptions,
															 setSelectedCyoaResponse,
															 responseData,
															 responseLabels,
															 pdfEngagementData,
															 pdfEngagementLabels,
															 cyoaDemoOptions,
															 sendAmplitudeEvent,
															 cyoaModalQuestionsAsked,
															 cyoaPdfOptions,
															 selectedCyoaPdf,
															 setSelectedCyoaPdf
														 }) => {
	const downloadLinkCSV = async (cyoaModalQuestionsAsked) => {
		toastLoading("Attempting download..");
		try {
			const csv = Papa.unparse(cyoaModalQuestionsAsked);

			const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
			let csvURL = null;
			if (navigator.msSaveBlob) {
				csvURL = navigator.msSaveBlob(csvData, `${selectedWidgets.label}.csv`);
			} else {
				csvURL = window.URL.createObjectURL(csvData);
			}

			let tempLink = document.createElement('a');
			tempLink.href = csvURL;
			tempLink.setAttribute('download', `${selectedWidgets.label}.csv`);
			tempLink.click();
			toast.dismiss();
		} catch (e) {
			toast.dismiss()
			toastError("Error downloading URLs")
		}
	}

	return (
		<>
			{selectedWidgets && selectedProspect && selectedWidgets.type === "cyoa" && (
				<div className="flex flex-col w-full rounded-[1rem] border border-[#D9D9D9]">
					<Sankey
						id={selectedWidgets.id}
						cyoaData={cyoaData.payload}
						sankeyData={sankeyData}
						sankeyColumn={sankeyColumn}
						sendAmplitudeEvent={sendAmplitudeEvent}
					/>
				</div>
			)}
			{selectedWidgets && selectedProspect && selectedWidgets.type === "cyoa" && (
				<div className="flex flex-col w-full rounded-[1rem] border border-[#D9D9D9] mt-[1.5rem]">
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-col w-full h-[5rem] items-around justify-center border-b border-[#D7D7D7]">
							<div className="flex flex-row items-center">
								<p className="ml-[1.13rem] mr-[0.75rem] font-medium">
									View Duration
								</p>
								<div className="rounded-full bg-[#A3A3A3] mr-1 w-[0.25rem] h-[0.25rem]"/>
								<Select
									styles={graphSelectionStyles}
									className="w-72 mt-[2px]"
									id="WidgetSelection"
									value={selectedCyoaDemo}
									components={makeAnimated()}
									options={cyoaDemoOptions}
									placeholder="Select Demo"
									onChange={(e) => {
										setSelectedCyoaDemo(e);
										localStorage.setItem('cyaDemo', JSON.stringify(e));
										sendAmplitudeEvent("AnalyticsWatchDurationSelected");
									}}
									isSearchable
								/>
							</div>
							<p className="ml-[1.13rem] text-[#7D7D7D] text-sm ">
								How much of the demo was watched?
							</p>
						</div>
						<div className="flex justify-center items-center w-full">
							{demoDurationData.length !== 0 ? (
								<div className="flex-grow">
									<Bar
										width={100}
										height={25}
										options={{
											maintainAspectRatio: true,
											plugins: {
												legend: {
													display: false,
												},
												tooltip: {
													backgroundColor: "#FFF",
													borderColor: "#D9D9D9",
													borderWidth: 1,
													bodyColor: "#737373",
													bodyFont: {
														size: 15,
														weight: 400,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													titleColor: "#444444",
													titleMarginBottom: 8,
													titleFont: {
														weight: 600,
														size: 15,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													cornerRadius: 11,
													padding: 12,
													displayColors: false,
													callbacks: {
														label: function (context) {
															return `View Duration:  ${context.parsed.y}`
														}
													}
												}
											},
											scales: {
												y: {
													beginAtZero: true,
													title: {
														display: true,
														text: "Sessions",
														font: {
															size: 15,
															family: "Proxima Nova",
															weight: 400,
														},
														color: "#7D7D7D",
														// padding: {
														// 	top: 22,
														// 	bottom: 40,
														// },
													},
													grid: {
														color: "#F0F0F0",
														borderWidth: 0,
													}
												},
												x: {
													title: {
														display: true,
														text: "How much of the video was watched?",
														font: {
															size: 15,
															family: "Proxima Nova",
															weight: 400,
														},
														color: "#7D7D7D",
														// padding: {
														// 	top: 20,
														// 	bottom: 22,
														// },
													},
													grid: {
														lineWidth: 0,
														color: "#F0F0F0",
														borderWidth: 0
													},
												},
											},
										}}
										data={{
											labels: demoDurationLabels,
											datasets: [
												{
													label: selectedCyoaDemo.label,
													data: demoDurationData,
													backgroundColor: "#1B85B6",
												},
											],
										}}
										type={"line"}
									/>
								</div>
							) : (
								<div className="flex justify-center items-center w-[98%] my-16">
									<p className="font-semibold">No Data To Display</p>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			{selectedWidgets && selectedProspect && selectedWidgets.type === "cyoa" && (
				<div className="flex flex-col w-full rounded-[1rem] border border-[#D9D9D9] mt-[1.5rem]">
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-col w-full h-[5rem] items-around justify-center border-b border-[#D7D7D7]">
							<div className="flex flex-row items-center">
								<p className="ml-[1.13rem] mr-[0.75rem] font-medium">
									PDF Engagement
								</p>
								<div className="rounded-full bg-[#A3A3A3] mr-1 w-[0.25rem] h-[0.25rem]"/>
								<Select
									styles={graphSelectionStyles}
									className="w-72 mt-[2px]"
									id="WidgetSelection"
									value={selectedCyoaPdf}
									components={makeAnimated()}
									options={cyoaPdfOptions}
									placeholder="Select PDF"
									onChange={(e) => {
										setSelectedCyoaPdf(e);
										sendAmplitudeEvent("AnalyticsPDFEngagementSelected");
									}}
									isSearchable
								/>
							</div>
							<p className="ml-[1.13rem] text-[#7D7D7D] text-sm ">
								How long was each page in the PDF viewed?
							</p>
						</div>
						<div className="flex justify-center items-center w-full">
							{pdfEngagementData.length !== 0 ? (
								<div className="flex-grow">
									<Bar
										width={100}
										height={25}
										options={{
											maintainAspectRatio: true,
											plugins: {
												legend: {
													display: false,
												},
												tooltip: {
													backgroundColor: "#FFF",
													borderColor: "#D9D9D9",
													borderWidth: 1,
													bodyColor: "#737373",
													bodyFont: {
														size: 15,
														weight: 400,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													titleColor: "#444444",
													titleMarginBottom: 8,
													titleFont: {
														weight: 600,
														size: 15,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													cornerRadius: 11,
													padding: 12,
													displayColors: false,
													callbacks: {
														label: function (context) {
															return `PDF Engagement:  ${context.parsed.y} seconds`
														}
													}
												}
											},
											scales: {
												y: {
													beginAtZero: true,
													title: {
														display: true,
														text: "Average time spent on a page",
														font: {
															size: 15,
															family: "Proxima Nova",
															weight: 400,
														},
														color: "#7D7D7D",
														// padding: {
														// 	top: 22,
														// 	bottom: 40,
														// },
													},
													grid: {
														color: "#F0F0F0",
														borderWidth: 0,
													}
												},
												x: {
													title: {
														display: true,
														text: "How long was each page in pdf viewed?",
														font: {
															size: 15,
															family: "Proxima Nova",
															weight: 400,
														},
														color: "#7D7D7D",
														// padding: {
														// 	top: 20,
														// 	bottom: 22,
														// },
													},
													grid: {
														lineWidth: 0,
														color: "#F0F0F0",
														borderWidth: 0
													},
												},
											},
										}}
										data={{
											labels: pdfEngagementLabels,
											datasets: [
												{
													label: selectedCyoaPdf.label,
													data: pdfEngagementData,
													backgroundColor: "#1B85B6",
												},
											],
										}}
										type={"line"}
									/>
								</div>
							) : (
								<div className="flex justify-center items-center w-[98%] my-16">
									<p className="font-semibold">No Data To Display</p>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			{selectedWidgets && selectedProspect && selectedWidgets.type === "cyoa" && (
				<div className="flex flex-col w-full rounded-[1rem] border border-[#D9D9D9] mt-[1.5rem]">
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-col w-full h-[5rem] items-around justify-center border-b border-[#D7D7D7]">
							<div className="flex flex-row items-center">
								<p className="ml-[1.13rem] font-medium mr-[0.75rem]">
									Responses
								</p>
								<div className="rounded-full bg-[#A3A3A3] mr-1 w-[0.25rem] h-[0.25rem]"/>
								<Select
									className="w-72 mt-[2px]"
									styles={graphSelectionStyles}
									id="WidgetSelection"
									value={selectedCyoaResponse}
									components={makeAnimated()}
									options={cyoaResponseOptions}
									placeholder="Select Question"
									onChange={(e) => {
										setSelectedCyoaResponse(e);
										sendAmplitudeEvent("AnalyticsResponsesSelected");
										localStorage.setItem('cyaResponse', JSON.stringify(e));
									}}
									isSearchable
								/>
							</div>
							<p className="ml-[1.13rem] text-[#7D7D7D] text-sm ">
								How many times was each response was given?
							</p>
						</div>
						<div className="flex justify-center items-center w-full">
							{responseData.length !== 0 ? (
								<div className="flex-grow">
									<Bar
										width={100}
										height={25}
										options={{
											indexAxis: "y",
											maintainAspectRatio: true,
											plugins: {
												legend: {
													display: false,
												},
												tooltip: {
													backgroundColor: "#FFF",
													borderColor: "#D9D9D9",
													borderWidth: 1,
													bodyColor: "#737373",
													bodyFont: {
														size: 15,
														weight: 400,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													titleColor: "#444444",
													titleMarginBottom: 8,
													titleFont: {
														weight: 600,
														size: 15,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													cornerRadius: 11,
													padding: 12,
													displayColors: false,
													callbacks: {
														label: function (context) {
															return `Responses:  ${context.parsed.x}`
														}
													}
												}
											},
											scales: {
												y: {
													beginAtZero: true,
													grid: {
														lineWidth: 0,
														color: "#F0F0F0",
														borderWidth: 0
													},
													title: {
														color: "#7D7D7D",
														font: {
															size: 14,
															family: "Proxima Nova",
															weight: 400,
														},
													},
												},
												x: {
													grid: {
														color: "#F0F0F0",
														borderWidth: 0
													}
												}
											},
										}}
										data={{
											labels: responseLabels,
											datasets: [
												{
													label: selectedCyoaResponse.label,
													data: responseData,
													backgroundColor: "#1B85B6",
												},
											],
										}}
										type={"line"}
									/>
								</div>
							) : (
								<div className="flex justify-center items-center w-[98%] my-16">
									<p className="font-semibold">No Data To Display</p>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			{selectedWidgets && selectedProspect && selectedWidgets.type === "cyoa" && (
				<div className="flex flex-col w-full rounded-[1rem] border border-[#D9D9D9] mt-[1.5rem]">
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-col w-full h-[5rem] items-around justify-center border-b border-[#D7D7D7]">
							<div className="flex flex-row items-center">
								<p className="ml-[1.13rem] font-medium mr-[0.75rem]">
									Questions from prospects
								</p>
							</div>
							<p className="ml-[1.13rem] text-[#7D7D7D] text-sm ">
								Actual question asked by the prospects
							</p>
						</div>
						<div className="flex justify-center items-center w-full">
							{cyoaModalQuestionsAsked ? (
								<div className="flex-grow justify-center items-center flex-row flex py-[30px] gap-x-[16px]">
									<p className={`text-[16px] leading-[16px] text-[#222222]`}>
										Download the list of questions
									</p>
									<button
										className={`flex justify-center items-center text-[15px] leading-[15px] text-[#0071F2] px-[10px] py-[5px] border border-[#0071F2] rounded-[5px]`}
										onClick={() => downloadLinkCSV(cyoaModalQuestionsAsked)}>
										Download CSV file
									</button>
								</div>
							) : (
								<div className="flex justify-center items-center w-[98%] my-16">
									<p className="font-semibold">No Data To Download</p>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { matchPath } from "react-router-dom";

import RecordingEditor from "./components/RecordingEditor/RecordingEditor";
import VideoEditor from "./components/VideoEditor";

const Editor = () => {
	const [isNew, setIsNew] = useState();
	const [id, setId] = useState();
	const [editor, setEditor] = useState();
	let selectedEditor = null;
	const location = useLocation();
	const params = useParams();

	useEffect(() => {
		const newRecordingMatch = matchPath(location.pathname, {
			path: "/feature/demos/recording",
			exact: true,
		});
		const editRecordingMatch = matchPath(location.pathname, {
			path: "/feature/demos/recording/:id",
			exact: true,
		});
		const editVideoMatch = matchPath(location.pathname, {
			path: "/feature/demos/video/:id",
			exact: true,
		});
		if (newRecordingMatch) {
			setEditor("NEW_RECORDING");
			setIsNew(true);
		} else if (editRecordingMatch) {
			setEditor("EDIT_RECORDING");
			setIsNew(false);
			setId(params.id);
		} else if (editVideoMatch) {
			setEditor("EDIT_VIDEO");
			setId(params.id);
		}
	}, []);

	switch (editor) {
		case "NEW_RECORDING":
			const mediaBlobUrl = location.state.mediaBlobUrl;
			const blob = location.state.blob;
			const type = location.state.type ?? "default";
			selectedEditor = (
				<RecordingEditor
					id={id}
					mediaBlobUrl={mediaBlobUrl}
					blob={blob}
					type={type}
					isNew={isNew}
					setId={setId}
					setIsNew={setIsNew}
				/>
			);
			break;
		case "EDIT_RECORDING":
			selectedEditor = <RecordingEditor id={id} isNew={isNew} />;
			break;
		case "EDIT_VIDEO":
			selectedEditor = <VideoEditor id={id} />;
			break;
		default:
			console.log("URL not found");
			break;
	}
	return selectedEditor;
};

export default Editor;

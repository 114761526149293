import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

export const FormTagTypes = {
	FORM: "forms",
};

export const formsApiSlice = createApi({
	reducerPath: "api/forms",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, {getState}) => {
			const token = getState().sessionData.token
			if (token) {
				headers.set('token', token)
			}
			return headers
		}
	}),
	tagTypes: Object.values(FormTagTypes),
	endpoints: builder => ({
		getForms: builder.query({
			query: (initialPost) => "form/" + initialPost.companyId,
			providesTags: [FormTagTypes.FORM],
			transformResponse(baseQueryReturnValue, meta) {
				return baseQueryReturnValue.payload;
			}
		}),
		createForm: builder.mutation({
			query: initialPost => ({
				url: "form/",
				method: "POST",
				body: {
					title: initialPost.title,
					formjson: initialPost.formJson,
					mapping: initialPost.mapping,
					company_id: initialPost.companyId,
					salesrep_id: initialPost.salesRepId
				},
			}),
			invalidatesTags: [FormTagTypes.FORM],
		}),
		updateForm: builder.mutation({
			query: initialPost => ({
				url: "form/" + initialPost.formId,
				method: "PUT",
				body: {
					title: initialPost.title,
					formjson: initialPost.formJson,
					mapping: initialPost.mapping,
				},
			}),
			invalidatesTags: [FormTagTypes.FORM],
		}),
		deleteForm: builder.mutation({
			query: initialPost => ({
				url: "form/" + initialPost.formId,
				method: "DELETE"
			}),
			invalidatesTags: [FormTagTypes.FORM]
		}),
		generatePublicLink: builder.mutation({
			query: initialPost => ({
				url: "/prospect/session",
				method: "POST",
				body: {
					name: initialPost.name,
					email: initialPost.email,
					pageId: initialPost.pageId,
					mappingData: initialPost.mappingData,
					salesrepId: initialPost.salesRepId,
					companyId: initialPost.companyId,
				}
			})
		}),
		generatePublicWebsiteLink: builder.mutation({
			query: initialPost => ({
				url: "/prospect/session/website",
				method: "POST",
				body: {
					pageId: initialPost.pageId,
					companyId: initialPost.companyId,
				}
			})
		}),
		sendFormByEmail: builder.mutation({
			query: initialPost => ({
				url: "/sendmailform",
				method: "POST",
				body: {
					name: initialPost.name,
					email: initialPost.email,
					message: initialPost.message,
					form_id: initialPost.formId,
				},
			})
		})
	})
})

export const {
	useGetFormsQuery,
	useCreateFormMutation,
	useUpdateFormMutation,
	useDeleteFormMutation,
	useGeneratePublicLinkMutation,
	useGeneratePublicWebsiteLinkMutation,
	useSendFormByEmailMutation
} = formsApiSlice;

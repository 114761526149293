import widgetIcon from "../../../icons/widgets-icon.svg";
import React, { useRef, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import moreIcon from "../../../icons/more-icon.svg";
import cloneIcon from "../../../icons/clone-icon.svg";
import deleteIcon from "../../../icons/delete-icon.svg";
import shareIcon from "../../../icons/share-icon.svg";
import DropdownIcon from "../../top-level/DropdownIcon";
import ReactTooltip from "react-tooltip";
import { getBackgroundColor } from "../../../utils/avatar";
import { GenerateWidgetPage } from "./GenerateWidgetPage";

export const EmbedBrowserCard = ({
	embedType,
	data,
	openEmbed,
	copyWidget,
	deleteWidget,
}) => {
	const [randomID] = useState(String(Math.random()));
	const [generateEmbedPage, setGenerateEmbedPage] = useState(false);
	const returnEmbedTypeColor = () => {
		switch (embedType) {
			case "Choose Your Adventure":
				return "text-[#246903] bg-[#DAF1E4]";
			case "Hero":
				return "text-[#49138D] bg-[#EDDEFF]";
			case "Solo":
				return "text-[#875102] bg-[#F2E1C7]";
			default:
				return "text-[#875102] bg-[#F2E1C7]";
		}
	};

	const moreSelectHandler = (value, element) => {
		switch (value) {
			case "embedPage":
				return setGenerateEmbedPage(true);
			case "clone":
				return embedType === "Choose Your Adventure"
					? copyWidget("Cyoa", data)
					: copyWidget(embedType, data);
			case "delete":
				return deleteWidget(element.id);
			default:
				console.log("Unknown choice in more dropdown");
				break;
		}
	};

	const returnEmbedTypeRoute = () => {
		switch (embedType) {
			case "Choose Your Adventure":
				return "cyoa";
			case "Hero":
				return "hero";
			case "Solo":
				return "solo";
			default:
				return "";
		}
	};

	return (
		<>
			<GenerateWidgetPage
				isOpen={generateEmbedPage}
				setIsOpen={setGenerateEmbedPage}
				widgetId={data.id}
				widgetType={returnEmbedTypeRoute()}
			/>
			<Link
				className="no-underline"
				to={`/feature/embeds/${returnEmbedTypeRoute()}/${data.id}`}
			>
				<ReactTooltip effect="solid" place="bottom" id={randomID} html={true} />
				<div className="relative flex flex-col w-[16.125rem] h-[13.063rem] rounded-[0.94rem] border border-[#D9D9D9] hover:shadow-lg cursor-pointer hover:-top-1 hover:transition-all hover:duration-200 hover:ease-linear">
					<div className="flex flex-col flex-grow">
						<div className="flex flex-row mt-[1rem] ml-[1rem] w-[14.13rem] h-[2rem]">
							<div className="flex justify-start items-center">
								<div
									className={`flex items-center justify-center ${returnEmbedTypeColor()} text-sm font-normal rounded-l-[12px] rounded-r-[12px]`}
								>
									<p className="my-[0.156rem] mx-[0.75rem]">{embedType}</p>
								</div>
							</div>
							<div className="flex flex-grow justify-end items-center">
								<div
									className="flex justify-center items-center text-white w-[2rem] h-[2rem] rounded-full"
									style={{
										backgroundColor: getBackgroundColor(data.salesrep_id),
									}}
								>
									{data.salesrep_name[0]}
								</div>
							</div>
						</div>
						<div className="flex flex-col w-[14.13rem] ml-[1rem] mt-[1.5rem]">
							<p
								className="font-medium text-[15px] overflow-ellipsis"
								data-tip={data.name}
							>
								{data.name.trim().length !== 0 ? data.name : "N/A"}
							</p>
							<p className="text-[#888888] font-medium text-[14px]">
								{new Date(data.date_created).toLocaleDateString("en-us", {
									year: "numeric",
									month: "long",
									day: "numeric",
								})}
							</p>
							<div className="flex flex-row items-center gap-x-[5px] h-[20px]">
								<div
									className={`h-[10px] w-[10px] ${
										data.live ? "bg-[#4CB051]" : "bg-[#A4A4A4]"
									} rounded-full`}
								/>
								<p className="text-[12px] text-[#222222] leading-[12px] mt-[3px]">
									{data.live ? "Active" : "Inactive"}
								</p>
							</div>
						</div>
					</div>
					<div className="flex flex-row h-[3.13rem] w-[16.13rem] border-t justify-between">
						<div
							className="flex"
							data-tip={
								!data.live
									? "<div class='flex flex-col justify-center items-center'><p>Publish the embed to</p><p>copy the code</p></div>"
									: null
							}
							data-for={randomID}
						>
							<button
								className="flex flex-row items-center w-[5.56rem] h-[1.75rem] rounded-[0.63rem] ml-[1rem] mt-[0.69rem] disabled:border disabled:border-[#D9D9D9] disabled:bg-[#FAFAFA] border-[#63A4FA] border bg-[#F1F7FF] hover:bg-white"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									openEmbed(embedType === "Hero" ? data.id : data);
								}}
								disabled={!data.live}
							>
								<img
									src={widgetIcon}
									alt="embedIcon"
									className={`w-4 h-4 ml-[0.75rem] ${
										!data.live ? "svg-embed-icon-gray" : "svg-embed-icon-blue"
									}`}
								/>
								<p
									className={`ml-[0.5rem] ${
										!data.live ? "text-[#7D7D7D]" : "text-[#1D60B5]"
									} text-[13px] pt-[0.8px]`}
								>
									Embed {/* Not changing this to dynamic naming */}
								</p>
							</button>
						</div>
						<div
							className="flex justify-center items-center mr-[1rem]"
							data-tip={"More"}
							data-for={randomID}
						>
							<DropdownIcon
								className="border border-white hover:bg-[#F8F8F8] hover:rounded-md hover:border hover:border-[#D2D2D2]"
								titleIcon={moreIcon}
								options={[
									{
										key: 1,
										value: "embedPage",
										label: "Share in a page",
										icon: shareIcon,
										element: data,
									},
									{
										key: 2,
										value: "clone",
										label: "Clone",
										icon: cloneIcon,
										element: data,
									},
									{
										key: 3,
										value: "delete",
										label: "Delete",
										icon: deleteIcon,
										element: data,
									},
								]}
								onSelectHandler={moreSelectHandler}
							/>
						</div>
					</div>
				</div>
			</Link>
		</>
	);
};

import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";

import {
  useGetVideoByIdQuery,
  useUpdateVideoMutation,
} from "../../../../../features/api/videoApiSlice";
import { PageSpinner } from "../../../../top-level/PageSpinner";
import StyledButton from "../../../../top-level/StyledButton";
import "./VideoEditor.css";
import {toastError} from "../../../../custom-toast/toasts";

const VideoEditor = ({ id }) => {
  const history = useHistory();

  const [updateVideo] = useUpdateVideoMutation();
  const { data, isLoading, isError, error } = useGetVideoByIdQuery({
    id: id,
  });

  if (isLoading) return <PageSpinner text="Loading Video.." />;

  const initialValues = {
    title: data.payload[0].title,
    description: data.payload[0].description,
  };

  const videoSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });

  const updateExistingVideo = async (values) => {
    if (values.title === "" || values.description === "") {
      toastError("Incomplete Data");
    } else {
      const videoData = {
        title: values.title,
        description: values.description,
        videoId: id,
      };
      const status = await updateVideo(videoData).unwrap();
      if (status.status === "success") {
        // toastSuccess("Video Updated");
        history.goBack();
      } else {
        toastError("Error updating video");
      }
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <div className="flex flex-col flex-grow-1 shadow-inner space-y-1 justify-center">
      <div className="mx-auto"></div>
      <Formik
        initialValues={initialValues}
        validationSchema={videoSchema}
        onSubmit={(values) => updateExistingVideo(values)}
      >
        {(formik) => {
          const { isValid, dirty } = formik;
          const classSubmit =
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
          return (
            <div className="w-full mx-auto">
              <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="title"
                  >
                    Title
                  </label>
                  <Field
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                    id="title"
                    type="input"
                    name="title"
                    placeholder="Add a title for this demo"
                  />
                  <ErrorMessage
                    name="title"
                    component="span"
                    className="text-red-500 text-xs italic"
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="description"
                  >
                    Description
                  </label>
                  <Field
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline"
                    id="description"
                    component="textarea"
                    type="description"
                    rows={4}
                    name="description"
                    placeholder="Add a description for this demo"
                  />
                  <ErrorMessage
                    name="description"
                    component="span"
                    className="text-red-500 text-xs italic"
                  />
                </div>
                <div className="player-wrapper flex items-center justify-center">
                  <ReactPlayer
                    className="react-player"
                    width="100%"
                    height="100%"
                    controls
                    url={data.payload[0].url}
                  />
                </div>
                <div className="flex items-center mt-3 pt-5 justify-end gap-x-3">
                  <StyledButton
                    className={
                      isValid
                        ? classSubmit
                        : `bg-gray-300 py-2 px-4 rounded disabled cursor-not-allowed`
                    }
                    type="submit"
                    disabled={!isValid}
                  >
                    SAVE
                  </StyledButton>
                  <StyledButton
                    type="secondary"
                    onClick={(e) => cancelHandler(e)}
                  >
                    Cancel
                  </StyledButton>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default VideoEditor;

import React from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import closeCrossIcon from "../../../../../../icons/closecross-icon.svg";
import infoCircleIcon from "../../../../../../icons/info-circle-icon.svg";
import {customStyles} from "../../../../../../utils/modalStyles";


const PostPublishModal = ({
	open,
	setOpen,
	neverShowPrompt,
	setNeverShowPrompt,
	onSubmit,
	reducer,
}) => {
	const dispatch = useDispatch();
	return (
		<Modal
			isOpen={open}
			onRequestClose={() => {
				setOpen(false);
			}}
			style={customStyles}
		>
			<div className="flex flex-col py-[36px] px-[40px]">
				<div className="flex justify-end items-center">
					<button onClick={() => setOpen(false)}>
						<img
							src={closeCrossIcon}
							alt="close"
							className="w-[34px] h-[34px]"
						/>
					</button>
				</div>
				<div className="flex flex-col gap-y-[10px]">
					<div className="flex flex-row gap-x-[1rem] items-center">
						<img
							src={infoCircleIcon}
							alt="help"
							className="w-[30px] h-[30px]"
						/>
						<p className="font-medium text-[18px] leading-[18px] text-[#222222]">
							Confirm Save
						</p>
					</div>
					<div className="flex flex-col ml-[46px] w-[455px]">
						<p className="text-[15px] leading-[22.5px] text-[#222222]">
							The changes you made won’t go live until you publish.
						</p>
					</div>
				</div>
				<div className="flex flex-row justify-between items-center mt-[33px] ml-[46px] gap-x-[18px]">
					<div className="flex flex-row gap-x-[1rem] items-center">
						<input
							type="checkbox"
							className="w-[18px] h-[18px] text-[#5B5B5B] rounded"
							checked={neverShowPrompt}
							onChange={(e) => {
								setNeverShowPrompt((state) => !state);
							}}
						/>
						<p className="text-[16px] text-[#5B5B5B]">
							Don't show this message again
						</p>
					</div>
					<button
						className="w-[6.63rem] h-[2.5rem] flex items-center justify-center rounded-md bg-[#0071F2] text-white hover:bg-[#0351AA]"
						onClick={() => {
							dispatch(
								reducer({
									saveBeforePublishPrompt: !neverShowPrompt,
								})
							);
							onSubmit();
							setOpen(false);
						}}
					>
						Continue
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default PostPublishModal;

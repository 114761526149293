import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

export const AnalyticTagTypes = {
	ANALYTIC: "analytics",
	PROSPECT: "prospects",
	RESPONSE: "responses",
	RELATION: "relation",
	PAGE: "page"
};

export const videoAnalyticsApiSlice = createApi({
	reducerPath: "api/analytics",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, {getState}) => {
			const token = getState().sessionData.token
			if (token) {
				headers.set('token', token)
			}
			return headers
		}
	}),
	tagTypes: Object.values(AnalyticTagTypes),
	endpoints: builder => ({
		getAnalytics: builder.query({
			query: (initialPost) => "video/analytics/" + initialPost.relationshipId,
			providesTags: [AnalyticTagTypes.ANALYTIC],
		}),
		getRelation: builder.query({
			query: (initialPost) => "salesrep/" + initialPost.salesRepId + "/prospect/" + initialPost.prospectId + "/relation/",
			providesTags: [AnalyticTagTypes.RESPONSE],
		}),
		getForwardedVideos: builder.query({
			query: (initialPost) => "video/analytics/forwarded/name/" + initialPost.videoId + "/" + initialPost.prospectId,
			providesTags: [],
		}),
		getReceivedVideos: builder.query({
			query: (initialPost) => "video/analytics/received/name/" + initialPost.videoId + "/" + initialPost.prospectId,
			providesTags: [],
		}),
	})
})

export const {
	useGetAnalyticsQuery,
	useGetRelationQuery,
	useGetForwardedVideosQuery,
	useGetReceivedVideosQuery
} = videoAnalyticsApiSlice;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const VideoTagTypes = {
    VIDEO: "video",
    SINGLE_VIDEO: "single-video"
};

export const videoApiSlice = createApi({
    reducerPath: "api/video",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_SERVER}/web`,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().sessionData.token
            if (token) {
                headers.set('token', token)
            }
            return headers
        }
    }),
    tagTypes: Object.values(VideoTagTypes),
    endpoints: builder => ({
        getVideo: builder.query({
            query: (initialPost) => "/video/" + initialPost.companyId + `?limit=${initialPost.limit??100}&pageNo=${initialPost?.pageNo?.length==0?1:initialPost.pageNo??1}`,
            providesTags: [VideoTagTypes.VIDEO],
        }),
        getVideoById: builder.query({
            query: (initialPost) => "/video/id/" + initialPost.id,
            providesTags: [VideoTagTypes.SINGLE_VIDEO],
        }),
        createVideo: builder.mutation({
            query: initialPost => ({
                url: "/video",
                method: "POST",
                body: {
                    salesrep_id: initialPost.salesRepId,
                    company_id: initialPost.companyId,
                    url: initialPost.url,
                    title: initialPost.title,
                    description: initialPost.description,
                },
            }),
            invalidatesTags: [VideoTagTypes.VIDEO],
        }),
        updateVideo: builder.mutation({
            query: initialPost => ({
                url: "/video/" + initialPost.videoId,
                method: "PUT",
                body: {
                    title: initialPost.title,
                    description: initialPost.description,
                },
            }),
            invalidatesTags: [VideoTagTypes.VIDEO, VideoTagTypes.SINGLE_VIDEO],
        }),
        deleteVideo: builder.mutation({
            query: initialPost => ({
                url: "/video/" + initialPost.videoId,
                method: "DELETE",
            }),
            invalidatesTags: [VideoTagTypes.VIDEO],
        }),
    })
})

export const { useGetVideoQuery, useGetVideoByIdQuery, useCreateVideoMutation, useUpdateVideoMutation, useDeleteVideoMutation } = videoApiSlice;
import {Bar, Line} from "react-chartjs-2";
import React from "react";
import {format} from "date-fns";

export const SoloAnalytics = ({
																selectedWidgets,
																selectedProspect,
																soloHeroDemoDuration,
																demoDurationLabels,
																viewsGraphLabels,
																viewsGraphData,
																clicksGraphLabels,
																clicksGraphData,
																viewLoadRatioGraphLabels,
																viewLoadRatioGraphData,
															}) => {
	return (
		<>
			{selectedWidgets && selectedProspect &&
			(selectedWidgets.type === "hero" ||
				selectedWidgets.type === "solo") && (
				<div className="flex flex-col w-full rounded-[1rem] border border-[#D9D9D9]">
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-col w-full h-[4.69rem] items-around justify-center border-b border-[#D7D7D7]">
							<div className="flex flex-row items-center gap-x-[0.75rem]">
								<p className="ml-[1.13rem] font-medium">Demo Views</p>
								<div className="rounded-full bg-[#A3A3A3] w-[0.25rem] h-[0.25rem]"/>
								<p className="text-[#255CA4]">{selectedWidgets.label}</p>
							</div>
							<p className="ml-[1.13rem] mt-[5px] text-[#7D7D7D] text-sm ">
								Number of times the demo was watched.
							</p>
						</div>
						<div className="flex justify-center items-center w-full">
							{viewsGraphLabels.length !== 0 ? (
								<div className="flex-grow">
									<Line
										width={100}
										height={25}
										options={{
											maintainAspectRatio: true,
											plugins: {
												legend: {
													display: false,
												},
												tooltip: {
													backgroundColor: "#FFF",
													borderColor: "#D9D9D9",
													borderWidth: 1,
													bodyColor: "#737373",
													bodyFont: {
														size: 15,
														weight: 400,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													titleColor: "#444444",
													titleMarginBottom: 8,
													titleFont: {
														weight: 600,
														size: 15,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													cornerRadius: 11,
													padding: 12,
													displayColors: false,
													callbacks: {
														label: (tooltipItem) => {
															return `Views:  ${tooltipItem.parsed.y}`
														}
													}
												}
											},
											scales: {
												y: {
													title: {
														display: true,
														text: "Views",
														font: {
															size: 15,
															family: "Proxima Nova",
															weight: 400,
														},
														color: "#7D7D7D",
													},
													grid: {
														color: "#F0F0F0",
														borderWidth: 0
													},
												},
												x: {
													grid: {
														lineWidth: 0,
														color: "#F0F0F0",
														borderWidth: 0
													},
													ticks: {
														callback: function (value, index, values) {
															return format(new Date(viewsGraphLabels[value]), "MMM d");
														}
													},
												},
											},
										}}
										data={{
											labels: viewsGraphLabels,
											datasets: viewsGraphData,
										}}
										type={"line"}
									/>
								</div>
							) : (
								<div className="flex justify-center items-center w-[98%] my-16">
									<p className="font-semibold">No Data To Display</p>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			{selectedWidgets && selectedProspect &&
			(selectedWidgets.type === "hero" ||
				selectedWidgets.type === "solo") && (
				<div className="flex flex-col w-full rounded-[1rem] border border-[#D9D9D9] mt-[1.5rem]">
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-col w-full h-[4.69rem] items-around justify-center border-b border-[#D7D7D7]">
							<div className="flex flex-row items-center gap-x-[0.75rem]">
								<p className="ml-[1.13rem] font-medium">Clickthrough Rate</p>
								<div className="rounded-full bg-[#A3A3A3] w-[0.25rem] h-[0.25rem]"/>
								<p className="text-[#255CA4]">{selectedWidgets.label}</p>
							</div>
							<p className="ml-[1.13rem] mt-[5px] text-[#7D7D7D] text-sm ">
								How often was the demo played as a percentage of how many
								times it was presented to the users?
							</p>
						</div>
						<div className="flex justify-center items-center w-full">
							{viewLoadRatioGraphLabels.length !== 0 ? (
								<div className="flex-grow">
									<Line
										width={100}
										height={25}
										options={{
											maintainAspectRatio: true,
											plugins: {
												legend: {
													display: false,
												},
												tooltip: {
													backgroundColor: "#FFF",
													borderColor: "#D9D9D9",
													borderWidth: 1,
													bodyColor: "#737373",
													bodyFont: {
														size: 15,
														weight: 400,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													titleColor: "#444444",
													titleMarginBottom: 8,
													titleFont: {
														weight: 600,
														size: 15,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													cornerRadius: 11,
													padding: 12,
													displayColors: false,
													callbacks: {
														label: function (context) {
															return `Clickthrough Rate:  ${context.parsed.y}%`
														}
													}
												}
											},
											scales: {
												y: {
													title: {
														display: true,
														text: "Clickthrough Rate",
														font: {
															size: 15,
															family: "Proxima Nova",
															weight: 400,
														},
														color: "#7D7D7D",
													},
													ticks: {
														stepSize: 10,
														beginAtZero: true,
														callback: function (value, index, values) {
															return value + "%";
														}
													},
													grid: {
														color: "#F0F0F0",
														borderWidth: 0
													}
												},
												x: {
													grid: {
														lineWidth: 0,
														color: "#F0F0F0",
														borderWidth: 0
													},
													ticks: {
														callback: function (value, index, values) {
															return format(new Date(viewLoadRatioGraphLabels[value]), "MMM d");
														}
													},
												},
											},
										}
										}
										data={{
											labels: viewLoadRatioGraphLabels,
											datasets: viewLoadRatioGraphData,
										}}
										type={"line"}
									/>
								</div>
							) : (
								<div className="flex justify-center items-center w-[98%] my-16">
									<p className="font-semibold">No Data To Display</p>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			{selectedWidgets && selectedProspect &&
			(selectedWidgets.type === "hero" ||
				selectedWidgets.type === "solo") && (
				<div className="flex flex-col w-full rounded-[1rem] border border-[#D9D9D9] mt-[1.5rem]">
					<div className="flex flex-col gap-y-4">
						<div
							className="flex flex-col w-full h-[4.69rem] items-around justify-center border-b border-[#D7D7D7]">
							<div className="flex flex-row items-center gap-x-[0.75rem]">
								<p className="ml-[1.13rem] font-medium">View Duration</p>
								<div className="rounded-full bg-[#A3A3A3] w-[0.25rem] h-[0.25rem]"/>
								<p className="text-[#255CA4]">{selectedWidgets.label}</p>
							</div>
							<p className="ml-[1.13rem] mt-[5px] text-[#7D7D7D] text-sm ">
								How much of the demo was watched?
							</p>
						</div>
						<div className="flex justify-center items-center w-full">
							{soloHeroDemoDuration.length !== 0 ? (
								<div className="flex-grow">
									<Bar
										width={100}
										height={25}
										options={{
											maintainAspectRatio: true,
											plugins: {
												legend: {
													display: false,
												},
												tooltip: {
													backgroundColor: "#FFF",
													borderColor: "#D9D9D9",
													borderWidth: 1,
													bodyColor: "#737373",
													bodyFont: {
														size: 15,
														weight: 400,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													titleColor: "#444444",
													titleMarginBottom: 8,
													titleFont: {
														weight: 600,
														size: 15,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													cornerRadius: 11,
													padding: 12,
													displayColors: false,
													callbacks: {
														label: function (context) {
															return `View Duration:  ${context.parsed.y}`
														}
													}
												}
											},
											scales: {
												y: {
													beginAtZero: true,
													title: {
														display: true,
														text: "Session Count",
														font: {
															size: 15,
															family: "Proxima Nova",
															weight: 400,
														},
														color: "#7D7D7D",
													},
													grid: {
														color: "#F0F0F0",
														borderWidth: 0
													}
												},
												x: {
													title: {
														display: true,
														text: "How much of the video was watched",
														font: {
															size: 15,
															family: "Proxima Nova",
															weight: 400,
														},
														color: "#7D7D7D",
													},
													grid: {
														lineWidth: 0,
														color: "#F0F0F0",
														borderWidth: 0
													},
												},
											},
										}}
										data={{
											labels: demoDurationLabels,
											datasets: soloHeroDemoDuration,
										}}
										type={"line"}
									/>
								</div>
							) : (
								<div className="flex justify-center items-center w-[98%] my-16">
									<p className="font-semibold">No Data To Display</p>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			{selectedWidgets && selectedProspect && selectedWidgets.type === "hero" && (
				<div className="flex flex-col w-full rounded-[1rem] border border-[#D9D9D9] mt-[1.5rem]">
					<div className="flex flex-col gap-y-4">
						<div
							className="flex flex-col w-full h-[4.69rem] items-around justify-center border-b border-[#D7D7D7]">
							<div className="flex flex-row items-center gap-x-[0.75rem]">
								<p className="ml-[1.13rem] font-medium">CTA Clicks</p>
								<div className="rounded-full bg-[#A3A3A3] w-[0.25rem] h-[0.25rem]"/>
								<p className="text-[#255CA4]">{selectedWidgets.label}</p>
							</div>
							<p className="ml-[1.13rem] mt-[5px] text-[#7D7D7D] text-sm ">
								Number of times the CTA next to the demo was clicked.
							</p>
						</div>
						<div className="flex justify-center items-center w-full">
							{clicksGraphLabels.length !== 0 ? (
								<div className="flex-grow">
									<Line
										width={100}
										height={25}
										options={{
											maintainAspectRatio: true,
											plugins: {
												legend: {
													display: false,
												},
												tooltip: {
													backgroundColor: "#FFF",
													borderColor: "#D9D9D9",
													borderWidth: 1,
													bodyColor: "#737373",
													bodyFont: {
														size: 15,
														weight: 400,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													titleColor: "#444444",
													titleMarginBottom: 8,
													titleFont: {
														weight: 600,
														size: 15,
														lineHeight: "15px",
														family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
													},
													cornerRadius: 11,
													padding: 12,
													displayColors: false,
													callbacks: {
														label: function (context) {
															return `Clicks:  ${context.parsed.y}`
														}
													}
												}
											},
											scales: {
												y: {
													beginAtZero: true,
													title: {
														display: true,
														text: "Clicks",
														font: {
															size: 15,
															family: "Proxima Nova",
															weight: 400,
														},
														color: "#7D7D7D",
													},
													grid: {
														color: "#F0F0F0",
														borderWidth: 0
													}
												},
												x: {
													grid: {
														lineWidth: 0,
														borderWidth: 0
													},
													ticks: {
														callback: function (value, index, values) {
															return format(new Date(clicksGraphLabels[value]), "MMM d");
														}
													},
												}
											},
										}}
										data={{
											labels: clicksGraphLabels,
											datasets: clicksGraphData,
										}}
										type={"line"}
									/>
								</div>
							) : (
								<div className="flex justify-center items-center w-[98%] my-16">
									<p className="font-semibold">No Data To Display</p>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

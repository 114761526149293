import React from "react";
import { Route, Switch } from "react-router-dom";
import Editor from "./components/editor/Editor";
import DemoTableView from "./components/DemoTableView";
import DemoViewContainer from "../top-level/DemoViewContainer";

const Demos = () => {
  return (
    <DemoViewContainer>
      <Switch>
        <Route exact path={`/feature/demos/video/:id`} component={Editor} />
        <Route exact path={`/feature/demos/recording`} component={Editor} />
        <Route exact path={`/feature/demos/recording/:id`} component={Editor} />
        <Route path={`/feature/demos`} component={DemoTableView} />
      </Switch>
    </DemoViewContainer>
  );
};

export default Demos;

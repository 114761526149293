import AnalyticsViewContainer from "./AnalyticsViewContainer";
import TopBar from "../top-level/TopBar";
import {EMBED, PAGE} from "../../data/constants";
import React from "react";
import {Tabs, Tab, TabList, TabPanel} from "react-tabs";
import {WidgetAnalytics} from "./components/widget/WidgetAnalytics";
import {PageAnalytics} from "./components/page/PageAnalytics";

export const Analytics = () => {
	return (
		<AnalyticsViewContainer>
			<div className="flex flex-col justify-center ">
				<div className="sticky top-0 flex flex-row justify-between bg-white py-4 z-10">
					<div className="grow">
						<TopBar
							heading="Analytics"
							description={`Learn how your ${EMBED.toLowerCase()}s are performing`}
						/>
					</div>
				</div>
				<Tabs forceRenderTabPanel={false}>
					<TabList className="flex items-center w-full">
						<Tab
							className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
							selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
						>
							{`${EMBED}s`}
						</Tab>
						<Tab
							className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
							selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
						>
							{`${PAGE}s`}
						</Tab>
					</TabList>
					<TabPanel>
						<WidgetAnalytics/>
					</TabPanel>
					<TabPanel>
						<PageAnalytics/>
					</TabPanel>
				</Tabs>
			</div>
		</AnalyticsViewContainer>
	)
}

import { useSelector } from "react-redux";

export const EmbedViewContainer = (props) => {
    const expandedNavbar = useSelector(
        (state) => state.sessionData.expandedNavbar
    );
    return (
        <div
            className={`relative ${
                expandedNavbar ? "w-[85%] max-w-[75rem]" : "w-[95%] max-w-[85rem]"
            } mx-auto pb-4 min-w-[1180px] ${props.className}`}
        >
            {props.children}
        </div>
    );
};

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import ReactTooltip from "react-tooltip";

import playicon from "../../../icons/play-icon.svg";
import gificon from "../../../icons/gif-icon.svg";
import moreicon from "../../../icons/more-icon.svg";
import cloneicon from "../../../icons/clone-icon.svg";
import deleteicon from "../../../icons/delete-icon.svg";
import dividericon from "../../../icons/divider-icon.svg";
import closeCrossIcon from "../../../icons/closecross-icon.svg";

import { useCreateVideoMutation } from "../../../features/api/videoApiSlice";
import { useDeleteRecordingMutation } from "../../../features/api/recordingsApiSlice";
import { useDeleteVideoMutation } from "../../../features/api/videoApiSlice";
import { useCreateRecordingCopyMutation } from "../../../features/api/recordingsApiSlice";

import Modal from "../../modal/Modal";
import StyledButton from "../../top-level/StyledButton";
import PopupWindow from "./PopupWindow";
import ControlPanel from "./ControlPanel";
import DropdownIcon from "../../top-level/DropdownIcon";
import { DEMO } from "../../../data/constants";
import { getBackgroundColor } from "../../../utils/avatar";

import "../Demos.css";
import { toastError, toastLoading } from "../../custom-toast/toasts";

const DemoTable = (demos) => {
	const companyId = useSelector((state) => state.sessionData.companyId);
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const salesRepToken = useSelector((state) => state.sessionData.token);

	const [deleteRecording] = useDeleteRecordingMutation();
	const [deleteVideo] = useDeleteVideoMutation();

	const [recordings, setRecordings] = useState();
	const [popup, setPopup] = useState(false);
	const [openEmbedPopup, setOpenEmbedPopup] = useState(false);
	const [embedFrame, setEmbedFrame] = useState("");
	const [openGifModal, setOpenGifModal] = useState(false);
	const [gifModal, setGifModal] = useState();
	const [coordinates, setCoordinates] = useState();
	const [controls, setControls] = useState(false);
	const [popupUrl, setPopupUrl] = useState("");
	const [popupId, setPopupId] = useState(null);
	const [features, setFeatures] = useState();
	const history = useHistory();

	// Demo controls
	const [playing, setPlaying] = useState(true);
	const [playbackRate, setPlaybackRate] = useState(1);
	const [isToggledPointer, setIsToggledPointer] = useState(false);
	const [isMuted, setIsMuted] = useState(true);

	const [createVideo] = useCreateVideoMutation();
	const [createRecordingCopy] = useCreateRecordingCopyMutation();
	const demoCardIconsFilter = "demo-card-icons-filter";
	const [applyFilter, setApplyFilter] = useState({ index: -1, icon: "" });

	useEffect(() => {
		const sortedRecordings = [...demos.recordings].sort((a, b) => {
			return new Date(b.date_created) - new Date(a.date_created);
		});
		setRecordings(sortedRecordings);
	}, [demos.recordings]);

	const viewDemo = (
		isRecording,
		popupUrl,
		popupId,
		popupCoordinates = null
	) => {
		const feat = isRecording
			? {
					width: popupCoordinates.intrinsic.intrinsicVideoWidth,
					height: popupCoordinates.intrinsic.intrinsicVideoHeight - 20,
			  }
			: { width: "1280", height: "530" };
		setPopupUrl(popupUrl);
		setPopupId(popupId);
		setCoordinates(popupCoordinates);
		setFeatures(feat);
		setPopup(true);
		setControls(true);
	};

	const copyDemo = (rec) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="flex flex-col w-[36.38rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<div className="flex items-center justify-end">
							<button
								onClick={onClose}
								className="flex mt-[1.625rem] justify-center items-center"
							>
								<img
									src={closeCrossIcon}
									alt="close"
									className="w-[2.13rem] h-[2.13rem]"
								/>
							</button>
						</div>
						<p className="mt-[0.25rem] text-[18px] font-medium">
							Would you like to copy this preview with its settings?
						</p>
						<div className="flex flex-row justify-between mt-[4rem]">
							<button
								onClick={async () => {
									const recData = {
										salesrep_id: salesRepId,
										company_id: companyId,
										location: rec.location,
										type: rec.type,
										thumbnail: rec.thumbnail,
										preview: rec.preview,
										title: `${rec.title} - Copy`,
										description: rec.description,
										coordinates: rec.coordinates,
										date_created: new Date()
											.toISOString()
											.slice(0, 19)
											.replace("T", " "),
										date_modified: new Date()
											.toISOString()
											.slice(0, 19)
											.replace("T", " "),
									};
									const status = await createRecordingCopy(recData).unwrap();
									if (status.status === "success") {
										// toastSuccess(`${DEMO} Copied`);
										onClose();
									} else {
										toastError(`Error copying ${DEMO.toLowerCase()}`);
										onClose();
									}
								}}
								className="flex justify-center items-center w-[10.25rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Copy with settings
							</button>
							<button
								onClick={async () => {
									const recData = {
										salesrep_id: salesRepId,
										company_id: companyId,
										type: rec.type,
										location: rec.location,
										thumbnail: rec.thumbnail,
										preview: rec.preview,
										title: `${rec.title} - Copy`,
										description: rec.description,
										date_created: new Date()
											.toISOString()
											.slice(0, 19)
											.replace("T", " "),
										date_modified: new Date(rec.date_modified)
											.toISOString()
											.slice(0, 19)
											.replace("T", " "),
										coordinates: JSON.stringify({
											...JSON.parse(rec.coordinates),
											hotspots: [],
											defaultCaptionColor: "#000000",
											defaultHotspotColor: "#B9C3D6",
										}),
									};
									const status = await createRecordingCopy(recData).unwrap();
									if (status.status === "success") {
										// toastSuccess(`${DEMO} Copied`);
										onClose();
									} else {
										toastError(`Error copying ${DEMO.toLowerCase()}`);
										onClose();
									}
								}}
								className="flex justify-center items-center w-[11.69rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Copy without settings
							</button>
						</div>
					</div>
				);
			},
		});
	};

	const openGifModalOf = (element) => {
		setOpenGifModal(true);
		setGifModal(element);
	};

	const onUnloadWindow = () => {
		setPopup(false);
		setControls(false);
		setPlaybackRate(1);
		setPlaying(true);
		setIsToggledPointer(false);
	};

	const recordingDeleteHandler = (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="flex flex-col w-[30rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<p className="mt-[2.25rem] text-[18px] font-medium">
							{`Are you sure you want to delete this ${DEMO.toLowerCase()}?`}
						</p>
						<p className="mt-[1.5rem] text-[14px] leading-[14px]">
							Once deleted cannot be recovered
						</p>
						<div className="flex flex-row justify-between mt-[3.375rem]">
							<button
								onClick={onClose}
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Cancel
							</button>
							<button
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#EDC8C5] font-roboto text-[16px] leading-[24px] border border-[#D83A52] text-[#D83A52] rounded-[0.25rem]"
								onClick={async () => {
									const popup = toastLoading("Delete in Progress");
									try {
										const deletion = await deleteRecording({
											recordingId: id,
										}).unwrap();
										if (deletion.status === "success") {
											toast.dismiss(popup);
											// toastSuccess(`${DEMO} Deleted!`);
										} else {
											toast.dismiss(popup);
											toastError(`Failed to delete ${DEMO.toLowerCase()}`);
										}
										onClose();
									} catch (err) {
										toastError("Couldn't connect to database");
										onClose();
									}
								}}
							>
								Delete
							</button>
						</div>
					</div>
				);
			},
		});
	};

	const downloadGifHandler = async (e, gif) => {
		toastLoading("Attempting download..");
		const image = await fetch(gif.demo);
		const imageBlob = await image.blob();
		const imageURL = URL.createObjectURL(imageBlob);

		const link = document.createElement("a");
		link.href = imageURL;
		link.download = `${gif.title}.gif`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		toast.dismiss();
		// toastSuccess("Gif downloaded!");
	};

	const moreSelectHandler = (value, element) => {
		switch (value) {
			case "clone":
				copyDemo(element);
				break;
			case "delete":
				recordingDeleteHandler(element.id);
				break;
			default:
				console.log("Unknown option clicked");
				break;
		}
	};
	return (
		<>
			<ReactTooltip effect="solid" place="bottom" />
			<div className="grid grid-cols-4 gap-x-4 gap-y-6 mt-12 mb-8 w-full">
				{demos.recordings &&
					recordings &&
					recordings.map((element, i) => {
						return (
							<Link
								className="no-underline"
								to={`/feature/demos/recording/${element.id}`}
								key={i}
							>
								<div className="relative w-[16.44rem] min-w-[16.44rem] min-h-[18.19rem] h-[18.19rem] rounded-2xl border border-[#D9D9D9] hover:shadow-lg cursor-pointer hover:-top-1 hover:transition-all hover:duration-200 hover:ease-linear">
									<div className="flex flex-col">
										<div className="px-4 py-5 h-[4.75rem]">
											<div className="flex justify-between">
												<div className="flex flex-col gap-y-1 w-3/4">
													<p
														className="text-base font-medium leading-5 truncate"
														data-tip={element.title}
													>
														{element.title}
													</p>
													<p className="text-sm font-normal text-[#888888]">
														{new Date(element.date_created).toLocaleDateString(
															"en-us",
															{
																year: "numeric",
																month: "long",
																day: "numeric",
															}
														)}
													</p>
												</div>
												<div className="flex justify-center items-center">
													<div
														className={`flex justify-center items-center ml-[27px] w-8 h-8 rounded-full text-white`}
														style={{
															backgroundColor: getBackgroundColor(
																element.salesrep_id
															),
														}}
													>
														{element.salesrep_name.trim()[0]}
													</div>
												</div>
											</div>
										</div>
										<div className="w-full h-[10.31rem]">
											<img
												className="w-full h-full object-cover"
												src={`${process.env.REACT_APP_CDN}${element.thumbnail}`}
												alt="Zeros demo"
											/>
										</div>
										<div className="flex justify-between h-[3.13rem] px-3 py-2 items-center">
											<div className="flex items-center justify-between ml-1 w-1/3">
												<button
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														viewDemo(
															true,
															`${
																element.type === "default"
																	? process.env.REACT_APP_CDN
																	: ""
															}${element.location}`,
															element.id,
															JSON.parse(element.coordinates)
														);
													}}
													data-tip={`Play ${DEMO.toLowerCase()}`}
													className="border border-white hover:bg-[#F8F8F8] hover:rounded-md hover:border hover:border-[#D2D2D2]"
												>
													<img
														src={playicon}
														alt="Play"
														className={`min-w-[2rem] min-h-[2rem] ${
															applyFilter.index === element.id &&
															applyFilter.icon === "play"
																? demoCardIconsFilter
																: ""
														}`}
														onMouseEnter={() =>
															setApplyFilter({
																index: element.id,
																icon: "play",
															})
														}
														onMouseLeave={() =>
															setApplyFilter({ index: -1, icon: "" })
														}
													/>
												</button>
												<img
													src={dividericon}
													alt="|"
													className="w-[2rem] h-[2rem]"
												/>
												<button
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														openGifModalOf(element);
													}}
													data-tip="Download GIF"
													className="border border-white hover:bg-[#F8F8F8] hover:rounded-md hover:border hover:border-[#D2D2D2]"
												>
													<img
														src={gificon}
														alt="GIF"
														className={`min-w-[2rem] min-h-[2rem] ${
															applyFilter.index === element.id &&
															applyFilter.icon === "gif"
																? demoCardIconsFilter
																: ""
														}`}
														onMouseEnter={() =>
															setApplyFilter({ index: element.id, icon: "gif" })
														}
														onMouseLeave={() =>
															setApplyFilter({ index: -1, icon: "" })
														}
													/>
												</button>
											</div>
											<div className="flex items-center">
												<DropdownIcon
													className="border border-white hover:bg-[#F8F8F8] hover:rounded-md hover:border hover:border-[#D2D2D2]"
													titleIcon={moreicon}
													dataTip="More"
													options={[
														{
															key: 1,
															value: "clone",
															label: "Clone",
															icon: cloneicon,
															element: element,
														},
														{
															key: 2,
															value: "delete",
															label: "Delete",
															icon: deleteicon,
															element: element,
														},
													]}
													onSelectHandler={moreSelectHandler}
												/>
											</div>
										</div>
									</div>
								</div>
							</Link>
						);
					})}
			</div>
			<Modal open={openEmbedPopup} onClose={() => setOpenEmbedPopup(false)}>
				<form>
					<div className="mb-3" controlId="formBasicEmail">
						<label className="text-lg font-bold">Embeddable iframe: </label>
						<p className="text-red-800 text-xs font-bold">
							(the iframe expands to fill in the given space, you may bound its
							parent container at your own discretion)
						</p>
						<textarea
							className="shadow appearance-none border rounded w-full mt-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							id="link"
							type="link"
							rows={8}
							name="link"
							value={embedFrame}
							readOnly
						/>
						<StyledButton
							className="mt-2"
							type="success"
							onClick={(e) => {
								e.preventDefault();
								navigator.clipboard.writeText(embedFrame);
							}}
						>
							Copy
						</StyledButton>
					</div>
				</form>
			</Modal>
			{popup && (
				<PopupWindow
					setPlaying={setPlaying}
					coordinates={coordinates}
					url={popupUrl}
					playbackRate={playbackRate}
					features={features}
					playing={playing}
					isToggledPointer={isToggledPointer}
					isMuted={isMuted}
					setIsMuted={setIsMuted}
					onUnloadWindow={onUnloadWindow}
				/>
			)}
			{controls && (
				<ControlPanel
					setPlaybackRate={(rate) => setPlaybackRate(rate)}
					playing={playing}
					setPlaying={(bool) => setPlaying(bool)}
					popupId={popupId}
					setPopupUrl={(url, id, coord = null) => {
						coord ? setCoordinates(JSON.parse(coord)) : setCoordinates();
						setPopupId(id);
						setPopupUrl(url);
					}}
					open={controls}
					onClose={() => setControls(false)}
					isToggledPointer={isToggledPointer}
					setIsToggledPointer={setIsToggledPointer}
					isMuted={isMuted}
					setIsMuted={setIsMuted}
					videos={demos.videos}
					recordings={demos.recordings}
				/>
			)}
			{openGifModal && (
				<Modal open={openGifModal} onClose={() => setOpenGifModal(false)}>
					<div className="flex flex-col">
						<div className="flex flex-row justify-between gap-2">
							<div className="flex flex-col w-3/4">
								<p className="font-semibold text-lg">
									GIF for the {DEMO.toLowerCase()}
								</p>
								<p className="font-normal text-base pt-1">
									Duration is restricted to the first 10 seconds to limit size
								</p>
							</div>
						</div>
						<div className="my-4">
							<div className="relative mt-3 mb-1 flex justify-center items-center pt-[56.25%] w-[100%]">
								<img
									id={`gif-${gifModal.id}`}
									src={`${process.env.REACT_APP_CDN}${gifModal.preview}`}
									alt="GIF"
									className="absolute top-0 left-0 h-full w-full aspect-video object-cover border-2 border-slate-400 rounded-md"
								/>
							</div>
						</div>
						<div className="flex justify-between pt-3">
							<StyledButton
								className="w-1/4"
								type="cta"
								onClick={() => {
									navigator.clipboard.writeText(
										`${process.env.REACT_APP_CLIENT_PROSPECT}/${salesRepToken}/recording/${gifModal.id}/${companyId}`
									);
									// toastSuccess("Copied to clipboard!");
								}}
							>
								Copy URL
							</StyledButton>
							<StyledButton
								className="w-1/4"
								type="cta"
								onClick={(e) => downloadGifHandler(e, gifModal)}
							>
								Download GIF
							</StyledButton>
						</div>
						{/* <p className="my-2 text-md">URL for the demo</p> */}
						{/* <div className="flex flex-row justify-between gap-2">
               <input
                type="text"
                readOnly={true}
                className="py-3 w-3/4"
                value={`${process.env.REACT_APP_CLIENT_PROSPECT}/${salesRepToken}/recording/${gifModal.id}/${companyId}`}
              />
            </div> */}
					</div>
				</Modal>
			)}
		</>
	);
};
export default DemoTable;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setExpandedNavbar } from "../../features/state/session/sessionDataSlice";
import Demos from "../demos/Demos";
import { BrowserRouter, Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { logout } from "../../utils/logout";
import { Settings } from "../settings/Settings";
import { Page } from "../page/Page";
import { useGetSalesPermissionsQuery } from "../../features/api/settingsApiSlice";
import { useSelector } from "react-redux";
import { PageSpinner } from "../top-level/PageSpinner";
import zerosLogo from "../../icons/zeros-logo.png";
import analyticsIcon from "../../icons/analytics-icon.svg";
import demosIcon from "../../icons/demos-icon.svg";
import pagesIcon from "../../icons/pages-icon.svg";
import questionnaireIcon from "../../icons/questionnaire-icon.svg";
import widgetIcon from "../../icons/widgets-icon.svg";
import settingsIcon from "../../icons/settings-icon.svg";
import logoutIcon from "../../icons/logout-icon.svg";
import shieldIcon from "../../icons/shield-icon.svg";
import switchIcon from "../../icons/switch-icon.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "./Dashboard.css";
import { ClickOutside } from "../../features/hooks/ClickOutside";
import { amplitude } from "../amplitude/Amplitude";
import { Embed } from "../embed/Embed";
import ReactTooltip from "react-tooltip";
import {
	ANALYTIC,
	CONTACT,
	DEMO,
	EMBED,
	PAGE,
	QUESTIONNAIRE,
} from "../../data/constants";
import { getBackgroundColor } from "../../utils/avatar";
import { Analytics } from "../analytics/Analytics";
import { Contact } from "../contact-list/ContactList";
import Modal from "react-modal";
import { customStyles } from "../../utils/modalStyles";
import {
	useSendConsentMutation,
	useUpdateFlagMutation,
	useUpdateFlagsMutation,
} from "../../features/api/dashboardApiSlice";
import { setFlags } from "../../features/state/user-settings/userSettingSlice";

const ProfileButton = ({ expandedNavbar, setSelectedLink }) => {
	const [isOpenDropdown, setIsOpenDropdown] = useState(false);
	const history = useHistory();
	const options = [
		// {key: 0, value: "profile", label: "Profile"},
		{ key: 1, value: "settings", label: "Settings" },
		{ key: 2, value: "switch", label: "Switch to Matrix" },
		{ key: 3, value: "logout", label: "Logout" },
	];
	const sellerName = useSelector((state) => state.sessionData.name);
	const sellerId = useSelector((state) => state.sessionData.sellerId);
	const token = useSelector((state) => state.sessionData.token);
	const wrapperRef = useRef("menu");
	ClickOutside(wrapperRef, () => {
		setIsOpenDropdown(false);
	});


	const handleEvent = (event) => {
		switch (event) {
			case "logout":
				logout();
				break;
			case "switch":
				window.open(
					`${process.env.REACT_APP_MATRIX}/zeros${token ? `?token=${token}` : ""
					}`,
					"_blank",
					"noreferrer"
				);
				break;
			case "settings":
				setSelectedLink("");
				history.push("/feature/settings")
				break;
			default:
				console.log("Unhandled Case", event);
		}
	};

	const returnIcon = (label, expandedNavbar) => {
		switch (label) {
			case "logout":
				return (
					<img
						src={logoutIcon}
						alt="logoutIcon"
						className={`${expandedNavbar ? "ml-[1.688rem]" : ""
							} w-6 h-6 rotate-90 svg-white`}
					/>
				);
			case "settings":
				return (
					<img
						src={settingsIcon}
						alt="settingsIcon"
						className={`${expandedNavbar ? "ml-[1.688rem]" : ""
							} w-6 h-6 svg-white`}
					/>
				);
			case "switch":
				return (
					<img
						src={switchIcon}
						alt="switchIcon"
						className={`${expandedNavbar ? "ml-[1.688rem]" : ""
							} w-6 h-6 rotate-90`}
					/>
				);
			default:
				console.log("Unknown Icon");
		}
	};

	return (
		<div className="relative inline-block mx-[0.625rem]" ref={wrapperRef}>
			<button
				className={`h-[3.125rem] flex flex-row w-full items-center ${expandedNavbar ? "" : "justify-center"
					} bg-[#3D4A57] border-[#3D4A57] border rounded-lg hover:border-white ${isOpenDropdown ? "border-zeros-blue" : ""
					}`}
				onClick={() => setIsOpenDropdown(!isOpenDropdown)}
			>
				<div
					className={`flex justify-center items-center w-6 h-6 ${expandedNavbar ? "ml-[1.688rem]" : ""
						} rounded-full text-white`}
					style={{
						backgroundColor: getBackgroundColor(sellerId),
					}}
				>
					{sellerName[0]}
				</div>
				<div
					className={
						expandedNavbar
							? "ml-[1rem] leading-[14px] text-[14px] text-[#F5F5F5] font-medium"
							: "hidden"
					}
				>
					{sellerName}
				</div>
			</button>
			{isOpenDropdown && (
				<div className="bg-[#3D4A57] w-full absolute bottom-full rounded-lg mb-[8px]">
					{options.map((option, index) => (
						<>
							<hr className={(index === 0) ? "hidden" : `${expandedNavbar ? "mx-[24px]" : "mx-[12px]"}`} />
							<button
								className={`flex flex-row flex-grow w-full items-center ${expandedNavbar ? "" : "justify-center"
									} border border-transparent leading-4 text-base h-[3.125rem] font-normal cursor:pointer hover:border-white`}
								onClick={() => {
									handleEvent(option.value);
									setIsOpenDropdown(false);
								}}
							>
								{returnIcon(option.value, expandedNavbar)}
								<p
									className={
										expandedNavbar ? "ml-[1rem] text-[#F5F5F5]" : "hidden"
									}
								>
									{option.label}
								</p>
							</button>
						</>
					))}
				</div>
			)}
		</div>
	);
};

const SidebarFeature = ({ features, expandedNavbar, toggleExpandedNavbar }) => {
	const [selectedLink, setSelectedLink] = useState(`${DEMO}s`);

	const returnIcon = (label) => {
		switch (label) {
			case `${DEMO}s`:
				return demosIcon;
			case `${EMBED}s`:
				return widgetIcon;
			case `${PAGE}s`:
				return pagesIcon;
			case `${QUESTIONNAIRE}s`:
				return questionnaireIcon;
			case `${CONTACT}s`:
				return questionnaireIcon;
			case `${ANALYTIC}s`:
				return analyticsIcon;
			default:
				console.log("Unknown Icon");
		}
	};

	const handleSelectedLink = (label, index) => {
		setSelectedLink(label);
		amplitude.getInstance().logEvent("LeftNavClick", {
			section: label,
			position: index + 1,
			timestamp: new Date().toUTCString(),
		});
	};

	const [randomID] = useState(String(Math.random()));

	return (
		<div className="flex flex-col justify-between h-full">
			<ReactTooltip
				effect="solid"
				place="right"
				id={randomID}
				className="text-[14px] font-semibold"
			/>
			<div className="flex flex-col gap-y-2 mt-[1.656rem]">
				{features.map((content, ind) => (
					<Link
						key={ind}
						to={content.link}
						onClick={() => handleSelectedLink(content.label, ind)}
						disabled
					>
						<div
							className={`flex flex-row items-center h-10 ${expandedNavbar ? "" : "justify-center"
								} ${selectedLink === content.label
									? expandedNavbar
										? "bg-white"
										: "bg-zeros-blue"
									: "hover:bg-[#345678]"
								}`}
							data-tip={expandedNavbar ? "" : content.label}
							data-for={randomID}
						>
							<div
								className={
									expandedNavbar
										? `w-2.5 h-full ${selectedLink === content.label ? "bg-zeros-blue" : ""
										}`
										: "hidden"
								}
							/>
							<div
								className={`flex flex-row items-center justify-center h-full w-full ${selectedLink === content.label ? "bg-[#345678]" : ""
									}`}
							>
								<img
									src={returnIcon(content.label)}
									alt="NavBar Icons"
									className={`w-6 h-6 svg-white ${expandedNavbar ? "ml-[1.75rem]" : ""
										}`}
								/>
								<p
									className={
										expandedNavbar
											? `ml-[1rem] flex-1 ${selectedLink === content.label
												? "text-[#F5F5F5] text-base font-semibold"
												: "text-[#F5F5F5] text-[14px] font-medium"
											} no-underline leading-4`
											: "hidden"
									}
								>
									{content.label}
								</p>
							</div>
						</div>
					</Link>
				))}
			</div>
			<div className="flex flex-col-reverse h-full">
				<button
					className={`flex flex-row items-center ${expandedNavbar ? "" : "justify-center"
						} hover:bg-[#345678] mb-[2.375rem]`}
					onClick={() => toggleExpandedNavbar(!expandedNavbar)}
				>
					{expandedNavbar ? (
						<IoIosArrowBack
							className={`${expandedNavbar ? "ml-[2.188rem]" : ""
								} w-[1.5rem] h-[3rem] text-white`}
						/>
					) : (
						<IoIosArrowForward
							className={`${expandedNavbar ? "ml-[2.188rem]" : ""
								} w-[1.5rem] h-[3rem] text-white`}
						/>
					)}
					<p
						className={
							expandedNavbar
								? "ml-[1rem] text-[#F5F5F5] leading-4 text-base font-medium"
								: "hidden"
						}
					>
						Hide Navbar
					</p>
				</button>
				<div className="flex flex-col mb-[1rem]">
					<ProfileButton expandedNavbar={expandedNavbar} setSelectedLink={setSelectedLink} />
				</div>
			</div>
		</div>
	);
};

function Dashboard() {
	const features = [
		{ label: `${DEMO}s`, link: "/feature/demos" },
		{ label: `${EMBED}s`, link: "/feature/embeds" },
		{ label: `${PAGE}s`, link: "/feature/page/" },
		{ label: `${CONTACT}s`, link: "/feature/contact/" },
		// { label: `${QUESTIONNAIRE}s`, link: "/feature/questionnaire/" },
		{ label: `${ANALYTIC}s`, link: "/feature/analytics" },
	];
	const dispatch = useDispatch();
	const flags = useSelector((state) => state.userSetting.flags);
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);
	const token = useSelector((state) => state.sessionData.token);
	const expandedNavbar = useSelector(
		(state) => state.sessionData.expandedNavbar
	);
	const session = useSelector((state) => state.sessionData);
	const toggleExpandedNavbar = (bool) => {
		dispatch(setExpandedNavbar({ expandedNavbar: bool }));
	};
	// const [expandedNavbar, toggleExpandedNavbar] = useState(true);
	const { data, isLoading, isError } = useGetSalesPermissionsQuery({
		companyId: companyId,
	});
	const [sendConsent] = useSendConsentMutation();
	const [updateFlag] = useUpdateFlagMutation();
	const history = useHistory();

	useEffect(() => {
		//Amplitude Events
		const userProperties = {
			Username: session.userName,
			"Full Name": session.name,
			Company: session.companyName,
			Flags: flags,
		};
		amplitude.getInstance().setUserId(session.sellerId);
		amplitude.getInstance().setUserProperties(userProperties);
		amplitude
			.getInstance()
			.logEvent("Login", { timestamp: `${new Date().toUTCString()}` });
	}, []);

	if (!token) history.push("/login");
	if (isLoading) return <PageSpinner />;
	if (isError) return <p>Error Loading Information</p>;

	return (
		<BrowserRouter>
			<div>
				<Modal
					isOpen={JSON.parse(flags)?.consent?.popup?.show}
					style={customStyles}
				>
					<div className={`flex flex-col px-[40px] py-[36px] gap-y-[48px]`}>
						<div className={`flex flex-col gap-y-[4px]`}>
							<div
								className={`flex flex-row gap-x-[16px] justify-start items-center`}
							>
								<img src={shieldIcon} alt="shield" />
								<p
									className={`text-[24px] leading-[24px] text-[#222222] font-medium`}
								>
									Privacy Policy and Terms of Service
								</p>
							</div>
							<p className={`text-[16px] leading-[26px] ml-[48px] w-[500px]`}>
								By clicking the button below, you understand that Zeros will
								process your personal information in accordance with its{" "}
								<a
									href="https://go.zeroshq.com/Terms-of-Service"
									target={`_blank`}
									className={`font-inter text-[16px] leading-[26px] hover:underline text-[#1A73E8]`}
									onClick={() =>
										amplitude
											.getInstance()
											.logEvent("ConsentPrivacyPolicyClicked", {
												timestamp: `${new Date().toUTCString()}`,
											})
									}
								>
									Privacy Policy
								</a>{" "}
								and{" "}
								<a
									href="https://go.zeroshq.com/Terms-of-Service"
									target={`_blank`}
									className={`font-inter text-[16px] leading-[26px] hover:underline text-[#1A73E8]`}
									onClick={() =>
										amplitude.getInstance().logEvent("ConsentTOSClicked", {
											timestamp: `${new Date().toUTCString()}`,
										})
									}
								>
									Terms of Service.
								</a>
							</p>
						</div>
						<div
							className={`flex flex-row gap-x-[24px] items-center justify-end`}
						>
							<button
								className={`flex justify-center items-center px-[20px] py-[10px] border border-[#D9D9D9] bg-white rounded-[8px] hover:bg-[#E6E9EF]`}
								onClick={async () => {
									amplitude.getInstance().logEvent("ConsentLogOutClicked", {
										timestamp: `${new Date().toUTCString()}`,
									});
									await sendConsent({
										salesRepId: salesRepId,
										companyId: companyId,
										consent: "logged out",
										timestamp: new Date()
											.toISOString()
											.slice(0, 19)
											.replace("T", " "),
									});
									logout();
								}}
							>
								<p className={`text-[16px] text-[#555555]`}>Logout</p>
							</button>
							<button
								className={`flex justify-center items-center px-[20px] py-[10px] border border-[#1A73E8] bg-[#1A73E8] hover:bg-[#0351AA] hover:border-[#0351AA] rounded-[8px]`}
								onClick={async () => {
									const updatedFlags = { ...JSON.parse(flags) };
									updatedFlags.consent.popup.show = false;
									dispatch(
										setFlags({
											flags: JSON.stringify(updatedFlags),
										})
									);
									amplitude.getInstance().logEvent("ConsentAgreeClicked", {
										timestamp: `${new Date().toUTCString()}`,
									});
									await sendConsent({
										salesRepId: salesRepId,
										companyId: companyId,
										consent: "consented",
										timestamp: new Date()
											.toISOString()
											.slice(0, 19)
											.replace("T", " "),
									});
									await updateFlag({
										salesRepId: salesRepId,
										companyId: companyId,
										config: JSON.stringify(updatedFlags),
									});
								}}
							>
								<p className={`text-[16px] text-white font-[600]`}>I agree</p>
							</button>
						</div>
					</div>
				</Modal>
				<div className="flex flex-row">
					<div
						className={`flex flex-shrink-0 flex-col ${expandedNavbar ? "w-[214px] min-w-[214px]" : "w-[80px] min-w-[80px]"
							} bg-[#28394A]`}
					>
						<div
							className={`flex flex-row mt-[1.031rem] ${expandedNavbar ? "ml-[1.938rem]" : "justify-center"
								} gap-x-[0.7rem] items-center`}
						>
							<img
								src={zerosLogo}
								alt="zeros-logo"
								className="w-[2.188rem] h-[2.313rem]"
							/>
							<h1
								className={
									expandedNavbar
										? "text-[28px] font-bold leading-9 text-white font-ibm-plex"
										: "hidden"
								}
							>
								Zeros
							</h1>
						</div>
						<SidebarFeature
							features={
								data.payload.length !== 0
									? features.filter(
										(it) =>
											!JSON.parse(data.payload[0].flags).includes(it.label)
									)
									: features
							}
							expandedNavbar={expandedNavbar}
							toggleExpandedNavbar={toggleExpandedNavbar}
						/>
					</div>
					<div className="flex-1 h-screen min-w-[1200px] overflow-y-auto">
						<Switch>
							<Route path="/feature/embeds" component={Embed} />
							<Route path="/feature/demos" component={Demos} />
							<Route path="/feature/analytics" component={Analytics} />
							<Route path="/feature/settings" component={Settings} />
							{/*<Route path="/feature/questionnaire" component={Questionnaire} />*/}
							<Route path="/feature/page" component={Page} />
							<Route path="/feature/contact" component={Contact} />
							<Redirect
								exact
								from="/feature"
								to={
									data.payload.length !== 0
										? features.filter(
											(it) =>
												!JSON.parse(data.payload[0].flags).includes(it.label)
										)[0].link
										: features[0].link
								}
							/>
							<Redirect exact from="/" to="/feature" />
						</Switch>
					</div>
				</div>
			</div>
		</BrowserRouter>
	);
}

export default Dashboard;

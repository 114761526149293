// state to persist in cookies
export const paths = {
	"sessionData.token": { name: "session_token" },
	"sessionData.companyId": { name: "session_company_id" },
	"sessionData.sellerId": { name: "session_seller_id" },
	"userSetting.demoRecordingPrompt": { name: "user_setting_recording_prompt" },
	"userSetting.saveBeforePublishPrompt_Cyoa": {
		name: "save_before_publish_prompt_cyoa",
	},
	"userSetting.saveBeforePublishPrompt_Solo": {
		name: "save_before_publish_prompt_solo",
	},
	"userSetting.saveBeforePublishPrompt_Hero": {
		name: "save_before_publish_prompt_hero",
	},
	"userSetting.saveBeforePublishPrompt_Page": {
		name: "save_before_publish_prompt_page",
	},
};

import React from "react";
const Hotspot = ({
  tooltip,
  tooltipColor,
  hotspotColor,
  percentageLeft,
  percentageTop,
  onHotspotClickHandler,
}) => {
  return (
    <>
      <button
        type="button"
        className="absolute animate-ping p-5 rounded-full"
        style={{
          left: `${percentageLeft}%`,
          top: `${percentageTop}%`,
          background: `${hotspotColor ?? "#D9D9D9"}`,
        }}
        onClick={() => onHotspotClickHandler()}
      ></button>
      <div
        className="absolute max-w-xs px-4 py-3 test-sm font-normal non-italic rounded-lg border border-[#414357]"
        style={{
          left: `${percentageLeft + 3}%`,
          top: `${percentageTop}%`,
          background: `${hotspotColor ?? "#D9D9D9"}`,
          color: `${tooltipColor ?? "#000000"}`,
          fontFamily: "Open Sans",
        }}
      >
        {tooltip}
      </div>
    </>
  );
};
export default Hotspot;

import React, { useState, useEffect, useRef } from "react";

const DefaultField = ({ currentColor, defaultColor, onChange }) => {
	const [isDefault, setIsDefault] = useState(false);
	const checkboxRef = useRef();
	useEffect(() => {
		if (defaultColor === currentColor) {
			setIsDefault(true);
		} else {
			setIsDefault(false);
		}
	}, [defaultColor, currentColor]);

	return isDefault ? (
		<div className="flex items-center justify-center mx-2 my-2">
			<div className="flex h-[2rem] px-3 justify-center items-center rounded-[8px] bg-[#C9E2FF] text-sm text-[#0B4CA3] gap-x-2">
				Default color
				<div className="border rounded">
					<div
						className="w-[1.06rem] h-[1.06rem]"
						style={{
							background: defaultColor,
						}}
					/>
				</div>
			</div>
		</div>
	) : (
		<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
			<label>Set as default</label>
			<input
				type="checkbox"
				ref={checkboxRef}
				// onClick={() => {
				//   checkboxRef.current.checked = !checkboxRef.current.checked;
				// }}
				onChange={(e) => {
					e.preventDefault();
					e.stopPropagation();
					if (checkboxRef.current.checked) {
						onChange(currentColor);
					}
				}}
			/>
		</div>
	);
};

export default React.memo(DefaultField);

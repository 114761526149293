import {toast} from "react-toastify";
import toastSuccessIcon from "../../icons/toast-success-icon.svg";
import React from "react";
import toastErrorIcon from "../../icons/toast-error-icon.svg";
import toastLoadingIcon from "../../icons/toast-loading-icon.svg";

export const toastSuccess = (msg, options = {}) => {
		return toast.success(msg, {
			...options,
			icon: ({theme, type}) =>  <img src={toastSuccessIcon} alt={"toast success"}/>,
		});
}

export const toastError = (msg, options = {}) => {
	return toast.error(msg, {
		...options,
		icon: ({theme, type}) =>  <img src={toastErrorIcon} alt={"toast error"}/>,
	});
}

export const toastLoading = (msg = "loading...", options = {}) => {
	return toast.loading(msg, {
		...options,
		icon: ({theme, type}) =>  <img src={toastLoadingIcon} alt={"toast loading"}/>,
	});
}



import {CYARuleConditionSingle} from "./CYARuleConditionSingle";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {AiFillDelete} from "react-icons/ai";
import {flatReactSelectStyle} from "../../../../../../utils/styleObjects";
import {logicSelect} from "../../../../../../utils/reactSelectStyles";
import cyaDeleteIcon from "../../../../../../icons/cya-delete-icon.svg";

export const CYARuleConditionGroup = forwardRef(({
																									 index,
																									 questionOptions,
																									 answerOptions,
																									 logicOperator,
																									 setLogicOperator,
																									 logicOperatorsList,
																									 conditionIdList,
																									 condition,
																									 ruleToDelete
																								 }, ref) => {
	const [subConditionIdList, setSubConditionIdList] = useState([]);
	const [conditionList, setConditionList] = useState([]);
	const [groupLogicOperator, setGroupLogicOperator] = useState({key: "0", value: "&&", label: "And"});

	const ruleRef = useRef([]);

	//Functions that can be called by parent
	useImperativeHandle(ref, () => ({
		sendCondition() {
			return returnCondition();
		},
	}));

	useEffect(() => {
		if (condition !== undefined) {
			addCondition(condition.variables.length);
			setConditionList(condition.variables);

			const logic = logicOperatorsList.filter(it => it.value === condition.logic);
			if (logic.length !== 0) {
				setLogicOperator(logic[0]);
			}
		} else {
			addCondition();
		}
	}, [condition])

	const addCondition = (batch = null) => {
		if (batch === null) {
			const conditionList = [...subConditionIdList];
			const id = conditionList.length !== 0 ? conditionList[conditionList.length - 1] + 1 : 0;
			conditionList.push(id);
			setSubConditionIdList(conditionList);
		} else {
			const conditionList = [];
			for (let i = 0; i < batch; i++) {
				const id = conditionList.length !== 0 ? conditionList[conditionList.length - 1] + 1 : 0;
				conditionList.push(id);
			}
			setSubConditionIdList(conditionList);
		}
	}

	const conditionToDelete = (id) => {
		const conditionList = subConditionIdList.filter((it) => it !== id);
		setSubConditionIdList(conditionList);
	}

	const returnCondition = () => {
		const conditions = [];
		let integrityFlag = true;

		subConditionIdList.forEach((it) => {
			conditions.push(ruleRef.current[it].sendCondition());
		});

		conditions.forEach((it) => {
			if (it === null) {
				integrityFlag = false;
			}
		})

		if (!integrityFlag || conditions.length === 0) {
			return null;
		}

		const conditionObject = {}
		const variables = []
		let condition = ""

		conditions.forEach((it, i) => {
			variables.push(it.condition);
			if (i === 0) {
				condition += it.condition.expression;
			} else {
				condition += ` ${it.condition.logic} ${it.condition.expression}`;
			}
		})

		conditionObject.variables = variables;
		conditionObject.condition = condition;
		conditionObject.logic = (index === conditionIdList[0]) ? "" : logicOperator.value;
		return {condition: conditionObject, type: "group"}
	}

	return (
		<div className="flex flex-row gap-x-[10px]">
			{(index === conditionIdList[0]) ?
				<p className="font-medium text-[15px] w-[64px]">
					When
				</p>
				:
				<Select
					styles={logicSelect}
					className="w-[64px] rounded-[10px]"
					value={logicOperator}
					components={makeAnimated()}
					options={logicOperatorsList}
					placeholder="Operator"
					onChange={(e) => setLogicOperator(e)}
					isSearchable
				/>
			}
			<div className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[5px]`}>
				<div className={"flex items-center justify-between h-[2.38rem] bg-transparent border-[#DEE8ED] border-b"}>
					<p className="text-[#255CA4] text-[16px] mx-[20px]">
						{(groupLogicOperator.value === "&&") ? "All of the following are true" : "Any of the following is true"}
					</p>
					<button className="flex items-center justify-center mr-[18px]" onClick={() => ruleToDelete(index)}>
						<img src={cyaDeleteIcon} alt="delete" className="w-5 h-5"/>
					</button>
				</div>
				<div className="bg-[#F9FBFC]">
					<div className={"flex flex-col my-[20px] space-y-[18px]"}>
						<div className={"flex flex-col space-y-[14px] items-center mx-[20px]"}>
							{
								subConditionIdList.map((it, i) => {
									return (
										<div key={it}>
											<CYARuleConditionSingle
												ref={(el) => (ruleRef.current[it] = el)}
												index={it}
												questionOptions={questionOptions}
												answerOptions={answerOptions}
												logicOperator={groupLogicOperator}
												setLogicOperator={setGroupLogicOperator}
												logicOperatorsList={logicOperatorsList}
												condition={conditionList[it]}
												conditionIdList={subConditionIdList}
												conditionToDelete={conditionToDelete}
												conditionGroup={true}
											/>
										</div>
									)
								})
							}
						</div>
						<div className="flex mx-[20px]">
							<button className="ml-[84px] text-[#1D60B5] text-[14px] hover:underline"
									onClick={() => addCondition()}>
								+ Add Condition
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
})

import { useEffect, useRef, useState } from "react";

import format from "date-fns/format";
import { addDays } from "date-fns";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DateRangePicker from "react-date-range/dist/components/DateRangePicker";
import calendarIcon from "../../../../icons/calendar-icon.svg";
import DateRange from "react-date-range/dist/components/DateRange";

export const DateRangePickerComp = ({ dateRange, setDateRange }) => {
	// open close
	const [open, setOpen] = useState(false);

	// get the target element to toggle
	const refOne = useRef(null);

	useEffect(() => {
		// event listeners
		document.addEventListener("keydown", hideOnEscape, true);
		document.addEventListener("click", hideOnClickOutside, true);
	}, []);

	// hide dropdown on ESC press
	const hideOnEscape = (e) => {
		if (e.key === "Escape") {
			setOpen(false);
		}
	};

	// Hide dropdown on outside click
	const hideOnClickOutside = (e) => {
		if (refOne.current && !refOne.current.contains(e.target)) {
			setOpen(false);
		}
	};

	return (
		<div className="relative inline-block">
			<div
				className="flex flex-row w-[18.19rem] h-[2.5rem] border border-[#C5C7D0] justify-around justify-center items-center rounded-[0.25rem] gap-x-[0.625rem]"
				onClick={() => setOpen(!open)}
			>
				<p
					className={
						"flex flex-col justify-center items-center text-[#737373] text-[14px] font-normal"
					}
				>
					{`${format(dateRange[0].startDate, "MMM d, yyyy")}`}
				</p>
				<div className="bg-black w-[0.625rem] h-[2px] rounded" />
				<p
					className={
						"flex flex-col justify-center items-center text-[#737373] text-[14px] font-normal"
					}
				>
					{`${format(dateRange[0].endDate, "MMM d, yyyy")}`}
				</p>
				<img src={calendarIcon} alt="calendar" className={"w-5 h-5"} />
			</div>
			<div className={open ? "" : "hidden"} ref={refOne}>
				<DateRange
					onChange={(item) => setDateRange([item.selection])}
					editableDateInputs={true}
					moveRangeOnFirstSelection={false}
					ranges={dateRange}
					months={2}
					direction="horizontal"
					className="absolute top-[40px] z-50 border rounded-lg shadow-xl mt-2 w-[160%] md:w-[120%] sm:w-[100%]"
				/>
			</div>
		</div>
	);
};

const TopBar = (props) => {
  const { heading, description } = {
    heading: props.heading,
    description: props.description,
  };

  return (
    <div className="flex flex-col">
      <p className="font-medium text-3xl">{heading}</p>
      <p className="text-base font-normal">{description}</p>
      {props.children && (
        <div className="flex flex-col pt-4">{props.children}</div>
      )}
    </div>
  );
};

export default TopBar;

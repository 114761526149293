import React from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../../utils/ItemTypes";

const Draggable = (props) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.TOOL,
      item: { name: props.role },
      canDrag: !props.playing,
      // end: (item, monitor) => {
      //   const dropResult = monitor.getDropResult();
      //   if (item && dropResult) {
      //     alert(`You dropped ${item.name} into ${dropResult.name}!`);
      //     console.log(monitor.getClientOffset());
      //   }
      // },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [props.playing]
  );
  return (
    <div
      className={`${props.className} ${isDragging && "opacity-50"}`}
      ref={drag}
      role={props.role}
    >
      {props.children}
    </div>
  );
};

export default Draggable;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const CyoaTagTypes = {
	GROUP_CYOA: "group_cyoa",
	SINGLE_CYOA: "single_cyoa",
};

export const cyoaApiSlice = createApi({
	reducerPath: "api/cyoa",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().sessionData.token;
			if (token) {
				headers.set("token", token);
			}
			return headers;
		},
	}),
	tagTypes: Object.values(CyoaTagTypes),
	endpoints: (builder) => ({
		getCyoa: builder.query({
			query: (initialPost) => "/cyoa/" + initialPost.companyId + `?limit=${initialPost.limit??1000}&pageNo=${initialPost?.pageNo?.length==0?1:initialPost.pageNo??1}`,
			providesTags: [CyoaTagTypes.GROUP_CYOA],
		}),
		getCyoaById: builder.query({
			query: (initialPost) => "/cyoa/id/" + initialPost.id,
			providesTags: [CyoaTagTypes.SINGLE_CYOA],
		}),
		createCyoa: builder.mutation({
			query: (initialPost) => ({
				url: "/cyoa",
				method: "POST",
				body: initialPost.fd,
			}),
			invalidatesTags: [CyoaTagTypes.GROUP_CYOA, CyoaTagTypes.SINGLE_CYOA],
		}),
		createCyoaCopy: builder.mutation({
			query: (initialPost) => ({
				url: "/cyoa/copy",
				method: "POST",
				body: initialPost,
			}),
			invalidatesTags: [CyoaTagTypes.GROUP_CYOA, CyoaTagTypes.SINGLE_CYOA],
		}),
		updateCyoa: builder.mutation({
			query: (initialPost) => ({
				url: "/cyoa/" + initialPost.cyoaId,
				method: "PUT",
				body: initialPost.fd,
			}),
			invalidatesTags: [CyoaTagTypes.GROUP_CYOA, CyoaTagTypes.SINGLE_CYOA],
		}),
		updateCyoaStatus: builder.mutation({
			query: (initialPost) => ({
				url: "/cyoa/" + initialPost.cyoaId + "/publish",
				method: "PUT",
				body: {
					live: initialPost.live,
					date_modified: initialPost.dateModified,
				},
			}),
			invalidatesTags: [CyoaTagTypes.GROUP_CYOA, CyoaTagTypes.SINGLE_CYOA],
		}),
		deleteCyoa: builder.mutation({
			query: (initialPost) => ({
				url: "/cyoa/" + initialPost.cyoaId,
				method: "DELETE",
			}),
			invalidatesTags: [CyoaTagTypes.GROUP_CYOA, CyoaTagTypes.SINGLE_CYOA],
		}),
	}),
});

export const {
	useGetCyoaQuery,
	useGetCyoaByIdQuery,
	useCreateCyoaMutation,
	useCreateCyoaCopyMutation,
	useUpdateCyoaMutation,
	useUpdateCyoaStatusMutation,
	useDeleteCyoaMutation,
} = cyoaApiSlice;

import React from "react";
import Modal from "react-modal";

import closeCrossIcon from "../../../../../../icons/closecross-icon.svg";
import helpIcon from "../../../../../../icons/help-circle-icon.svg";
import { customStyles } from "../../../../../../utils/modalStyles";

const PublishModal = ({ open, setOpen, onSubmit, isCyoa = false }) => {
	return (
		<Modal
			isOpen={open}
			onRequestClose={() => {
				setOpen(false);
			}}
			style={customStyles}
		>
			<div className="flex flex-col px-[40px] py-[36px]">
				<div className="flex justify-end items-center">
					<button onClick={() => setOpen(false)}>
						<img
							src={closeCrossIcon}
							alt="close"
							className="w-[34px] h-[34px]"
						/>
					</button>
				</div>
				<div className="flex flex-col gap-y-[10px]">
					<div className="flex flex-row gap-x-[1rem] items-center">
						<img src={helpIcon} alt="help" className="w-[30px] h-[30px]" />
						<p className="font-medium text-[18px] leading-[18px] text-[#222222]">
							Confirm Publish
						</p>
					</div>
					{isCyoa ? (
						<div className="flex flex-col ml-[46px] w-[26rem]">
							<p className="text-[15px] leading-[22.5px] text-[#222222]">
								Publishing will finalize the content. You can still change the
								Settings after that but any changes to the following Content
								elements after publishing will reset the analytics.
								<br />
								You won’t be able to see the old data prior to the change.
							</p>
							<ul className="text-[15px] leading-[22.5px] text-[#222222] list-disc ml-[1rem]">
								<li>Question text</li>
								<li>Answer options</li>
								<li>Title of Modal</li>
								<li>Button text</li>
							</ul>
						</div>
					) : (
						<div className="flex flex-col ml-[46px] w-[26rem]">
							<p className="text-[15px] leading-[22.5px] text-[#222222]">
								Publishing will finalize the content. You can still change the
								Settings after that.
							</p>
						</div>
					)}
					<div className="flex items-center px-[8px] py-[6px] bg-[#EDF5FE] rounded-md ml-[46px] w-[450px] mr-[40px]">
						<p className="text-[13px] leading-[22px] text-[#222222] font-inter">
							If you are still working on the content then we recommend you
							<span className="font-semibold">{` “Save” `}</span><br/>
							for now and only publish when you are ready to take it live.
						</p>
					</div>
					<div className="ml-[46px]">
						<p className="text-[15px] leading-[22.5px] text-[#222222]">
							Are you sure you want to publish?
						</p>
					</div>
				</div>
				<div className="flex flex-row justify-end items-center mt-[36px] gap-x-[18px]">
					<button
						className="w-[3.31rem] h-[2.5rem] flex border items-center justify-center rounded-md text-[#222222] hover:bg-[#E6E9EF] border-[#C5C7D0]"
						onClick={() => setOpen(false)}
					>
						No
					</button>
					<button
						className="w-[4.06rem] h-[2.5rem] flex items-center justify-center rounded-md bg-[#0071F2] text-white hover:bg-[#0351AA]"
						onClick={() => {
							onSubmit();
							setOpen(false);
						}}
					>
						Yes
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default PublishModal;

import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import Modal from "react-modal";
import StyledButton from "../../top-level/StyledButton";
import {customStyles} from "../../../utils/modalStyles";
import closeCrossIcon from "../../../icons/closecross-icon.svg";

export const GenerateWidgetPage = ({isOpen, setIsOpen, widgetId, widgetType}) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [link, setLink] = useState("")
	const [dialogue, setDialogue] = useState("Copy Link");
	const [generated, setGenerated] = useState(false);

	const companyId = useSelector((state) => state.sessionData.companyId);
	const salesRepToken = useSelector((state) => state.sessionData.token);

	const generateLink = () => {
		if (title === "" || description === "") return
		const newLink = `${process.env.REACT_APP_CLIENT_PROSPECT}/${salesRepToken}/page/widget/${widgetType}/${widgetId}/${companyId}?title=${title}&description=${description}`
		setLink(newLink);
	}

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={() => {
				setIsOpen(false);
				setGenerated(false);
				setDialogue("Copy Link");
				setLink("");
			}}
			style={customStyles}
		>
			<div className="w-[600px] flex flex-col my-[36px] mx-[40px] gap-y-[24px]">
				<div className="flex justify-end items-center">
					<button onClick={() => {
						setIsOpen(false)
						setGenerated(false)
						setDialogue("Copy Link")
						setLink("")
					}}>
						<img
							src={closeCrossIcon}
							alt="close"
							className="w-[34px] h-[34px]"
						/>
					</button>
				</div>
				<div className="flex flex-col gap-y-[18px]">
					<p className="text-[24px] text-[#222222] font-semibold leading-[24px]">
						Share this Embed
					</p>
					<input type="text"
								 className={
									 `flex text-[15px] leading-[15px] text-[#222222] px-[12px] rounded-[4px] border ${generated && title === "" ? "border-red-600" : "border-[#C5C7D0]"} w-[600px] h-[36px]`
								 }
								 value={title}
								 onChange={(e) => setTitle(e.target.value)} placeholder="Title"/>
					<textarea value={description} placeholder="Description - 400 characters or less"
										maxLength={400}
										onChange={(e) => setDescription(e.target.value)}
										className={`flex text-[15px] leading-[15px] text-[#222222] px-[12px] rounded-[4px] border ${generated && description === "" ? "border-red-600" : "border-[#C5C7D0]"}  w-[600px] h-[72px]`}/>
					<StyledButton
						type="primary"
						className="w-[600px] font-bold text-[16px] leading-[24px]"
						onClick={() => {
							generateLink();
							setGenerated(true);
						}}
					>
						Generate Page URL
					</StyledButton>
				</div>
				<div className={link === "" ? "hidden" : "flex flex-col gap-y-[24px]"}>
					<hr className={"text-[#D9D9D9]"}/>
					<div className={"flex flex-col gap-y-[8px]"}>
						<p className={"text-[16px] leading-[16px] text-[#222222]"}>
							Share embed link
						</p>
						<div className="flex flex-row gap-x-[8px]">
							<input type="text"
										 className={
											 `flex bg-[#F4F4F4] text-[15px] leading-[15px] text-[#656565] px-[12px] rounded-[4px] border border-[#C5C7D0] w-[500px] h-[36px]`
										 }
										 value={link}
										 disabled={true}
										 />
							<button className="w-[98px] h-[36px] bg-[#F1F7FF] hover:bg-white rounded-[8px] text-[#0071F2] border border-[#0071F2] text-[15px] leading-[15px]" onClick={() => {
								navigator.clipboard.writeText(link);
								setDialogue("Copied!")
							}}>
								{dialogue}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { toast } from "react-toastify";
import { useFilePicker } from "use-file-picker";
import { MaterialSwitch } from "../../../../../custom/MaterialSwitch";
import {
	BLINKER_PACK,
	DEMO,
	EMBED_CTA_TYPES,
} from "../../../../../../data/constants";
import infoIcon from "../../../../../../icons/info-icon.svg";
import { StyledRadio } from "../../../../../custom/StyledRadio";
import playLaunchStyleImg from "../../../../../../icons/play-launch-style-img.svg";
import playLaunchStyleMini from "../../../../../../icons/play-launch-style-mini.svg";
import hotspotLaunchStyleImg from "../../../../../../icons/hotspot-launch-style-img.svg";
import arrowupIcon from "../../../../../../icons/arrowup-icon.svg";
import arrowdownIcon from "../../../../../../icons/arrowdown-icon.svg";
import closeCrossIcon from "../../../../../../icons/closecross-icon.svg";
import embedPlusCircle from "../../../../../../icons/embed-plus-icon.svg";
import { BlinkersPack } from "../../../../../top-level/blinker-pack/BlinkersPack";
import "../../../../../top-level/blinker-pack/BlinkersPack.scss";
import usePickerClickOutside from "../../../../../../features/hooks/usePickerClickOutside";
import "../../../../Embed.css";
import { toastError } from "../../../../../custom-toast/toasts";
import { CYAGating } from "./CYAGating";

export const CYASettings = ({
	websiteStyle,
	audioOnLaunch,
	setAudioOnLaunch,
	verifyIdentity,
	setVerifyIdentity,
	redirectToRestrictedDomain,
	setRedirectToRestrictedDomain,
	customVerification,
	setCustomVerification,
	isRequired,
	setIsRequired,
	checkBlocklist,
	setCheckBlocklist,
	checkDomain,
	setCheckDomain,
	checkTwoFactor,
	setCheckTwoFactor,
	wrapUpScreen,
	setWrapUpScreen,
	showControls,
	setShowControls,
	playInline,
	setPlayInline,
	isSneak,
	setIsSneak,
	isCta,
	setIsCta,
	ctaType,
	setCtaType,
	ctaText,
	setCtaText,
	blinkerStyle,
	setBlinkerStyle,
	blinkerSize,
	setBlinkerSize,
	customBlinker,
	setCustomBlinker,
	launchTriangleColor,
	setLaunchTriangleColor,
	launchCircleColor,
	setLaunchCircleColor,
	launchHoverColor,
	setLaunchHoverColor,
	launchHotspotColor,
	setLaunchHotspotColor,
	showCustomThumbnail,
	setShowCustomThumbnail,
	customThumbnail,
	setCustomThumbnail,
	setThumbnailBlob,
	thumbnailBlobUrl,
	setThumbnailBlobUrl,
	setIconBlob,
	iconBlobUrl,
	setIconBlobUrl,
	demoOptions,
	selectedDemo,
}) => {
	const [displayBlinkerStylePicker, setDisplayBlinkerStylePicker] =
		useState(false);
	const [displayTriangleColorPicker, setDisplayTriangleColorPicker] =
		useState(false);
	const [displayCircleColorPicker, setDisplayCircleColorPicker] =
		useState(false);
	const [displayHoverColorPicker, setDisplayHoverColorPicker] = useState(false);
	const [displayHotspotColorPicker, setDisplayHotspotColorPicker] =
		useState(false);
	const [displayPlayStylePicker, setDisplayPlayStylePicker] = useState(false);

	const [
		openThumbnailFileSelector,
		{
			filesContent: thumbnailFilesContent,
			loading: thumbnailLoading,
			errors: thumbnailErrors,
			clear: thumbnailClear,
		},
	] = useFilePicker({
		multiple: false,
		readAs: "DataURL",
		accept: [".jpg", ".jpeg", ".png"],
		limitFilesConfig: { min: 1, max: 1 },
		// minFileSize: 1, // in megabytes
		// maxFileSize: 1,
		// readFilesContent: false, // ignores file content
	});
	useEffect(() => {
		if (thumbnailErrors.length > 0) {
			toastError("Error uploading thumbnail");
			return;
		}
		if (thumbnailFilesContent.length > 0) {
			if (
				hasExtension(thumbnailFilesContent[0].name, [".jpg", ".jpeg", ".png"])
			) {
				fetch(thumbnailFilesContent[0].content).then((res) => {
					res.blob().then((newBlob) => {
						setThumbnailBlob(newBlob);
						setThumbnailBlobUrl(URL.createObjectURL(newBlob));
						setShowCustomThumbnail(true);
					});
				});
			} else {
				thumbnailClear();
				return toastError("Invalid file extension");
			}
		}
	}, [thumbnailFilesContent]);

	const [
		openIconFileSelector,
		{
			filesContent: iconFilesContent,
			loading: iconLoading,
			errors: iconErrors,
			clear: iconClear,
		},
	] = useFilePicker({
		multiple: false,
		readAs: "DataURL",
		accept: [".jpg", ".jpeg", ".png", ".svg"],
		limitFilesConfig: { min: 1, max: 1 },
		// minFileSize: 1, // in megabytes
		// maxFileSize: 1,
		// readFilesContent: false, // ignores file content
	});

	useEffect(() => {
		if (iconErrors.length > 0) {
			toastError("Error uploading thumbnail");
			return;
		}
		if (iconFilesContent.length > 0) {
			if (
				hasExtension(iconFilesContent[0].name, [
					".jpg",
					".jpeg",
					".png",
					".svg",
				])
			) {
				fetch(iconFilesContent[0].content).then((res) => {
					res.blob().then((newBlob) => {
						setIconBlob(newBlob);
						setIconBlobUrl(URL.createObjectURL(newBlob));
						setBlinkerStyle("CUSTOM");
						setDisplayBlinkerStylePicker(false);
						setDisplayPlayStylePicker(false);
					});
				});
			} else {
				iconClear();
				return toastError("Invalid file extension");
			}
		}
	}, [iconFilesContent]);

	const hasExtension = (name, exts) => {
		return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
			name
		);
	};

	const popoverTrianglePicker = useRef();
	const popoverCirclePicker = useRef();
	const popoverHoverPicker = useRef();
	const popoverHotspotPicker = useRef();
	const popoverBlinkerStylePicker = useRef();
	const popoverPlayStylePicker = useRef();

	const closeTrianglePicker = useCallback(
		() => setDisplayTriangleColorPicker(false),
		[]
	);
	const closeCirclePicker = useCallback(
		() => setDisplayCircleColorPicker(false),
		[]
	);
	const closeHoverPicker = useCallback(
		() => setDisplayHoverColorPicker(false),
		[]
	);
	const closeHotspotPicker = useCallback(
		() => setDisplayHotspotColorPicker(false),
		[]
	);
	const closeBlinkerStylePicker = useCallback(
		() => setDisplayBlinkerStylePicker(false),
		[]
	);
	const closePlayStylePicker = useCallback(
		() => setDisplayPlayStylePicker(false),
		[]
	);

	usePickerClickOutside(popoverTrianglePicker, closeTrianglePicker);
	usePickerClickOutside(popoverCirclePicker, closeCirclePicker);
	usePickerClickOutside(popoverHoverPicker, closeHoverPicker);
	usePickerClickOutside(popoverHotspotPicker, closeHotspotPicker);
	usePickerClickOutside(popoverBlinkerStylePicker, closeBlinkerStylePicker);
	usePickerClickOutside(popoverPlayStylePicker, closePlayStylePicker);

	const trianglePickerClickHandler = () => {
		setDisplayTriangleColorPicker((state) => !state);
	};
	const circlePickerClickHandler = () => {
		setDisplayCircleColorPicker((state) => !state);
	};
	const hoverPickerClickHandler = () => {
		setDisplayHoverColorPicker((state) => !state);
	};
	const hotspotPickerClickHandler = () => {
		setDisplayHotspotColorPicker((state) => !state);
	};
	const blinkerStylePickerClickHandler = () => {
		setDisplayBlinkerStylePicker((state) => !state);
	};
	const playStylePickerClickHandler = () => {
		setDisplayPlayStylePicker((state) => !state);
	};

	const [randomID] = useState(String(Math.random()));

	return (
		<div className="flex flex-col justify-start">
			<ReactTooltip
				effect="solid"
				place="right"
				multiline={true}
				id={randomID}
				html={true}
				className="max-w-[350px] font-inter text-xs leading-[22px]"
			/>
			<div className="mt-6 ml-6">
				<p className="text-base font-medium text-[#222222]">General</p>
				<div className="flex flex-col my-6 mx-5 gap-y-5">
					<div className="flex items-center gap-x-4">
						<MaterialSwitch
							color="primary"
							className="mx-2"
							checked={!audioOnLaunch}
							onChange={(event, checked) => {
								setAudioOnLaunch(!checked);
							}}
						/>
						<div className="flex gap-x-2">
							<label
								className="text-sm font-normal text-[#222222]"
								htmlFor="is_muted"
							>
								{`${DEMO}`} is muted upon launch
							</label>
							<img
								src={infoIcon}
								alt="info"
								data-tip="The audio is muted at the time of launch. The user can turn it on if the video player controls are visible. You can toggle the setting below."
								data-for={randomID}
							/>
						</div>
					</div>
					<CYAGating
						verifyIdentity={verifyIdentity}
						setVerifyIdentity={setVerifyIdentity}
						isRequired={isRequired}
						customVerification={customVerification}
						setCustomVerification={setCustomVerification}
						checkBlocklist={checkBlocklist}
						setCheckBlocklist={setCheckBlocklist}
						redirectToRestrictedDomain={redirectToRestrictedDomain}
						setRedirectToRestrictedDomain={setRedirectToRestrictedDomain}
						checkDomain={checkDomain}
						setCheckDomain={setCheckDomain}
						checkTwoFactor={checkTwoFactor}
						setCheckTwoFactor={setCheckTwoFactor}
						setIsRequired={setIsRequired}
						randomID={randomID}
					/>
					<div className="flex items-center gap-x-4">
						<MaterialSwitch
							color="primary"
							className="mx-2"
							checked={wrapUpScreen}
							onChange={(event, checked) => {
								setWrapUpScreen(checked ? true : false);
							}}
						/>
						<div className="flex gap-x-2">
							<label
								className="text-sm font-normal text-[#222222]"
								htmlFor="is_gated"
							>
								Show wrap-up screen at the end
							</label>
							<img
								src={infoIcon}
								alt="info"
								data-tip="When toggled on, the user will see a wrap-up screen at the end. Check out it's contents under the Content tab."
								data-for={randomID}
							/>
						</div>
					</div>
					<div className="flex items-center gap-x-4">
						<MaterialSwitch
							color="primary"
							className="mx-2"
							id="show_controls"
							checked={showControls}
							name="show_controls"
							onChange={(event, checked) => {
								setShowControls(checked ? true : false);
							}}
						/>
						<div className="flex gap-x-2">
							<label
								className="text-sm font-normal text-[#222222]"
								htmlFor="show_controls"
							>
								Show video player controls
							</label>
							<img
								src={infoIcon}
								alt="info"
								data-tip="When toggled on, the video player controls will be visible at the time of launch."
								data-for={randomID}
							/>
						</div>
					</div>
				</div>
			</div>
			<hr className="border border-[#d9d9d9]" />
			<div className="mt-6 ml-6">
				<p className="text-base font-medium text-[#222222]">Teaser style</p>
				<div className="flex flex-col my-6 mx-5 gap-y-5">
					<div className="flex items-center gap-x-4">
						<StyledRadio
							onClick={() => {
								setPlayInline(false);
								setIsSneak(undefined);
								setIsCta(undefined);
							}}
							checked={
								!playInline && isSneak === undefined && isCta === undefined
							}
							id="show_preview"
							name="show_preview"
						/>
						<div className="flex flex-col gap-y-1 w-[17.31rem]">
							<p className="font-normal text-sm text-[#222222]">Thumbnail</p>
							<p className="font-normal text-[#666666] text-[13px]">
								A static image represents this embed. The preview will only show
								it after it has been saved.
							</p>
						</div>
					</div>
					{!playInline && isSneak === undefined && isCta === undefined && (
						<div className="flex flex-row mx-14 gap-x-10">
							<div className="flex flex-col justify-center items-center">
								{selectedDemo ? (
									<div
										className={
											showCustomThumbnail
												? "w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
												: "w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
										}
										onClick={() => {
											setShowCustomThumbnail(false);
										}}
									>
										<img
											className="w-full h-full object-cover rounded-xl"
											src={`${process.env.REACT_APP_CDN}${
												demoOptions.find(
													(demo) => demo.value === selectedDemo.demo.value
												)?.thumbnail
											}`}
											alt="Default thumbnail"
										/>
									</div>
								) : (
									<div
										className={
											showCustomThumbnail
												? "w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
												: "w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
										}
										onClick={() => {
											setShowCustomThumbnail(false);
										}}
									></div>
								)}
								<div className="flex flex-row gap-x-1 justify-center items-center">
									<StyledRadio
										onClick={() => {
											setShowCustomThumbnail(false);
										}}
										checked={!showCustomThumbnail}
										id="show_custom_thumbnail"
										name="show_custom_thumbnail"
									/>
									<p className="font-normal text-sm text-[#222222]">Default</p>
								</div>
							</div>
							<div className="flex flex-col justify-centeer items-center">
								{thumbnailBlobUrl ? (
									<div
										className={
											!showCustomThumbnail
												? "relative w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
												: "relative w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
										}
										onClick={() => {
											setShowCustomThumbnail(true);
										}}
									>
										<img
											className="relative w-full h-full object-cover rounded-xl"
											src={thumbnailBlobUrl}
											alt="Default thumbnail"
										/>
										<img
											className="absolute -right-4 -top-4 w-[34px] h-[34px]"
											src={closeCrossIcon}
											alt="close"
											onClick={(e) => {
												e.stopPropagation();
												setShowCustomThumbnail(false);
												setCustomThumbnail("");
												setThumbnailBlobUrl(undefined);
											}}
										/>
									</div>
								) : customThumbnail && customThumbnail !== "" ? (
									<div
										className={
											!showCustomThumbnail
												? "relative w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
												: "relative w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
										}
										onClick={() => {
											setShowCustomThumbnail(true);
										}}
									>
										<img
											className="relative w-full h-full object-cover rounded-xl"
											src={`${process.env.REACT_APP_CDN}${customThumbnail}`}
											alt="Custom thumbnail"
										/>
										<img
											className="absolute -right-4 -top-4 w-[34px] h-[34px]"
											src={closeCrossIcon}
											alt="close"
											onClick={(e) => {
												e.stopPropagation();
												setShowCustomThumbnail(false);
												setCustomThumbnail("");
											}}
										/>
									</div>
								) : (
									<div
										className={
											!showCustomThumbnail
												? "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
												: "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
										}
										onClick={() => {
											openThumbnailFileSelector();
										}}
									>
										<img
											className="w-[36px] h-[36px]"
											src={embedPlusCircle}
											alt="No thumbnail"
										/>
										<p className="text-center text-[#514C4C] text-xs mx-4 mt-2">
											(Try to match the thumbnail to the video's dimensions to
											avoid cropping)
										</p>
									</div>
								)}
								<div className="flex flex-row gap-x-1 justify-center items-center">
									<StyledRadio
										onClick={() => {
											if (!thumbnailBlobUrl && customThumbnail === "") {
												openThumbnailFileSelector();
											} else {
												setShowCustomThumbnail(true);
											}
										}}
										checked={showCustomThumbnail}
										id="show_custom_thumbnail"
										name="show_custom_thumbnail"
									/>
									<p className="font-normal text-sm text-[#222222]">Custom</p>
								</div>
							</div>
						</div>
					)}
					{selectedDemo &&
						demoOptions.find((demo) => demo.value === selectedDemo.demo.value)
							?.recordingType === "default" && (
							<div className="flex items-center gap-x-4">
								<StyledRadio
									onClick={() => {
										setPlayInline(true);
										setIsSneak(undefined);
										setIsCta(undefined);
									}}
									checked={
										playInline && isSneak === undefined && isCta === undefined
									}
									id="show_preview"
									name="show_preview"
								/>
								<div className="flex flex-col gap-y-1 w-[17.31rem]">
									<p className="font-normal text-sm text-[#222222]">
										Animation
									</p>
									<p className="font-normal text-[#666666] text-[13px]">
										A short animation represents the embed. Check the preview to
										see how it looks.
									</p>
								</div>
							</div>
						)}
					<div className="flex items-center gap-x-4">
						<StyledRadio
							onClick={() => {
								setPlayInline(undefined);
								setIsCta(undefined);
								setIsSneak(true);
							}}
							checked={
								playInline === undefined && isCta === undefined && isSneak
							}
							id="show_preview"
							name="show_preview"
						/>
						<div className="flex flex-col gap-y-1 w-[17.31rem]">
							<p className="font-normal text-sm text-[#222222]">Hotspot</p>
							<p className="font-normal text-[#666666] text-[13px]">
								There is no image or animation, just a blinking hotspot that
								launches the player upon click.
							</p>
						</div>
					</div>
					<div className="flex items-center gap-x-4">
						<StyledRadio
							onClick={() => {
								setPlayInline(undefined);
								setIsSneak(undefined);
								setIsCta(true);
							}}
							checked={
								playInline === undefined && isSneak === undefined && isCta
							}
							id="is_cta"
							name="is_cta"
						/>
						<div className="flex flex-col gap-y-1 w-[17.31rem]">
							<p className="font-normal text-sm text-[#222222]">CTA</p>
							<p className="font-normal text-[#666666] text-[13px]">
								There is no image or animation, just a CTA that launches the
								embed upon click. Styling comes from global settings.
							</p>
						</div>
					</div>
					{playInline === undefined && isSneak === undefined && isCta && (
						<>
							<div className="flex mx-14 gap-x-2 items-center">
								<input
									className="p-0 pl-2 w-[17.31rem] h-[2.25rem] border border-[#C5C7D0] rounded"
									id="ctaText"
									type="input"
									name="ctaText"
									onChange={(e) => setCtaText(e.currentTarget.value)}
									value={ctaText}
									placeholder="Enter text for this CTA"
								/>
							</div>
							<div className="flex flex-row mx-14 gap-x-10">
								<div className="flex flex-col justify-center items-center">
									<div
										className={
											ctaType !== EMBED_CTA_TYPES.LINK
												? "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
												: "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
										}
										onClick={() => {
											setCtaType(EMBED_CTA_TYPES.LINK);
										}}
									>
										<p
											className="text-center mx-4 mt-2"
											style={{
												font: "initial",
												// fontFamily: "Nunito Sans",
												padding: "10px 24px",
												fontStyle: "normal",
												fontWeight: 700,
												fontSize: "20px",
												// lineHeight: "160%",
												minWidth: "115px",
												// height: "50px",
												justifyContent: "right",
												backgroundColor: "#FFFFFF",
												color: websiteStyle.buttonBgColor,
												cursor: "pointer",
												borderRadius: "4px",
												border: "none",
											}}
										>
											{ctaText}
										</p>
									</div>
									<div className="flex flex-row gap-x-1 justify-center items-center">
										<StyledRadio
											onClick={() => {
												setCtaType(EMBED_CTA_TYPES.LINK);
											}}
											checked={ctaType === EMBED_CTA_TYPES.LINK}
											id="cta_type"
											name="cta_type"
										/>
										<p className="font-normal text-sm text-[#222222]">Link</p>
									</div>
								</div>
								<div className="flex flex-col justify-centeer items-center">
									<div
										className={
											ctaType !== EMBED_CTA_TYPES.BUTTON
												? "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
												: "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
										}
										onClick={() => {
											setCtaType(EMBED_CTA_TYPES.BUTTON);
										}}
									>
										<p
											className={`text-center mx-4 mt-2 hover:bg-[${websiteStyle.buttonBgHoverColor}]`}
											style={{
												font: "initial",
												fontFamily: "Nunito Sans",
												padding: "10px 24px",
												fontStyle: "normal",
												fontWeight: 700,
												fontSize: "20px",
												// lineHeight: "160%",
												minWidth: "115px",
												// height: "50px",
												justifyContent: "right",
												backgroundColor: websiteStyle.buttonBgColor,
												color: websiteStyle.bgColor,
												cursor: "pointer",
												borderRadius: "4px",
												border: "none",
											}}
										>
											{ctaText}
										</p>
									</div>
									<div className="flex flex-row gap-x-1 justify-center items-center">
										<StyledRadio
											onClick={() => {
												setCtaType(EMBED_CTA_TYPES.BUTTON);
											}}
											checked={ctaType === EMBED_CTA_TYPES.BUTTON}
											id="cta_type"
											name="cta_type"
										/>
										<p className="font-normal text-sm text-[#222222]">Button</p>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			<hr className="border border-[#d9d9d9]" />
			{!isCta && (
				<div className="mt-6 ml-6">
					<p className="text-base font-medium text-[#222222]">Launch button</p>
					<div className="my-6 mx-5">
						<p className="text-sm font-medium text-[#222222]">Style</p>
						<div className="flex flex-row justify-start items-center my-4 mx-12 gap-x-10">
							<button
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setDisplayBlinkerStylePicker(false);
									playStylePickerClickHandler();
								}}
								className={
									blinkerStyle === "PLAY"
										? "relative flex justify-center items-center border-2 border-[#1A73E8] w-[110px] h-[70px] rounded-xl"
										: "relative flex justify-center items-center border border-[#d9d9d9] w-[110px] h-[70px] rounded-xl"
								}
							>
								<div
									className="flex justify-center items-center gap-x-3"
									ref={popoverPlayStylePicker}
								>
									<div className="relative">
										<img
											className={blinkerSize === "SMALL" ? "" : "hidden"}
											src={playLaunchStyleMini}
											alt="play button"
										/>
										<img
											className={blinkerSize === "LARGE" ? "" : "hidden"}
											src={playLaunchStyleImg}
											alt="play button"
										/>
									</div>
									<div>
										{displayPlayStylePicker ? (
											<img
												className="relative svg-embed-icon-gray"
												src={arrowupIcon}
												alt="up"
											/>
										) : (
											<img
												className="relative svg-embed-icon-gray"
												src={arrowdownIcon}
												alt="down"
											/>
										)}
									</div>
								</div>
								<div>
									{displayPlayStylePicker && (
										<div className="flex flex-wrap w-[286px] h-[100px] p-2 absolute left-0 mt-3 z-10 border rounded-lg bg-white shadow-md">
											<button
												className={
													"small" === blinkerSize.toLowerCase()
														? "flex items-center justify-center p-3 w-1/2 border border-1 border-[#D9D9D9] rounded bg-[#FAFAFA]"
														: "flex items-center justify-center p-3 w-1/2 border border-1 border-white rounded hover:border hover:border-[#D9D9D9]"
												}
												onClick={() => {
													setBlinkerSize("SMALL");
													setBlinkerStyle("PLAY");
												}}
											>
												<div className="flex items-center justify-start gap-x-3">
													<p className="text-sm whitespace-nowrap">Small</p>
													<img src={playLaunchStyleMini} alt="play button" />
												</div>
											</button>
											<button
												className={
													"large" === blinkerSize.toLowerCase()
														? "flex items-center justify-center p-3 w-1/2 border border-1 border-[#D9D9D9] rounded bg-[#FAFAFA]"
														: "flex items-center justify-center p-3 w-1/2 border border-1 border-white rounded hover:border hover:border-[#D9D9D9]"
												}
												onClick={() => {
													setBlinkerSize("LARGE");
													setBlinkerStyle("PLAY");
												}}
											>
												<div className="flex items-center justify-start gap-x-3">
													<p className="text-sm whitespace-nowrap">Large</p>
													<img src={playLaunchStyleImg} alt="play button" />
												</div>
											</button>
										</div>
									)}
								</div>
							</button>
							<div className="flex flex-col justify-centeer items-center">
								{iconBlobUrl ? (
									<div
										className={
											blinkerStyle === "CUSTOM"
												? "relative flex justify-center items-center border-2 hover:cursor-pointer border-[#1A73E8] w-[110px] h-[70px] rounded-xl"
												: "relative flex justify-center items-center border hover:cursor-pointer border-[#d9d9d9] w-[110px] h-[70px] rounded-xl"
										}
										onClick={(e) => {
											setBlinkerStyle("CUSTOM");
											e.preventDefault();
											e.stopPropagation();
											setDisplayBlinkerStylePicker(false);
											setDisplayPlayStylePicker(false);
										}}
									>
										<img
											className="relative object-contain rounded-xl w-[32px] h-[32px]"
											src={iconBlobUrl}
											alt="Custom icon"
										/>
										<img
											className="absolute -right-4 -top-4 w-[34px] h-[34px]"
											src={closeCrossIcon}
											alt="close"
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setDisplayBlinkerStylePicker(false);
												setDisplayPlayStylePicker(false);
												setBlinkerStyle("PLAY");
												setCustomBlinker("");
												setIconBlobUrl(undefined);
											}}
										/>
									</div>
								) : customBlinker ? (
									<div
										className={
											blinkerStyle !== "CUSTOM"
												? "relative flex justify-center items-center border hover:cursor-pointer border-[#d9d9d9] w-[110px] h-[70px] rounded-xl"
												: "relative flex justify-center items-center border-2 hover:cursor-pointer border-[#1A73E8] w-[110px] h-[70px] rounded-xl"
										}
										onClick={() => {
											setBlinkerStyle("CUSTOM");
										}}
									>
										<img
											className="relative object-contain rounded-xl w-[32px] h-[32px]"
											src={`${process.env.REACT_APP_CDN}${customBlinker}`}
											alt="Custom blinker"
										/>
										<img
											className="absolute -right-4 -top-4 w-[34px] h-[34px]"
											src={closeCrossIcon}
											alt="close"
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setDisplayBlinkerStylePicker(false);
												setDisplayPlayStylePicker(false);
												setBlinkerStyle("PLAY");
												setCustomBlinker("");
											}}
										/>
									</div>
								) : (
									<div
										className={
											blinkerStyle !== "CUSTOM"
												? "relative flex flex-col justify-center items-center w-[110px] h-[70px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
												: "relative flex flex-col justify-center items-center w-[110px] h-[70px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
										}
										onClick={() => {
											openIconFileSelector();
										}}
									>
										<img
											className="w-[32px] h-[32px]"
											src={embedPlusCircle}
											alt="No icon"
										/>
										<p className="text-center text-[#514C4C] text-xs mx-4 mt-2">
											(Add icon)
										</p>
									</div>
								)}
							</div>
							<button
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setDisplayPlayStylePicker(false);
									blinkerStylePickerClickHandler();
								}}
								className={
									blinkerStyle !== "PLAY" && blinkerStyle !== "CUSTOM"
										? "relative px-3 border-2 border-[#1A73E8] w-[110px] h-[70px] rounded-xl"
										: "relative px-3 border border-[#d9d9d9] w-[110px] h-[70px] rounded-xl"
								}
							>
								<div
									className="flex justify-center items-center gap-x-3"
									ref={popoverBlinkerStylePicker}
								>
									<div
										className={`relative ${
											blinkerStyle !== "PLAY" && blinkerStyle !== "CUSTOM"
												? BLINKER_PACK[blinkerStyle.toUpperCase()]
												: BLINKER_PACK["DARK"]
										} ${
											!isSneak
												? blinkerStyle.toUpperCase() === "SLACK"
													? "scale-1"
													: "scale-150"
												: blinkerStyle.toUpperCase() === "SLACK"
												? "scale-[55%]"
												: "scale-75"
										}`}
									/>
									<div>
										{displayBlinkerStylePicker ? (
											<img
												className="relative svg-embed-icon-gray"
												src={arrowupIcon}
												alt="up"
											/>
										) : (
											<img
												className="relative svg-embed-icon-gray"
												src={arrowdownIcon}
												alt="down"
											/>
										)}
									</div>
								</div>
								{displayBlinkerStylePicker && (
									<BlinkersPack
										selected={blinkerStyle}
										onClick={(e, type) => {
											setBlinkerStyle(type);
										}}
									/>
								)}
							</button>
						</div>
					</div>
					{blinkerStyle !== "CUSTOM" && (
						<div className="my-6 mx-5">
							<p className="text-sm font-medium text-[#222222]">Colors</p>
							{blinkerStyle === "PLAY" ? (
								<div className="flex justify-start items-center my-4 mx-12 gap-x-5">
									<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
										<p className="text-sm font-medium text-[#222222]">
											Circle - Default
										</p>
										<button
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setDisplayTriangleColorPicker(false);
												setDisplayHoverColorPicker(false);
												circlePickerClickHandler();
											}}
											className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D]"
										>
											<div className="flex justify-between items-center h-[1.88rem]">
												<p className="mx-3 text-sm font-normal text-[#222222]">
													{launchCircleColor}
												</p>
												<div className="mx-1">
													<div className="border rounded">
														<div
															className="w-[1.06rem] h-[1.06rem]"
															style={{
																background: launchCircleColor,
															}}
														/>
													</div>
													{displayCircleColorPicker && (
														<div
															className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
															ref={popoverCirclePicker}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
															}}
														>
															<HexColorPicker
																color={launchCircleColor}
																onChange={(color) => {
																	setLaunchCircleColor(color);
																}}
															/>
															<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																<label>HEX</label>
																<HexColorInput
																	className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																	color={launchCircleColor}
																	onChange={(color) =>
																		setLaunchCircleColor(color)
																	}
																	placeholder="Type hex value of color"
																	prefixed
																	alpha={false}
																/>
															</div>
														</div>
													)}
												</div>
											</div>
										</button>
									</div>
									<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
										<p className="text-sm font-medium text-[#222222]">
											Circle - Hover
										</p>
										<button
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setDisplayTriangleColorPicker(false);
												setDisplayCircleColorPicker(false);
												hoverPickerClickHandler();
											}}
											className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D]"
										>
											<div className="flex justify-between items-center h-[1.88rem]">
												<p className="mx-3 text-sm font-normal text-[#222222]">
													{launchHoverColor}
												</p>
												<div className="mx-1">
													<div className="border rounded">
														<div
															className="w-[1.06rem] h-[1.06rem]"
															style={{
																background: launchHoverColor,
															}}
														/>
													</div>
													{displayHoverColorPicker && (
														<div
															className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
															ref={popoverHoverPicker}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
															}}
														>
															<HexColorPicker
																color={launchHoverColor}
																onChange={(color) => {
																	setLaunchHoverColor(color);
																}}
															/>
															<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																<label>HEX</label>
																<HexColorInput
																	className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																	color={launchHoverColor}
																	onChange={(color) =>
																		setLaunchHoverColor(color)
																	}
																	placeholder="Type hex value of color"
																	prefixed
																	alpha={false}
																/>
															</div>
														</div>
													)}
												</div>
											</div>
										</button>
									</div>
									<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
										<p className="text-sm font-medium text-[#222222]">
											Triangle
										</p>
										<button
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setDisplayCircleColorPicker(false);
												setDisplayHoverColorPicker(false);
												trianglePickerClickHandler();
											}}
											className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D]"
										>
											<div className="flex justify-between items-center h-[1.88rem]">
												<p className="mx-3 text-sm font-normal text-[#222222]">
													{launchTriangleColor}
												</p>
												<div className="mx-1">
													<div className="border rounded">
														<div
															className="w-[1.06rem] h-[1.06rem]"
															style={{
																background: launchTriangleColor,
															}}
														/>
													</div>
													{displayTriangleColorPicker && (
														<div
															className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
															ref={popoverTrianglePicker}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
															}}
														>
															<HexColorPicker
																color={launchTriangleColor}
																onChange={(color) => {
																	setLaunchTriangleColor(color);
																}}
															/>
															<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																<label>HEX</label>
																<HexColorInput
																	className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																	color={launchTriangleColor}
																	onChange={(color) =>
																		setLaunchTriangleColor(color)
																	}
																	placeholder="Type hex value of color"
																	prefixed
																	alpha={false}
																/>
															</div>
														</div>
													)}
												</div>
											</div>
										</button>
									</div>
								</div>
							) : (
								<div className="flex justify-start items-center my-4 mx-12 gap-x-5">
									<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
										<p className="text-sm font-medium text-[#222222]">
											Hotspot color
										</p>
										<button
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												hotspotPickerClickHandler();
											}}
											className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D]"
										>
											<div className="flex justify-between items-center h-[1.88rem]">
												<p className="mx-3 text-sm font-normal text-[#222222]">
													{launchHotspotColor}
												</p>
												<div className="mx-1">
													<div className="border rounded">
														<div
															className="w-[1.06rem] h-[1.06rem]"
															style={{
																background: launchHotspotColor,
															}}
														/>
													</div>
													{displayHotspotColorPicker && (
														<div
															className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
															ref={popoverHotspotPicker}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
															}}
														>
															<HexColorPicker
																color={launchHotspotColor}
																onChange={(color) => {
																	setLaunchHotspotColor(color);
																}}
															/>
															<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																<label>HEX</label>
																<HexColorInput
																	className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																	color={launchHotspotColor}
																	onChange={(color) =>
																		setLaunchHotspotColor(color)
																	}
																	placeholder="Type hex value of color"
																	prefixed
																	alpha={false}
																/>
															</div>
														</div>
													)}
												</div>
											</div>
										</button>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const ContactTagTypes = {
	CONTACT_LIST: "contact_list",
	SINGLE_CONTACT_LIST: "single_contact_list"
};

export const contactsApiSlice = createApi({
	reducerPath: "api/contact",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().sessionData.token;
			if (token) {
				headers.set("token", token);
			}
			return headers;
		},
	}),
	tagTypes: Object.values(ContactTagTypes),
	endpoints: (builder) => ({
		getAllContactLists: builder.query({
			query: (initialPost) => "/contact/company/" + initialPost.companyId,
			providesTags: [ContactTagTypes.CONTACT_LIST],
		}),
		getContactList: builder.query({
			query: (initialPost) => "/contact/" + initialPost.id,
			providesTags: [ContactTagTypes.SINGLE_CONTACT_LIST],
		}),
		createContactList: builder.mutation({
			query: (initialPost) => ({
				url: "/contact/",
				method: "POST",
				body: {
					dateCreated: initialPost.dateCreated,
					contactBank: initialPost.contactBank,
					name: initialPost.name,
					companyId: initialPost.companyId,
					salesRepId: initialPost.salesRepId
				},
			}),
			invalidatesTags: [ContactTagTypes.CONTACT_LIST, ContactTagTypes.SINGLE_CONTACT_LIST],
		}),
		updateContactList: builder.mutation({
			query: (initialPost) => ({
				url: `/contact/${initialPost.id}`,
				body: {
					contactBank: initialPost.contactBank,
					name: initialPost.name,
				},
				method: "PUT",
			}),
			invalidatesTags: [ContactTagTypes.CONTACT_LIST, ContactTagTypes.SINGLE_CONTACT_LIST],
		}),
		deleteContactList: builder.mutation({
			query: (initialPost) => ({
				url: `/contact/${initialPost.id}`,
				method: "DELETE",
			}),
			invalidatesTags: [ContactTagTypes.CONTACT_LIST, ContactTagTypes.SINGLE_CONTACT_LIST],
		}),
	}),
});

export const {
	useGetAllContactListsQuery,
	useGetContactListQuery,
	useCreateContactListMutation,
	useUpdateContactListMutation,
	useDeleteContactListMutation,
} = contactsApiSlice;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { RelationTagTypes } from "./relationshipApiSlice";

export const SettingsTagTypes = {
	LOGO_URL: "logo_url",
	LOGO_IMAGE: "logo_image",
	COMPANY_STYLE: "company_style",
	EMAIL_BLOCK_LIST: "email_block_list",
	PAGE_STYLE: "page_style",
	PERMISSION: "permission",
	SINGLE_SOLO: "single-solo",
	GROUP_CYOA: "group_cyoa",
	SINGLE_CYOA: "single_cyoa",
};

export const settingsApiSlice = createApi({
	reducerPath: "api/settings",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().sessionData.token;
			if (token) {
				headers.set("token", token);
			}
			return headers;
		},
	}),
	tagTypes: Object.values(SettingsTagTypes),
	endpoints: (builder) => ({
		getSalesPermissions: builder.query({
			query: (initialPost) =>
				`setting/feature/permission/${initialPost.companyId}`,
			providesTags: [SettingsTagTypes.PERMISSION],
		}),
		getCompanyStyleTemplate: builder.query({
			query: (initialPost) => "setting/company/" + initialPost.companyId,
			providesTags: [SettingsTagTypes.COMPANY_STYLE],
		}),
		getPageStyleTemplate: builder.query({
			query: (initialPost) => "setting/page/" + initialPost.pageId,
			providesTags: [SettingsTagTypes.PAGE_STYLE],
		}),
		getLogo: builder.query({
			query: (initialPost) => "setting/company/logo/" + initialPost.companyId,
			providesTags: [SettingsTagTypes.LOGO_IMAGE, SettingsTagTypes.LOGO_URL],
		}),
		getEmailsBlockList: builder.query({
			query: (initialPost) => "setting/company/emails/" + initialPost.companyId,
			providesTags: [SettingsTagTypes.EMAIL_BLOCK_LIST],
		}),
		updatePageStyle: builder.mutation({
			query: (initialPost) => ({
				url: "setting/page/style/" + initialPost.pageId,
				method: "PUT",
				body: {
					style: initialPost.style,
					placeholders: initialPost.placeholders,
				},
			}),
			invalidatesTags: [
				SettingsTagTypes.PAGE_STYLE,
				SettingsTagTypes.SINGLE_SOLO,
				SettingsTagTypes.SINGLE_CYOA,
				SettingsTagTypes.GROUP_CYOA,
			],
		}),
		updateCompanySettings: builder.mutation({
			query: (initialPost) => ({
				url: "setting/company/style/" + initialPost.companyId,
				method: "PUT",
				body: {
					style: initialPost.style,
					email_block_list: initialPost.email_block_list,
				},
			}),
			invalidatesTags: [
				SettingsTagTypes.COMPANY_STYLE,
				SettingsTagTypes.EMAIL_BLOCK_LIST,
			],
		}),
		insertCompanyLogoURL: builder.mutation({
			query: (initialPost) => ({
				url: "/setting/logo/url/" + initialPost.companyId,
				method: "POST",
				body: {
					url: initialPost.url,
				},
			}),
			invalidatesTags: [SettingsTagTypes.LOGO_URL],
		}),
		insertCompanyLogoImage: builder.mutation({
			query: (initialPost) => ({
				url: "/setting/logo/image/" + initialPost.companyId,
				method: "POST",
				body: {
					image: initialPost.image,
				},
			}),
			invalidatesTags: [SettingsTagTypes.LOGO_IMAGE],
		}),
	}),
});

export const {
	useGetSalesPermissionsQuery,
	useGetCompanyStyleTemplateQuery,
	useGetPageStyleTemplateQuery,
	useGetLogoQuery,
	useGetEmailsBlockListQuery,
	useUpdatePageStyleMutation,
	useUpdateCompanySettingsMutation,
	useInsertCompanyLogoURLMutation,
	useInsertCompanyLogoImageMutation,
} = settingsApiSlice;

import React, { useState, useEffect, useRef, useMemo } from "react";
import uuid from "react-uuid";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import ReactTooltip from "react-tooltip";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Rnd } from "react-rnd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import getBlobDuration from "get-blob-duration";
import { uuid4 } from "@sentry/utils";
import {
	useCreateRecordingMutation,
	useDeleteRecordingMutation,
	useGetRecordingByIdQuery,
	useUpdateRecordingMutation,
} from "../../../../../../features/api/recordingsApiSlice";
import { useSendPreviewDumpMutation } from "../../../../../../features/api/previewApiSlice";
import { PageSpinner } from "../../../../../top-level/PageSpinner";
import Droppable from "../../../../../top-level/Droppable";
import Draggable from "../../../../../top-level/Draggable";
import RectangleEditor from "./components/RectangleEditor";
import HotspotEditor from "./components/HotspotsEditor";

import focalicon from "../../../../../../icons/focal-icon.svg";
import hotspoticon from "../../../../../../icons/hotspot-icon.svg";
import closeCrossIcon from "../../../../../../icons/closecross-icon.svg";
import helpIcon from "../../../../../../icons/help-circle-icon.svg";
import previewIcon from "../../../../../../icons/preview-icon.svg";
import rectangleicon from "../../../../../../icons/rectangle-icon.svg";
import tagicon from "../../../../../../icons/tag-icon.svg";
import zoomicon from "../../../../../../icons/zoom-icon.svg";
import getThumbnailForVideo from "../../../../../../utils/thumbnailExtractor";
import { amplitude } from "../../../../../amplitude/Amplitude";
import {
	DEMO,
	DEMO_CONTROLS,
	DEMO_TYPES,
} from "../../../../../../data/constants";
import { getBackgroundColor } from "../../../../../../utils/avatar";

import "./RecordingEditor.css";
import {
	toastError,
	toastLoading,
	toastSuccess,
} from "../../../../../custom-toast/toasts";
import UploadModal from "./components/UploadModal";
import CancelUploadModal from "./components/CancelUploadModal";

const ffmpeg = createFFmpeg({
	log: false,
	corePath: "https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js",
});

const RecordingEditor = ({
	id,
	blob,
	type: recordingType,
	isNew,
	mediaBlobUrl,
	setId,
	setIsNew,
}) => {
	const history = useHistory();
	// Redux controls
	const sellerName = useSelector((state) => state.sessionData.name);
	const companyId = useSelector((state) => state.sessionData.companyId);
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	// API controls
	const [updateRecording] = useUpdateRecordingMutation();
	const [createRecording] = useCreateRecordingMutation();
	const [sendPreviewDump] = useSendPreviewDumpMutation();
	const [deleteRecording] = useDeleteRecordingMutation();
	// Meta controls
	const [ffmpegIsReady, setFfmpegIsReady] = useState(false);
	// Video controls
	const [intrinsicVideoHeight, setIntrinsicVideoHeight] = useState();
	const [intrinsicVideoWidth, setIntrinsicVideoWidth] = useState();
	const [totalDuration, setTotalDuration] = useState(null);
	const [videoIsReady, setVideoIsReady] = useState(false);
	// Thumbnail controls
	const thumbnailRef = useRef();
	const [onThumbnail, setOnThumbnail] = useState(true);
	// Player controls
	const playerRef = useRef();
	const [playing, setPlaying] = useState(true);
	const [playingDemo, setPlayingDemo] = useState(true);
	// Hotspot controls
	const [hotspotList, setHotspotList] = useState([]);
	const [defaultCaptionColor, setDefaultCaptionColor] = useState("#000000");
	const [defaultHotspotColor, setDefaultHotspotColor] = useState("#B9C3D6");
	const [enableHotspot, setEnableHotspot] = useState(false);
	const [hotspotMetrics, setHotspotMetrics] = useState({});
	// Rectangle controls
	const [rectangleList, setRectangleList] = useState([]);
	const [defaultBorderColor, setDefaultBorderColor] = useState("#000000");
	const [defaultBackgroundColor, setDefaultBackgroundColor] =
		useState("#B9C3D6");
	const [enableRectangle, setEnableRectangle] = useState(false);
	const [rectMetrics, setRectMetrics] = useState({});
	// Caption controls
	const [enableCaption, setEnableCaption] = useState(false);
	const [captionValue, setCaptionValue] = useState();
	const [captionMetrics, setCaptionMetrics] = useState({});
	// Uploading controls
	const [isSaving, setIsSaving] = useState(false);
	const [isCanceling, setIsCanceling] = useState(false);
	const [confirmCancelUpload, setConfirmCancelUpload] = useState(false);
	const [cancelInProgress, setCancelInProgress] = useState(false);

	useEffect(() => {
		load().then(() => {
			if (isNew && videoIsReady) {
				setIsSaving(true);
				addNewRecording({
					title: `${DEMO} - ${new Date().toUTCString()}`,
					description: "",
				});
			}
		});
	}, [videoIsReady]);

	useEffect(() => {
		if (!isSaving && confirmCancelUpload) {
			try {
				deleteRecording({
					recordingId: id,
				})
					.unwrap()
					.then((res) => {
						if (res.status === "success") {
							toast.dismiss();
							setIsCanceling(false);
							setConfirmCancelUpload(false);
							setCancelInProgress(false);
							history.push("..");
							toastSuccess(`${DEMO} Upload Cancelled!`);
						} else {
							toast.dismiss();
							toastError(`Failed to cancel ${DEMO.toLowerCase()} upload`);
						}
					});
			} catch (err) {
				toastError("Couldn't connect to database");
			}
		}
	}, [confirmCancelUpload, isSaving]);

	async function load() {
		if (ffmpeg.isLoaded()) {
			setFfmpegIsReady(true);
			return;
		} else {
			await ffmpeg.load();
			setFfmpegIsReady(true);
		}
	}

	let initialValues = {
		title: `${DEMO} - ${new Date().toUTCString()}`,
		description: "",
	};

	const sortedControls = useMemo(() => {
		const controls = [
			...hotspotList.map((each, index) => ({
				...each,
				type: "HOTSPOT",
				originalIndex: index,
			})),
			...rectangleList.map((each, index) => ({
				...each,
				type: "RECTANGLE",
				originalIndex: index,
			})),
		];
		return controls.sort((a, b) => a.appearAt - b.appearAt);
	}, [hotspotList, rectangleList]);

	const onPlayHandler = () => {
		setPlaying(true);
		// if (showHotspot) setShowHotspot(false);
	};

	const onPauseHandler = () => {
		setPlaying(false);
	};

	const convertToGif = async (video) => {
		// Write the .mp4 to the FFmpeg file system
		ffmpeg.FS("writeFile", "video1.mp4", await fetchFile(video));
		// Run the FFmpeg command-line tool, converting
		// the .mp4 into .gif file
		await ffmpeg.run(
			"-t",
			"10",
			"-i",
			"video1.mp4",
			"-t",
			"7",
			"-f",
			"gif",
			"out.gif"
		);
		// Read the .gif file back from the FFmpeg file system
		const data = ffmpeg.FS("readFile", "out.gif");
		return new Blob([data.buffer], { type: "image/gif" });
	};

	const fixCodec = async (video) => {
		// Write the .mp4 to the FFmpeg file system
		ffmpeg.FS("writeFile", "video1.mp4", await fetchFile(video));
		// Run the FFmpeg command-line tool, converting
		await ffmpeg.run("-i", "video1.mp4", "-c", "copy", "out.mp4");
		// Read the .gif file back from the FFmpeg file system
		const data = ffmpeg.FS("readFile", "out.mp4");
		return new Blob([data.buffer], { type: "video/mp4" });
	};

	// Logic to display controls inline (incomplete)
	// const onProgressHandler = (e) => {
	// if (!playing) setPlayed(e.playedSeconds);
	// let idxNewHotspot = -1;
	// let newHotspotList = hotspotList;
	/* Logic for displaying hotspots in realtime. */
	// for (let i = 0; i < hotspotList.length; i++) {
	//   if (!hotspotList[i].shown && e.playedSeconds > hotspotList[i].appearAt) {
	//     idxNewHotspot = i;
	//     newHotspotList = newHotspotList.map((el, ind) => {
	//       if (ind == i) {
	//         return { ...el, shown: true };
	//       }
	//       return { ...el };
	//     });
	//     console.log(newHotspotList);
	//     setHotspotList(newHotspotList);
	//     break;
	//   }
	// }
	// if (idxNewHotspot !== -1) {
	//   setSelectedHotspot(idxNewHotspot);
	//   setShowHotspot(true);
	//   setPlaying(false);
	// }
	// };

	// const onHotspotClickHandler = () => {
	//   setPlaying(true);
	//   setShowHotspot(false);
	// };

	const { data, isLoading, isFetching, isError, error } =
		useGetRecordingByIdQuery({
			id: id,
		});

	useEffect(() => {
		if (!isNew && !isLoading) {
			setHotspotList(JSON.parse(data.payload[0].coordinates).hotspots);
			setRectangleList(
				JSON.parse(data.payload[0].coordinates).rectangles ?? []
			);
			setDefaultCaptionColor(
				JSON.parse(data.payload[0].coordinates).defaultCaptionColor ?? "#000000"
			);
			setDefaultHotspotColor(
				JSON.parse(data.payload[0].coordinates).defaultHotspotColor ?? "#B9C3D6"
			);
			setDefaultBackgroundColor(
				JSON.parse(data.payload[0].coordinates).defaultBackgroundColor ??
					"#B9C3D6"
			);
			setDefaultBorderColor(
				JSON.parse(data.payload[0].coordinates).defaultBorderColor ?? "#000000"
			);
			setIntrinsicVideoHeight(
				JSON.parse(data.payload[0].coordinates).intrinsic.intrinsicVideoHeight
			);
			setIntrinsicVideoWidth(
				JSON.parse(data.payload[0].coordinates).intrinsic.intrinsicVideoWidth
			);
			// setDescription(data.payload[0].description);
		}
	}, [data]);

	if (isLoading || isFetching || !ffmpegIsReady)
		return <PageSpinner text={`Loading ${DEMO}s..`} />;

	if (!isNew) {
		initialValues = {
			title: data.payload[0].title,
			description: data.payload[0].description,
		};
	}

	const recordingSchema = Yup.object().shape({
		title: Yup.string().required("Title is required"),
		description: Yup.string(),
	});

	async function addNewRecording(values) {
		if (values.title === "") {
			toastError("Incomplete Data");
		} else {
			amplitude
				.getInstance()
				.logEvent("SaveDemoClicked", { timestamp: new Date().toUTCString() });
			switch (recordingType) {
				case DEMO_TYPES.PUBLIC: // Run gdrive case for public as well
				case DEMO_TYPES.GDRIVE: {
					const alert = toastLoading(`Saving ${DEMO.toLowerCase()}`);
					// const img = await getThumbnailForVideo(mediaBlobUrl);
					// const gif = await convertToGif(mediaBlobUrl);
					// const fixedBlob = await fixCodec(blob);
					const fd = new FormData();
					fd.append("salesrep_id", salesRepId);
					fd.append("company_id", companyId);
					fd.append(
						"coordinates",
						JSON.stringify({
							intrinsic: { intrinsicVideoHeight, intrinsicVideoWidth },
							hotspots: hotspotList,
							rectangles: rectangleList,
							defaultCaptionColor: defaultCaptionColor ?? "#000000",
							defaultBorderColor: defaultBorderColor ?? "#000000",
							defaultHotspotColor: defaultHotspotColor ?? "#B9C3D6",
							defaultBackgroundColor: defaultHotspotColor ?? "#B9C3D6",
						})
					);
					fd.append("type", recordingType);
					fd.append("location", mediaBlobUrl);
					fd.append("thumbnail", "/videos/thumbnail_placeholder.jpeg");
					// fd.append("video", fixedBlob);
					// fd.append("image", img);
					// fd.append("preview", gif);
					fd.append(
						"date_created",
						new Date(Date.now()).toISOString().slice(0, 19).replace("T", " ")
					);
					fd.append(
						"date_modified",
						new Date(Date.now()).toISOString().slice(0, 19).replace("T", " ")
					);
					fd.append("title", values.title);
					fd.append("description", values.description);

					const duration = totalDuration;
					const status = await createRecording(fd).unwrap();
					if (status.status === "success") {
						setId(status.payload);
						amplitude.getInstance().logEvent("DemoUploadComplete", {
							duration: `${duration / 60} minutes`,
							// size: `${size} bytes`,
							timestamp: new Date().toUTCString(),
						});
						amplitude.getInstance().logEvent("DemoSaved", {
							name: values.title,
							description: values.description,
							name_updated: false,
							description_updated: false,
							timestamp: new Date().toUTCString(),
						});
						toast.dismiss(alert);
						if (cancelInProgress) toastSuccess(`${DEMO} Added`);
						setIsSaving(false);
						setIsNew(false);
						// history.goBack();
					} else {
						setIsSaving(false);
						toast.dismiss(alert);
						toastError(`Error adding ${DEMO.toLowerCase()}`);
					}
					break;
				}
				default: {
					// const alert = toastLoading(`Saving content`);
					const img = await getThumbnailForVideo(mediaBlobUrl);
					const gif = await convertToGif(blob);
					const fixedBlob = await fixCodec(blob);
					const fd = new FormData();
					fd.append("salesrep_id", salesRepId);
					fd.append("company_id", companyId);
					fd.append(
						"coordinates",
						JSON.stringify({
							intrinsic: { intrinsicVideoHeight, intrinsicVideoWidth },
							hotspots: hotspotList,
							rectangles: rectangleList,
							defaultCaptionColor: defaultCaptionColor ?? "#000000",
							defaultBorderColor: defaultBorderColor ?? "#000000",
							defaultHotspotColor: defaultHotspotColor ?? "#B9C3D6",
							defaultBackgroundColor: defaultHotspotColor ?? "#B9C3D6",
						})
					);
					fd.append("type", recordingType);
					if (!fixedBlob || fixedBlob.size === 0) {
						console.log(
							"%c[DANGER] Blob size is 0 bytes",
							"color: yellow; background-color: red; padding: 2px"
						);
					}
					fd.append("video", fixedBlob);
					fd.append("image", img);
					fd.append("preview", gif);
					fd.append(
						"date_created",
						new Date(Date.now()).toISOString().slice(0, 19).replace("T", " ")
					);
					fd.append(
						"date_modified",
						new Date(Date.now()).toISOString().slice(0, 19).replace("T", " ")
					);
					fd.append("title", values.title);
					fd.append("description", values.description);

					let duration = 0;
					const size = blob.size;
					getBlobDuration(blob).then(function (blobDuration) {
						duration = blobDuration;
					});

					const status = await createRecording(fd).unwrap();
					if (status.status === "success") {
						setId(status.payload);
						amplitude.getInstance().logEvent("DemoUploadComplete", {
							duration: `${duration / 60} minutes`,
							size: `${size} bytes`,
							timestamp: new Date().toUTCString(),
						});
						amplitude.getInstance().logEvent("DemoSaved", {
							name: values.title,
							description: values.description,
							name_updated: false,
							description_updated: false,
							timestamp: new Date().toUTCString(),
						});
						toast.dismiss(alert);
						toastSuccess(`${DEMO} Added`);
						setIsSaving(false);
						setIsNew(false);
						// history.goBack();
					} else {
						setIsSaving(false);
						toast.dismiss(alert);
						toastError(`Error adding ${DEMO.toLowerCase()}`);
					}
					break;
				}
			}
		}
	}

	async function updateExistingRecording(values) {
		if (values.title === "") {
			toastError("Incomplete Data");
		} else {
			const alert = toastLoading("Saving changes");
			const recordingData = {
				title: values.title,
				description: values.description,
				recordingId: id,
				dateModified: new Date(Date.now())
					.toISOString()
					.slice(0, 19)
					.replace("T", " "),
				coordinates: JSON.stringify({
					intrinsic: { intrinsicVideoHeight, intrinsicVideoWidth },
					hotspots: hotspotList,
					rectangles: rectangleList,
					defaultCaptionColor: defaultCaptionColor ?? "#000000",
					defaultBorderColor: defaultBorderColor ?? "#000000",
					defaultHotspotColor: defaultHotspotColor ?? "#B9C3D6",
					defaultBackgroundColor: defaultHotspotColor ?? "#B9C3D6",
				}),
			};
			const status = await updateRecording(recordingData).unwrap();
			if (status.status === "success") {
				amplitude.getInstance().logEvent("DemoSaved", {
					name: values.title,
					description: values.description,
					name_updated: values.title !== data.payload[0].title,
					description_updated: data.payload[0].description,
					timestamp: new Date().toUTCString(),
				});
				toast.dismiss(alert);
				toastSuccess(`Changes saved`);
				// history.goBack();
			} else {
				toast.dismiss(alert);
				toastError(`Error updating ${DEMO.toLowerCase()}`);
			}
		}
	}

	const onReadyHandler = () => {
		const vidRef = playerRef.current.getInternalPlayer();
		setIntrinsicVideoHeight(vidRef.videoHeight);
		setIntrinsicVideoWidth(vidRef.videoWidth);
		if (vidRef.duration === Infinity) {
			vidRef.currentTime = 1e101;
			vidRef.ontimeupdate = () => {
				vidRef.ontimeupdate = () => {
					return;
				};
				vidRef.currentTime = 0;
			};
		}
		setTotalDuration(vidRef.duration);
		setVideoIsReady(true);
	};

	const onDropHandler = (item, monitor) => {
		const videoBoundingRect = playerRef.current
			.getInternalPlayer()
			.getBoundingClientRect();
		const onDropCoordinates = monitor.getClientOffset();
		// Disallow drop when out of video bounds.
		if (
			onDropCoordinates.x < videoBoundingRect.left ||
			onDropCoordinates.x > videoBoundingRect.right ||
			onDropCoordinates.y < videoBoundingRect.top ||
			onDropCoordinates.y > videoBoundingRect.bottom
		)
			return;
		switch (item.name) {
			case "HOTSPOT": {
				setHotspotMetrics({
					x: onDropCoordinates.x - videoBoundingRect.left,
					y: onDropCoordinates.y - videoBoundingRect.top,
					width: 45,
					height: 45,
				});
				setCaptionMetrics({
					x: onDropCoordinates.x - 50 - videoBoundingRect.left,
					y: onDropCoordinates.y + 50 - videoBoundingRect.top,
					width: 150,
					height: 50,
				});
				setCaptionValue(null);
				setEnableHotspot(true);
				break;
			}
			case "RECTANGLE": {
				setRectMetrics({
					x: onDropCoordinates.x - videoBoundingRect.left,
					y: onDropCoordinates.y - videoBoundingRect.top,
					width: 100,
					height: 50,
				});
				setCaptionMetrics({
					x: onDropCoordinates.x - 50 - videoBoundingRect.left,
					y: onDropCoordinates.y + 100 - videoBoundingRect.top,
					width: 150,
					height: 50,
				});
				setCaptionValue(null);
				setEnableRectangle(true);
				break;
			}
			default:
				break;
		}
		// return crucial for DnD module
		return { name: "Video" };
	};

	const onChangeCaption = (index, caption, control = DEMO_CONTROLS.HOTSPOT) => {
		switch (control) {
			case DEMO_CONTROLS.HOTSPOT: {
				const newHotspotList = hotspotList.map((el, idx) => {
					if (idx === index) return { ...el, caption: caption };
					return { ...el };
				});
				setHotspotList(newHotspotList);
				break;
			}
			case DEMO_CONTROLS.RECTANGLE: {
				const newRectangleList = rectangleList.map((el, idx) => {
					if (idx === index) return { ...el, caption: caption };
					return { ...el };
				});
				setRectangleList(newRectangleList);
				break;
			}
			default:
				console.log("Control not found");
				break;
		}
	};

	const hotspotTimestampHandler = (index, timestamp) => {
		const newHotspotList = hotspotList.map((el, idx) => {
			if (idx === index) return { ...el, appearAt: timestamp };
			return { ...el };
		});
		setHotspotList(newHotspotList);
	};

	const rectangleTimestampHandler = (index, timestamp) => {
		const newRectangleList = rectangleList.map((el, idx) => {
			if (idx === index) return { ...el, appearAt: timestamp };
			return { ...el };
		});
		setRectangleList(newRectangleList);
	};

	const onSelectCaptionColor = (
		index,
		color,
		control = DEMO_CONTROLS.HOTSPOT
	) => {
		switch (control) {
			case DEMO_CONTROLS.HOTSPOT: {
				const newHotspotList = hotspotList.map((el, idx) => {
					if (idx === index) return { ...el, captionColor: color };
					return { ...el };
				});
				setHotspotList(newHotspotList);
				break;
			}
			case DEMO_CONTROLS.RECTANGLE: {
				const newRectangleList = rectangleList.map((el, idx) => {
					if (idx === index) return { ...el, captionColor: color };
					return { ...el };
				});
				setRectangleList(newRectangleList);
				break;
			}
			default:
				console.log("Control not found");
				break;
		}
	};

	const saveDefaultCaptionColor = (color) => {
		setDefaultCaptionColor(color);
	};

	const onSelectBackgroundColor = (index, color) => {
		const newRectangleList = rectangleList.map((el, idx) => {
			if (idx === index) return { ...el, backgroundColor: color };
			return { ...el };
		});
		setRectangleList(newRectangleList);
	};

	const saveDefaultBackgroundColor = (color) => {
		setDefaultBackgroundColor(color);
	};

	const onSelectHotspotColor = (index, color) => {
		const newHotspotList = hotspotList.map((el, idx) => {
			if (idx === index) return { ...el, hotspotColor: color };
			return { ...el };
		});
		setHotspotList(newHotspotList);
		// saveDefaultHotspotColor(color);
	};

	const saveDefaultHotspotColor = (color) => {
		setDefaultHotspotColor(color);
	};

	const hotspotSeekHandler = (appearAt) => {
		playerRef.current.seekTo(appearAt, "seconds");
		setPlaying(false);
	};

	const hotspotDeleteHandler = (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="bg-white w-[655px] h-[246px] rounded-[8px] mx-[72px]">
						<div className="flex flex-col mx-[4.5rem]">
							<div className="flex justify-end items-center mt-[26px]">
								<button onClick={onClose}>
									<img
										src={closeCrossIcon}
										alt="close"
										className="w-[34px] h-[34px]"
									/>
								</button>
							</div>
							<div className="flex flex-col gap-y-[10px]">
								<div className="flex flex-row gap-x-[1rem] items-center">
									<img
										src={helpIcon}
										alt="help"
										className="w-[30px] h-[30px]"
									/>
									<p className="font-medium text-[18px] leading-[18px] text-[#222222]">
										Confirm Delete
									</p>
								</div>
								<div className="flex flex-col ml-[46px] w-[26rem]">
									<p className="text-[15px] leading-[22.5px] text-[#222222]">
										Are you sure you want to delete this?
									</p>
								</div>
							</div>
							<div className="flex flex-row justify-end items-center mt-[33px] gap-x-[18px]">
								<button
									className="w-[3.31rem] h-[2.5rem] flex border items-center justify-center rounded-md text-[#222222] hover:bg-[#E6E9EF] border-[#C5C7D0]"
									onClick={onClose}
								>
									No
								</button>
								<button
									className="w-[4.06rem] h-[2.5rem] flex items-center justify-center rounded-md bg-[#0071F2] text-white hover:bg-[#0351AA]"
									onClick={() => {
										const popup = toastLoading("Deleting Hotspot");
										const newHotspotList = hotspotList.filter((el, idx) => {
											if (idx !== id) return true;
											else return false;
										});
										setHotspotList(newHotspotList);
										toast.dismiss(popup);
										// toastSuccess("Hotspot Deleted!");
										onClose();
									}}
								>
									Yes
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	const onSelectBorderColor = (index, color) => {
		const newRectangleList = rectangleList.map((el, idx) => {
			if (idx === index) return { ...el, borderColor: color };
			return { ...el };
		});
		setRectangleList(newRectangleList);
		// saveDefaultHotspotColor(color);
	};

	const onSelectHotspotStyle = (index, type) => {
		const newHotspotList = hotspotList.map((el, idx) => {
			if (idx === index) return { ...el, hotspotStyle: type };
			return { ...el };
		});
		setHotspotList(newHotspotList);
		// saveDefaultHotspotColor(color);
	};

	const saveDefaultBorderColor = (color) => {
		setDefaultBorderColor(color);
	};

	const rectangleSeekHandler = (appearAt) => {
		playerRef.current.seekTo(appearAt, "seconds");
		setPlaying(false);
	};

	const rectangleDeleteHandler = (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="bg-white w-[655px] h-[246px] rounded-[8px] mx-[72px]">
						<div className="flex flex-col mx-[4.5rem]">
							<div className="flex justify-end items-center mt-[26px]">
								<button onClick={onClose}>
									<img
										src={closeCrossIcon}
										alt="close"
										className="w-[34px] h-[34px]"
									/>
								</button>
							</div>
							<div className="flex flex-col gap-y-[10px]">
								<div className="flex flex-row gap-x-[1rem] items-center">
									<img
										src={helpIcon}
										alt="help"
										className="w-[30px] h-[30px]"
									/>
									<p className="font-medium text-[18px] leading-[18px] text-[#222222]">
										Confirm Delete
									</p>
								</div>
								<div className="flex flex-col ml-[46px] w-[26rem]">
									<p className="text-[15px] leading-[22.5px] text-[#222222]">
										Are you sure you want to delete this?
									</p>
								</div>
							</div>
							<div className="flex flex-row justify-end items-center mt-[33px] gap-x-[18px]">
								<button
									className="w-[3.31rem] h-[2.5rem] flex border items-center justify-center rounded-md text-[#222222] hover:bg-[#E6E9EF] border-[#C5C7D0]"
									onClick={onClose}
								>
									No
								</button>
								<button
									className="w-[4.06rem] h-[2.5rem] flex items-center justify-center rounded-md bg-[#0071F2] text-white hover:bg-[#0351AA]"
									onClick={() => {
										const popup = toastLoading("Deleting Click region");
										const newRectangleList = rectangleList.filter((el, idx) => {
											if (idx !== id) return true;
											else return false;
										});
										setRectangleList(newRectangleList);
										toast.dismiss(popup);
										// toastSuccess("Click region Deleted!");
										onClose();
									}}
								>
									Yes
								</button>
							</div>
						</div>
					</div>
				);
			},
		});
	};

	const cancelHandler = (e) => {
		e.preventDefault();
		history.goBack();
	};

	const preview = async (e, vals) => {
		e.preventDefault();
		if (vals.title === "") {
			toastError("Incomplete Data");
			return;
		}
		const values = JSON.parse(JSON.stringify(vals));
		values.id = data.payload[0].id;
		values.salesrepId = salesRepId;
		values.companyId = companyId;
		values.location = data.payload[0].location;
		values.thumbnail = data.payload[0].thumbnail;
		values.preview = data.payload[0].preview;
		values.type = data.payload[0].type;
		values.coordinates = JSON.stringify({
			intrinsic: { intrinsicVideoHeight, intrinsicVideoWidth },
			hotspots: hotspotList,
			rectangles: rectangleList,
			defaultCaptionColor: defaultCaptionColor ?? "#000000",
			defaultHotspotColor: defaultHotspotColor ?? "#B9C3D6",
		});
		const toast_id = toastLoading();
		const status = await sendPreviewDump({
			dump: JSON.stringify(values),
		}).unwrap();
		if (status.status === "success") {
			toast.dismiss(toast_id);
			const newLink = `${
				process.env.REACT_APP_CLIENT_PROSPECT
			}/preview/demo?uuid=${uuid()}&data_id=${uuid()}-${
				status.payload
			}&template=${companyId}`;
			window.open(newLink, "_blank").focus();
		} else {
			toast.dismiss(toast_id);
			toastError("Unable to preview widget");
		}
	};

	const cancelHotspotHandler = () => {
		setEnableHotspot(false);
	};

	const saveHotspotHandler = () => {
		const vidRef = document.getElementsByTagName("video")[0];
		const videoBoundingRect = vidRef.getBoundingClientRect();
		const hotspot = {
			id: uuid4(),
			needsPlacement: false,
			caption: captionValue,
			captionPercentageFromTop:
				(captionMetrics.y / videoBoundingRect.height) * 100,
			captionPercentageFromLeft:
				(captionMetrics.x / videoBoundingRect.width) * 100,
			captionPercentageHeight:
				(parseFloat(captionMetrics.height, 10) / videoBoundingRect.height) *
				100,
			captionPercentageWidth:
				(parseFloat(captionMetrics.width, 10) / videoBoundingRect.width) * 100,
			captionColor: defaultCaptionColor,
			hotspotColor: defaultHotspotColor,
			appearAt: vidRef.currentTime,
			hotspotStyle: "DARK",
			percentageFromTop: (hotspotMetrics.y / videoBoundingRect.height) * 100,
			percentageFromLeft: (hotspotMetrics.x / videoBoundingRect.width) * 100,
		};
		setHotspotList((currentState) => [...currentState, hotspot]);
		setEnableHotspot(false);
		setCaptionValue(null);
	};

	const cancelRectangleHandler = () => {
		setEnableRectangle(false);
	};

	const saveRectangleHandler = () => {
		const vidRef = document.getElementsByTagName("video")[0];
		const videoBoundingRect = vidRef.getBoundingClientRect();
		const rectangle = {
			id: uuid4(),
			needsPlacement: false,
			caption: captionValue,
			captionPercentageFromTop:
				(captionMetrics.y / videoBoundingRect.height) * 100,
			captionPercentageFromLeft:
				(captionMetrics.x / videoBoundingRect.width) * 100,
			captionPercentageHeight:
				(parseFloat(captionMetrics.height, 10) / videoBoundingRect.height) *
				100,
			captionPercentageWidth:
				(parseFloat(captionMetrics.width, 10) / videoBoundingRect.width) * 100,
			captionColor: defaultCaptionColor,
			borderColor: defaultBorderColor,
			backgroundColor: defaultHotspotColor,
			captionBackgroundColor: defaultHotspotColor,
			appearAt: vidRef.currentTime,
			percentageFromTop: (rectMetrics.y / videoBoundingRect.height) * 100,
			percentageFromLeft: (rectMetrics.x / videoBoundingRect.width) * 100,
			percentageHeight:
				(parseFloat(rectMetrics.height, 10) / videoBoundingRect.height) * 100,
			percentageWidth:
				(parseFloat(rectMetrics.width, 10) / videoBoundingRect.width) * 100,
		};
		setRectangleList((currentState) => [...currentState, rectangle]);
		setEnableRectangle(false);
		setCaptionValue(null);
	};

	const onCancelUpload = () => {
		setIsCanceling(true);
	};

	const onConfirmCancelUpload = async () => {
		const popup = toastLoading("Cancellation in Progress");
		setCancelInProgress(true);
		setConfirmCancelUpload(true);
	};

	return (
		<div className="flex flex-col justify-center">
			<Formik
				initialValues={initialValues}
				validationSchema={recordingSchema}
				onSubmit={
					!isNew
						? (values) => updateExistingRecording(values)
						: (values) => addNewRecording(values)
				}
			>
				{(formik) => {
					const { isValid } = formik;
					const classSubmit =
						"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
					return (
						<div className="w-full mx-auto">
							<Form className="bg-white pb-8 mb-4 w-full">
								<div className="sticky top-0 flex flex-row justify-between py-4 bg-white z-50">
									<div className="flex flex-col w-3/5 mb-4">
										<Field
											className="px-1.5 py-0.5 font-normal leading-5 text-xl font-medium text-[#222222] border border-white hover:border hover:rounded-sm hover:border-zeros-blue focus:rounded-sm focus:border-zeros-blue"
											id="title"
											type="input"
											name="title"
											placeholder={`Title for this ${DEMO.toLowerCase()}`}
										/>
										<ErrorMessage
											name="title"
											component="span"
											className="text-red-500 text-xs italic"
										/>

										{!isNew ? (
											<div className="flex flex-row mx-3 mt-1 gap-x-2 items-center">
												<div className="flex justify-center items-center gap-x-2">
													<div
														className="flex justify-center items-center w-6 h-6 rounded-full text-white"
														style={{
															backgroundColor: getBackgroundColor(
																data.payload[0].salesrep_id
															),
														}}
													>
														{data.payload[0].salesrep_name.trim()[0]}
													</div>
													<p className="text-base font-normal text-[#888888]">
														{data.payload[0].salesrep_name.trim()}
													</p>
												</div>
												<div className="w-1 h-1 rounded-full bg-[#686868]" />
												<p className="text-base font-normal text-[#888888]">
													{new Date(
														data.payload[0].date_created
													).toLocaleDateString("en-us", {
														year: "numeric",
														month: "long",
														day: "numeric",
													})}
												</p>
											</div>
										) : (
											<div className="flex flex-row mx-3 mt-1 gap-x-2 items-center">
												<div className="flex justify-center items-center gap-x-2">
													<div className="flex justify-center items-center w-6 h-6 rounded-full bg-[#FF922E] text-white">
														{sellerName.trim()[0]}
													</div>
													<p className="text-base font-normal text-[#888888]">
														{sellerName.trim()}
													</p>
												</div>
												<div className="w-1 h-1 rounded-full bg-[#686868]" />
												<p className="text-base font-normal text-[#888888]">
													{new Date().toLocaleDateString("en-us", {
														year: "numeric",
														month: "long",
														day: "numeric",
													})}
												</p>
											</div>
										)}
									</div>
									<div className="flex gap-x-[16px]">
										<button
											className={
												!isNew
													? `border w-[44px] h-[44px] rounded-md mt-1 font-medium bg-[#FFFFFF] hover:bg-[#E6E9EF] flex items-center justify-center`
													: `border w-[44px] h-[44px] rounded-md mt-1 font-medium bg-[#E8E8E8] flex items-center justify-center disabled cursor-not-allowed`
											}
											type="button"
											onClick={(e) => preview(e, formik.values)}
											disabled={isNew}
										>
											<img
												src={previewIcon}
												alt="preview"
												className="w-[24px] h-[24px]"
											/>
										</button>
										<button
											className={
												isValid
													? `border w-[4.75rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA]`
													: `border w-[4.75rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium bg-gray-300 hover:bg-gray-400 rounded disabled cursor-not-allowed`
											}
											type="submit"
											disabled={!isValid}
										>
											Save
										</button>
									</div>
								</div>
								<div className="mb-6">
									<label
										className="px-1.5 block text-[#222222] text-base font-medium mb-2"
										htmlFor="description"
									>
										Description
									</label>
									<Field
										className="border rounded w-full py-2.5 px-3 text-[#222222]"
										id="description"
										component="textarea"
										type="description"
										rows={2}
										name="description"
										placeholder="Add a description for this demo"
									/>
								</div>
								<div className="relative">
									<DndProvider backend={HTML5Backend}>
										<Droppable
											className="flex items-center justify-center relative min-h-[32rem]"
											role="Video"
											onDropHandler={onDropHandler}
										>
											<div className="table-cell">
												<ReactPlayer
													light={
														!isNew
															? `${process.env.REACT_APP_CDN}${data.payload[0].thumbnail}`
															: false
													}
													// light={true}
													ref={playerRef}
													onReady={onReadyHandler}
													playing={playing}
													onPlay={onPlayHandler}
													// onProgress={(e) => onProgressHandler(e)}
													onPause={onPauseHandler}
													playIcon={
														<div
															ref={thumbnailRef}
															onClick={() => {
																setOnThumbnail(false);
																setPlaying(true);
															}}
														>
															<svg
																width="99"
																height="99"
																viewBox="0 0 99 99"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<g filter="url(#filter0_b_997_8097)">
																	<rect
																		width="99"
																		height="99"
																		rx="49.5"
																		fill="#222222"
																		fill-opacity={"0.3"} // TODO: Add dynamic opacity upon hover
																	/>
																	<path
																		d="M31.7266 26.9305C31.7266 24.557 34.3523 23.1235 36.3488 24.407L71.8345 47.2192C73.6715 48.4001 73.6715 51.0854 71.8345 52.2663L36.3488 75.0785C34.3523 76.362 31.7266 74.9285 31.7266 72.555V26.9305Z"
																		fill="white"
																	/>
																</g>
																<defs>
																	<filter
																		id="filter0_b_997_8097"
																		x="-7"
																		y="-7"
																		width="113"
																		height="113"
																		filterUnits="userSpaceOnUse"
																		color-interpolation-filters="sRGB"
																	>
																		<feFlood
																			flood-opacity="0"
																			result="BackgroundImageFix"
																		/>
																		<feGaussianBlur
																			in="BackgroundImage"
																			stdDeviation="3.5"
																		/>
																		<feComposite
																			in2="SourceAlpha"
																			operator="in"
																			result="effect1_backgroundBlur_997_8097"
																		/>
																		<feBlend
																			mode="normal"
																			in="SourceGraphic"
																			in2="effect1_backgroundBlur_997_8097"
																			result="shape"
																		/>
																	</filter>
																</defs>
															</svg>
														</div>
													}
													width={
														intrinsicVideoWidth
															? intrinsicVideoHeight > intrinsicVideoWidth
																? intrinsicVideoWidth * 0.4
																: "1100px"
															: "100%"
													}
													height={
														intrinsicVideoHeight
															? intrinsicVideoHeight > intrinsicVideoWidth
																? intrinsicVideoHeight * 0.4
																: "100%"
															: "100%"
													}
													controls={true}
													style={{
														position: "relative",
														backgroundColor: "#000",
														overflow: "hidden",
														borderRadius: "10px",
														border: "1px solid #D9D9D9",
													}}
													type="video/mp4"
													url={
														isNew
															? mediaBlobUrl
															: `${
																	data.payload[0].type === DEMO_TYPES.DEFAULT
																		? process.env.REACT_APP_CDN
																		: ""
															  }${data.payload[0].location}`
													}
												/>
												{enableRectangle && (
													<>
														<Rnd
															className="border border-2 border-[#D9D9D9]"
															size={{
																width: rectMetrics.width,
																height: rectMetrics.height,
															}}
															position={{ x: rectMetrics.x, y: rectMetrics.y }}
															onDragStop={(e, d) =>
																setRectMetrics((state) => ({
																	...state,
																	x: d.x,
																	y: d.y,
																}))
															}
															onResizeStop={(
																e,
																direction,
																ref,
																delta,
																position
															) => {
																setRectMetrics({
																	width: ref.style.width,
																	height: ref.style.height,
																	...position,
																});
															}}
															enableResizing={{
																top: true,
																right: true,
																bottom: true,
																bottomRight: true,
																left: true,
																bottomLeft: true,
																topRight: false,
																topLeft: false,
															}}
															bounds="parent"
														>
															<div
																className="absolute rounded-full w-4 h-4 bg-green-600 -top-3 -right-3 cursor-pointer"
																onClick={saveRectangleHandler}
															/>
															<div
																className="absolute rounded-full w-4 h-4 bg-red-600 -top-3 -left-3 cursor-pointer"
																onClick={cancelRectangleHandler}
															/>
														</Rnd>
														<Rnd
															className="border border-2 rounded-lg"
															size={{
																width: captionMetrics.width,
																height: captionMetrics.height,
															}}
															position={{
																x: captionMetrics.x,
																y: captionMetrics.y,
															}}
															onDragStop={(e, d) =>
																setCaptionMetrics((state) => ({
																	...state,
																	x: d.x,
																	y: d.y,
																}))
															}
															onResizeStop={(
																e,
																direction,
																ref,
																delta,
																position
															) => {
																setCaptionMetrics({
																	width: ref.style.width,
																	height: ref.style.height,
																	...position,
																});
															}}
															enableResizing={{
																top: true,
																right: true,
																bottom: true,
																bottomRight: true,
																left: true,
																bottomLeft: true,
																topRight: true,
																topLeft: true,
															}}
															// minWidth="150px"
															minHeight="48px"
															bounds="parent"
														>
															<textarea
																className="w-full h-full p-3 rounded-lg text-sm cursor-move border-none"
																style={{
																	backgroundColor: defaultHotspotColor,
																	color: defaultCaptionColor,
																	resize: "none",
																}}
																type="text"
																placeholder="Enter caption here"
																maxLength={250}
																value={captionValue}
																onChange={(e) =>
																	setCaptionValue(e.target.value)
																}
															/>
														</Rnd>
													</>
												)}
												{enableHotspot && (
													<>
														<Rnd
															className="border border-2 rounded-full"
															style={{
																borderColor: defaultHotspotColor,
																backgroundColor: defaultHotspotColor,
															}}
															size={{
																width: hotspotMetrics.width,
																height: hotspotMetrics.height,
															}}
															position={{
																x: hotspotMetrics.x,
																y: hotspotMetrics.y,
															}}
															onDragStop={(e, d) =>
																setHotspotMetrics((state) => ({
																	...state,
																	x: d.x,
																	y: d.y,
																}))
															}
															enableResizing={false}
															bounds="parent"
														>
															<div
																className="absolute rounded-full w-4 h-4 bg-green-600 -top-3 -right-3 cursor-pointer"
																onClick={saveHotspotHandler}
															/>
															<div
																className="absolute rounded-full w-4 h-4 bg-red-600 -top-3 -left-3 cursor-pointer"
																onClick={cancelHotspotHandler}
															/>
														</Rnd>
														<Rnd
															className="border border-2 rounded-lg"
															size={{
																width: captionMetrics.width,
																height: captionMetrics.height,
															}}
															position={{
																x: captionMetrics.x,
																y: captionMetrics.y,
															}}
															onDragStop={(e, d) =>
																setCaptionMetrics((state) => ({
																	...state,
																	x: d.x,
																	y: d.y,
																}))
															}
															onResizeStop={(
																e,
																direction,
																ref,
																delta,
																position
															) => {
																setCaptionMetrics({
																	width: ref.style.width,
																	height: ref.style.height,
																	...position,
																});
															}}
															enableResizing={{
																top: true,
																right: true,
																bottom: true,
																bottomRight: true,
																left: true,
																bottomLeft: true,
																topRight: true,
																topLeft: true,
															}}
															// minWidth="150px"
															minHeight="48px"
															bounds="parent"
														>
															<textarea
																className="w-full h-full p-3 rounded-lg text-sm cursor-move border-none"
																style={{
																	backgroundColor: defaultHotspotColor,
																	color: defaultCaptionColor,
																	resize: "none",
																}}
																type="text"
																placeholder="Enter caption here"
																maxLength={250}
																value={captionValue}
																onChange={(e) =>
																	setCaptionValue(e.target.value)
																}
															/>
														</Rnd>
													</>
												)}
												{/* {showHotspot &y (
                      <Hotspot
                        percentageTop={
                          hotspotList[selectedHotspot].percentageFromTop
                        }
                        percentageLeft={
                          hotspotList[selectedHotspot].percentageFromLeft
                        }
                        onHotspotClickHandler={onHotspotClickHandler}
                      />
                    )} */}
											</div>
										</Droppable>
										<div className="flex flex-row w-full my-4 justify-center items-center">
											<div className="flex justify-center items-center w-fit h-[3.75rem] p-2 bg-[#2C2C2C] opacity-90 rounded-xl">
												<ReactTooltip
													id="toolbox"
													effect="solid"
													place="bottom"
													html={true}
												/>
												<div className="flex flex-row divide-x divide-[#7D7D7D]">
													<div className="flex flex-row">
														<Draggable
															playing={playing}
															className="flex justify-center items-center"
															role="HOTSPOT"
														>
															<button
																className={`border-1 m-1 rounded-md bg-[#2C2C2C] hover:bg-[#5C5C5C] ${
																	playing && "disabled cursor-not-allowed"
																}`}
																type="button"
																data-tip='<div class="flex flex-col justify-center items-center"><p class="font-medium font-[Proxima_Nova] text-base text-[EFEEEE]">Hotspot</p><p class="font-normal font-[Proxima_Nova] text-xs text-[EFEEEE]">Drag to apply</p></div>'
																data-for="toolbox"
															>
																<img
																	src={hotspoticon}
																	alt="HOTSPOT"
																	className="min-w-[2.5rem] min-h-[2.5rem]"
																/>
															</button>
														</Draggable>
														<Draggable
															playing={playing}
															className="flex justify-center items-center"
															role="RECTANGLE"
														>
															<button
																className={`border-1 m-1 rounded-md bg-[#2C2C2C] hover:bg-[#5C5C5C] ${
																	playing && "disabled cursor-not-allowed"
																}`}
																type="button"
																data-tip='<div class="flex flex-col justify-center items-center"><p class="font-medium font-[Proxima_Nova] text-base text-[EFEEEE]">Click region</p><p class="font-normal font-[Proxima_Nova] text-xs text-[EFEEEE]">Drag to apply</p></div>'
																data-for="toolbox"
															>
																<img
																	src={rectangleicon}
																	alt="RECTANGLE"
																	className="min-w-[2.5rem] min-h-[2.5rem]"
																/>
															</button>
														</Draggable>
													</div>
													{/* <div className="flex flex-row">
														<Draggable
															playing={playing}
															className="flex justify-center items-center"
															role="Zoom"
														>
															<button
																className={`border-1 m-1 rounded-md bg-[#2C2C2C] hover:bg-[#5C5C5C] ${
																	playing && "disabled cursor-not-allowed"
																}`}
																type="button"
																data-tip='<div class="flex flex-col justify-center items-center"><p class="font-medium font-[Proxima_Nova] text-base text-[EFEEEE]">Zoom</p><p class="font-normal font-[Proxima_Nova] text-xs text-[EFEEEE]">Drag to apply</p></div>'
																data-for="toolbox"
															>
																<img
																	src={zoomicon}
																	alt="Zoom"
																	className="min-w-[2.5rem] min-h-[2.5rem]"
																/>
															</button>
														</Draggable>
														<Draggable
															playing={playing}
															className="flex justify-center items-center"
															role="Focal"
														>
															<button
																className={`border-1 m-1 rounded-md bg-[#2C2C2C] hover:bg-[#5C5C5C] ${
																	playing && "disabled cursor-not-allowed"
																}`}
																type="button"
																data-tip='<div class="flex flex-col justify-center items-center"><p class="font-medium font-[Proxima_Nova] text-base text-[EFEEEE]">Spotlight</p><p class="font-normal font-[Proxima_Nova] text-xs text-[EFEEEE]">Drag to apply</p></div>'
																data-for="toolbox"
															>
																<img
																	src={focalicon}
																	alt="Focal"
																	className="min-w-[2.5rem] min-h-[2.5rem]"
																/>
															</button>
														</Draggable>
													</div> */}
													{/* <div className="flex flex-row">
														<Draggable
															playing={playing}
															className="flex justify-center items-center"
															role="Tag"
														>
															<button
																className={`border-1 m-1 rounded-md bg-[#2C2C2C] hover:bg-[#5C5C5C] ${
																	playing && "disabled cursor-not-allowed"
																}`}
																type="button"
																data-tip='<div class="flex flex-col justify-center items-center"><p class="font-medium font-[Proxima_Nova] text-base text-[EFEEEE]">Tag</p><p class="font-normal font-[Proxima_Nova] text-xs text-[EFEEEE]">Drag to apply</p></div>'
																data-for="toolbox"
															>
																<img
																	src={tagicon}
																	alt="Tag"
																	className="min-w-[2.5rem] min-h-[2.5rem]"
																/>
															</button>
														</Draggable>
													</div> */}
												</div>
											</div>
										</div>
									</DndProvider>
								</div>
								{(hotspotList?.length !== 0 || rectangleList?.length !== 0) && (
									<p className="text-base font-medium text-[#222222] mb-6">
										Annotations
									</p>
								)}
								<div className="flex flex-col items-start gap-y-2 justify-start">
									{sortedControls.length !== 0 &&
										sortedControls.map((el, idx) => {
											if (el.type === "HOTSPOT") {
												return (
													<div key={el.id ?? idx} className="w-full">
														<HotspotEditor
															idx={el.originalIndex}
															appearAt={el.appearAt}
															selectedBackgroundColor={el.hotspotColor}
															onSelectBackgroundColor={onSelectHotspotColor}
															defaultBackgroundColor={defaultHotspotColor}
															selectedCaptionColor={el.captionColor}
															onSelectCaptionColor={onSelectCaptionColor}
															defaultCaptionColor={defaultCaptionColor}
															saveDefaultCaptionColor={saveDefaultCaptionColor}
															saveDefaultBackgroundColor={
																saveDefaultHotspotColor
															}
															caption={el.caption}
															onChangeCaption={onChangeCaption}
															selectedHotspotStyle={el.hotspotStyle}
															onSelectHotspotStyle={onSelectHotspotStyle}
															hotspotTimestampHandler={hotspotTimestampHandler}
															hotspotDeleteHandler={hotspotDeleteHandler}
															hotspotSeekHandler={hotspotSeekHandler}
															totalDuration={totalDuration}
															onThumbnail={onThumbnail}
															thumbnailRef={thumbnailRef}
														/>
													</div>
												);
											}
											return (
												<div key={el.id ?? idx} className="w-full">
													<RectangleEditor
														idx={el.originalIndex}
														appearAt={el.appearAt}
														selectedCaptionColor={el.captionColor}
														onSelectCaptionColor={onSelectCaptionColor}
														defaultCaptionColor={defaultCaptionColor}
														saveDefaultCaptionColor={saveDefaultCaptionColor}
														caption={el.caption}
														onChangeCaption={onChangeCaption}
														selectedBorderColor={el.borderColor}
														onSelectBorderColor={onSelectBorderColor}
														defaultBorderColor={defaultBorderColor}
														saveDefaultBorderColor={saveDefaultBorderColor}
														saveDefaultBackgroundColor={saveDefaultHotspotColor}
														selectedBackgroundColor={el.backgroundColor}
														onSelectBackgroundColor={onSelectBackgroundColor}
														defaultBackgroundColor={defaultHotspotColor}
														// selectedRectangleStyle={el.rectangleStyle}
														// onSelectRectangleStyle={onSelectRectangleStyle}
														rectangleTimeStampHandler={
															rectangleTimestampHandler
														}
														rectangleDeleteHandler={rectangleDeleteHandler}
														rectangleSeekHandler={rectangleSeekHandler}
														totalDuration={totalDuration}
														onThumbnail={onThumbnail}
														thumbnailRef={thumbnailRef}
													/>
												</div>
											);
										})}
								</div>
							</Form>
						</div>
					);
				}}
			</Formik>
			<UploadModal
				open={isSaving}
				setOpen={setIsSaving}
				onClick={onCancelUpload}
			/>
			<CancelUploadModal
				open={isCanceling && isSaving}
				setOpen={setIsCanceling}
				onClick={onConfirmCancelUpload}
				isLoading={cancelInProgress}
			/>
		</div>
	);
};

export default RecordingEditor;

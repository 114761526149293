import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {embedSelect, logicSelect, operatorSelect} from "../../../../../../utils/reactSelectStyles";
import crossIcon from "../../../../../../icons/cross-icon.svg"
import infoIcon from "../../../../../../icons/info-icon.svg"
import ReactTooltip from "react-tooltip";

export const CYARuleConditionSingle = forwardRef(({
																										index,
																										questionOptions,
																										answerOptions,
																										logicOperator,
																										setLogicOperator,
																										logicOperatorsList,
																										condition,
																										conditionIdList,
																										conditionToDelete,
																										conditionGroup
																									}, ref) => {
	const listOperators = [
		{key: "0", value: "equals", label: "exactly these options", type: "list", isMulti: true},
		{key: "1", value: "includes", label: "any of these options", type: "list", isMulti: true},
		{key: "2", value: "includes all", label: "all of these options", type: "list", isMulti: true},
		{key: "3", value: "not includes", label: "none of these options", type: "list", isMulti: true}
	]
	const stringOperators = [
		{key: "0", value: "===", label: "exactly these options", type: "string", isMulti: false},
		{key: "1", value: "includes", label: "any of these options", type: "list", isMulti: true},
		{key: "2", value: "not includes", label: "none of these options", type: "list", isMulti: true}
	]
	const modalOperators = [
		{key: "1", value: "modal includes", label: "any of these words", type: "modal", isMulti: true},
		{key: "2", value: "modal not includes", label: "none of these words", type: "modal", isMulti: true}
	]

	const [testOperatorsList, setTestOperatorsList] = useState(stringOperators);
	const [variable, setVariable] = useState(null);
	const [testOperator, setTestOperator] = useState(null);
	const [value, setValue] = useState([]);
	const [modalValues, setModalValues] = useState("");

	//Functions that can be called by parent
	useImperativeHandle(ref, () => ({
		sendCondition() {
			return returnCondition();
		},
	}));
	useEffect(() => {
		if (condition !== undefined) {
			if (!variable) {
				const variable = questionOptions.filter(it => it.value === condition.variable);
				const operation = extractOperation(condition.operation, variable[0]?.type);
				const logic = logicOperatorsList.filter(it => it.value === condition.logic);

				if (variable.length !== 0) {
					setVariable(variable[0]);
					if (variable[0].type === "modal") {
						const keywords = condition.value.reduce((a,b) => {
							if (a === "") return a+b;
							return `${a},${b}`;
						}, "");
						setModalValues(keywords);
					} else {
						const value = extractValue(answerOptions[variable[0].index], condition.value, condition.type);
						setValue(value);
					}
				}
				if (operation.length !== 0) {
					setTestOperator(operation[0]);
				}

				if (logic.length !== 0) {
					setLogicOperator(logic[0]);
				}
			}
		}
	}, [condition, variable, answerOptions])

	useEffect(() => {
		if (variable) {
			if (variable.type === "checkbox") {
				setTestOperatorsList(listOperators);
			}
			if (variable.type === "radio" || variable.type === "radiogroup") {
				setTestOperatorsList(stringOperators);
			}
			if (variable.type === "modal") {
				setTestOperatorsList(modalOperators);
			}
		}
	}, [variable])

	useEffect(() => {
		setTimeout(() => {
			if (variable) {
				const newQuestion = questionOptions.filter(it => it.value === variable.value);
				if (newQuestion.length !== 0) {
					if (newQuestion[0].value !== variable.value) {
						setVariable(newQuestion[0]);
					}
				}
			}
		}, 1000)
	}, [questionOptions])

	const sanitizeValue = (data) => {
		const list = [];
		if (testOperator.isMulti) {
			data.forEach((it) => {
				list.push(it.label.toLowerCase())
			})
		} else {
			return (Array.isArray(data)) ? data[0].label.toLowerCase() : data.label.toLowerCase();
		}
		return list;
	}

	const splitKeywords = (data) => {
		const keywords = data.split(",");
		return keywords.map(it => it.trim());
	}

	const extractValue = (answers, value, type) => {
		console.log(answers);
		if (type === "list") {
			const list = [];
			value.forEach((el) => {
				const data = answers.filter(it => it.label.toLowerCase() === el);
				if (data.length !== 0) {
					list.push(data[0]);
				}
			})
			return list;
		}
		if (type === "string") {
			return answers.filter(it => it.label.toLowerCase() === value);
		}
	}

	const extractOperation = (value, type) => {
		if (type === "checkbox") {
			return listOperators.filter(it => it.value === value);
		}
		if (type === "radio" || type === "radiogroup") {
			return stringOperators.filter(it => it.value === value);
		}
		if (type === "modal") {
			return modalOperators.filter(it => it.value === value);
		}
		return [];
	}

	const returnCondition = () => {
		if (!variable || Object.keys(logicOperator).length === 0 || !testOperator || (variable?.type !== "modal" && value.length === 0) || (variable?.type === "modal" && modalValues === "")) {
			return null;
		}

		const condition = {};
		condition.variable = variable.value;
		condition.operation = testOperator.value;
		condition.value = (variable?.type === "modal") ? splitKeywords(modalValues) : sanitizeValue(value);
		condition.expression = `${variable.value} ${testOperator.value} ${condition.value}`;
		condition.type = testOperator.type;
		condition.logic = (index === conditionIdList[0]) ? "" : logicOperator.value;
		return {condition: condition, type: "single"};
	}

	const [randomID] = useState(String(Math.random()));
	useEffect(() => {
		ReactTooltip.rebuild()
	}, [variable]);

	return (
		<>
			<ReactTooltip
				effect="solid"
				place="bottom"
				id={randomID}
				html={true}
				className="max-w-[350px] font-inter text-xs leading-[22px]"
			/>
			<div className="flex flex-row items-center gap-x-[10px]">
				{(index === conditionIdList[0]) ?
					<p className="font-medium text-[15px] w-[64px]">
						When
					</p>
					:
					<Select
						styles={logicSelect}
						className="w-[64px] rounded-[10px]"
						value={logicOperator}
						components={makeAnimated()}
						options={logicOperatorsList}
						placeholder="Operator"
						onChange={(e) => setLogicOperator(e)}
						isSearchable
					/>
				}
				<div
					data-tip={variable?.label}
					data-for={randomID}>
					<Select
						className={!conditionGroup ? "w-[438px]" : "w-[342px]"}
						value={variable}
						components={makeAnimated()}
						styles={embedSelect}
						placeholder="Question title"
						options={questionOptions}
						onChange={(e) => {
							setVariable(e);
							setValue([]);
							setTestOperator(null);
						}}
						isSearchable
					/>
				</div>
				<Select
					className="w-[220px]"
					value={testOperator}
					components={makeAnimated()}
					options={testOperatorsList}
					styles={operatorSelect}
					placeholder="Contains"
					onChange={(e) => {
						setTestOperator(e)
						setValue([]);
					}}
					isSearchable
				/>
				{
					(variable?.type === "modal") ?
						<div className={`flex flex-col w-[262px] pt-[28px]`}>
							<input type="text"
										 className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF]"
										 value={modalValues}
										 onChange={(e) => {setModalValues(e.target.value)}} placeholder="Enter a list of words"/>
							<div className={`flex flex-row gap-x-[6px] justify-start mt-[2px]`}>
								<img src={infoIcon} alt="info" className={`w-[12px] h-[12px] mt-[1px] svg-color-7D7D7D`}/>
								<p className={`text-[11px] leading-[14px] text-[#7D7D7D]`}>
								Use a comma separated list of multiple words to search in the user’s input. See how it works <a href="https://zeroshq.com/embed" target="_blank" rel="noopener noreferrer" className={`hover:underline text-[#0058BD]`}>See how it works</a>
								</p>
							</div>
						</div>
						:
						<Select
							className="w-[262px]"
							value={value}
							components={makeAnimated()}
							styles={embedSelect}
							placeholder="Answer option"
							options={answerOptions[variable?.index]}
							onChange={(e) => {
								setValue(e)
							}}
							isMulti={testOperator?.isMulti}
							isSearchable
						/>
				}
				<button className="flex justify-center items-center" onClick={() => conditionToDelete(index)}>
					<img src={crossIcon} alt="close" className="w-5 h-5"/>
				</button>
			</div>
		</>
	)
})

import { useSelector } from "react-redux";

const ViewContainer = (props) => {
  const expandedNavbar = useSelector(
    (state) => state.sessionData.expandedNavbar
  );
  return (
    <div
      className={`relative ${
        expandedNavbar
          ? "w-[68.75rem] max-w-[68.75rem]"
          : "w-[68.75rem] max-w-[68.75rem]"
      } mx-auto pb-4 min-w-[1100px] ${props.className}`}
    >
      {props.children}
    </div>
  );
};

export default ViewContainer;

import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useGetHeroQuery } from "../../../features/api/heroApiSlice";
import { useGetSoloQuery } from "../../../features/api/soloApiSlice";
import { useGetSneakpeekQuery } from "../../../features/api/sneakpeekApiSlice";

import { PageSpinner } from "../../top-level/PageSpinner";
import EmbedBrowser from "./EmbedBrowser";
import { useGetCyoaQuery } from "../../../features/api/cyoaApiSlice";

const EmbedBrowserView = () => {
  const companyId = useSelector((state) => state.sessionData.companyId);
  const [limit, setLimit] = useState(25)
  const [pageNo, setPageNo] = useState(1)

  const {
    data: heroData,
    isLoading: heroIsLoading,
    isError: heroIsError,
    error: heroError,
  } = useGetHeroQuery({
    companyId: companyId,
    limit,
    pageNo
  });
  const {
    data: soloData,
    isLoading: soloIsLoading,
    isError: soloIsError,
    error: soloError,
  } = useGetSoloQuery({
    companyId: companyId,
    limit,
    pageNo
  });
  const {
    data: cyoaData,
    isLoading: cyoaIsLoading,
    isError: cyoaIsError,
    error: cyoaError,
  } = useGetCyoaQuery({
    companyId: companyId,
    limit,
    pageNo
  });

  const moveToPrevPage = (event) => {
    if (pageNo != 1 && pageNo > 1) {
      console.log("SETING PREVIOUS PAGE")
      setPageNo(pageNo - 1)
    }
  }
  const moveToNextPage = (event) => {
    if (soloData?.payload?.length < 25 && heroData?.payload?.length < 25 && cyoaData?.payload?.length < 25) {
      return
    }
    setPageNo(pageNo + 1)
  }
  if (heroIsLoading || soloIsLoading || cyoaIsLoading)
    return <PageSpinner text="Loading Widgets..." />;
  if (heroIsError || soloIsError || cyoaIsError)
    return <div>{JSON.stringify(heroError ? heroError : soloError)}</div>;
  return (
    <EmbedBrowser
      pageNo={pageNo}
      setLimit={setLimit}
      moveToNextPage={moveToNextPage}
      moveToPrevPage={moveToPrevPage}
      setPageNo={setPageNo}
      solos={soloData.payload}
      heros={heroData.payload}
      cyoa={cyoaData.payload}
    />
  );
};
export default EmbedBrowserView;

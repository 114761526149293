import React from "react";
import {StyledRadio} from "../../../../../custom/StyledRadio";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {embedSelect} from "../../../../../../utils/reactSelectStyles";
import {MaterialSwitch} from "../../../../../custom/MaterialSwitch";

export const CYARestrictedRedirectCard = ({restrictedRedirectObject, setRestrictedRedirectObject, demoOptions}) => {
	return (
		<div className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px]`}>
			<div className={"flex items-center justify-start h-[2.38rem] bg-transparent border-[#DEE8ED] border-b"}>
				<p className="text-[#255CA4] text-[18px] leading-[18px] mx-[3.938rem]">
					Redirect users from restricted domain to this card
				</p>
			</div>
			<div className={`flex flex-col bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px] my-[20px] space-y-[20px]`}>
				<div className="flex flex-col gap-y-[8px] mx-[48px]">
					<div className="flex flex-row items-center gap-x-[20px]">
						<StyledRadio name="Play Demo"
												 checked={restrictedRedirectObject.restrictType === "demo"}
												 onClick={() => {
													 setRestrictedRedirectObject(state => {
														 const copy = JSON.parse(JSON.stringify(state));
														 copy.restrictType = "demo";
														 return copy;
													 })
												 }}
						/>
						<p className={"font-light text-[#222222] text-[15px]"}>
							Play Demo
						</p>
					</div>
					<div className={"flex items-center mx-[3.7rem]"}>
						<Select
							className="w-[19.81rem]"
							value={restrictedRedirectObject.demo}
							components={makeAnimated()}
							options={demoOptions}
							styles={embedSelect}
							placeholder="Select demo"
							isDisabled={restrictedRedirectObject.restrictType !== "demo"}
							onChange={(e) => {
								setRestrictedRedirectObject(state => {
									const copy = JSON.parse(JSON.stringify(state));
									copy.demo = e;
									return copy;
								})
							}}
							isSearchable
						/>
					</div>
				</div>
				<div className="flex flex-col gap-y-[8px] mx-[48px]">
					<div className="flex flex-row items-center gap-x-[20px]">
						<StyledRadio name="Show Message"
												 checked={restrictedRedirectObject.restrictType === "message"}
												 onClick={() => {
													 setRestrictedRedirectObject(state => {
														 const copy = JSON.parse(JSON.stringify(state));
														 copy.restrictType = "message";
														 return copy;
													 })
												 }}
						/>
						<p className={"font-light text-[#222222] text-[15px]"}>
							Show a message
						</p>
					</div>
					<div className={"flex flex-col space-y-[18px] mx-[3.7rem]"}>
						<div className="flex flex-col space-y-[18px]">
							<input type="text"
										 className={`flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] ${restrictedRedirectObject.restrictType !== "message" ? "text-[#7D7D7D]" : "text-[#222222]"}`}
										 onChange={(e) => {
											 setRestrictedRedirectObject(state => {
												 const copy = JSON.parse(JSON.stringify(state));
												 copy.message.title = e.target.value;
												 return copy;
											 })
										 }}
										 value={restrictedRedirectObject.message.title}
										 placeholder="Title"
										 disabled={restrictedRedirectObject.restrictType !== "message"}
							/>
							<textarea
								placeholder="Description - 400 characters or less"
								maxLength={400}
								onChange={(e) => {
									setRestrictedRedirectObject(state => {
										const copy = JSON.parse(JSON.stringify(state));
										copy.message.description = e.target.value;
										return copy;
									})
								}}
								value={restrictedRedirectObject.message.description}
								disabled={restrictedRedirectObject.restrictType !== "message"}
								className={`flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] h-[4.5rem] ${restrictedRedirectObject.restrictType !== "message" ? "text-[#7D7D7D]" : "text-[#222222]"}`}/>
						</div>
						<div className="flex flex-col gap-y-[8px]">
							<div className="flex flex-row items-center gap-x-[0.5rem]">
								<MaterialSwitch color={"primary"}
																disabled={restrictedRedirectObject.restrictType !== "message"}
																onClick={(e) => {
																	setRestrictedRedirectObject(state => {
																		const copy = JSON.parse(JSON.stringify(state));
																		copy.message.primaryButton = !copy.message.primaryButton;
																		return copy;
																	})
																}}
																checked={restrictedRedirectObject.message.primaryButton}
								/>
								<p
									className={`font-light ${restrictedRedirectObject.restrictType !== "message" || !restrictedRedirectObject.message.primaryButton ? "text-[#7D7D7D]" : "text-[#222222]"} text-[15px] ml-[12px]`}>
									Add primary button
								</p>
							</div>
							<input type="text"
										 className={`flex text-[15px] leading-[15px] px-[12px] ${restrictedRedirectObject.restrictType !== "message" || !restrictedRedirectObject.message.primaryButton ? "text-[#7E7E7E]" : ""} rounded-[4px] border border-[#BFCACF] w-[300px] ml-[60px]`}
										 placeholder="Button Label"
										 disabled={restrictedRedirectObject.restrictType !== "message" || !restrictedRedirectObject.message.primaryButton}
										 onChange={(e) => {
											 setRestrictedRedirectObject(state => {
												 const copy = JSON.parse(JSON.stringify(state));
												 copy.message.buttonLabel = e.target.value;
												 return copy;
											 })
										 }}
										 value={restrictedRedirectObject.message.buttonLabel}
							/>
							<input type="text"
										 className={`flex text-[15px] leading-[15px] px-[12px]  ${restrictedRedirectObject.restrictType !== "message" || !restrictedRedirectObject.message.primaryButton ? "text-[#7E7E7E]" : ""} rounded-[4px] border border-[#BFCACF] w-[300px] ml-[60px]`}
										 placeholder="Enter URL"
										 disabled={restrictedRedirectObject.restrictType !== "message" || !restrictedRedirectObject.message.primaryButton}
										 onChange={(e) => {
											 setRestrictedRedirectObject(state => {
												 const copy = JSON.parse(JSON.stringify(state));
												 copy.message.URL = e.target.value;
												 return copy;
											 })
										 }}
										 value={restrictedRedirectObject.message.URL}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

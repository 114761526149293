import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const LinkedInTagTypes = {
	LinkedIn: "linkedin",
};

export const linkedInApiSlice = createApi({
	reducerPath: "api/linkedin",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
	}),
	tagTypes: Object.values(LinkedInTagTypes),
	endpoints: (builder) => ({
		postAuth: builder.mutation({
			query: (initialPost) => ({
				url: "/login/signup/linkedin",
				method: "POST",
				body: {
					authCode: initialPost.authCode,
					clientId: initialPost.clientId,
					redirectUri: initialPost.redirectUri,
					companyName: initialPost.companyName,
				},
			}),
			invalidatesTags: [LinkedInTagTypes.LinkedIn],
		}),
		postLogin: builder.mutation({
			query: (initialPost) => ({
				url: "/login/linkedin",
				method: "POST",
				body: {
					authCode: initialPost.authCode,
					clientId: initialPost.clientId,
					redirectUri: initialPost.redirectUri,
				},
			}),
			invalidatesTags: [LinkedInTagTypes.LinkedIn],
		}),
	}),
});

export const { usePostAuthMutation, usePostLoginMutation } = linkedInApiSlice;

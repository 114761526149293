import React from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../utils/ItemTypes";

const Droppable = (props) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.TOOL,
    drop: (item, monitor) => props.onDropHandler(item, monitor),
    // (item, monitor) => {
    //   const vidRef = document.getElementsByTagName("video")[0];
    //   console.log(vidRef.getBoundingClientRect());
    //   console.log(monitor.getClientOffset());
    //   //   const hotspot = document.createElement('div');
    //   //   hotspot.className = "";
    //   return { name: props.role };
    // },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  const isActive = canDrop && isOver;
  return (
    <div
      className={`${props.className} ${isActive && "opacity-50"}`}
      ref={drop}
      role={props.role}
    >
      {props.children}
    </div>
  );
};

export default Droppable;

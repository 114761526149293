import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useGetRecordingQuery } from "../../../../../features/api/recordingsApiSlice";
import {
	useCreateSneakpeekMutation,
	useGetSneakpeekByIdQuery,
	useUpdateSneakpeekMutation,
} from "../../../../../features/api/sneakpeekApiSlice";
import { PageSpinner } from "../../../../top-level/PageSpinner";
import StyledButton from "../../../../top-level/StyledButton";
import {toastError, toastLoading} from "../../../../custom-toast/toasts";

const SneakpeekEditor = ({ id, isNew }) => {
	const companyId = useSelector((state) => state.sessionData.companyId);
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	let initialValues = {
		name: "",
		recording: "",
		is_muted: "0",
		is_gated: "0",
		show_controls: "1",
		live: "0",
	};
	const history = useHistory();
	const [createSneakpeek] = useCreateSneakpeekMutation();

	const { data, isLoading } = useGetSneakpeekByIdQuery({
		id: id,
	});
	const { data: recData, isLoading: recIsLoading } = useGetRecordingQuery({
		companyId: companyId,
		limit:1000,
		pageNo:1
	});

	const [updateSneakpeek] = useUpdateSneakpeekMutation();

	if (isLoading || recIsLoading)
		return <PageSpinner text="Loading Sneakpeek.." />;

	if (!isNew) {
		initialValues = {
			name: data.payload[0].name,
			recording: data.payload[0].recording_id,
			is_muted: JSON.stringify(data.payload[0].is_muted),
			is_gated: JSON.stringify(data.payload[0].is_gated),
			show_controls: JSON.stringify(data.payload[0].show_controls),
			live: JSON.parse(data.payload[0].live),
		};
	}

	const sneakpeekSchema = Yup.object().shape({
		name: Yup.string().required("Title is required"),
		is_muted: Yup.boolean(),
		is_gated: Yup.boolean(),
		show_controls: Yup.boolean(),
	});

	const addNewSneakpeek = async (values) => {
		if (values.name === "" || values.recording === "") {
			toastError("Incomplete Data");
		} else {
			const alert = toastLoading("Please wait...");
			const sneakpeekData = {
				salesrep_id: salesRepId,
				company_id: companyId,
				recording_id: +values.recording,
				name: values.name,
				is_muted: values.is_muted,
				is_gated: values.is_gated,
				show_controls: values.show_controls,
				date_created: new Date().toISOString().slice(0, 19).replace("T", " "),
				date_modified: new Date().toISOString().slice(0, 19).replace("T", " "),
			};
			const status = await createSneakpeek(sneakpeekData).unwrap();
			if (status.status === "success") {
				toast.dismiss(alert);
				// toastSuccess("Sneakpeek Widget Added");
				history.goBack();
			} else {
				toast.dismiss(alert);
				toastError("Error adding sneakpeek widget");
			}
		}
	};

	const updateExistingSneakpeek = async (values) => {
		if (values.name === "" || values.recording === "") {
			toastError("Incomplete Data");
		} else {
			const sneakpeekData = {
				name: values.name,
				sneakpeekId: +id,
				recordingId: +values.recording,
				is_muted: values.is_muted,
				is_gated: values.is_gated,
				show_controls: values.show_controls,
				date_modified: new Date().toISOString().slice(0, 19).replace("T", " "),
			};
			const status = await updateSneakpeek(sneakpeekData).unwrap();
			if (status.status === "success") {
				// toastSuccess("Sneakpeek Widget Updated");
				history.goBack();
			} else {
				toastError("Error updating sneakpeek widget");
			}
		}
	};

	const cancelHandler = (e) => {
		e.preventDefault();
		history.goBack();
	};

	return (
		<div className="flex flex-col flex-grow-1 shadow-inner space-y-1 justify-center">
			<div className="mx-auto"></div>
			<Formik
				initialValues={initialValues}
				validationSchema={sneakpeekSchema}
				onSubmit={
					!isNew
						? (values) => updateExistingSneakpeek(values)
						: (values) => addNewSneakpeek(values)
				}
			>
				{(formik) => {
					const { isValid } = formik;
					const classSubmit =
						"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
					return (
						<div className="w-full mx-auto">
							<Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
								<div className="mb-4">
									<label
										className="block text-gray-700 text-sm font-bold mb-2"
										htmlFor="name"
									>
										Name
									</label>
									<Field
										className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline"
										id="name"
										type="input"
										name="name"
										placeholder="Add a name for this widget"
									/>
									<ErrorMessage
										name="name"
										component="span"
										className="text-red-500 text-xs italic"
									/>
								</div>
								<div className="mb-4">
									<label
										className="block text-gray-700 text-sm font-bold mb-2 w-max"
										htmlFor="is_muted"
									>
										Play demo muted
									</label>
									<label>
										<Field
											className="mx-2"
											id="is_muted"
											type="radio"
											name="is_muted"
											value="1"
										/>
										Yes
									</label>
									<label>
										<Field
											className="mx-2"
											id="is_muted"
											type="radio"
											name="is_muted"
											value="0"
										/>
										No
									</label>
								</div>
								<div className="mb-4">
									<label
										className="block text-gray-700 text-sm font-bold mb-2 w-max"
										htmlFor="is_gated"
									>
										Verify viewer's identity before playing demo
									</label>
									<label>
										<Field
											className="mx-2"
											id="is_gated"
											type="radio"
											name="is_gated"
											value="1"
										/>
										Yes
									</label>
									<label>
										<Field
											className="mx-2"
											id="is_gated"
											type="radio"
											name="is_gated"
											value="0"
										/>
										No
									</label>
								</div>
								<div className="mb-4">
									<label
										className="block text-gray-700 text-sm font-bold mb-2 w-max"
										htmlFor="show_controls"
									>
										Show video controls
									</label>
									<label>
										<Field
											className="mx-2"
											id="show_controls"
											type="radio"
											name="show_controls"
											value="1"
										/>
										Yes
									</label>
									<label>
										<Field
											className="mx-2"
											id="show_controls"
											type="radio"
											name="show_controls"
											value="0"
										/>
										No
									</label>
								</div>
								<div className="mb-4">
									<label
										className="block text-gray-700 text-sm font-bold mb-2"
										htmlFor="recording"
									>
										Sneakpeek Demo
									</label>
									<Field
										className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline"
										id="recording"
										as="select"
										name="recording"
										placeholder="Select a demo"
									>
										<option disabled value="">
											Select a Demo
										</option>
										{recData.payload.map((it) => (
											<option key={it.id} value={it.id}>
												{it.title}
											</option>
										))}
									</Field>
									<ErrorMessage
										name="recording"
										component="span"
										className="text-red-500 text-xs italic"
									/>
								</div>
								<div className="flex items-center mt-3 pt-5 justify-end gap-x-3">
									<StyledButton
										className={
											isValid
												? classSubmit
												: `bg-gray-300 py-2 px-4 rounded disabled cursor-not-allowed`
										}
										type="submit"
										disabled={!isValid}
									>
										{isNew
											? "Generate Sneakpeek Widget Code"
											: "Update Sneakpeek Widget Code"}
									</StyledButton>
									<StyledButton
										type="secondary"
										onClick={(e) => cancelHandler(e)}
									>
										Cancel
									</StyledButton>
								</div>
							</Form>
						</div>
					);
				}}
			</Formik>
		</div>
	);
};

export default SneakpeekEditor;

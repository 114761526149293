import React from "react";
import {compose, palette, spacing, styleFunctionSx} from "@material-ui/system";
import {createMuiTheme, MuiThemeProvider, Radio, styled} from "@material-ui/core";
import {purple} from "@material-ui/core/colors";

export const StyledRadio = ({onClick, checked, id, name, value, disabled}) => {
	const theme = createMuiTheme({
		palette: {
			primary: { main: "#1A73E8" }, // Purple and green play nicely together
		},
	});

    return (
			<MuiThemeProvider theme={theme}>
				<Radio
					onClick={onClick} checked={checked}
					id={id} name={name} value={value}
					disabled={disabled}
					style={{backgroundColor: 'transparent'}}
					color={"primary"}
				/>
			</MuiThemeProvider>
    )
}

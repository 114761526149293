export const FONTS = [
	"Arial (sans-serif)",
	"Arial Black (sans-serif)",
	"Verdana (sans-serif)",
	"Tahoma (sans-serif)",
	"Nunito Sans (serif)",
	"Manrope (sans-serif)",
	"Source Sans Pro (sans-serif)",
	"Trebuchet MS (sans-serif)",
	"Impact (sans-serif)",
	"Times New Roman (serif)",
	"Didot (serif)",
	"Georgia (serif)",
	"American Typewriter (serif)",
	"Andalé Mono (monospace)",
	"Courier (monospace)",
	"Lucida Console (monospace)",
	"Monaco (monospace)",
	"Bradley Hand (cursive)",
	"Brush Script MT (cursive)",
	"Luminari (fantasy)",
	"Comic Sans MS (cursive)",
];

export const CORP_DOMAINS = [
	"dispostable.com",
	"gmail.com",
	"hotmail.com",
	"yahoo.com",
];

export const AVATAR_PALETTE = [
	"#18B0AA",
	"#DF0046",
	"#E95700",
	"#2E71FD",
	"#E20FAC",
	"#AC1DFF",
	"#19A93F",
];

export const DEMO_CONTROLS = {
	HOTSPOT: "hotspot",
	RECTANGLE: "rectangle",
};

export const DEMO_TYPES = {
	PUBLIC: "public",
	GDRIVE: "gdrive",
	DEFAULT: "default",
};
export const EMBED_CTA_TYPES = {
	BUTTON: "button",
	LINK: "link",
};

export const BLINKER_PACK = {
	MORPH: "morph",
	SONAR: "sonar",
	LIGHT: "light",
	EGG: "egg",
	SLACK: "slack",
	DARK: "rounded-full w-8 h-8 bg-[#2222220F] border border-[#D9D9D9]",
};

/**
 * Follow the given rules -
 *
 * Make sure to assign single word only.
 * Word should be in start case i.e. "Demo" instead of "demo"
 * Word should be singular i.e. "Demo" instead of "Demos"
 */
export const DEMO = "Demo";
export const EMBED = "Embed";
export const PAGE = "Page";
export const CONTACT = "Contact";
export const QUESTIONNAIRE = "Questionnaire";
export const ANALYTIC = "Analytic";

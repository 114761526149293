import {EMBED, PAGE} from "../../../../data/constants";
import Select from "react-select";
import {DateRangePickerComp} from "../utils/DateRangePickerComp";
import React, {useEffect, useState} from "react";
import {addDays, format} from "date-fns";
import {embedSelect, graphSelectionStyles} from "../../../../utils/reactSelectStyles";
import {useSelector} from "react-redux";
import {
	useGetProspectContactInfoQuery,
	useGetProspectPagesQuery,
	useGetProspectsQuery,
	useGetResponsesQuery
} from "../../../../features/api/analyticApiSlice";
import {PageSpinner} from "../../../top-level/PageSpinner";
import {
	useGetFetchPageRecommendationsQuery,
	useGetPageRecommendationViewsQuery,
	useGetPageViewsQuery
} from "../../../../features/api/analyticApiSlice";
import {Bar, Line} from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import {adaptToChart, extractByDateRange, postProcessDateRange, sortByDate} from "../utils/AnalyticsUtils";
import makeAnimated from "react-select/animated/dist/react-select.esm";

export const PageAnalytics = () => {
	const [dateRange, setDateRange] = useState([
		{
			startDate: addDays(new Date(), -7),
			endDate: new Date(),
			key: "selection",
		},
	]);
	const [selectedProspect, setSelectedProspect] = useState(null);
	const [prospectOptions, setProspectOptions] = useState([]);
	const [selectedPage, setSelectedPage] = useState(null);
	const [pageOptions, setPageOptions] = useState([]);
	const [selectedDemo, setSelectedDemo] = useState(null);
	const [demoOptions, setDemoOptions] = useState([]);
	const [selectedResponse, setSelectedResponse] = useState(null);
	const [responseOptions, setResponseOptions] = useState([]);

	const [pageViewsGraphLabels, setPageViewsGraphLabels] = useState([]);
	const [pageViewsGraphData, setPageViewsGraphData] = useState([]);
	const [demoViewsGraphLabels, setDemoViewsGraphLabels] = useState([]);
	const [demoViewsGraphData, setDemoViewsGraphData] = useState([]);
	const [pageResponseLabels, setPageResponseLabels] = useState([]);
	const [pageResponseData, setPageResponseData] = useState([]);
	const [prospectContactInfo, setProspectContactInfo] = useState([]);

	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);

	const {
		data: prospectData,
		isFetching: prospectIsFetching,
		isError: prospectIsError,
		error: prospectError
	} = useGetProspectsQuery({
		companyId: companyId
	});

	const {
		data: pagesData,
		isFetching: pagesIsFetching,
		isError: pagesIsError,
		error: pagesError
	} = useGetProspectPagesQuery({
			prospectId: selectedProspect?.value
		}
	)

	const {
		data: pageViewsData,
		isFetching: pageViewsDataIsFetching,
		isError: pageViewsDataIsError,
		error: pageViewsDataError
	} = useGetPageViewsQuery({
			pageId: selectedPage?.pageId,
			prospectId: selectedProspect?.value
		}
	)

	const {
		data: fetchPageRecommendations,
		isFetching: fetchPageRecommendationsIsFetching,
		isError: fetchPageRecommendationsIsError,
		error: fetchPageRecommendationsError
	} = useGetFetchPageRecommendationsQuery({
			pageId: selectedPage?.pageId,
		}
	)

	const {
		data: pageRecommendationViews,
		isFetching: pageRecommendationViewsIsFetching,
		isError: pageRecommendationViewsIsError,
		error: pageRecommendationViewsError
	} = useGetPageRecommendationViewsQuery({
			pageId: selectedPage?.pageId,
		}
	)

	const {
		data: pageResponse,
		isFetching: pageResponseIsFetching,
		isError: pageResponseIsError,
		error: pageResponseError
	} = useGetResponsesQuery({
		pageId: selectedPage?.pageId,
		relationshipId: selectedPage?.relationshipId
	});

	const {
		data: prospectContact,
		isFetching: prospectContactIsFetching,
		isError: prospectContactIsError,
		error: prospectContactError
	} = useGetProspectContactInfoQuery({
		pageId: selectedPage?.pageId,
		prospectId: selectedProspect?.value,
		companyId: companyId
	});

	const resetGraphs = () => {
		setPageViewsGraphData([]);
		setPageViewsGraphLabels([]);
		setDemoViewsGraphData([]);
		setDemoViewsGraphLabels([]);
		setPageResponseData([]);
		setPageResponseLabels([]);
		setProspectContactInfo([]);
		setSelectedDemo(null);
		setSelectedResponse(null);
	}

	const showPageViews = (data, startDate, endDate) => {
		const views = extractByDateRange(startDate, endDate, data);
		const sortedViews = sortByDate(views);
		const graphData = adaptToChart([{label:selectedPage?.label, data: sortedViews}]);
		const postProcess = postProcessDateRange(startDate, endDate, graphData.label, graphData.dataset);
		setPageViewsGraphLabels(postProcess.label);
		setPageViewsGraphData(postProcess.dataset);
	}

	useEffect(() => {
		const date = localStorage.getItem("dateRangePage");
		if (date) {
			const parsedDate = JSON.parse(date);
			const startDate = new Date(parsedDate[0].startDate.split("T")[0]);
			const endDate = new Date(parsedDate[0].endDate.split("T")[0]);
			setDateRange([{startDate: startDate, endDate: endDate, key: parsedDate[0].key}]);
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (prospectOptions.length !== 0 && !selectedProspect) {
				const localSelectedProspect = localStorage.getItem("selectedProspectPage");
				if (localSelectedProspect) {
					const parsedSelection = JSON.parse(localSelectedProspect);
					const filteredSelection = prospectOptions.filter(
						(it) =>
							it.label === parsedSelection.label
					);
					if (filteredSelection.length !== 0) {
						setSelectedProspect(filteredSelection[0]);
					}
				}
			}
		}, 200);
	}, [prospectOptions]);

	// useEffect(() => {
	// 	if (!selectedPage && pageOptions.length !== 0) {
	// 		setSelectedPage(pageOptions[0]);
	// 	}
	// }, [pageOptions])

	useEffect(() => {
		setTimeout(() => {
			if (!selectedPage && pageOptions.length !== 0) {
				const localSelectedPage = localStorage.getItem("selectedPage");
				if (localSelectedPage) {
					const parsedSelection = JSON.parse(localSelectedPage);
					console.log(pageOptions, parsedSelection);
					const filteredSelection = pageOptions.filter(
						(it) =>
							it.pageId === parsedSelection.pageId && it.relationshipId === parsedSelection.relationshipId
					);
					if (filteredSelection.length !== 0) {
						setSelectedPage(filteredSelection[0]);
					}
				}
			}
		}, 200);
	}, [pageOptions]);

	useEffect(() => {
		if (!prospectIsFetching) {
			const options = [];
			prospectData?.payload.forEach((it, i) => {
				const filteredIds = prospectData?.payload.filter(el => el.email === it.email);
				const relationIds = filteredIds.map(el => {
					return el.relationshipId;
				})
				if (options.filter(el => el.label === it.email).length === 0)
					options.push({key: i, value: it.id, label: it.email, relationIds: relationIds});
			})
			setProspectOptions(options);
		}
	}, [prospectIsFetching, prospectData])

	useEffect(() => {
		if (!pagesIsFetching) {
			const options = [];
			const sortedPages = [...pagesData?.payload].sort((a, b) => {
				return new Date(b.date_created) - new Date(a.date_created);
			});
			sortedPages.forEach((it, i) => {
				options.push({key: i, value: i, label: it.title, type: it.type, relationshipId: it.id, pageId: it.page_id, salesRepId: it.salesrep_id});
			})
			setPageOptions(options);
		}
	}, [pagesIsFetching, pagesData])

	useEffect(() => {
		if (!fetchPageRecommendationsIsFetching) {
			const options = [];
			fetchPageRecommendations.payload.forEach((it, i) => {
				options.push({key: i, value: it.value, label: it.label});
			})
			setDemoOptions(options);
		}
	}, [fetchPageRecommendations, fetchPageRecommendationsIsFetching])

	useEffect(() => {
		if (!pageResponseIsFetching) {
			const options = [];
			if (pageResponse.payload.length !== 0) {
				const parsedResponse = JSON.parse(pageResponse.payload[0].answers);
				const choices = Object.values(parsedResponse);
				Object.keys(parsedResponse).forEach((it, i) => {
					options.push({key: i, value: i, label: it, response: choices[i]});
				})
			}
			setResponseOptions(options);
		}
	}, [pageResponse, pageResponseIsFetching])

	useEffect(() => {
		if (selectedPage && selectedProspect) {
			const startDate = format(new Date(dateRange[0].startDate), "yyyy/MM/dd");
			const endDate = format(new Date(dateRange[0].endDate), "yyyy/MM/dd");
			if (!pageViewsDataIsFetching) {
				if (pageViewsData?.payload.length !== 0) {
					showPageViews(pageViewsData.payload, startDate, endDate);
				}
			}
			if (!prospectContactIsFetching) {
				if (prospectContact?.payload.length !== 0) {
					if (prospectContact.payload.contactInfo.length === 0 || prospectContact.payload.contactFields.length === 0) {
						return;
					}
					const contactInfo = JSON.parse(prospectContact.payload.contactInfo[0].prospect_contact_information);
					const contactFields = prospectContact.payload.contactFields;
					const contact = contactFields.map(it => {
						if (contactInfo[it.fieldName.trim()] !== undefined) {
							return {field: it.fieldName.trim(), value: contactInfo[it.fieldName.trim()]};
						}
					})
					setProspectContactInfo(contact);
				}
			}
		}
	}, [selectedPage, selectedProspect, dateRange, pageViewsData, pageViewsDataIsFetching, prospectContactIsFetching, prospectContact])

	useEffect(() => {
		if (!pageRecommendationViewsIsFetching && selectedDemo) {
			const startDate = format(new Date(dateRange[0].startDate), "yyyy/MM/dd");
			const endDate = format(new Date(dateRange[0].endDate), "yyyy/MM/dd");
			if (!pageRecommendationViews || pageRecommendationViews?.payload.length === 0) {
				return
			}
			const filterOnRelation = pageRecommendationViews.payload.filter(it => it.relationship_id === selectedPage?.relationshipId);
			if (filterOnRelation.length === 0) {
				return
			}
			const views = extractByDateRange(startDate, endDate, filterOnRelation);
			const sortedViews = sortByDate(views);
			const graphData = adaptToChart([{label: selectedDemo?.label,data: sortedViews}])
			const postProcess = postProcessDateRange(startDate, endDate, graphData.label, graphData.dataset);
			setDemoViewsGraphLabels(postProcess.label);
			setDemoViewsGraphData(postProcess.dataset);
		}
	}, [selectedDemo, pageRecommendationViewsIsFetching, pageRecommendationViews, dateRange])

	useEffect(() => {
		if (!pageResponseIsFetching && selectedResponse) {
			if (!pageResponse || pageResponse?.payload.length === 0) {
				return
			}
			const label = [];
			const data = [];
			if (Array.isArray(selectedResponse.response)) {
				selectedResponse.response.forEach(it => {
					label.push(it);
					data.push(1);
				})
			} else {
				label.push(selectedResponse.response);
				data.push(1);
			}
			setPageResponseLabels(label);
			setPageResponseData(data);
		}
	}, [dateRange, selectedResponse, pageResponse, pageResponseIsFetching])

	if (
		prospectIsFetching ||
		pagesIsFetching ||
		pageViewsDataIsFetching ||
		fetchPageRecommendationsIsFetching ||
		pageRecommendationViewsIsFetching ||
		pageResponseIsFetching ||
		prospectContactIsFetching
	)
		return <PageSpinner text="Loading Analytics..."/>;

	if (
		prospectIsError ||
		pagesIsError ||
		pageViewsDataIsError ||
		fetchPageRecommendationsIsError ||
		pageRecommendationViewsIsError ||
		pageResponseIsError ||
		prospectContactIsError
	)
		return <div>Error occurred while fetching data</div>;

	return (
		<>
			<div className="flex flex-col gap-y-4 mt-[3rem]">
				<div className="flex flex-row gap-x-[2rem]">
					<div className="flex flex-col gap-y-[0.5rem] w-[19.81rem]">
						<p className="font-semibold ml-[0.7rem]">{`Select Prospect`}</p>
						<Select
							className="w-full h-[2.5rem]"
							styles={embedSelect}
							value={selectedProspect}
							options={prospectOptions}
							onChange={(e) => {
								localStorage.setItem("selectedProspectPage", JSON.stringify(e));
								setSelectedProspect(e);
								setSelectedPage(null);
								resetGraphs();
							}}
							placeholder={`Select prospect`}
							autofocus
						/>
					</div>
					<div className="flex flex-col gap-y-[0.5rem] w-[19.81rem]">
						<p className="font-semibold ml-[0.7rem]">{`Select ${PAGE}`}</p>
						<Select
							className="w-full h-[2.5rem]"
							styles={embedSelect}
							value={selectedPage}
							options={pageOptions}
							onChange={(e) => {
								localStorage.setItem("selectedPage", JSON.stringify(e));
								setSelectedPage(e);
								resetGraphs();
							}}
							placeholder={`Select ${PAGE.toLowerCase()}`}
						/>
					</div>
					<div className="flex flex-col gap-y-[0.5rem] w-[19.81rem]">
						<p className="font-semibold ml-[0.7rem]">Select Date</p>
						<DateRangePickerComp
							dateRange={dateRange}
							setDateRange={(e) => {
								localStorage.setItem("dateRangePage", JSON.stringify(e));
								setDateRange(e);
							}}
						/>
					</div>
				</div>
			</div>
			{
				(selectedPage && selectedProspect) ?
					<div className="w-full mt-[3.375rem]">
						<div className="flex flex-col w-full rounded-[1rem] border border-[#D9D9D9]">
							<div className="flex flex-col gap-y-4">
								<div className="flex flex-col w-full h-[4.69rem] items-around justify-center border-b border-[#D7D7D7]">
									<div className="flex flex-row items-center gap-x-[0.75rem]">
										<p className="ml-[1.13rem] font-medium">Page Views</p>
										<div className="rounded-full bg-[#A3A3A3] w-[0.25rem] h-[0.25rem]"/>
										<p className="text-[#255CA4]">{selectedPage?.label}</p>
									</div>
									<p className="ml-[1.13rem] mt-[5px] text-[#7D7D7D] text-sm ">
										Number of times the page was watched.
									</p>
								</div>
								<div className="flex justify-center items-center w-full">
									{pageViewsGraphLabels.length !== 0 ? (
										<div className="flex-grow">
											<Line
												width={100}
												height={25}
												options={{
													maintainAspectRatio: true,
													plugins: {
														legend: {
															display: false,
														},
														tooltip: {
															backgroundColor: "#FFF",
															borderColor: "#D9D9D9",
															borderWidth: 1,
															bodyColor: "#737373",
															bodyFont: {
																size: 15,
																weight: 400,
																lineHeight: "15px",
																family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
															},
															titleColor: "#444444",
															titleMarginBottom: 8,
															titleFont: {
																weight: 600,
																size: 15,
																lineHeight: "15px",
																family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
															},
															cornerRadius: 11,
															padding: 12,
															displayColors: false,
															callbacks: {
																label: (tooltipItem) => {
																	return `Views:  ${tooltipItem.parsed.y}`
																}
															}
														}
													},
													scales: {
														y: {
															title: {
																display: true,
																text: "Views",
																font: {
																	size: 15,
																	family: "Proxima Nova",
																	weight: 400,
																},
																color: "#7D7D7D",
															},
															grid: {
																color: "#F0F0F0",
																borderWidth: 0
															},
														},
														x: {
															grid: {
																lineWidth: 0,
																color: "#F0F0F0",
																borderWidth: 0
															},
															ticks: {
																callback: function (value, index, values) {
																	return format(new Date(pageViewsGraphLabels[value]), "MMM d");
																}
															},
														},
													},
												}}
												data={{
													labels: pageViewsGraphLabels,
													datasets: pageViewsGraphData,
												}}
												type={"line"}
											/>
										</div>
									) : (
										<div className="flex justify-center items-center w-[98%] my-16">
											<p className="font-semibold">No Data To Display</p>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className={(selectedPage?.type === "embed") ? "hidden" : "flex flex-col w-full rounded-[1rem] border border-[#D9D9D9] mt-[1.5rem]"}>
							<div className="flex flex-col gap-y-4">
								<div className="flex flex-col w-full h-[5rem] items-around justify-center border-b border-[#D7D7D7]">
									<div className="flex flex-row items-center">
										<p className="ml-[1.13rem] mr-[0.75rem] font-medium">
											Demo Views
										</p>
										<div className="rounded-full bg-[#A3A3A3] mr-1 w-[0.25rem] h-[0.25rem]"/>
										<Select
											styles={graphSelectionStyles}
											className="w-72 mt-[2px]"
											id="WidgetSelection"
											value={selectedDemo}
											components={makeAnimated()}
											options={demoOptions}
											placeholder="Select Demo"
											onChange={(e) => {
												setSelectedDemo(e);
												setDemoViewsGraphData([]);
												setDemoViewsGraphLabels([]);
												// setSelectedCyoaDemo(e);
												// localStorage.setItem('cyaDemo', JSON.stringify(e));
												// sendAmplitudeEvent("AnalyticsWatchDurationSelected");
											}}
											isSearchable
										/>
									</div>
									<p className="ml-[1.13rem] text-[#7D7D7D] text-sm ">
										Number of times the demo was watched.
									</p>
								</div>
								<div className="flex justify-center items-center w-full">
									{demoViewsGraphLabels.length !== 0 ? (
										<div className="flex-grow">
											<Line
												width={100}
												height={25}
												options={{
													maintainAspectRatio: true,
													plugins: {
														legend: {
															display: false,
														},
														tooltip: {
															backgroundColor: "#FFF",
															borderColor: "#D9D9D9",
															borderWidth: 1,
															bodyColor: "#737373",
															bodyFont: {
																size: 15,
																weight: 400,
																lineHeight: "15px",
																family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
															},
															titleColor: "#444444",
															titleMarginBottom: 8,
															titleFont: {
																weight: 600,
																size: 15,
																lineHeight: "15px",
																family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
															},
															cornerRadius: 11,
															padding: 12,
															displayColors: false,
															callbacks: {
																label: (tooltipItem) => {
																	return `Views:  ${tooltipItem.parsed.y}`
																}
															}
														}
													},
													scales: {
														y: {
															title: {
																display: true,
																text: "Views",
																font: {
																	size: 15,
																	family: "Proxima Nova",
																	weight: 400,
																},
																color: "#7D7D7D",
															},
															grid: {
																color: "#F0F0F0",
																borderWidth: 0
															},
														},
														x: {
															grid: {
																lineWidth: 0,
																color: "#F0F0F0",
																borderWidth: 0
															},
															ticks: {
																callback: function (value, index, values) {
																	return format(new Date(demoViewsGraphLabels[value]), "MMM d");
																}
															},
														},
													},
												}}
												data={{
													labels: demoViewsGraphLabels,
													datasets: demoViewsGraphData,
												}}
												type={"line"}
											/>
										</div>
									) : (
										<div className="flex justify-center items-center w-[98%] my-16">
											<p className="font-semibold">No Data To Display</p>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className={(selectedPage?.type === "embed") ? "hidden" : "flex flex-col w-full rounded-[1rem] border border-[#D9D9D9] mt-[1.5rem]"}>
							<div className="flex flex-col gap-y-4">
								<div className="flex flex-col w-full h-[5rem] items-around justify-center border-b border-[#D7D7D7]">
									<div className="flex flex-row items-center">
										<p className="ml-[1.13rem] mr-[0.75rem] font-medium">
											Responses
										</p>
										<div className="rounded-full bg-[#A3A3A3] mr-1 w-[0.25rem] h-[0.25rem]"/>
										<Select
											styles={graphSelectionStyles}
											className="w-72 mt-[2px]"
											id="WidgetSelection"
											value={selectedResponse}
											components={makeAnimated()}
											options={responseOptions}
											placeholder="Select Question"
											onChange={(e) => {
												setSelectedResponse(e);
												setPageResponseLabels([]);
												setPageViewsGraphData([]);
												// setSelectedCyoaDemo(e);
												// localStorage.setItem('cyaDemo', JSON.stringify(e));
												// sendAmplitudeEvent("AnalyticsWatchDurationSelected");
											}}
											isSearchable
										/>
									</div>
									<p className="ml-[1.13rem] text-[#7D7D7D] text-sm ">
										How many times was each response was given?
									</p>
								</div>
								<div className="flex justify-center items-center w-full">
									{pageResponseData.length !== 0 ? (
										<div className="flex-grow">
											<Bar
												width={100}
												height={25}
												options={{
													indexAxis: "y",
													maintainAspectRatio: true,
													animation: false,
													plugins: {
														legend: {
															display: false,
														},
														tooltip: {
															backgroundColor: "#FFF",
															borderColor: "#D9D9D9",
															borderWidth: 1,
															bodyColor: "#737373",
															bodyFont: {
																size: 15,
																weight: 400,
																lineHeight: "15px",
																family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
															},
															titleColor: "#444444",
															titleMarginBottom: 8,
															titleFont: {
																weight: 600,
																size: 15,
																lineHeight: "15px",
																family: "'Proxima Nova','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
															},
															cornerRadius: 11,
															padding: 12,
															displayColors: false,
															callbacks: {
																label: function (context) {
																	return `Responses:  ${context.parsed.x}`
																}
															}
														}
													},
													scales: {
														y: {
															beginAtZero: true,
															grid: {
																lineWidth: 0,
																color: "#F0F0F0",
																borderWidth: 0
															},
															title: {
																color: "#7D7D7D",
																font: {
																	size: 14,
																	family: "Proxima Nova",
																	weight: 400,
																},
															},
														},
														x: {
															grid: {
																color: "#F0F0F0",
																borderWidth: 0
															}
														}
													},
												}}
												data={{
													labels: pageResponseLabels,
													datasets: [
														{
															label: selectedPage?.label,
															data: pageResponseData,
															backgroundColor: "#1B85B6",
														},
													],
												}}
												type={"line"}
											/>
										</div>
									) : (
										<div className="flex justify-center items-center w-[98%] my-16">
											<p className="font-semibold">No Data To Display</p>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className={(selectedPage?.type === "embed") ? "hidden" : "flex flex-col w-full rounded-[1rem] border border-[#D9D9D9] mt-[1.5rem]"}>
							<div className="flex flex-col gap-y-4">
								<div className="flex flex-col w-full h-[5rem] items-around justify-center border-b border-[#D7D7D7]">
									<div className="flex flex-row items-center">
										<p className="ml-[1.13rem] mr-[0.75rem] font-medium">
											Contact Information
										</p>
									</div>
									<p className="ml-[1.13rem] text-[#7D7D7D] text-sm ">
										Contact Information fields entered by the user
									</p>
								</div>
								<div className="flex justify-center items-center w-full">
									{prospectContactInfo.length !== 0 ? (
										<div className="flex-grow mb-2 mx-[18px]">
											<table className="w-full">
												<thead>
												<tr className="text-left">
													{["Field", "Value"].map(
														(it, ind) => (
															<th
																key={ind}
																className="px-6 pb-[10px] text-[#4444444] font-semibold text-[15px] leading-[15px]"
															>
																{it}
															</th>
														)
													)}
												</tr>
												</thead>
												<tbody>
												{
													prospectContactInfo.map((element, index) => {
														return (
															<tr key={index} className="border-t border-[#D9D9D9]">
																<td className="px-6 py-[10px] text-[#7D7D7D] text-[15px] leading-[15px]">
																	{element.field}
																</td>
																<td className="px-6 py-[10px] text-[#7D7D7D] text-[15px] leading-[15px]"
																>
																	{element.value}
																</td>
															</tr>
														)
													})
												}
												</tbody>
											</table>
										</div>
									) : (
										<div className="flex justify-center items-center w-[98%] my-16">
											<p className="font-semibold">No Data To Display</p>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					:
					null
			}
		</>
	)
}

import React, { useEffect, useRef, useState } from "react";
import {
	useCreateFormMutation,
	useGetFormsQuery,
	useUpdateFormMutation,
} from "../../../features/api/formsApiSlice";
import { useGetVideoQuery } from "../../../features/api/videoApiSlice";
import { useSelector } from "react-redux";
import { PageSpinner } from "../../top-level/PageSpinner";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import ViewContainer from "../../top-level/ViewContainer";
import {
	useGetPageTitleQuery,
	useUpdatePageTitleMutation,
	useUpdatePageVisibilityMutation,
} from "../../../features/api/pagesApiSlice";
import {
	useGetPageStyleTemplateQuery,
	useUpdatePageStyleMutation,
} from "../../../features/api/settingsApiSlice";
import { useGetRecordingQuery } from "../../../features/api/recordingsApiSlice";
import { PAGE } from "../../../data/constants";
import { getBackgroundColor } from "../../../utils/avatar";
import previewIcon from "../../../icons/preview-icon.svg";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { PageLayout } from "./PageLayout";
import { Questions } from "./Questions";
import { Mapping } from "./Mapping";
import { Settings } from "./Settings";
import PublishModal from "../../embed/components/editor/components/components/PublishModal";
import PostPublishModal from "../../embed/components/editor/components/components/PostPublishModal";
import PrePublishModal from "../../embed/components/editor/components/components/PrePublishModal";
import userSettingReducer, {
	setSaveBeforePublishPrompt_Page,
} from "../../../features/state/user-settings/userSettingSlice";
import { getStateFromCookies } from "redux-cookies-middleware";
import { paths } from "../../../features/cookies-paths/paths";
import { extractPlaceholders } from "../../../utils/extractPlaceholders";
import {
	toastError,
	toastLoading,
	toastSuccess,
} from "../../custom-toast/toasts";

export const PageEditStandardCustomized = ({ id, live, type }) => {
	const flags = useSelector((state) => JSON.parse(state.userSetting.flags));
	const [title, setTitle] = useState("");
	const [headline, setHeadline] = useState("");
	const [welcomeMessage, setWelcomeMessage] = useState("");
	const [demoOptions, setDemoOptions] = useState([]);
	const [welcomeDemo, setWelcomeDemo] = useState({});
	const [demoMessage, setDemoMessage] = useState("");
	const [contactInfoMessage, setContactInfoMessage] = useState("");
	const [standardDemoVideos, setStandardDemoVideos] = useState([]);
	const [standardDemoOptions, setStandardDemoOptions] = useState([]);

	const [questions, setQuestions] = useState(null);
	const [mapping, setMapping] = useState(null);
	const [questionId, setQuestionId] = useState(null);
	const mappingRef = useRef([]);
	const [isShown, setIsShown] = useState(false);
	const [triggerRefresh, setTriggerRefresh] = useState(0);

	const [contactFields, setContactFields] = useState([
		{ fieldName: "", type: "", required: false },
	]);
	const [fieldTypeOptions, setFieldTypeOptions] = useState([
		{ key: 1, value: "text", label: "Text" },
		{ key: 2, value: "email", label: "Email" },
		{ key: 3, value: "tel", label: "Phone Number" },
	]);
	const [actionMessage, setActionMessage] = useState("");
	const [buttonLabel, setButtonLabel] = useState("");
	const [redirectURL, setRedirectURL] = useState("");
	const [verifyIdentity, setVerifyIdentity] = useState(flags.gating.checked);
	const [customVerification, setCustomVerification] = useState(
		flags.gating.page.verify.checked
	);
	const [isOutbound, setIsOutbound] = useState(
		flags.gating.page.outbound.checked
	);
	const [isRequired, setIsRequired] = useState(
		flags.gating.page.required.checked
	);
	const [checkBlocklist, setCheckBlocklist] = useState(
		flags.gating.page.verify.blocklist.checked
	);
	const [checkDomain, setCheckDomain] = useState(
		flags.gating.page.verify.domain.checked
	);
	const [checkIntended, setCheckIntended] = useState(
		flags.gating.page.verify.intended.checked
	);
	const [checkTwoFactor, setCheckTwoFactor] = useState(
		flags.gating.page.verify.twofactor.checked
	);
	const [verificationContent, setVerificationContent] = useState({
		title: "Enter your email address to continue",
		description: "Enter your email address below to access the content",
		placeholder: "",
	});

	const history = useHistory();

	const saveBeforePublishPrompt = getStateFromCookies(userSettingReducer, paths)
		.userSetting.saveBeforePublishPrompt_Page;
	const [openPublishPopUp, setOpenPublishPopUp] = useState(false);
	const [openSaveAfterPublish, setOpenSaveAfterPublish] = useState(false);
	const [openSaveBeforePublish, setOpenSaveBeforePublish] = useState(false);
	const [neverShowPrompt, setNeverShowPrompt] = useState(false);

	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);

	const fetchedForms = useGetFormsQuery({ companyId: companyId });
	const fetchedVideos = useGetVideoQuery({ companyId: companyId,limit:10000,pageNo:1 });
	const fetchPageTemplate = useGetPageStyleTemplateQuery({ pageId: id });
	const fetchedDemos = useGetRecordingQuery({
		companyId: companyId, limit: 1000,
		pageNo: 1
	});
	const fetchTitle = useGetPageTitleQuery({ pageId: id });
	const [updateStyle] = useUpdatePageStyleMutation();
	const [goLive] = useUpdatePageVisibilityMutation();
	const [createForm] = useCreateFormMutation();
	const [updateForm] = useUpdateFormMutation();
	const [updatePageTitle] = useUpdatePageTitleMutation();

	useEffect(() => {
		if (!fetchTitle.isLoading) {
			if (fetchTitle.data !== null) {
				setTitle(fetchTitle.data.payload[0].title);
			}
		}
	}, [fetchTitle]);

	useEffect(() => {
		if (!fetchedForms.isLoading && !fetchPageTemplate.isLoading) {
			if (fetchPageTemplate.data.payload[0]?.page_style === undefined) return;
			if (fetchedForms.data !== null) {
				const template = JSON.parse(
					fetchPageTemplate.data.payload[0].page_style
				);
				if (template.form !== null && template.form !== undefined) {
					const questionnaire = fetchedForms.data.filter(
						(it) => it.id === template.form
					);
					if (questionnaire.length !== 0) {
						setQuestionId(template.form);
						setQuestions(
							JSON.parse(questionnaire[0].formjson).questions.map((it, i) => {
								return { id: i + 1, data: it };
							})
						);
						setMapping(
							JSON.parse(questionnaire[0].mapping)?.map((it, i) => {
								return { id: i + 1, data: it };
							})
						);
					}
				}
			}
		}
	}, [fetchedForms, fetchPageTemplate]);

	useEffect(() => {
		if (!fetchedVideos.isLoading && !fetchedDemos.isLoading) {
			if (
				fetchedVideos.data.status !== "error" &&
				fetchedDemos.data.status !== "error"
			) {
				let video = [];
				let demosAndVideos = [];
				let index = 1;
				// fetchedVideos.data.payload.forEach((it) => {
				// 	video.push({
				// 		key: index,
				// 		value: it.id,
				// 		label: it.title,
				// 		type: "video",
				// 	});
				// 	demosAndVideos.push({
				// 		key: index,
				// 		value: it.id,
				// 		label: it.title,
				// 		type: "video",
				// 	});
				// 	index += 1;
				// });
				fetchedDemos.data.payload.forEach((it) => {
					video.push({
						key: index,
						value: it.id,
						label: it.title,
						type: "demo",
					});
					demosAndVideos.push({
						key: index,
						value: it.id,
						label: it.title,
						type: "demo",
					});
					index += 1;
				});
				setStandardDemoOptions(demosAndVideos);
				video.unshift({ key: -1, value: -1, label: "No Demo", type: "demo" });
				setWelcomeDemo({ key: -1, value: -1, label: "No Demo", type: "demo" });
				setDemoOptions(video);
			}
		}
	}, [fetchedVideos, fetchedDemos]);

	useEffect(() => {
		if (!fetchPageTemplate.isLoading) {
			if (fetchPageTemplate.data.payload.length !== 0) {
				const template = JSON.parse(
					fetchPageTemplate.data.payload[0].page_style
				);
				const parsedVerificationContent = template?.verificationContent;

				console.dir(JSON.parse(fetchPageTemplate.data.payload[0].page_style));
				setHeadline(template.headline);
				setWelcomeMessage(template.welcomeMessage);
				setActionMessage(template.actionMessage);
				setRedirectURL(template.redirectURL);
				setButtonLabel(template.actionButtonLabel);
				setContactInfoMessage(template.contactInfoMessage);
				setDemoMessage(template.previewMessage);
				setStandardDemoVideos(template.standardPreviewVideos);
				template.standardPreviewVideos === undefined
					? setStandardDemoVideos([])
					: setStandardDemoVideos(template.standardPreviewVideos);
				setVerifyIdentity(template.settings?.verifyIdentity);
				setCustomVerification(template.settings?.customVerification);
				setIsOutbound(template.settings?.isOutbound);
				setIsRequired(template.settings?.isRequired);
				setCheckBlocklist(template.settings?.checkBlocklist);
				setCheckDomain(template.settings?.checkDomain);
				setCheckIntended(template.settings?.checkIntended);
				setCheckTwoFactor(template.settings?.checkTwoFactor);

				if (parsedVerificationContent) {
					setVerificationContent(parsedVerificationContent);
				}

				const demo = demoOptions.filter(
					(it) => it.value === template.welcomePreview.id
				);
				if (demo !== undefined && demo.length !== 0) {
					setWelcomeDemo(demo[0]);
				}
				if (template.contactFields !== undefined) {
					if (template.contactFields.length !== 0) {
						setContactFields(template.contactFields);
					}
				}
			}
		}
	}, [fetchPageTemplate, demoOptions]);

	const saveQuestionMapping = async () => {
		if (!questions || questions.length <= 0) {
			return null;
		}

		const questionsList = questions
			.map((it) => it.data)
			.filter(
				(it) =>
					it.choices !== undefined &&
					it.choices.length !== 0 &&
					it.name !== undefined &&
					it.name !== ""
			);
		const mappingList = [];
		try {
			let integrityFlag = true;
			mapping.forEach((it) => {
				const data = mappingRef.current[it.id].sendData();
				if (data === null || data === undefined || Object.keys(data?.result?.demo).length === 0) {
					integrityFlag = false;
				}
			});
			if (!integrityFlag) {
				toastError("Mapping not saved due to incomplete fields");
				return null;
			} else {
				mapping.forEach((it) => {
					const data = mappingRef.current[it.id].sendData();
					if (data !== null && data !== undefined) {
						if (
							data.result.episode === "demo" &&
							Object.keys(data.result.demo).length !== 0
						) {
							mappingList.push(data);
						}
					}
				});
			}
		} catch (e) {
			console.log(e);
		}

		let status;
		if (!questionId) {
			status = await createForm({
				title: title,
				formJson: JSON.stringify({ questions: questionsList }),
				mapping: (!mapping || mapping.length <= 0) ? null : JSON.stringify(mappingList),
				salesRepId: salesRepId,
				companyId: companyId,
			}).unwrap();
		} else {
			status = await updateForm({
				title: title,
				formJson: JSON.stringify({ questions: questionsList }),
				mapping: (!mapping || mapping.length <= 0) ? null : JSON.stringify(mappingList),
				formId: questionId,
			}).unwrap();
		}

		if (status.status === "error") {
			return Error;
		} else {
			setQuestionId(status.payload);
			return status.payload;
		}
	};

	const savePageSetting = async () => {
		if (title === "") {
			return toastError("Page Title is empty");
		}
		if (type === "standard" && standardDemoVideos.length === 0) {
			return toastError("Please add at least one demo");
		}
		const toastId = toastLoading();

		let questionMappingId;
		try {
			questionMappingId = await saveQuestionMapping();
		} catch (e) {
			toast.dismiss(toastId);
			toastError("Unable to update page");
			return false;
		}

		const pageTitleStatus = await updatePageTitle({
			pageId: id,
			title: title,
		}).unwrap();

		const welcomeDemoValue =
			Object.keys(welcomeDemo).length !== 0 ? welcomeDemo.value : "";
		const welcomeDemoType =
			Object.keys(welcomeDemo).length !== 0 ? welcomeDemo.type : "";
		const pageData = {
			headline: headline,
			welcomeMessage: welcomeMessage,
			welcomePreview: { id: welcomeDemoValue, type: welcomeDemoType },
			form: questionId ? questionId : questionMappingId,
			previewMessage: demoMessage,
			contactInfoMessage: contactInfoMessage,
			contactFields: contactFields,
			actionMessage: actionMessage,
			actionButtonLabel: buttonLabel,
			redirectURL: redirectURL,
			standardPreviewVideos: standardDemoVideos,
			settings: {
				verifyIdentity,
				customVerification,
				isOutbound,
				isRequired,
				checkBlocklist,
				checkDomain,
				checkIntended,
				checkTwoFactor,
			},
			verificationContent,
		};
		const status = await updateStyle({
			pageId: id,
			style: JSON.stringify(pageData),
			placeholders: JSON.stringify(
				extractPlaceholders(
					headline,
					welcomeMessage,
					demoMessage,
					contactInfoMessage,
					actionMessage,
					buttonLabel,
					redirectURL
				)
			),
		}).unwrap();
		if (status.status === "success") {
			toast.dismiss(toastId);
			toastSuccess("Page Updated");
			return true;
		} else {
			toast.dismiss(toastId);
			toastError("Error Encountered While Updating Page");
			return false;
		}
	};

	const handlePreview = async () => {
		let contactFieldFlag = true;
		contactFields.forEach((el) => {
			if (el.fieldName === "" || el.type === "") {
				// toastError("Contact Fields Incomplete");
				contactFieldFlag = false;
			}
		});
		if (
			headline === "" ||
			welcomeMessage === "" ||
			actionMessage === "" ||
			buttonLabel === "" ||
			redirectURL === "" ||
			demoMessage === "" ||
			contactInfoMessage === "" ||
			!contactFieldFlag
		) {
			return toastError("Fields Incomplete");
		}
		const status = await savePageSetting();

		if (contactFieldFlag) {
			const encryptedId =
				"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDEsIm5hbWUiOiJVc2VyIiwiZW1haWwiOiJ6ZXJvc0BwcmV2aWV3LmNvbSIsInBhZ2VJZCI6Nywic2FsZXNyZXBJZCI6OSwiY29tcGFueUlkIjoxNywic3RhbXAiOjE2NDc0MzIxMDYxMzcsImlhdCI6MTY0NzQzMjEwNn0.agGHhfVAjDoh4YsrJ8u61M2WG6kzKMCfDiZNAX1NR64";
			if (status) {
				window.open(
					`${process.env.REACT_APP_CLIENT_PROSPECT}/${encryptedId}/preview/${id}/${companyId}/demo`
				);
			}
		}
	};

	const handlePublishPage = async () => {
		await savePageSetting();
		let contactFieldFlag = true;
		contactFields.forEach((el) => {
			if (el.fieldName === "" || el.type === "") {
				// toastError("Contact Fields Incomplete");
				contactFieldFlag = false;
			}
		});

		if (
			type === "customized" &&
			(questions?.length === 0 || mapping?.length === 0 || !questionId)
		) {
			return toastError("Please create Questions & Mapping");
		}

		if (
			headline === "" ||
			welcomeMessage === "" ||
			actionMessage === "" ||
			buttonLabel === "" ||
			redirectURL === "" ||
			demoMessage === "" ||
			contactInfoMessage === ""
		) {
			return toastError("Fields Incomplete");
		}

		if (contactFieldFlag) {
			const status = await goLive({
				pageId: id,
				companyId: companyId,
				live: parseInt(live) !== 0,
			}).unwrap();
			if (status.status === "success") {
				// toastSuccess("Page Visibility Updated");
				history.push("..");
			} else if (status.status === "error" && status.payload === null) {
				toastError("Create Page and Company Settings Before Publishing!");
			} else {
				toastError("Error Encountered While Publishing Page");
			}
		}
	};

	const handleFieldNameChange = (value, index) => {
		let info = [...contactFields];
		info[index].fieldName = value;
		setContactFields(info);
	};

	const handleTypeChange = (value, index) => {
		let info = [...contactFields];
		info[index].type = value;
		setContactFields(info);
	};

	const handleRequiredChange = (index) => {
		let info = [...contactFields];
		info[index].required = !info[index].required;
		setContactFields(info);
	};

	const handleAddNewField = () => {
		let info = [...contactFields];
		info.push({ fieldName: "", type: "", required: false });
		setContactFields(info);
	};

	const handleDeleteContactField = (index) => {
		let info = [...contactFields];
		info.splice(index, 1);
		setContactFields(info);
	};

	const handleStandardDemoChange = (value, index) => {
		let info = [...standardDemoVideos];
		info.splice(index, 1, value);
		setStandardDemoVideos(info);
	};

	const handleDeleteStandardDemoField = (index) => {
		let info = [...standardDemoVideos];
		info.splice(index, 1);
		setStandardDemoVideos(info);
	};

	const handleAddNewStandardDemoField = () => {
		let info = [...standardDemoVideos];
		info.push({ key: 0, value: "", label: "", type: "" });
		setStandardDemoVideos(info);
	};

	if (
		fetchedForms.isLoading ||
		fetchedVideos.isLoading ||
		fetchTitle.isLoading
	) {
		return <PageSpinner text="Loading Page..." />;
	}

	if (fetchedForms.isError || fetchedVideos.isError || fetchTitle.isError) {
		toastError("Couldn't retrieve data. Refresh page.");
	}

	return (
		<ViewContainer>
			<div className="flex flex-col justify-center items-center">
				<div className="w-[69rem]">
					<div className="sticky top-0 flex flex-row justify-between py-4 bg-white z-50">
						<div className="flex flex-col w-3/5 mb-4">
							<input
								type="text"
								className="px-1.5 py-0.5 font-normal leading-5 text-xl font-medium text-[#222222] border border-white focus:rounded-sm focus:border-zeros-blue"
								value={title}
								placeholder={`Title for this ${PAGE.toLowerCase()}`}
								onChange={(e) => setTitle(e.target.value)}
							/>
							<div className="flex flex-row mx-3 mt-1 gap-x-2 items-center">
								<div className="flex justify-center items-center gap-x-2">
									<div
										className="flex justify-center items-center w-6 h-6 rounded-full text-white"
										style={{
											backgroundColor: getBackgroundColor(
												fetchTitle.data.payload[0].owner_id
											),
										}}
									>
										{fetchTitle.data.payload[0].name.trim()[0]}
									</div>
									<p className="text-base font-normal text-[#888888]">
										{fetchTitle.data.payload[0].name.trim()}
									</p>
								</div>
								<div className="w-1 h-1 rounded-full bg-[#686868]" />
								<p className="text-base font-normal text-[#888888]">
									{new Date().toLocaleDateString("en-us", {
										year: "numeric",
										month: "long",
										day: "numeric",
									})}
								</p>
							</div>
						</div>
						<div className="flex gap-x-[18px]">
							<button
								className={`border w-[44px] h-[44px] rounded-md mt-1 font-medium bg-[#FFFFFF] hover:bg-[#E6E9EF] flex items-center justify-center`}
								type="button"
								onClick={() => handlePreview()}
							// disabled={isNew}
							>
								<img
									src={previewIcon}
									alt="preview"
									className="w-[24px] h-[24px]"
								/>
							</button>
							{live === "0" ? (
								<button
									className={
										live === "0"
											? `border border-[#63A4FA] w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-[#1D60B5] bg-[#FFFFFF] hover:bg-[#CCE5FF]`
											: `border w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium bg-[#E8E8E8] text-[#6F6F6F] rounded disabled cursor-not-allowed`
									}
									onClick={() => setOpenPublishPopUp(true)}
									// type="button"
									disabled={live === "1"}
								>
									Publish
								</button>
							) : (
								<button
									className={`w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 bg-[#E8E8E8] disabled cursor-not-allowed`}
									// onClick={() => publish()}
									type="button"
									disabled={true}
								>
									<div className="flex flex-row justify-center items-center gap-x-[8px]">
										<div className="w-[14px] h-[14px] bg-[#4CB051] rounded-full" />
										<p className="text-[16px] leading-[16px] text-[#6F6F6F]">
											Published
										</p>
									</div>
								</button>
							)}
							<button
								className={`border w-[4.75rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA]`}
								type="button"
								onClick={() => {
									live === "1"
										? setOpenSaveAfterPublish(true)
										: saveBeforePublishPrompt ||
											saveBeforePublishPrompt === undefined
											? setOpenSaveBeforePublish(true)
											: savePageSetting();
								}}
							>
								Save
							</button>
						</div>
					</div>
					<Tabs
						forceRenderTabPanel={true}
						onSelect={() => {
							setIsShown(false);
							setTriggerRefresh(triggerRefresh + 1);
						}}
					>
						<TabList className="flex items-center w-full">
							<Tab
								className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
								selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
							>
								Layout
							</Tab>
							{type === "customized" && (
								<Tab
									className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
									selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
								>
									Questions
								</Tab>
							)}
							{type === "customized" && (
								<Tab
									className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
									selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
								>
									Mapping
								</Tab>
							)}
							<Tab
								className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
								selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
							>
								Settings
							</Tab>
						</TabList>
						<TabPanel>
							<PageLayout
								buttonLabel={buttonLabel}
								setButtonLabel={setButtonLabel}
								demoOptions={demoOptions}
								actionMessage={actionMessage}
								setActionMessage={setActionMessage}
								contactFields={contactFields}
								contactInfoMessage={contactInfoMessage}
								demoMessage={demoMessage}
								demoType={type}
								setContactInfoMessage={setContactInfoMessage}
								redirectURL={redirectURL}
								setRedirectURL={setRedirectURL}
								fieldTypeOptions={fieldTypeOptions}
								handleAddNewField={handleAddNewField}
								handleAddNewStandardDemoField={handleAddNewStandardDemoField}
								handleDeleteStandardDemoField={handleDeleteStandardDemoField}
								handleDeleteContactField={handleDeleteContactField}
								handleFieldNameChange={handleFieldNameChange}
								handleRequiredChange={handleRequiredChange}
								handleStandardDemoChange={handleStandardDemoChange}
								handleTypeChange={handleTypeChange}
								headline={headline}
								setHeadline={setHeadline}
								setDemoMessage={setDemoMessage}
								setWelcomeDemo={setWelcomeDemo}
								welcomeDemo={welcomeDemo}
								setWelcomeMessage={setWelcomeMessage}
								welcomeMessage={welcomeMessage}
								standardDemoOptions={standardDemoOptions}
								standardDemoVideos={standardDemoVideos}
								verifyIdentity={verifyIdentity}
								verificationContent={verificationContent}
								setVerificationContent={setVerificationContent}
							/>
						</TabPanel>
						{type === "customized" && (
							<TabPanel>
								<Questions
									questionList={questions}
									setQuestionList={setQuestions}
								/>
							</TabPanel>
						)}
						{type === "customized" && (
							<TabPanel>
								<Mapping
									mapping={mapping}
									setMapping={setMapping}
									questionList={questions}
									demoOptions={demoOptions}
									mappingRef={mappingRef}
									isShown={isShown}
									setIsShown={setIsShown}
									triggerRefresh={triggerRefresh}
								/>
							</TabPanel>
						)}
						<TabPanel>
							<Settings
								verifyIdentity={verifyIdentity}
								setVerifyIdentity={setVerifyIdentity}
								customVerification={customVerification}
								setCustomVerification={setCustomVerification}
								isOutbound={isOutbound}
								setIsOutbound={setIsOutbound}
								isRequired={isRequired}
								setIsRequired={setIsRequired}
								checkBlocklist={checkBlocklist}
								setCheckBlocklist={setCheckBlocklist}
								checkDomain={checkDomain}
								setCheckDomain={setCheckDomain}
								checkIntended={checkIntended}
								setCheckIntended={setCheckIntended}
								checkTwoFactor={checkTwoFactor}
								setCheckTwoFactor={setCheckTwoFactor}
							/>
						</TabPanel>
					</Tabs>
					<PublishModal
						open={openPublishPopUp}
						setOpen={setOpenPublishPopUp}
						onSubmit={handlePublishPage}
					/>
					<PostPublishModal
						open={openSaveAfterPublish}
						setOpen={setOpenSaveAfterPublish}
						onSubmit={() => {
							savePageSetting();
						}}
					/>
					<PrePublishModal
						open={openSaveBeforePublish}
						setOpen={setOpenSaveBeforePublish}
						neverShowPrompt={neverShowPrompt}
						setNeverShowPrompt={setNeverShowPrompt}
						onSubmit={() => {
							savePageSetting();
						}}
						reducer={setSaveBeforePublishPrompt_Page}
					/>
				</div>
			</div>
		</ViewContainer>
	);
};

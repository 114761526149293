const StyledButton = (props) => {
  const { className, onClick, type } = {
    onClick: props.onClick,
    className: props.className,
    type: props.type,
  };

  const types = {
    primary: "text-white bg-[#0071F2] hover:bg-[#0351AA]",
    secondary: "text-white bg-gray-800 hover:bg-gray-600",
    info: "text-white bg-yellow-800 hover:bg-yellow-600",
    danger: "text-white bg-red-800 hover:bg-red-600",
    success: "text-white bg-green-800 hover:bg-green-600",
    link: "text-black bg-gray-100 hover:underline",
    cta: "text-black bg-white hover:bg-[#E6E9EF] focus:bg-[#CCE5FF] focus:border-[#0073EA] border-[#C5C7D0]",
  };

  return (
    <button
      className={`border rounded-md p-3 font-medium ${types[type]} ${className}`}
      onClick={onClick}
    >
      {props.children}
    </button>
  );
};

export default StyledButton;

import {useSelector} from "react-redux";
import {Route, Switch, useHistory} from "react-router-dom";
import {PageSpinner} from "../top-level/PageSpinner";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {
	useCopyPageMutation,
	useDeletePageMutation,
	useGetAllPagesQuery,
	useInsertNewPageMutation,
} from "../../features/api/pagesApiSlice";
import {confirmAlert} from "react-confirm-alert";
import TopBar from "../top-level/TopBar";
import ViewContainer from "../top-level/ViewContainer";
import StyledButton from "../top-level/StyledButton";
import {GeneratePageDialog} from "./components/GeneratePageDialogue";
import Modal from "react-modal";
import {useDeleteRelationshipMutation, useGetRelationshipsQuery,} from "../../features/api/relationshipApiSlice";
import {PAGE} from "../../data/constants";
import plusCircle from "../../icons/plus-circle.svg";
import blueDownloadArrowDown from "../../icons/blue-download-arrow-down.svg";
import {customStyles} from "../../utils/modalStyles";
import closeCrossIcon from "../../icons/closecross-icon.svg";
import {getBackgroundColor} from "../../utils/avatar";
import pageLinkIcon from "../../icons/page-link-icon.svg";
import ReactTooltip from "react-tooltip";
import DropdownIcon from "../top-level/DropdownIcon";
import moreIcon from "../../icons/more-icon.svg";
import cloneIcon from "../../icons/clone-icon.svg";
import deleteIcon from "../../icons/delete-icon.svg";

import "./Page.css";
import {PageTypeRouter} from "./components/PageTypeRouter";
import collectionImg from "../../icons/collection-img.svg";
import QAImg from "../../icons/q&a-img.svg";
import embedImg from "../../icons/embed-img.svg";
import {Spinner} from "../top-level/Spinner";
import {toastError, toastLoading, toastSuccess} from "../custom-toast/toasts";
import Papa from "papaparse";

const CreateNewPage = ({isOpen, setIsOpen}) => {
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);
	const history = useHistory();

	const [pageType, setPageType] = useState("customized");
	const [insertNewPage, {}] = useInsertNewPageMutation();

	const createPage = async () => {
		const status = await insertNewPage({
			salesRepId: salesRepId,
			companyId: companyId,
			title: `Title - ${new Date().toISOString().slice(0, 19).replace("T", " ")}`,
			type: pageType,
			dateCreated: new Date().toISOString().slice(0, 19).replace("T", " "),
			dateModified: new Date().toISOString().slice(0, 19).replace("T", " "),
		}).unwrap();
		if (status.status === "success") {
			history.push(`/feature/page/edit/${status.payload[0].id}/${status.payload[0].type}/${status.payload[0].live.data[0]}`)
		} else {
			toastError("Error Encountered While Creating Page");
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={() => {
				setIsOpen(false);
			}}
			style={customStyles}
		>
			<div className="flex flex-col mx-[80px] my-[48px] gap-y-[48px]">
				<div className="flex flex-col justify-center items-center">
					<p className="font-medium text-[28px]">
						Select the type of {`${PAGE.toLowerCase()}`} to create
					</p>
				</div>

				<div
					className="flex flex-row justify-center items-center gap-x-[32px]"
					onClick={() => setPageType("embed")}
				>
					<div className="flex flex-col justify-center items-center rounded-full w-6 h-6 border-2 border-[#2B71CE]">
						<div
							className={
								pageType === "embed"
									? "w-[0.875rem] h-[0.875rem] rounded-full bg-[#2B71CE]"
									: "hidden"
							}
						/>
					</div>
					<div>
						<img
							src={embedImg}
							alt=""
							className={`w-[11.75rem] h-[9rem] rounded-[0.81rem] ${
								pageType === "embed" ? "border-2 border-zeros-blue" : ""
							}`}
						/>
					</div>
					<div className="flex flex-col gap-y-[0.5rem] ml-[0.875rem]">
						<p className="font-medium text-[24px]">Embed</p>
						<p className="font-normal text-[13px] text-[#7D7D7D] w-[20rem]">
							Send a ‘Choose Your Adventure’ journey or any other embed to a target account.
							Add a welcome message and use placeholders to personalize it in bulk for an entire contact list.
						</p>
					</div>
				</div>

				<div
					className="flex flex-row justify-center items-center gap-x-6"
					onClick={() => setPageType("customized")}
				>
					<div className="flex flex-col justify-center items-center rounded-full w-6 h-6 border-2 border-[#2B71CE]">
						<div
							className={
								pageType === "customized"
									? "w-[0.875rem] h-[0.875rem] rounded-full bg-[#2B71CE]"
									: "hidden"
							}
						/>
					</div>
					<div>
						<img
							src={QAImg}
							alt=""
							className={`w-[11.75rem] h-[9rem] rounded-[0.81rem] ${
								pageType === "customized" ? "border-2 border-zeros-blue" : ""
							}`}
						/>
					</div>
					<div className="flex flex-col gap-y-[0.5rem] ml-[0.875rem]">
						<p className="font-medium text-[24px]">Q&A</p>
						<p className="font-normal text-[13px] text-[#7D7D7D] w-[20rem]">
							Send a dialogue to a target account. Ask questions and deliver content tailored to their responses.
							Use placeholders to personalize the page in bulk for an entire contact list.
						</p>
					</div>
				</div>

				<div
					className="flex flex-row justify-center items-center gap-x-6"
					onClick={() => setPageType("standard")}
				>
					<div className="flex flex-col justify-center items-center rounded-full w-6 h-6 border-2 border-[#2B71CE]">
						<div
							className={
								pageType === "standard"
									? "w-[0.875rem] h-[0.875rem] rounded-full bg-[#2B71CE]"
									: "hidden"
							}
						/>
					</div>
					<div>
						<img
							src={collectionImg}
							alt=""
							className={`w-[11.75rem] h-[9rem] rounded-[0.81rem] ${
								pageType === "standard" ? "border-2 border-zeros-blue" : ""
							}`}
						/>
					</div>
					<div className="flex flex-col gap-y-[0.5rem] ml-[0.875rem]">
						<p className="font-medium text-[24px]">Collection</p>
						<p className="font-normal text-[13px] text-[#7D7D7D] w-[20rem]">
							Send a collection of demos, videos and other content to a target account.
							Use placeholders to personalize the page in bulk for an entire contact list.
						</p>
					</div>
				</div>

				<div className="flex flex-row-reverse gap-x-[2rem] items-center">
					<StyledButton
						type="primary"
						className="flex justify-center items-center w-[6.63rem] h-[2.75rem] bg-[#0071F2] text-white text-[16px] font-semibold rounded-[0.25rem]"
						onClick={async (e) => {
							e.preventDefault();
							await createPage();
							setIsOpen(false);
						}}
					>
						Continue
					</StyledButton>
					<button
						className="hover:underline"
						onClick={() => setIsOpen(false)}
					>
						Cancel
					</button>
				</div>
			</div>
		</Modal>
	);
};
const PageTableView = () => {
	const companyId = useSelector((state) => state.sessionData.companyId);
	const {data, isLoading, isError, error} = useGetAllPagesQuery({
		companyId: companyId,
	});

	if (isLoading) return <PageSpinner text="Loading Pages..."/>;
	if (isError) return <div>{JSON.stringify(error)}</div>;

	return (
		<div>
			<PageTable pages={data.payload}/>
			{/*<Tabs disableUpDownKeys={true} selectedTabClassName="tabs">*/}
			{/*	<TabList className="flex flex-row gap-x-12">*/}
			{/*		<Tab className="text-2xl my-4 font-semibold">{PAGE} List</Tab>*/}
			{/*		<Tab className="text-2xl my-4 font-semibold">Redirect Rules</Tab>*/}
			{/*	</TabList>*/}
			{/*	<TabPanel>*/}
			{/*		<PageTable pages={data.payload} />*/}
			{/*	</TabPanel>*/}
			{/*	<TabPanel>*/}
			{/*		<PageRedirectRules data={data.payload} />*/}
			{/*	</TabPanel>*/}
			{/*</Tabs>*/}
		</div>
	);
};

const PageTable = ({pages: unsortedPages}) => {
	const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
	const [deletePage, {}] = useDeletePageMutation();
	const [copyPage, {}] = useCopyPageMutation();
	const companyId = useSelector((state) => state.sessionData.companyId);
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const [randomID] = useState(String(Math.random()));
	const [pages, setPages] = useState();
	const [showPageSender, setShowPageSender] = useState(false);
	const [pageId, setPageId] = useState(0);

	const history = useHistory();

	useEffect(() => {
		const sortedPages = [...unsortedPages].sort((a, b) => {
			return new Date(b.date_created) - new Date(a.date_created);
		});
		setPages(sortedPages);
	}, [unsortedPages]);

	useEffect(() => {
		ReactTooltip.rebuild()
	}, [pages]);

	const deleting = async (pageId) => {
		const id = toastLoading("Delete in Progress");
		try {
			const deletion = await deletePage({pageId: pageId}).unwrap();
			if (deletion.status === "success") {
				toast.dismiss(id);
			} else {
				toast.dismiss(id);
				toastError("Failed to Delete Page");
			}
		} catch (err) {
			toastError("Couldn't connect to database");
		}
	};

	const pageDeleteHandler = async (pageId) => {
		confirmAlert({
			customUI: ({onClose}) => {
				return (
					<div className="flex flex-col w-[30rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<p className="mt-[2.25rem] text-[18px] font-medium">
							{`Are you sure you want to delete this ${PAGE.toLowerCase()}?`}
						</p>
						<p className="mt-[1.5rem] text-[14px] leading-[14px]">
							Once deleted cannot be recovered
						</p>
						<div className="flex flex-row justify-between mt-[3.375rem]">
							<button
								onClick={onClose}
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Cancel
							</button>
							<button
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#EDC8C5] font-roboto text-[16px] leading-[24px] border border-[#D83A52] text-[#D83A52] rounded-[0.25rem]"
								onClick={() => {
									setShowPageSender(false);
									deleting(pageId);
									onClose();
								}}
							>
								Delete
							</button>
						</div>
					</div>
				);
			},
		});
	};

	const pageCloneHandler = (page) => {
		confirmAlert({
			customUI: ({onClose}) => {
				return (
					<div
						className="flex flex-col w-[36.38rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<div className="flex items-center justify-end">
							<button
								onClick={onClose}
								className="flex mt-[1.625rem] justify-center items-center"
							>
								<img
									src={closeCrossIcon}
									alt="close"
									className="w-[2.13rem] h-[2.13rem]"
								/>
							</button>
						</div>
						<p className="mt-[0.25rem] text-[18px] font-medium">
							Would you like to clone this {`${PAGE.toLowerCase()}`} with its
							settings?
						</p>
						<div className="flex flex-row justify-between mt-[4rem]">
							<button
								onClick={onClose}
								className="flex justify-center items-center w-[10.25rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Cancel
							</button>
							<button
								onClick={async () => {
									const pageData = {
										cloneFromId: page.id,
										salesRepId: salesRepId,
										companyId: companyId,
										title: `${page.title} - Copy`,
										type: page.type,
										dateCreated: new Date()
											.toISOString()
											.slice(0, 19)
											.replace("T", " "),
										dateModified: new Date()
											.toISOString()
											.slice(0, 19)
											.replace("T", " "),
									};
									const status = await copyPage(pageData).unwrap();
									if (status.status === "success") {
										// toastSuccess(`${DEMO} Copied`);
										onClose();
									} else {
										toastError(`Error copying ${PAGE.toLowerCase()}`);
										onClose();
									}
								}}
								className="flex justify-center items-center w-[10.25rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Clone
							</button>
						</div>
					</div>
				);
			},
		});
	};

	const moreSelectHandler = (value, element) => {
		switch (value) {
			case "clone":
				pageCloneHandler(element);
				break;
			case "delete":
				pageDeleteHandler(element.id);
				break;
			default:
				console.log("Action does not exist");
		}
	};

	const pageTypeNameHandler = (type) => {
		switch (type) {
			case "customized":
				return "Q&A"
			case "standard":
				return "Collection"
			case "embed":
				return "Embed"
		}
	}

	return (
		<>
			<ReactTooltip effect="solid" place="bottom" id={randomID} html={true}/>
			<div className="flex flex-col justify-center items-center">
				<div className="sticky top-0 flex flex-row justify-between bg-white py-4 w-[69rem] z-10">
					<div className="grow">
						<TopBar
							heading={`${PAGE}s`}
							description="Create target pages personalized to each prospect"
						/>
					</div>
					<div className="flex grow">
						<div className="flex items-center justify-center">
							<div className="flex flex-row items-center gap-x-6">
								<div>
									<div>
										<button
											className="flex items-center h-[2.75rem] rounded-[0.375rem] text-white bg-[#0071F2] hover:bg-[#0351AA]"
											onClick={() => setOpenCreateDialogue(true)}
										>
											<div className="flex flex-row mx-5 gap-x-[0.5rem]">
												<img
													src={plusCircle}
													alt="plusCircle"
													// className="w-[1.48rem] h-[1.48rem]"
												/>
												<p className="font-semibold text-base">
													Create {`${PAGE}`}
												</p>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-[69rem]">
					<div className="mt-[48px]">
						<table className="w-full">
							<thead>
							<tr className="text-left">
								{["Title", "Status", "Page Type", "Owner", "Created On", "Action"].map(
									(it, ind) => (
										<th
											key={ind}
											className="px-[40px] py-[10px] bg-[#F8F8F8] text-[#4444444] font-bold text-[15px] leading-[15px]"
										>
											{it}
										</th>
									)
								)}
							</tr>
							</thead>
							<tbody>
							{pages &&
							pages.map((element, index) => {
								return (
									<tr
										className="hover:drop-shadow-lg cursor-pointer"
										onClick={() => {
											history.push(
												`/feature/page/edit/${element.id}/${element.type}/${element.live.data[0]}`
											);
										}}
										key={element.id}
									>
										<td
											className={`px-[40px] py-[23px] text-[14px] leading-[14px] text-[#222222] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}
										>
											{element.title.trim().length !== 0
												? element.title
												: "N/A"}
										</td>
										<td
											className={`px-[40px] py-[23px] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}
										>
											{element.live.data[0] === 1 ? (
												<div className="flex flex-row justify-start items-center gap-x-[8px]">
													<div className="w-[4px] h-[4px] bg-[#39C081] rounded-full"/>
													<p className="text-[14px] leading-[14px] text-[#39C081]">
														Active
													</p>
												</div>
											) : (
												<div className="flex flex-row justify-start items-center gap-x-[8px]">
													<div className="w-[4px] h-[4px] bg-[#556172] rounded-full"/>
													<p className="text-[16px] leading-[16px] text-[#556172]">
														Inactive
													</p>
												</div>
											)}
										</td>
										<td
											className={`px-[40px] py-[23px] text-[14px] leading-[14px] text-[#7D7D7D] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}
										>
											{pageTypeNameHandler(element.type)}
										</td>
										<td
											className={`px-[40px] py-[23px] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}
										>
											<div className="flex flex-grow justify-start items-center ml-[8px]">
												<div
													className="flex justify-center items-center text-white w-[2rem] h-[2rem] rounded-full"
													style={{
														backgroundColor: getBackgroundColor(
															element.owner_id
														),
													}}
												>
													{element.name[0]}
												</div>
											</div>
										</td>
										<td
											className={`px-[40px] py-[23px] text-[14px] leading-[14px] text-[#7D7D7D] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}
										>
											{new Date(element.date_created).toLocaleDateString(
												"en-us",
												{
													year: "numeric",
													month: "long",
													day: "numeric",
												}
											)}
										</td>
										<td
											className={`px-[40px] py-[23px] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}
										>
											<div className="flex flex-row items-center justify-start gap-x-[16px] ml-[-16px]">
												<div
													data-tip={"Share"}
													data-for={randomID}
													className="border border-transparent hover:bg-[#F8F8F8] hover:rounded-md hover:border-[#D2D2D2] mb-1"
												>
													<button
														className="flex justify-center items-center no-underline text-black"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setPageId(element.id);
															setShowPageSender(true);
														}}
													>
														<img
															src={pageLinkIcon}
															alt="link"
															className={"w-[30px] h-[30px]"}
														/>
													</button>
												</div>
												<div
													className="flex justify-center items-center mr-[1rem]"
													data-tip={"More"}
													data-for={randomID}
												>
													<DropdownIcon
														className="border border-transparent hover:bg-[#F8F8F8] hover:rounded-md hover:border hover:border-[#D2D2D2]"
														titleIcon={moreIcon}
														options={[
															{
																key: 1,
																value: "clone",
																label: "Clone",
																icon: cloneIcon,
																element: element,
															},
															{
																key: 2,
																value: "delete",
																label: "Delete",
																icon: deleteIcon,
																element: element,
															},
														]}
														onSelectHandler={moreSelectHandler}
													/>
												</div>
											</div>
										</td>
									</tr>
								);
							})}
							</tbody>
						</table>
						<CreateNewPage
							isOpen={openCreateDialogue}
							setIsOpen={setOpenCreateDialogue}
						/>
						{
							(showPageSender) ? <PageSender pages={pages} pageId={pageId}/> : null
						}
					</div>
				</div>
			</div>
		</>
	);
};

const PageSender = ({pages, pageId}) => {
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const numericId = parseInt(pageId);
	const currentPageName = pages.filter((it) => it.id === numericId)[0]?.title;
	const isLive = pages.filter((it) => it.id === numericId)[0].live.data[0];
	const [isOpen, setIsOpen] = useState(false);
	const [randomID] = useState(String(Math.random()));
	const [bulkDownloadIds, setBulkDownloadIds] = useState([]);

	const {data, isLoading, isError, error} = useGetRelationshipsQuery({
		salesRepId: salesRepId,
		pageId: pageId,
	});
	const [deleteRelationship, {}] = useDeleteRelationshipMutation();

	const smoothScroll = (elementId) => {
		const element = document.getElementById(elementId);
		if (!element) return;
		element.scrollIntoView({behavior: "smooth"});
	};

	useEffect(() => {
		if (!isLoading) {
			setTimeout(() => smoothScroll("generateLink"), 100);
		}
	}, [isLoading]);


	const linkDeleteHandler = (relationshipId) => {
		confirmAlert({
			customUI: ({onClose}) => {
				return (
					<div className="flex flex-col w-[30rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<p className="mt-[2.25rem] text-[18px] font-medium">
							{`Are you sure you want to delete this link?`}
						</p>
						<p className="mt-[1.5rem] text-[14px] leading-[14px]">
							Once deleted cannot be recovered
						</p>
						<div className="flex flex-row justify-between mt-[3.375rem]">
							<button
								onClick={onClose}
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Cancel
							</button>
							<button
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#EDC8C5] font-roboto text-[16px] leading-[24px] border border-[#D83A52] text-[#D83A52] rounded-[0.25rem]"
								onClick={async () => {
									const popup = toastLoading("Delete in Progress");
									try {
										const deletion = await deleteRelationship({
											relationshipId: relationshipId,
										}).unwrap();
										if (deletion.status === "success") {
											toast.dismiss(popup);
											onClose();
										} else {
											toast.dismiss(popup);
											toastError("Failed to delete relationship");
											onClose();
										}
									} catch (err) {
										toastError("Couldn't connect to database");
										onClose();
									}
								}}
							>
								Delete
							</button>
						</div>
					</div>
				);
			},
		});
	};

	useEffect(() => {
		if (!isLoading) {
			if (data.payload.length !== 0) {
				const list = data.payload.map(it => {
					return {
						id: it.id,
						selected: false,
						"Name": it.name,
						"Email": it.email,
						"Date Created": new Date(it.date_created).toLocaleDateString(
							"en-us",
							{
								year: "numeric",
								month: "long",
								day: "numeric",
							}
						),
						"URL": `${process.env.REACT_APP_CLIENT_PROSPECT}/${it.encrypted_id}/login`
					}
				})
				setBulkDownloadIds(list);
			}
		}
	}, [data, isLoading])

	const selectDownloadRow = (id) => {
		const list = bulkDownloadIds.map(it => {
			if (it.id === id) {
				it.selected = !it.selected;
			}
			return it;
		});
		setBulkDownloadIds(list);
	}

	const selectDownloadAll = (checked) => {
		const list = bulkDownloadIds.map(it => {
			it.selected = checked;
			return it;
		})
		setBulkDownloadIds(list);
	}

	const downloadLinkCSV = async () => {
		toastLoading("Attempting download..");
		try {
			const list = []
			bulkDownloadIds.forEach(it => {
				if (it.selected === true) {
					delete it.selected;
					delete it.id;
					list.push(it);
				}
			})
			const csv = Papa.unparse(list);

			const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
			let csvURL =  null;
			if (navigator.msSaveBlob)
			{
				csvURL = navigator.msSaveBlob(csvData, `${currentPageName}.csv`);
			}
			else
			{
				csvURL = window.URL.createObjectURL(csvData);
			}

			let tempLink = document.createElement('a');
			tempLink.href = csvURL;
			tempLink.setAttribute('download', `${currentPageName}.csv`);
			tempLink.click();
			toast.dismiss();
			// toastSuccess("Gif downloaded!");
		} catch (e) {
			toast.dismiss()
			toastError("Error downloading URLs")
		}
	}

	return (
		<>
			{isLoading ? (
				<Spinner text="Loading links..."/>
			) : error ? (
				toastError("Cannot retrieve data")
			) : (
				<div className="mt-12">
					<ReactTooltip
						effect="solid"
						place="bottom"
						id={randomID}
						html={true}
					/>
					<GeneratePageDialog
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						pageId={numericId}
						isLive={isLive}
					/>
					<div className="flex flex-row justify-between bg-white py-4 w-[69rem] z-10">
						<div className="flex flex-col grow">
							<p className="font-medium text-[24px] leading-[30px]">{`Prospect links for "${currentPageName}"`}</p>
							<p className="text-base text-[#7D7D7D] font-normal">
								{"Unique links for each prospect who receives this page"}
							</p>
						</div>
						<div className="flex grow">
							<div className="flex items-center justify-center">
								<div className="flex flex-row items-center gap-x-6">
									<div>
										<div>
											{
												(bulkDownloadIds.filter(it => it.selected === true)).length === 0 ?
													<button
														className="flex items-center border border-[#63A4FA] rounded-[0.375rem] text-[#1D60B5] bg-[#F1F7FF] hover:bg-white"
														onClick={() => setIsOpen(true)}
													>
														<div className="flex flex-row items-center mx-[16px] my-[11px] gap-x-[0.5rem]">
															<img
																src={plusCircle}
																alt="plusCircle"
																className={"svg-zeros-blue"}
																// className="w-[1.48rem] h-[1.48rem]"
															/>
															<p className="text-[16px] leading-[16px]">
																Generate Link
															</p>
														</div>
													</button>
													:
													<button
														className="flex items-center border border-[#63A4FA] rounded-[0.375rem] text-[#1D60B5] bg-[#FFFFFF] hover:bg-[#F1F7FF]"
														onClick={() => downloadLinkCSV()}
													>
														<div className="flex flex-row items-center mx-[12px] my-[7px] gap-x-[0.5rem]">
															<img
																src={blueDownloadArrowDown}
																alt="download"
																className={"svg-zeros-blue"}
																// className="w-[1.48rem] h-[1.48rem]"
															/>
															<p className="text-[16px] leading-[16px]">
																Download URLs
															</p>
														</div>
													</button>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="generateLink">
						<table className="mt-2 w-full">
							<thead>
							<tr className="text-left">
								<th
									className="px-[40px] py-[10px] bg-[#F8F8F8] text-[#4444444] font-bold text-[15px] leading-[15px] w-[2%]"
								>
									<input
										type="checkbox"
										className="w-[18px] h-[18px] text-[#0071F2] rounded"
										defaultChecked={false}
										onChange={(e) => {
											selectDownloadAll(e.target.checked);
										}}
									/>
								</th>
								{["Prospect Name", "Email address", "URL", "Action"].map(
									(it, index) => (
										<th
											className="px-[40px] py-[10px] bg-[#F8F8F8] text-[#4444444] font-bold text-[15px] leading-[15px]"
											key={index}
										>
											{it}
										</th>
									)
								)}
							</tr>
							</thead>
							<tbody>
							{data.payload.length !== 0 &&
							data.payload.map((element, index) => {
								const url = `${process.env.REACT_APP_CLIENT_PROSPECT}/${element.encrypted_id}/login`;
								return (
									<tr className="hover:drop-shadow-lg" key={index}>
										<td className={`px-[40px] py-[23px] text-[14px] leading-[14px] text-[#222222] w-[2%] ${
											(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
										}`}>
											<input
												type="checkbox"
												className="w-[18px] h-[18px] text-[#0071F2] rounded"
												checked={bulkDownloadIds.filter(it => it.id === element.id)[0]?.selected ?? false}
												onChange={(e) => {
													selectDownloadRow(element.id);
												}}
											/>
										</td>
										<td
											className={`px-[40px] py-[23px] text-[14px] leading-[14px] text-[#222222] w-[40%] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}
										>
											{element.name.trim().length !== 0
												? element.name
												: "N/A"}
										</td>
										<td
											className={`px-[40px] py-[23px] text-[14px] leading-[14px] text-[#7D7D7D] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}
										>
											{element.email}
										</td>
										<td
											className={`px-[40px] py-[23px] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}
										>
											<button
												className="flex items-center justify-center border border-[#63A4FA] h-[25px] rounded-[0.375rem] text-[#0071F2] bg-[#F1F7FF] hover:bg-white ml-[-14px]"
												onClick={() => {
													navigator.clipboard.writeText(url);
													toastSuccess("Copied");
												}}
											>
												<p className="text-[13px] leading-[13px] mx-[12px]">
													Copy
												</p>
											</button>
										</td>
										<td
											className={`px-[40px] py-[23px] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}
										>
											<div
												className="flex flex-row justify-start items-center"
												data-tip="Delete"
												data-for={randomID}
											>
												<button
													className="no-underline text-black"
													onClick={() => linkDeleteHandler(element.id)}
												>
													<img
														src={deleteIcon}
														alt="delete"
														className="w-[20px] h-[20px] ml-[8px]"
													/>
												</button>
											</div>
										</td>
									</tr>
								);
							})}
							</tbody>
						</table>
					</div>
				</div>
			)}
		</>
	);
};

export const Page = () => {
	return (
		<ViewContainer>
			<Switch>
				<Route
					exact
					path={`/feature/page/edit/:id/:type/:live`}
					component={PageTypeRouter}
				/>
				<Route path={`/feature/page/`} component={PageTableView}/>
			</Switch>
		</ViewContainer>
	);
};

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import "react-tabs/style/react-tabs.css";
import { useGetRecordingQuery } from "../../../../../features/api/recordingsApiSlice";
import {
	useCreateSoloMutation,
	useGetSoloByIdQuery,
	useUpdateSoloMutation,
} from "../../../../../features/api/soloApiSlice";
import { PageSpinner } from "../../../../top-level/PageSpinner";
import { DEMO, EMBED, EMBED_CTA_TYPES } from "../../../../../data/constants";
import SoloForm from "./components/SoloForm";
import {
	toastError,
	toastLoading,
	toastSuccess,
} from "../../../../custom-toast/toasts";

const SoloEditor = ({ id, isNew, setId, setIsNew }) => {
	const sellerName = useSelector((state) => state.sessionData.name);
	const sellerId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const websiteStyle = useSelector((state) => state.userSetting.websiteStyle);
	const flags = useSelector((state) => JSON.parse(state.userSetting.flags));
	const [thumbnailBlob, setThumbnailBlob] = useState();
	const [thumbnailBlobUrl, setThumbnailBlobUrl] = useState();
	const [iconBlob, setIconBlob] = useState();
	const [iconBlobUrl, setIconBlobUrl] = useState();

	let initialValues = {
		name: "",
		demo: "",
		show_preview: "0",
		show_custom_thumbnail: "0",
		custom_thumbnail: "",
		is_muted: "0",
		is_gated: flags.gating.checked ? "1" : "0",
		is_sneak: "0",
		is_cta: "0",
		cta_type: EMBED_CTA_TYPES.LINK,
		cta_text: "",
		show_controls: "1",
		blinker_style: "PLAY",
		blinker_size: "LARGE",
		custom_blinker: "",
		launch_triangle_color: "#FFFFFF",
		launch_circle_color: "#1E41AF",
		launch_hover_color: "1E3B8A",
		launch_hotspot_color: "#1E41AF",
		redirect_to_restricted_domain: flags.gating.embed.verify.blocklist.redirect
			.checked
			? "1"
			: "0",
		live: "0",
		custom_verification: flags.gating.embed.verify.checked ? "1" : "0",
		email_required: flags.gating.embed.required.checked ? "1" : "0",
		check_blocklist: flags.gating.embed.verify.blocklist.checked ? "1" : "0",
		check_domain: flags.gating.embed.verify.domain.checked ? "1" : "0",
		check_twofactor: flags.gating.embed.verify.twofactor.checked ? "1" : "0",
		verification_content: {
			title: "Enter your email address to continue",
			description: "Enter your email address below to access the content",
			placeholder: "",
		},
		restricted_redirect: {
			restrictType: "demo",
			demo: null,
			message: {
				title: "",
				description: "",
				primaryButton: false,
				buttonLabel: "",
				URL: "",
			},
		},
	};
	const history = useHistory();
	const [createSolo] = useCreateSoloMutation();

	const { data, isLoading, isFetching } = useGetSoloByIdQuery({
		id: id,
	});
	const {
		data: recData,
		isLoading: recIsLoading,
		isFetching: recIsFetching,
	} = useGetRecordingQuery({
		companyId: companyId,
		limit:1000,
		pageNo:1
	});

	const [updateSolo] = useUpdateSoloMutation();

	if (isLoading || isFetching || recIsFetching || recIsLoading)
		return <PageSpinner text="Loading Solo.." />;

	if (!isNew) {
		initialValues = {
			name: data.payload[0].name,
			demo: data.payload[0].recording_id,
			show_preview: JSON.stringify(data.payload[0].show_preview),
			show_custom_thumbnail: JSON.stringify(
				data.payload[0].show_custom_thumbnail
			),
			custom_thumbnail: data.payload[0].custom_thumbnail,
			is_muted: JSON.stringify(data.payload[0].is_muted),
			is_gated: JSON.stringify(data.payload[0].is_gated),
			is_sneak: JSON.stringify(data.payload[0].is_sneak),
			is_cta: JSON.stringify(data.payload[0].is_cta),
			cta_type: data.payload[0].cta_type,
			cta_text: data.payload[0].cta_text,
			redirect_to_restricted_domain: JSON.stringify(
				data.payload[0].redirect_to_restricted_domain
			),
			show_controls: JSON.stringify(data.payload[0].show_controls),
			blinker_style: data.payload[0].blinker_style,
			blinker_size:
				data.payload[0].blinker_size ??
				(data.payload[0].is_sneak === 1 ? "SMALL" : "LARGE"),
			custom_blinker: data.payload[0].custom_blinker,
			launch_triangle_color:
				JSON.parse(data.payload[0].launch_button)?.triangle_color ?? "#FFFFFF",
			launch_circle_color:
				JSON.parse(data.payload[0].launch_button)?.circle_color ?? "#1E41AF",
			launch_hover_color:
				JSON.parse(data.payload[0].launch_button)?.hover_color ?? "#1E3B8A",
			launch_hotspot_color:
				JSON.parse(data.payload[0].launch_button)?.hotspot_color ?? "#1E41AF",
			live: JSON.stringify(data.payload[0].live),
			custom_verification: JSON.stringify(data.payload[0].custom_verification),
			email_required: JSON.stringify(data.payload[0].email_required),
			check_blocklist: JSON.stringify(data.payload[0].check_blocklist),
			check_domain: JSON.stringify(data.payload[0].check_domain),
			check_twofactor: JSON.stringify(data.payload[0].check_twofactor),
			verification_content: JSON.parse(data.payload[0].verification_content)
				? JSON.parse(data.payload[0].verification_content)
				: {
						title: "Enter your email address to continue",
						description: "Enter your email address below to access the content",
						placeholder: "",
				  },
			restricted_redirect: JSON.parse(data.payload[0].restricted_redirect)
				? JSON.parse(data.payload[0].restricted_redirect)
				: {
						restrictType: "demo",
						demo: null,
						message: {
							title: "",
							description: "",
							primaryButton: false,
							buttonLabel: "",
							URL: "",
						},
				  },
		};
	}

	const soloSchema = Yup.object().shape({
		name: Yup.string().required("Title is required"),
		show_preview: Yup.boolean(),
		is_muted: Yup.boolean(),
		is_gated: Yup.boolean(),
		is_sneak: Yup.boolean(),
		is_cta: Yup.boolean(),
		cta_text: Yup.string().when("is_cta", {
			is: true,
			then: Yup.string().required("Cannot leave empty"),
		}),
		show_controls: Yup.boolean(),
	});

	const addNewSolo = async (values) => {
		if (values.name === "" || values.demo === "") {
			toastError("Incomplete Data");
		} else {
			const alert = toastLoading("Please wait...");
			const soloData = {
				salesrep_id: salesRepId,
				company_id: companyId,
				recording_id: +values.demo,
				name: values.name,
				show_preview: values.show_preview,
				show_custom_thumbnail: values.show_custom_thumbnail,
				is_muted: values.is_muted,
				is_gated: values.is_gated,
				is_sneak: values.is_sneak,
				is_cta: values.is_cta,
				cta_type: values.cta_type,
				cta_text: values.cta_text,
				show_controls: values.show_controls,
				blinker_style: values.blinker_style,
				blinker_size: values.blinker_size,
				redirect_to_restricted_domain: values.redirect_to_restricted_domain,
				launch_button: JSON.stringify({
					triangle_color: values.launch_triangle_color,
					circle_color: values.launch_circle_color,
					hover_color: values.launch_hover_color,
					hotspot_color: values.launch_hotspot_color,
				}),
				date_created: new Date().toISOString().slice(0, 19).replace("T", " "),
				date_modified: new Date().toISOString().slice(0, 19).replace("T", " "),
				live: values.live,
				verification_content: JSON.stringify(values.verification_content),
				custom_verification: values.custom_verification,
				email_required: values.email_required,
				check_blocklist: values.check_blocklist,
				check_domain: values.check_domain,
				check_twofactor: values.check_twofactor,
				restricted_redirect: JSON.stringify(values.restricted_redirect),
			};
			const fd = new FormData();
			fd.append("soloData", JSON.stringify(soloData));
			if (thumbnailBlob && values.show_custom_thumbnail) {
				fd.append("image", thumbnailBlob);
			}
			if (iconBlob && values.blinker_style === "CUSTOM") {
				fd.append("icon", iconBlob);
			}
			const status = await createSolo({ fd }).unwrap();
			if (status.status === "success") {
				setId(status.payload);
				toast.dismiss(alert);
				toastSuccess(`Solo ${EMBED} Added`);
				setIsNew(false);
				// history.goBack();
			} else {
				toast.dismiss(alert);
				toastError(`Error adding solo ${EMBED.toLowerCase()}`);
			}
		}
	};

	const updateExistingSolo = async (values) => {
		if (values.name === "" || values.demo === "") {
			toastError("Incomplete Data");
		} else {
			const alert = toastLoading("Saving changes");
			const soloData = {
				name: values.name,
				soloId: +id,
				recording_id: +values.demo,
				show_preview: values.show_preview,
				show_custom_thumbnail: values.show_custom_thumbnail,
				is_muted: values.is_muted,
				is_gated: values.is_gated,
				is_sneak: values.is_sneak,
				is_cta: values.is_cta,
				cta_type: values.cta_type,
				cta_text: values.cta_text,
				show_controls: values.show_controls,
				blinker_style: values.blinker_style,
				blinker_size: values.blinker_size,
				redirect_to_restricted_domain: values.redirect_to_restricted_domain,
				launch_button: JSON.stringify({
					triangle_color: values.launch_triangle_color,
					circle_color: values.launch_circle_color,
					hover_color: values.launch_hover_color,
					hotspot_color: values.launch_hotspot_color,
				}),
				date_modified: new Date().toISOString().slice(0, 19).replace("T", " "),
				live: values.live,
				verification_content: JSON.stringify(values.verification_content),
				custom_verification: values.custom_verification,
				email_required: values.email_required,
				check_blocklist: values.check_blocklist,
				check_domain: values.check_domain,
				check_twofactor: values.check_twofactor,
				restricted_redirect: JSON.stringify(values.restricted_redirect),
			};
			const fd = new FormData();
			fd.append("soloData", JSON.stringify(soloData));
			if (thumbnailBlob && values.show_custom_thumbnail) {
				fd.append("image", thumbnailBlob);
			}
			if (iconBlob && values.blinker_style === "CUSTOM") {
				fd.append("icon", iconBlob);
			}
			const status = await updateSolo({ soloId: +id, fd }).unwrap();
			if (status.status === "success") {
				toast.dismiss(alert);
				toastSuccess(`Changes saved`);
				// history.goBack();
			} else {
				toast.dismiss(alert);
				toastError(`Error updating solo ${EMBED.toLowerCase()}`);
			}
		}
	};

	const cancelHandler = (e) => {
		e.preventDefault();
		history.goBack();
	};

	return (
		<div className="flex flex-col justify-center items-center">
			<ReactTooltip effect="solid" place="right" />
			<Formik
				initialValues={initialValues}
				validationSchema={soloSchema}
				onSubmit={
					!isNew
						? (values) => updateExistingSolo(values)
						: (values) => addNewSolo(values)
				}
				children={(props) => (
					<SoloForm
						{...props}
						isNew={isNew}
						data={data}
						recDataList={[...recData.payload].sort(
							(a, b) => new Date(b.date_created) - new Date(a.date_created)
						)}
						sellerName={sellerName}
						sellerId={sellerId}
						setThumbnailBlob={setThumbnailBlob}
						thumbnailBlobUrl={thumbnailBlobUrl}
						setThumbnailBlobUrl={setThumbnailBlobUrl}
						setIconBlob={setIconBlob}
						iconBlobUrl={iconBlobUrl}
						setIconBlobUrl={setIconBlobUrl}
						websiteStyle={JSON.parse(websiteStyle)}
					/>
				)}
			/>
		</div>
	);
};

export default SoloEditor;

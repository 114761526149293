import React, { useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import StyledButton from "../../../../top-level/StyledButton";
import { CYADemoCard } from "./CYAComponents/CYADemoCard";
import { useGetVideoQuery } from "../../../../../features/api/videoApiSlice";
import { useGetRecordingQuery } from "../../../../../features/api/recordingsApiSlice";
import PublishModal from "./components/PublishModal";
import PostPublishModal from "./components/PostPublishModal";
import PrePublishModal from "./components/PrePublishModal";
import { useDispatch, useSelector } from "react-redux";
import { PageSpinner } from "../../../../top-level/PageSpinner";
import { toast } from "react-toastify";
import { CYAQuestionCard } from "./CYAComponents/CYAQuestionCard";
import { CYAModalCard } from "./CYAComponents/CYAModalCard";
import { CYARuleCard } from "./CYAComponents/CYARuleCard";
import {
	useCreateCyoaMutation,
	useGetCyoaByIdQuery,
	useUpdateCyoaMutation,
	useUpdateCyoaStatusMutation,
} from "../../../../../features/api/cyoaApiSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DEMO, EMBED, EMBED_CTA_TYPES } from "../../../../../data/constants";
import { getBackgroundColor } from "../../../../../utils/avatar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import previewIcon from "../../../../../icons/preview-icon.svg";
import { CYASettings } from "./CYAComponents/CYASettings";
import Modal from "react-modal";
import closeCrossIcon from "../../../../../icons/closecross-icon.svg";
import helpIcon from "../../../../../icons/help-circle-icon.svg";
import greenInfoBulbIcon from "../../../../../icons/green-info-bulb-icon.svg";
import externalLink from "../../../../../icons/external-link.svg";
import { getStateFromCookies } from "redux-cookies-middleware";
import userSettingReducer, {
	setSaveBeforePublishPrompt_Cyoa,
} from "../../../../../features/state/user-settings/userSettingSlice";
import { paths } from "../../../../../features/cookies-paths/paths";
import { CYARecommendationsCard } from "./CYAComponents/CYARecommendationsCard";
import { useSendPreviewDumpMutation } from "../../../../../features/api/previewApiSlice";
import { CYARestrictedRedirectCard } from "./CYAComponents/CYARestrictedRedirectCard";
import { CYAVerificationCard } from "./CYAComponents/CYAVerificationCard";
import { amplitude } from "../../../../amplitude/Amplitude";
import {
	toastError,
	toastLoading,
	toastSuccess,
} from "../../../../custom-toast/toasts";
import {CYAPdfCard} from "./CYAComponents/CYAPdfCard";

export const CYAEditor = ({ id, isNew }) => {

	//All of these states are for the widget settings and CYASettings component mirror SoloSettings component
	const flags = useSelector((state) => JSON.parse(state.userSetting.flags));
	const [widgetName, setWidgetName] = useState("");
	const [playInline, setPlayInline] = useState(false);
	const [isSneak, setIsSneak] = useState(undefined);
	const [isCta, setIsCta] = useState(undefined);
	const [ctaType, setCtaType] = useState(EMBED_CTA_TYPES.LINK);
	const [ctaText, setCtaText] = useState("");
	const [showCustomThumbnail, setShowCustomThumbnail] = useState(false);
	const [customThumbnail, setCustomThumbnail] = useState("");
	const [blinkerStyle, setBlinkerStyle] = useState("PLAY");
	const [blinkerSize, setBlinkerSize] = useState("LARGE");
	const [customBlinker, setCustomBlinker] = useState("");
	const [launchTriangleColor, setLaunchTriangleColor] = useState("#FFFFFF");
	const [launchCircleColor, setLaunchCircleColor] = useState("#1E41AF");
	const [launchHoverColor, setLaunchHoverColor] = useState("#1E3B8A");
	const [launchHotspotColor, setLaunchHotspotColor] = useState("#1E41AF");
	const [audioOnLaunch, setAudioOnLaunch] = useState(true);
	const [verifyIdentity, setVerifyIdentity] = useState(flags.gating.checked);
	const [customVerification, setCustomVerification] = useState(
		flags.gating.embed.verify.checked
	);
	const [isRequired, setIsRequired] = useState(
		flags.gating.embed.required.checked
	);
	const [checkBlocklist, setCheckBlocklist] = useState(
		flags.gating.embed.verify.blocklist.checked
	);
	const [redirectToRestrictedDomain, setRedirectToRestrictedDomain] = useState(
		flags.gating.embed.verify.blocklist.redirect.checked
	);
	const [checkDomain, setCheckDomain] = useState(
		flags.gating.embed.verify.domain.checked
	);
	const [checkTwoFactor, setCheckTwoFactor] = useState(
		flags.gating.embed.verify.twofactor.checked
	);
	const [wrapUpScreen, setWrapUpScreen] = useState(true);
	const [showControls, setShowControls] = useState(true);
	const [isLive, setIsLive] = useState(false);
	const [thumbnailBlob, setThumbnailBlob] = useState();
	const [thumbnailBlobUrl, setThumbnailBlobUrl] = useState();
	const [iconBlob, setIconBlob] = useState();
	const [iconBlobUrl, setIconBlobUrl] = useState();
	const [restrictedRedirectObject, setRestrictedRedirectObject] = useState({
		restrictType: "demo",
		demo: null,
		message: {
			title: "",
			description: "",
			primaryButton: false,
			buttonLabel: "",
			URL: "",
		},
	});

	const [verificationContent, setVerificationContent] = useState({
		title: "Enter your email address to continue",
		description: "Enter your email address below to access the content",
		placeholder: "",
	});


	//CYA Episodes related states
	const [episodeList, setEpisodeList] = useState([]);

	//The values you see in Show Demo dropdown during demo selection
	const [demoOptions, setDemoOptions] = useState([]);

	//Contains all the questions from CYAQuestionCard to show in CYARuleCard
	const [questionOptions, setQuestionOptions] = useState([]);

	//Contains all the answers to the questions from CYAQuestionCard to show in CYARuleCard
	const [answerOptions, setAnswerOptions] = useState([]);

	//Contains data for each episode
	const [fetchedData, setFetchedData] = useState([]);

	//Related to Publish Modal
	const [openPublishPopUp, setOpenPublishPopUp] = useState(false);
	const [openSaveBeforePublish, setOpenSaveBeforePublish] = useState(false);
	const [openSaveAfterPublish, setOpenSaveAfterPublish] = useState(false);
	const [neverShowPrompt, setNeverShowPrompt] = useState(false);

	//A toggleable card at the end of CYA Episodes
	const [showRecommendations, setShowRecommendations] = useState(false);
	const [recommendationsList, setRecommendationsList] = useState({ 0: null });

	const [cyoaId, setCyoaId] = useState(id);
	const episodeRef = useRef([]);

	//Fetching user data from redux and CYOA data from DB
	const sellerName = useSelector((state) => state.sessionData.name);
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);
	const websiteStyle = useSelector((state) => state.userSetting.websiteStyle);
	const fetchedDemos = useGetRecordingQuery({ companyId: companyId,limit:1000,pageNo:1 });
	const saveBeforePublishPrompt = getStateFromCookies(userSettingReducer, paths)
		.userSetting.saveBeforePublishPrompt_Cyoa;

	const { data, isFetching, isError } = useGetCyoaByIdQuery({ id: cyoaId });
	const [uploadCYOA, {}] = useCreateCyoaMutation();
	const [updateCYOA, {}] = useUpdateCyoaMutation();
	const [updateCYOAStatus, {}] = useUpdateCyoaStatusMutation();
	const [sendPreviewDump, {}] = useSendPreviewDumpMutation();

	//This useEffect is responsible for hydrating the states after fetching the values from DB
	useEffect(() => {
		if (cyoaId !== undefined && !isFetching) {
			if (data.payload.length !== 0) {
				const parsedSettings = JSON.parse(data.payload[0].settings);
				const parsedData = JSON.parse(data.payload[0].episodes);
				const parsedRecommendations = JSON.parse(
					data.payload[0].recommendations
				);
				const parsedRestrict = JSON.parse(data.payload[0].restricted_redirect);
				const parsedVerificationContent = JSON.parse(
					data.payload[0].verification_content
				);

				if (parsedRecommendations) {
					setShowRecommendations(parsedRecommendations.show);
					const recommendations = { 0: null };
					parsedRecommendations.list.forEach((it, i) => {
						recommendations[i] = it;
					});
					setRecommendationsList(recommendations);
				}

				if (parsedRestrict) {
					setRestrictedRedirectObject(parsedRestrict);
				}

				if (parsedVerificationContent) {
					setVerificationContent(parsedVerificationContent);
				}

				setWidgetName(data.payload[0].name);
				setPlayInline(parsedSettings.playInline);
				setIsSneak(parsedSettings.isSneak);
				setIsCta(parsedSettings.isCta);
				setCtaType(parsedSettings.ctaType);
				setCtaText(parsedSettings.ctaText ?? "");
				setShowCustomThumbnail(parsedSettings.showCustomThumbnail ?? false);
				setCustomThumbnail(data.payload[0].custom_thumbnail ?? "");
				setBlinkerStyle(parsedSettings.blinkerStyle ?? "PLAY");
				setBlinkerSize(
					parsedSettings.blinkerSize ??
						(parsedSettings.isSneak ? "SMALL" : "LARGE")
				);
				setCustomBlinker(data.payload[0].custom_blinker ?? "");
				setLaunchTriangleColor(parsedSettings.launchTriangleColor ?? "#FFFFFF");
				setLaunchCircleColor(parsedSettings.launchCircleColor ?? "#1E41AF");
				setLaunchHoverColor(parsedSettings.launchHoverColor ?? "#1E3B8A");
				setLaunchHotspotColor(parsedSettings.launchHotspotColor ?? "#1E41AF");
				setAudioOnLaunch(parsedSettings.audioOnLaunch);
				setVerifyIdentity(parsedSettings.verifyIdentity);
				setWrapUpScreen(parsedSettings.wrapUpScreen);
				setShowControls(parsedSettings.showControls);
				setRedirectToRestrictedDomain(
					parsedSettings.redirectToRestrictedDomain
				);
				setCustomVerification(parsedSettings.customVerification);
				setIsRequired(parsedSettings.isRequired);
				setCheckBlocklist(parsedSettings.checkBlocklist);
				setCheckDomain(parsedSettings.checkDomain);
				setCheckTwoFactor(parsedSettings.checkTwoFactor);
				batchAddEpisode(parsedData);
				setFetchedData(parsedData);
				setIsLive(JSON.parse(data.payload[0].live) === 1);
			}
		}
	}, [data, isFetching]);

	//Convert fetched demos from DB into objects readable by React-Select
	useEffect(() => {
		if (!fetchedDemos.isLoading) {
			if (fetchedDemos.data.status !== "error") {
				let video = [];
				let index = 1;
				fetchedDemos.data.payload.forEach((it) => {
					video.push({
						key: index,
						value: it.id,
						label: it.title,
						type: "demo",
						recordingType: it.type,
						coordinates: it.coordinates,
						thumbnail: it.thumbnail,
						location: it.location,
						preview: it.preview,
						date_created: it.date_created,
					});
					index += 1;
				});
				setDemoOptions(
					video.sort((a, b) => {
						return new Date(b.date_created) - new Date(a.date_created);
					})
				);
			}
		}
	}, [fetchedDemos]);

	//After every 0.3 seconds, check if new data has been added to questions or modal card for extraction
	useEffect(() => {
		if (episodeList.length !== 0) {
			setTimeout(extractQA, 300);
		}
	}, [episodeList, answerOptions]);

	//CYAEditor uses forwardRef for fetching data from all of its card
	//We are using that to pull out all the data from Questions and Modal that we can use in CYARuleCard

	const extractQA = async () => {
		const questionList = [];
		const answerList = [];
		try {
			episodeList.forEach((it, i) => {
				const data = episodeRef.current[it.id].sendData();
				if (data !== null && data !== undefined) {
					if (data.episode === "rule") {
						const ruleData = episodeRef.current[it.id].sendModule();
						if (ruleData.episode === "question") {
							if (ruleData.question !== "" && ruleData.choices.length !== 0) {
								questionList.push({
									data: ruleData.question,
									type: ruleData.type,
									index: ruleData.index,
								});
								answerList.push({
									index: ruleData.index,
									answers: ruleData.choices,
								});
							}
						}
						if (ruleData.episode === "modal") {
							if (ruleData.title !== "" && ruleData.showInputBox) {
								questionList.push({
									data: ruleData.title,
									type: "modal",
									index: ruleData.index,
								});
							}
						}
					}

					if (data.episode === "modal") {
						if (data.title !== "" && data.showInputBox) {
							questionList.push({
								data: data.title,
								type: "modal",
								index: data.index,
							});
						}
					}

					if (data.episode === "question") {
						if (data.question !== "" && data.choices.length !== 0) {
							questionList.push({
								data: data.question,
								type: data.type,
								index: data.index,
							});
							answerList.push({ index: data.index, answers: data.choices });
						}
					}
				}
			});
			setQuestionOptions(questionList);
			setAnswerOptions(answerList);
		} catch (e) {
			console.log(e);
		}
	};

	const addEpisode = (type) => {
		const epiList = [...episodeList];
		const sortedEpiList = [...epiList];
		sortedEpiList.sort((a, b) => a.id - b.id);
		const id =
			sortedEpiList.length !== 0
				? sortedEpiList[sortedEpiList.length - 1].id + 1
				: 1;
		switch (type) {
			case "demo":
				epiList.push({ id: id, type: "demo" });
				setEpisodeList(epiList);
				break;
			case "question":
				epiList.push({ id: id, type: "question" });
				setEpisodeList(epiList);
				break;
			case "modal":
				epiList.push({ id: id, type: "modal" });
				setEpisodeList(epiList);
				break;
			case "rule":
				epiList.push({ id: id, type: "rule" });
				setEpisodeList(epiList);
				break;
			case "pdf":
				epiList.push({ id: id, type: "pdf" });
				setEpisodeList(epiList);
				break;
			default:
				console.log("Not Implemented");
		}
	};

	//Useful when adding hydrating the CYAEditor with episodes fetched from DB
	const batchAddEpisode = (data) => {
		const epiList = [];
		data.forEach((el) => {
			epiList.push({ id: el.index, type: el.episode });
		});
		setEpisodeList(epiList);
	};

	const deleteEpisode = (id) => {
		setEpisodeList(episodeList.filter((it) => it.id !== id));
		setFetchedData(fetchedData.filter((it) => it.index !== id));
	};

	//Extract data from all CYA episodes
	//Useful when working with drag n drop
	const extractCYA = () => {
		const episodes = [];

		episodeList.forEach((it) => {
			const data = episodeRef.current[it.id].sendData();
			if (data !== null && data !== undefined) {
				episodes.push(data);
			}
		});
		return episodes;
	};

	const saveCYA = async (preview = false) => {
		let integrityFlag = true;
		const episodeArray = [];

		//Loop through all the episodes and check if there is any missing field
		episodeList.forEach((it, i) => {
			const data = episodeRef.current[it.id].sendData();
			episodeArray.push(data);
			if (data === null || data === undefined) {
				integrityFlag = false;
			} else {
				if (data.episode === "demo" && Object.keys(data.demo).length === 0) {
					integrityFlag = false;
				}
				if (data.episode === "question") {
					if (
						data.question === "" ||
						data.choices.length === 0 ||
						data.type === ""
					) {
						integrityFlag = false;
					}
				}
				if (data.episode === "pdf") {
					if (data.pdf.link === "" || data.pdf.title === "") integrityFlag = false;
				}
				if (data.episode === "rule") {
					if (
						data.result.episode === "demo" &&
						Object.keys(data.result.demo).length === 0
					) {
						integrityFlag = false;
					}
					if (data.result.episode === "question") {
						if (
							data.result.question === "" ||
							data.result.choices.length === 0 ||
							data.result.type === ""
						) {
							integrityFlag = false;
						}
					}
					if (data.result.episode === "pdf") {
						if (data.result.pdf.link === "" || data.result.pdf.title === "") integrityFlag = false;
					}
				}
			}
		});

		if (showRecommendations)
			integrityFlag = Object.values(recommendationsList).every(
				(it) => it !== null
			);

		if (isCta) integrityFlag = ctaText.length > 0;

		//If an empty field is detected, send error message and return
		if (!integrityFlag) {
			return toastError("Incomplete fields");
		} else {
			if (episodeArray.length === 0) {
				return toastError("A widget cannot be empty");
			}
			if (widgetName === "") {
				return toastError("Please add widget name");
			}

			//Constructing the CYOA object that is read by CYOA widget
			const CYAObject = {};
			CYAObject.name = widgetName;
			CYAObject.data = episodeArray;
			CYAObject.setting = {};
			CYAObject.setting.playInline = playInline;
			CYAObject.setting.isSneak = isSneak;
			CYAObject.setting.isCta = isCta;
			CYAObject.setting.ctaType = ctaType;
			CYAObject.setting.ctaText = ctaText;
			CYAObject.setting.showCustomThumbnail = showCustomThumbnail;
			CYAObject.setting.blinkerStyle = blinkerStyle;
			CYAObject.setting.blinkerSize = blinkerSize;
			CYAObject.setting.launchTriangleColor = launchTriangleColor;
			CYAObject.setting.launchCircleColor = launchCircleColor;
			CYAObject.setting.launchHoverColor = launchHoverColor;
			CYAObject.setting.launchHotspotColor = launchHotspotColor;
			CYAObject.setting.audioOnLaunch = audioOnLaunch;
			CYAObject.setting.verifyIdentity = verifyIdentity;
			CYAObject.setting.redirectToRestrictedDomain = redirectToRestrictedDomain;
			CYAObject.setting.customVerification = customVerification;
			CYAObject.setting.isRequired = isRequired;
			CYAObject.setting.checkBlocklist = checkBlocklist;
			CYAObject.setting.checkDomain = checkDomain;
			CYAObject.setting.checkTwoFactor = checkTwoFactor;
			CYAObject.setting.wrapUpScreen = wrapUpScreen;
			CYAObject.setting.showControls = showControls;
			CYAObject.recommendations = {};
			CYAObject.recommendations.show = showRecommendations;
			CYAObject.recommendations.list = showRecommendations
				? Object.values(recommendationsList)
				: Object.values(recommendationsList).filter((it) => it !== null);
			CYAObject.restrict = restrictedRedirectObject;
			CYAObject.verificationContent = verificationContent;

			if (preview) return CYAObject;

			//Saving the CYOA object to DB
			let status;
			const fd = new FormData();
			if (thumbnailBlob && showCustomThumbnail) {
				fd.append("image", thumbnailBlob);
			}
			if (iconBlob && blinkerStyle === "CUSTOM") {
				fd.append("icon", iconBlob);
			}
			const alert = toastLoading("Saving changes");

			if (cyoaId === undefined) {
				// For new CYOA
				const cyoaData = {
					salesrep_id: salesRepId,
					company_id: companyId,
					date_created: new Date(Date.now())
						.toISOString()
						.slice(0, 19)
						.replace("T", " "),
					date_modified: new Date(Date.now())
						.toISOString()
						.slice(0, 19)
						.replace("T", " "),
					cyoa: CYAObject,
				};
				fd.append("cyoaData", JSON.stringify(cyoaData));
				status = await uploadCYOA({ fd }).unwrap();
			} else {
				// For existing CYOA
				const cyoaData = {
					cyoaId: cyoaId,
					date_modified: new Date(Date.now())
						.toISOString()
						.slice(0, 19)
						.replace("T", " "),
					cyoa: CYAObject,
				};
				fd.append("cyoaData", JSON.stringify(cyoaData));
				status = await updateCYOA({ cyoaId: cyoaId, fd }).unwrap();
			}

			console.log(JSON.stringify(CYAObject));

			if (status.status === "success") {
				toast.dismiss(alert);
				toastSuccess(`Changes saved`);
				if (isNew) {
					setCyoaId(status.payload);
				}
			} else {
				toast.dismiss(alert);
				toastError("An Error Occurred!");
				return Error;
			}
		}
	};

	const previewCYA = async () => {
		try {
			const values = await saveCYA(true);
			values.id = cyoaId;
			values.salesrepId = salesRepId;
			values.companyId = companyId;
			values.customThumbnail = customThumbnail;
			values.customBlinker = customBlinker;
			values.flags = flags;
			const toast_id = toastLoading();

			const status = await sendPreviewDump({
				dump: JSON.stringify(values),
			}).unwrap();
			if (status.status === "success") {
				toast.dismiss(toast_id);
				const newLink = `${
					process.env.REACT_APP_CLIENT_PROSPECT
				}/preview/cyoa?uuid=${uuid()}&data_id=${uuid()}-${
					status.payload
				}&template=${companyId}`;
				window.open(newLink, "_blank").focus();
			} else {
				toast.dismiss(toast_id);
				toastError("Unable to preview widget");
			}
		} catch (e) {
			console.log("Unable to preview widget");
		}
	};

	const publishCYA = async () => {
		try {
			await saveCYA();
			const status = await updateCYOAStatus({
				cyoaId: cyoaId,
				dateModified: new Date(Date.now())
					.toISOString()
					.slice(0, 19)
					.replace("T", " "),
				live: true,
			}).unwrap();
			if (status.status === "success") {
				setIsLive(true);
			} else {
				toastError("Unable to publish");
			}
		} catch (e) {
			console.log("CYOA Not Published");
		}
	};

	//Function called by React Beautiful DnD once we stop dragging a card
	//Responsible for making sure that the correct data goes into each card after DnD
	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const extractedEpisodes = extractCYA();
		setFetchedData(extractedEpisodes);

		const list = [...episodeList];
		const [removed] = list.splice(result.source.index, 1);
		list.splice(result.destination.index, 0, removed);
		setEpisodeList(list);
	};

	if (fetchedDemos.isLoading || isFetching) {
		return <PageSpinner text="Loading Widget..." />;
	}

	if (fetchedDemos.isError || isError) {
		toastError("Couldn't retrieve data. Refresh page.");
	}

	return (
		<div className="w-[69rem] mx-auto">
			<div className="bg-white pb-8 mb-4 w-full">
				<div className="sticky top-0 flex flex-row justify-between py-4 bg-white z-50">
					<div className="flex flex-col w-3/5 mb-4">
						<input
							className="px-1.5 py-0.5 font-normal leading-5 text-xl font-medium text-[#222222] border border-white hover:border hover:rounded-sm hover:border-zeros-blue focus:rounded-sm focus:border-zeros-blue"
							id="name"
							type="input"
							name="name"
							placeholder={`Title for this ${EMBED.toLowerCase()}`}
							value={widgetName}
							onChange={(e) => setWidgetName(e.target.value)}
						/>
						{!isNew ? (
							<div className="flex flex-row mx-3 mt-1 gap-x-2 items-center">
								<div className="flex justify-center items-center gap-x-2">
									<div
										className="flex justify-center items-center w-6 h-6 rounded-full text-white"
										style={{
											backgroundColor: getBackgroundColor(
												data.payload[0].salesrep_id
											),
										}}
									>
										{data.payload[0].salesrep_name.trim()[0]}
									</div>
									<p className="text-base font-normal text-[#888888]">
										{data.payload[0].salesrep_name.trim()}
									</p>
								</div>
								<div className="w-1 h-1 rounded-full bg-[#686868]" />
								<p className="text-base font-normal text-[#888888]">
									{new Date(data.payload[0].date_created).toLocaleDateString(
										"en-us",
										{
											year: "numeric",
											month: "long",
											day: "numeric",
										}
									)}
								</p>
							</div>
						) : (
							<div className="flex flex-row mx-3 mt-1 gap-x-2 items-center">
								<div className="flex justify-center items-center gap-x-2">
									<div
										className="flex justify-center items-center w-6 h-6 rounded-full text-white"
										style={{
											backgroundColor: getBackgroundColor(salesRepId),
										}}
									>
										{sellerName.trim()[0]}
									</div>
									<p className="text-base font-normal text-[#888888]">
										{sellerName.trim()}
									</p>
								</div>
								<div className="w-1 h-1 rounded-full bg-[#686868]" />
								<p className="text-base font-normal text-[#888888]">
									{new Date().toLocaleDateString("en-us", {
										year: "numeric",
										month: "long",
										day: "numeric",
									})}
								</p>
							</div>
						)}
					</div>
					<div className="flex gap-x-[18px]">
						<button
							className={
								cyoaId
									? `border w-[44px] h-[44px] rounded-md mt-1 font-medium bg-[#FFFFFF] hover:bg-[#E6E9EF] flex items-center justify-center`
									: `border w-[44px] h-[44px] rounded-md mt-1 font-medium bg-[#E8E8E8] flex items-center justify-center disabled cursor-not-allowed`
							}
							type="button"
							onClick={() => previewCYA()}
							disabled={!cyoaId}
						>
							<img
								src={previewIcon}
								alt="preview"
								className="w-[24px] h-[24px]"
							/>
						</button>
						{!isLive ? (
							<button
								className={
									!isLive && cyoaId !== undefined
										? `border border-[#63A4FA] w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-[#1D60B5] bg-[#FFFFFF] hover:bg-[#CCE5FF]`
										: `border w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium bg-[#E8E8E8] text-[#6F6F6F] rounded disabled cursor-not-allowed`
								}
								onClick={() => setOpenPublishPopUp(true)}
								type="button"
								disabled={isLive || cyoaId === undefined}
							>
								Publish
							</button>
						) : (
							<button
								className={`w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 bg-[#E8E8E8] disabled cursor-not-allowed`}
								// onClick={() => publish()}
								type="button"
								disabled={true}
							>
								<div className="flex flex-row justify-center items-center gap-x-[8px]">
									<div className="w-[14px] h-[14px] bg-[#4CB051] rounded-full" />
									<p className="text-[16px] leading-[16px] text-[#6F6F6F]">
										Published
									</p>
								</div>
							</button>
						)}
						<button
							className={`border w-[4.75rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA]`}
							onClick={() => {
								isLive
									? setOpenSaveAfterPublish(true)
									: saveBeforePublishPrompt ||
									  saveBeforePublishPrompt === undefined
									? setOpenSaveBeforePublish(true)
									: saveCYA();
							}}
						>
							Save
						</button>
					</div>
				</div>
				<Tabs forceRenderTabPanel={true}>
					<TabList className="flex items-center w-full">
						<Tab
							className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
							selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
						>
							Content
						</Tab>
						<Tab
							className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
							selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
						>
							Settings
						</Tab>
					</TabList>

					<TabPanel>
						<div className="flex flex-col">
							<div
								className={`flex flex-col bg-[#CFFFF1] rounded-[8px] mt-8 pt-[8px] pb-[14px] pl-[63px] pr-[31px]`}
							>
								<div className={`flex flex-row items-start justify-between`}>
									<div className={`flex flex-row items-center gap-x-[16px]`}>
										<img src={greenInfoBulbIcon} alt="info" />
										<div className="flex flex-row justify-start gap-x-1 cursor-pointer hover:underline">
											<a
												href="https://go.zeroshq.com/Terms-of-Service"
												target={`_blank`}
												className="font-medium text-[16px] text-[#0D6048] leading-[16px] pt-1"
												onClick={() =>
													amplitude
														.getInstance()
														.logEvent("SampleGuideClicked", {
															timestamp: `${new Date().toUTCString()}`,
														})
												}
											>
												Examples of Choose Your Adventure™ journeys
											</a>

											<img
												src={externalLink}
												alt="externalLink"
												// className="w-[1.48rem] h-[1.48rem]"
											/>
										</div>
									</div>
								</div>
							</div>

							{/*Wrapping our cards in React Beautiful DnD Context*/}
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{episodeList.map((it, i) => {
												if (it.type === "demo") {
													return (
														<Draggable
															draggableId={i.toString()}
															index={i}
															key={i}
															isDragDisabled={isLive}
														>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	<div key={it.id}>
																		<CYADemoCard
																			index={it.id}
																			ref={(el) =>
																				(episodeRef.current[it.id] = el)
																			}
																			isLive={isLive}
																			demoOptions={demoOptions}
																			deleteEpisode={deleteEpisode}
																			fetchedDemo={
																				fetchedData.filter(
																					(el) => el.index === it.id
																				).length === 0
																					? undefined
																					: fetchedData.filter(
																							(el) => el.index === it.id
																					  )[0]
																			}
																			showIndex={true}
																		/>
																	</div>
																</div>
															)}
														</Draggable>
													);
												} else if (it.type === "pdf") {
													return (
														<Draggable
															draggableId={i.toString()}
															index={i}
															key={i}
															isDragDisabled={isLive}
														>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	<div key={it.id}>
																		<CYAPdfCard
																			index={it.id}
																			ref={(el) =>
																				(episodeRef.current[it.id] = el)
																			}
																			fetchedPdf={
																				fetchedData.filter(
																					(el) => el.index === it.id
																				).length === 0
																					? undefined
																					: fetchedData.filter(
																						(el) => el.index === it.id
																					)[0]
																			}
																			isLive={isLive}
																			deleteEpisode={deleteEpisode}
																			showIndex={true}
																		/>
																	</div>
																</div>
															)}
														</Draggable>
													);
												} else if (it.type === "question") {
													return (
														<Draggable
															draggableId={i.toString()}
															index={i}
															key={i}
															isDragDisabled={isLive}
														>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	<div key={it.id}>
																		<CYAQuestionCard
																			index={it.id}
																			ref={(el) =>
																				(episodeRef.current[it.id] = el)
																			}
																			isLive={isLive}
																			deleteEpisode={deleteEpisode}
																			fetchedQuestion={
																				fetchedData.filter(
																					(el) => el.index === it.id
																				).length === 0
																					? undefined
																					: fetchedData.filter(
																							(el) => el.index === it.id
																					  )[0]
																			}
																			showIndex={true}
																		/>
																	</div>
																</div>
															)}
														</Draggable>
													);
												} else if (it.type === "modal") {
													return (
														<Draggable
															draggableId={i.toString()}
															index={i}
															key={i}
															isDragDisabled={isLive}
														>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	<div key={it.id}>
																		<CYAModalCard
																			index={it.id}
																			ref={(el) =>
																				(episodeRef.current[it.id] = el)
																			}
																			isLive={isLive}
																			deleteEpisode={deleteEpisode}
																			fetchedModal={
																				fetchedData.filter(
																					(el) => el.index === it.id
																				).length === 0
																					? undefined
																					: fetchedData.filter(
																							(el) => el.index === it.id
																					  )[0]
																			}
																			showIndex={true}
																		/>
																	</div>
																</div>
															)}
														</Draggable>
													);
												} else if (it.type === "rule") {
													return (
														<Draggable
															draggableId={i.toString()}
															index={i}
															key={i}
															isDragDisabled={isLive}
														>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	<div key={it.id}>
																		<CYARuleCard
																			index={it.id}
																			ref={(el) =>
																				(episodeRef.current[it.id] = el)
																			}
																			isLive={isLive}
																			isPage={false}
																			demoOptions={demoOptions}
																			ruleToDelete={deleteEpisode}
																			questionList={questionOptions}
																			answerList={answerOptions}
																			rule={
																				fetchedData.filter(
																					(el) => el.index === it.id
																				).length === 0
																					? undefined
																					: fetchedData.filter(
																							(el) => el.index === it.id
																					  )[0]
																			}
																		/>
																	</div>
																</div>
															)}
														</Draggable>
													);
												} else {
													console.log("Unknown Episode Type");
													return null;
												}
											})}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							<div className="flex flex-row justify-center items-center mt-[2rem]">
								<button
									className="bg-[#F1F7FF] border-l border-t border-b border-r border-[#63A4FA] hover:bg-white px-[16px] py-[8px] text-[#1D60B5] text-[14px] rounded-tl-[10px] rounded-bl-[10px]"
									onClick={() => addEpisode("demo")}
								>
									+ Demo
								</button>
								<button
									className="bg-[#F1F7FF] border-t border-b border-[#63A4FA] hover:bg-white px-[16px] py-[8px] text-[#1D60B5] text-[14px]"
									onClick={() => addEpisode("pdf")}
								>
									+ PDF
								</button>
								<button
									className="bg-[#F1F7FF] border-t border-b border-l border-[#63A4FA] hover:bg-white px-[16px] py-[8px] text-[#1D60B5] text-[14px]"
									onClick={() => addEpisode("question")}
								>
									+ Question
								</button>
								<button
									className="bg-[#F1F7FF] border-t border-b border-l border-[#63A4FA] hover:bg-white px-[16px] py-[8px] text-[#1D60B5] text-[14px]"
									onClick={() => addEpisode("modal")}
								>
									+ Modal
								</button>
								<button
									className="bg-[#F1F7FF] border-t border-b border-r border-l border-[#63A4FA] hover:bg-white px-[16px] py-[8px] text-[#1D60B5] text-[14px] rounded-tr-[10px] rounded-br-[10px]"
									onClick={() => addEpisode("rule")}
								>
									+ Rule
								</button>
							</div>
							<div
								className={
									verifyIdentity ? "flex flex-col mt-[2rem]" : "hidden"
								}
							>
								<CYAVerificationCard
									verificationContent={verificationContent}
									setVerificationContent={setVerificationContent}
								/>
							</div>
							<div
								className={wrapUpScreen ? "flex flex-col mt-[2rem]" : "hidden"}
							>
								<CYARecommendationsCard
									showRecommendations={showRecommendations}
									setShowRecommendations={setShowRecommendations}
									demoOptions={demoOptions}
									recommendationsList={recommendationsList}
									setRecommendationsList={setRecommendationsList}
								/>
							</div>
							<div
								className={
									verifyIdentity && redirectToRestrictedDomain
										? "flex flex-col mt-[2rem]"
										: "hidden"
								}
							>
								<CYARestrictedRedirectCard
									restrictedRedirectObject={restrictedRedirectObject}
									setRestrictedRedirectObject={setRestrictedRedirectObject}
									demoOptions={demoOptions}
								/>
							</div>
						</div>
					</TabPanel>

					<TabPanel>
						<CYASettings
							websiteStyle={JSON.parse(websiteStyle)}
							audioOnLaunch={audioOnLaunch}
							setAudioOnLaunch={setAudioOnLaunch}
							isSneak={isSneak}
							setIsSneak={setIsSneak}
							isCta={isCta}
							setIsCta={setIsCta}
							ctaType={ctaType}
							setCtaType={setCtaType}
							ctaText={ctaText}
							setCtaText={setCtaText}
							playInline={playInline}
							setPlayInline={setPlayInline}
							showControls={showControls}
							setShowControls={setShowControls}
							verifyIdentity={verifyIdentity}
							setVerifyIdentity={setVerifyIdentity}
							redirectToRestrictedDomain={redirectToRestrictedDomain}
							setRedirectToRestrictedDomain={setRedirectToRestrictedDomain}
							customVerification={customVerification}
							setCustomVerification={setCustomVerification}
							isRequired={isRequired}
							setIsRequired={setIsRequired}
							checkBlocklist={checkBlocklist}
							setCheckBlocklist={setCheckBlocklist}
							checkDomain={checkDomain}
							setCheckDomain={setCheckDomain}
							checkTwoFactor={checkTwoFactor}
							setCheckTwoFactor={setCheckTwoFactor}
							wrapUpScreen={wrapUpScreen}
							setWrapUpScreen={setWrapUpScreen}
							blinkerStyle={blinkerStyle}
							setBlinkerStyle={setBlinkerStyle}
							blinkerSize={blinkerSize}
							setBlinkerSize={setBlinkerSize}
							customBlinker={customBlinker}
							setCustomBlinker={setCustomBlinker}
							launchTriangleColor={launchTriangleColor}
							setLaunchTriangleColor={setLaunchTriangleColor}
							launchCircleColor={launchCircleColor}
							setLaunchCircleColor={setLaunchCircleColor}
							launchHoverColor={launchHoverColor}
							setLaunchHoverColor={setLaunchHoverColor}
							launchHotspotColor={launchHotspotColor}
							setLaunchHotspotColor={setLaunchHotspotColor}
							showCustomThumbnail={showCustomThumbnail}
							setShowCustomThumbnail={setShowCustomThumbnail}
							customThumbnail={customThumbnail}
							setCustomThumbnail={setCustomThumbnail}
							setThumbnailBlob={setThumbnailBlob}
							thumbnailBlobUrl={thumbnailBlobUrl}
							setThumbnailBlobUrl={setThumbnailBlobUrl}
							setIconBlob={setIconBlob}
							iconBlobUrl={iconBlobUrl}
							setIconBlobUrl={setIconBlobUrl}
							demoOptions={demoOptions}
							selectedDemo={episodeRef.current[
								episodeList.find((episode) => episode.type === "demo")?.id
							]?.sendData()}
						/>
					</TabPanel>
				</Tabs>
				<PublishModal
					open={openPublishPopUp}
					setOpen={setOpenPublishPopUp}
					onSubmit={publishCYA}
					isCyoa={true}
				/>
				<PostPublishModal
					open={openSaveAfterPublish}
					setOpen={setOpenSaveAfterPublish}
					onSubmit={saveCYA}
				/>
				<PrePublishModal
					open={openSaveBeforePublish}
					setOpen={setOpenSaveBeforePublish}
					neverShowPrompt={neverShowPrompt}
					setNeverShowPrompt={setNeverShowPrompt}
					onSubmit={saveCYA}
					reducer={setSaveBeforePublishPrompt_Cyoa}
				/>
			</div>
		</div>
	);
};

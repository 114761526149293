import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import cyaDeleteIcon from "../../../../../../icons/cya-delete-icon.svg";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {flatReactSelectStyle} from "../../../../../../utils/styleObjects";
import {embedSelect} from "../../../../../../utils/reactSelectStyles";
import dragHandle from "../../../../../../icons/drag-handle.svg";
import uuid from 'react-uuid';
import ReactTooltip from "react-tooltip";

export const CYADemoCard = forwardRef(({index, demoOptions, fetchedDemo, showIndex, deleteEpisode, isLive}, ref) => {
	const [selectedDemo, setSelectedDemo] = useState({});
	const [uniqueId, setUniqueId] = useState(uuid());

	useImperativeHandle(ref, () => ({
		sendData() {
			return {demo: selectedDemo, episode: "demo", uniqueId: uniqueId, index: index};
		},
	}));

	useEffect(() => {
		if (fetchedDemo !== undefined && fetchedDemo !== null) {
			if (fetchedDemo.episode === "demo") {
				const demo = demoOptions.filter(it => it.label === fetchedDemo.demo.label);
				if (demo.length !== 0) {
					setSelectedDemo(demo[0]);
				} else {
					setSelectedDemo(fetchedDemo.demo);
				}
				if (fetchedDemo.uniqueId !== undefined) {
					setUniqueId(fetchedDemo.uniqueId);
				}
			}
		}
	}, [fetchedDemo]);

	useEffect(() => {
		ReactTooltip.rebuild()
	}, [selectedDemo]);

	return (
		<>
			<ReactTooltip
				effect="solid"
				place="bottom"
				id={uniqueId}
				html={true}
				className="max-w-[350px] font-inter text-xs leading-[22px]"
			/>
			<div className={showIndex ? `flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px] mt-[2rem]` : "flex"}>
				<div className={showIndex ? "flex items-center justify-between h-[2.38rem] bg-transparent border-[#DEE8ED] border-b" : "hidden"}>
					<p className="text-[#255CA4] text-[18px] leading-[18px] mx-[3.938rem]">
						Play Demo
					</p>
					<button className="flex items-center justify-center mr-[2.063rem]" onClick={() => deleteEpisode(index)}>
						<img src={cyaDeleteIcon} alt="delete" className="w-5 h-5"/>
					</button>
				</div>
				<div className={`flex flex-row ${showIndex ? "bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px]" : "bg-white"}`}>
					<div className={showIndex ? "flex items-center w-[50px]" : "hidden"}>
						{!isLive ? <img src={dragHandle} alt="drag" className="ml-[9px]"/> : null}
					</div>
					<div>
						<div className={showIndex ? "flex items-center my-[1.25rem]" : "flex"} data-tip={selectedDemo?.label} data-for={uniqueId}>
							<Select
								className="w-[19.81rem]"
								value={selectedDemo}
								components={makeAnimated()}
								options={demoOptions}
								styles={embedSelect}
								placeholder="Select a demo"
								onChange={(e) => setSelectedDemo(e)}
								isSearchable
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
});

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

export const AnalyticTagTypes = {
	//Legacy
	LEGACY_PROSPECT: "legacy_prospect",
	ELEMENT: "element",
	PAGE: "page",
	FREQUENCY: "frequency",
	DURATION: "duration",

	//Current
	PROSPECT: "prospect",
	WIDGET_VIEW: "widget_view",
	WIDGET_CTA: "widget_cta",
	WIDGET_CYOA_EVENT: "widget_cyoa_event",
	WIDGET_CYOA_DEMO_DURATION: "widget_cyoa_demo_duration",
	WIDGET_DEMO_DURATION: "widget_demo_duration",
	WIDGET_RENDER: "widget_render",
	PROSPECT_PAGE: "prospect_page",
	PAGE_VIEWS: "page_views",
	PAGE_RECOMMENDATIONS: "page_recommendations",
	RECOMMENDATION_VIEWS: "recommendation_views",
	PAGE_RESPONSE: "page_response",
	PAGE_PROSPECT_CONTACT: "page_prospect_contact"
};

export const analyticApiSlice = createApi({
	reducerPath: "api/analytic",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, {getState}) => {
			const token = getState().sessionData.token
			if (token) {
				headers.set('token', token)
			}
			return headers
		}
	}),
	tagTypes: Object.values(AnalyticTagTypes),
	endpoints: builder => ({
		//Widgets Analytics
		getWidgetRenders: builder.query({
			query: (initialPost) => {
				return {
					url: `/analytic/widget/render/${initialPost.companyId}`,
					params: {
						widgetID: initialPost.widgetID
					}
				}
			},
			providesTags: [AnalyticTagTypes.WIDGET_RENDER]
		}),
		getWidgetViews: builder.query({
			query: (initialPost) => {
				return {
					url: `/analytic/widget/views/${initialPost.companyId}`,
					params: {
						widgetID: initialPost.widgetID
					}
				}
			},
			providesTags: [AnalyticTagTypes.WIDGET_VIEW]
		}),
		getWidgetCTA: builder.query({
			query: (initialPost) => {
				return {
					url: `/analytic/widget/CTA/`,
					params: {
						widgetID: initialPost.widgetID
					}
				}
			},
			providesTags: [AnalyticTagTypes.WIDGET_CTA]
		}),
		getWidgetCyoaEvent: builder.query({
			query: (initialPost) => {
				return {
					url: `/analytic/widget/cyoa/`,
					params: {
						widgetID: initialPost.widgetID
					}
				}
			},
			providesTags: [AnalyticTagTypes.WIDGET_CYOA_EVENT]
		}),
		getCyoaDemoDuration: builder.query({
			query: (initialPost) => {
				return {
					url: `/analytic/widget/cyoa/demo/duration`,
					params: {
						widgetID: initialPost.widgetID
					}
				}
			},
			providesTags: [AnalyticTagTypes.WIDGET_CYOA_DEMO_DURATION]
		}),
		getDemoDuration: builder.query({
			query: (initialPost) => {
				return {
					url: `/analytic/widget/demo/duration`,
					params: {
						widgetID: initialPost.widgetID
					}
				}
			},
			providesTags: [AnalyticTagTypes.WIDGET_DEMO_DURATION]
		}),

		//Pages Analytics
		getProspects: builder.query({
			query: (initialPost) => "salesrep/" + initialPost.companyId + "/prospect",
			providesTags: [AnalyticTagTypes.PROSPECT],
		}),
		getProspectPages: builder.query({
			query: (initialPost) => "salesrep/" + initialPost.prospectId + "/prospect/page",
			providesTags: [AnalyticTagTypes.PROSPECT_PAGE],
		}),
		getPageViews: builder.query({
			query: (initialPost) => {
				return {
					url: `/analytic/page/views/${initialPost.pageId}/${initialPost.prospectId}`,
				}
			},
			providesTags: [AnalyticTagTypes.PAGE_VIEWS]
		}),
		getFetchPageRecommendations: builder.query({
			query: (initialPost) => {
				return {
					url: `/analytic/page/fetch/recommendations/${initialPost.pageId}`,
				}
			},
			providesTags: [AnalyticTagTypes.PAGE_RECOMMENDATIONS]
		}),
		getPageRecommendationViews: builder.query({
			query: (initialPost) => {
				return {
					url: `/analytic/page/${initialPost.pageId}/recording/views`,
				}
			},
			providesTags: [AnalyticTagTypes.RECOMMENDATION_VIEWS]
		}),
		getResponses: builder.query({
			query: (initialPost) => `analytic/page/${initialPost.pageId}/response/${initialPost.relationshipId}`,
			providesTags: [AnalyticTagTypes.PAGE_RESPONSE],
		}),
		getProspectContactInfo: builder.query({
			query: (initialPost) => `analytic/page/${initialPost.pageId}/prospect/${initialPost.prospectId}/contact/${initialPost.companyId}`,
			providesTags: [AnalyticTagTypes.PAGE_PROSPECT_CONTACT],
		}),

		//Legacy API Endpoints
		getLegacyProspects: builder.query({
			query: (initialPost) => "/analytic/prospect/" + initialPost.companyId,
			providesTags: [AnalyticTagTypes.LEGACY_PROSPECT],
		}),
		getElements: builder.query({
			query: (initialPost) => "/analytic/element/" + initialPost.companyId,
			providesTags: [AnalyticTagTypes.ELEMENT],
		}),
		getPages: builder.query({
			query: (initialPost) => "/analytic/page/" + initialPost.companyId,
			providesTags: [AnalyticTagTypes.PAGE],
		}),
		getElementFrequency: builder.query({
			query: (initialPost) => {
				return {
					url: "/analytic/frequency/element/",
					params: {
						elementId: initialPost.elementId,
						companyId: initialPost.companyId,
						prospectId: initialPost.prospectId,
						event: initialPost.event,
						startDate: initialPost.startDate,
						endDate: initialPost.endDate,
					}
				}
			},
			providesTags: [AnalyticTagTypes.FREQUENCY],
		}),
		getPageFrequency: builder.query({
			query: (initialPost) => {
				return {
					url: "/analytic/frequency/page/",
					params: {
						companyId: initialPost.companyId,
						prospectId: initialPost.prospectId,
						event: initialPost.event,
						page: initialPost.page,
						startDate: initialPost.startDate,
						endDate: initialPost.endDate,
					}
				}
			},
			providesTags: [AnalyticTagTypes.FREQUENCY],
		}),
		getDuration: builder.query({
			query: (initialPost) => {
				return {
					url: "/analytic/duration/",
					params: {
						companyId: initialPost.companyId,
						prospectId: initialPost.prospectId,
						page: initialPost.page,
						startDate: initialPost.startDate,
						endDate: initialPost.endDate,
					}
				}
			},
		}),
	})
})

export const {
	useGetWidgetRendersQuery,
	useGetWidgetViewsQuery,
	useGetWidgetCTAQuery,
	useGetWidgetCyoaEventQuery,
	useGetCyoaDemoDurationQuery,
	useGetDemoDurationQuery,
	useGetProspectsQuery,
	useGetProspectPagesQuery,
	useGetPageViewsQuery,
	useGetFetchPageRecommendationsQuery,
	useGetPageRecommendationViewsQuery,
	useGetResponsesQuery,
	useGetProspectContactInfoQuery,
	useGetLegacyProspectsQuery,
	useGetElementsQuery,
	useGetPagesQuery,
	useGetElementFrequencyQuery,
	useGetPageFrequencyQuery,
	useGetDurationQuery
} = analyticApiSlice;

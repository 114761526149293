import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const MappingTagTypes = {
    MAPPING: "mapping",
};

export const mappingApiSlice = createApi({
    reducerPath: "api/mapping",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_SERVER}/web`,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().sessionData.token
            if (token) {
                headers.set('token', token)
            }
            return headers
        }
    }),
    tagTypes: Object.values(MappingTagTypes),
    endpoints: builder => ({
        getMapping: builder.query({
            query: (initialPost) => "/mapping/" + initialPost.formId,
            providesTags: [MappingTagTypes.MAPPING],
        }),
        createMapping: builder.mutation({
            query: initialPost => ({
                url: "/mapping",
                method: "POST",
                body: {
                    form_id: initialPost.formId,
                    formjson: initialPost.formJson,
                },
            }),
            invalidatesTags: [MappingTagTypes.MAPPING],
        }),
        updateMapping: builder.mutation({
            query: initialPost => ({
                url: "/mapping/" + initialPost.formId,
                method: "PUT",
                body: {
                    formjson: initialPost.formJson,
                },
            }),
            invalidatesTags: [MappingTagTypes.MAPPING],
        }),
        deleteMapping: builder.mutation({
            query: initialPost => ({
                url: "/mapping/" + initialPost.formId,
                method: "DELETE",
            }),
            invalidatesTags: [MappingTagTypes.MAPPING],
        }),
    })
})

export const { useGetMappingQuery, useCreateMappingMutation, useUpdateMappingMutation, useDeleteMappingMutation } = mappingApiSlice;
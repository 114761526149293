import TopBar from "../../../top-level/TopBar";
import Select from "react-select";
import {DateRangePickerComp} from "../utils/DateRangePickerComp";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useGetHeroQuery} from "../../../../features/api/heroApiSlice";
import {useGetSoloQuery} from "../../../../features/api/soloApiSlice";
import {useGetCyoaQuery} from "../../../../features/api/cyoaApiSlice";
import {PageSpinner} from "../../../top-level/PageSpinner";
import {
	useGetCyoaDemoDurationQuery,
	useGetDemoDurationQuery, useGetProspectsQuery,
	useGetWidgetCTAQuery,
	useGetWidgetCyoaEventQuery,
	useGetWidgetRendersQuery,
	useGetWidgetViewsQuery,
} from "../../../../features/api/analyticApiSlice";
import {Sankey} from "./sankey/Sankey";
import {Bar, Line} from "react-chartjs-2";
import {Chart as ChartJS} from "chart.js/auto";
import {addDays, format, differenceInDays} from "date-fns";
import {
	adaptToChart,
	adaptViewLoadToChart,
	extractByDateRange, extractByRelation,
	getRandomColor,
	groupByWidgetType,
	groupHeroByWidgetID,
	groupSoloByWidgetID,
	makeDataset, postProcessDateRange,
	sortByDate,
} from "../utils/AnalyticsUtils";
import {findColumnIndex, findEpisodeType} from "../utils/SankeyAnalyticsUtils";
import {amplitude} from "../../../amplitude/Amplitude";
import AnalyticsViewContainer from "../../AnalyticsViewContainer";
import plusCircle from "../../../../icons/plus-circle.svg";
import {CyaAnalytics} from "./embeds/CyaAnalytics";
import {SoloAnalytics} from "./embeds/SoloAnalytics";
import {EMBED} from "../../../../data/constants";
import uuid from "react-uuid";
import {embedSelect} from "../../../../utils/reactSelectStyles";

export const WidgetAnalytics = () => {
	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);

	//All states that correspond to user selections (dropdowns)
	const [selectedProspect, setSelectedProspect] = useState(null);
	const [prospectOptions, setProspectOptions] = useState([]);
	const [widgetOptions, setWidgetOptions] = useState([]);
	const [selectedWidgets, setSelectedWidgets] = useState(null);
	const [cyoaDemoOptions, setCyoaDemoOptions] = useState([]);
	const [selectedCyoaDemo, setSelectedCyoaDemo] = useState(null);
	const [cyoaResponseOptions, setCyoaResponseOptions] = useState([]);
	const [selectedCyoaResponse, setSelectedCyoaResponse] = useState(null);
	const [cyoaPdfOptions, setCyoaPdfOptions] = useState([]);
	const [selectedCyoaPdf, setSelectedCyoaPdf] = useState(null);
	const [queryWidgets, setQueryWidgets] = useState([]);
	const [dateRange, setDateRange] = useState([
		{
			startDate: addDays(new Date(), -7),
			endDate: new Date(),
			key: "selection",
		},
	]);

	//All states that correspond to data consumed by chartJS

	//Demo Views Chart
	const [viewsGraphData, setViewsGraphData] = useState([]);
	const [viewsGraphLabels, setViewsGraphLabels] = useState([]);

	//CTA Clicks Chart (Hero Only)
	const [clicksGraphData, setClicksGraphData] = useState([]);
	const [clicksGraphLabels, setClicksGraphLabels] = useState([]);

	//Clickthrough Rate Chart
	const [viewLoadRatioGraphData, setViewLoadRatioGraphData] = useState([]);
	const [viewLoadRatioGraphLabels, setViewLoadRatioGraphLabels] = useState([]);

	//View Duration Chart (CYOA)
	const [demoDurationData, setDemoDurationData] = useState([]);

	//View Duration Chart (Solo & Hero)
	const [soloHeroDemoDuration, setSoloHeroDemoDuration] = useState([]);

	//Used for all View Duration Charts
	const [demoDurationLabels, setDemoDurationLabels] = useState([
		"1%-25%",
		"25%-50%",
		"50%-75%",
		"75%-100%",
	]);

	//Responses Chart (CYOA)
	const [responseData, setResponseData] = useState([]);
	const [responseLabels, setResponseLabels] = useState([]);

	//PDF Engagement Chart (CYOA)
	const [pdfEngagementLabels, setPdfEngagementLabels] = useState([]);
	const [pdfEngagementData, setPdfEngagementData] = useState([]);

	//Recommendation Chart (CYOA)
	const [cyoaModalQuestionsAsked, setCyoaModalQuestionsAsked] = useState(null);

	//Sankey Chart (CYOA)
	const [sankeyData, setSankeyData] = useState([]);
	const [sankeyColumn, setSankeyColumn] = useState({});

	//Fetching related data from APIs
	const {
		data: heroData,
		isLoading: heroIsLoading,
		isError: heroIsError,
	} = useGetHeroQuery({
		companyId: companyId,
		limit:1000,
		pageNo:1
	});
	const {
		data: soloData,
		isLoading: soloIsLoading,
		isError: soloIsError,
	} = useGetSoloQuery({
		companyId: companyId,
		limit:1000,
		pageNo:1
	});
	const {
		data: cyoaData,
		isLoading: cyoaIsLoading,
		isError: cyoaIsError,
	} = useGetCyoaQuery({
		companyId: companyId,
		limit:1000,
		pageNo:1
	});
	const {
		data: renderData,
		isFetching: renderIsFetching,
		isError: renderIsError,
	} = useGetWidgetRendersQuery({
		companyId: companyId,
		widgetID: JSON.stringify(queryWidgets),
	});
	const {
		data: viewData,
		isFetching: viewIsFetching,
		isError: viewIsError,
	} = useGetWidgetViewsQuery({
		companyId: companyId,
		widgetID: JSON.stringify(queryWidgets),
	});
	const {
		data: watchDurationData,
		isFetching: watchDurationIsFetching,
		isError: watchDurationIsError,
	} = useGetDemoDurationQuery({
		widgetID: JSON.stringify(queryWidgets),
	});
	const {
		data: CTAData,
		isFetching: CTAIsFetching,
		isError: CTAIsError,
	} = useGetWidgetCTAQuery({
		widgetID: JSON.stringify(queryWidgets),
	});
	const {
		data: cyoaEventData,
		isFetching: cyoaEventIsFetching,
		isError: cyoaEventIsError,
	} = useGetWidgetCyoaEventQuery({
		widgetID: JSON.stringify(queryWidgets),
	});
	const {
		data: cyoaDemoDurationData,
		isFetching: cyoaDemoDurationIsFetching,
		isError: cyoaDemoDurationIsError,
	} = useGetCyoaDemoDurationQuery({
		widgetID: JSON.stringify(queryWidgets),
	});

	const {
		data: prospectData,
		isFetching: prospectIsFetching,
		isError: prospectIsError,
		error: prospectError
	} = useGetProspectsQuery({
		companyId: companyId
	});

	const sendAmplitudeEvent = (event) => {
		const startDate = format(new Date(dateRange[0].startDate), "yyyy/MM/dd");
		const endDate = format(new Date(dateRange[0].endDate), "yyyy/MM/dd");
		const widgetNames = selectedWidgets.label;
		amplitude.getInstance().logEvent(event, {
			widgets: widgetNames.toString(),
			date_from: startDate,
			date_to: endDate,
			timestamp: new Date().toUTCString(),
		});
	};

	//Important since this is used to reset all the charts when something is selected
	const resetGraphs = () => {
		setSankeyData([]);
		setSelectedCyoaDemo(null);
		setSelectedCyoaResponse(null);
		setSelectedCyoaPdf(null);
		setDemoDurationData([]);
		setResponseData([]);
		setResponseLabels([]);
		setViewsGraphLabels([]);
		setViewLoadRatioGraphLabels([]);
		setPdfEngagementLabels([]);
		setPdfEngagementData([]);
		setCyoaModalQuestionsAsked(null);
	}

	//Called when a widget is selected in the dropdown
	const setWidgets = (event, storage = false) => {
		const widgets = [{type: event.type, id: event.id}];
		resetGraphs();
		setQueryWidgets(widgets);
		setSelectedWidgets(event);
		localStorage.setItem("selectedWidget", JSON.stringify(event));
	};

	//Called when a prospect is selected in the dropdown
	const setProspect = (event, storage = false) => {
		resetGraphs();
		setSelectedProspect(event)
		localStorage.setItem("selectedProspectWidget", JSON.stringify(event));
	}

	//Here starts all the functions used to create charts for our analytics
	//All charts use a re-usable pipeline (pipe and filter architecture)
	//All the functions in the pipeline are self-explanatory
	//At the end, we use a function tailor made to create analytics for a specific type of chart

	//Function responsible for creating the Demo Views chart data
	const showViewGraph = (startDate, endDate, viewData) => {
		const views = extractByDateRange(startDate, endDate, extractByRelation(selectedProspect, viewData));
		const sortedViews = sortByDate(views);
		const groupedViews = groupByWidgetType(sortedViews);
		const groupedHeroViews = groupHeroByWidgetID(
			selectedWidgets,
			groupedViews.hero
		);
		const groupedSoloViews = groupSoloByWidgetID(
			selectedWidgets,
			groupedViews.solo
		);
		const concatViews = groupedHeroViews.concat(groupedSoloViews);
		const chartData = adaptToChart(concatViews);
		const postProcess = postProcessDateRange(startDate, endDate, chartData.label, chartData.dataset); //This is an important step that makes sure that the starting and ending dates are also shown in the chart
		setViewsGraphLabels(postProcess.label);
		setViewsGraphData(postProcess.dataset);
	};

	//Function responsible for creating the ClickThrough Rate chart data
	const showViewLoadGraph = (startDate, endDate, viewLoadData) => {
		const views = extractByDateRange(startDate, endDate, extractByRelation(selectedProspect, viewLoadData));
		const sortedViews = sortByDate(views);
		const groupedViews = groupByWidgetType(sortedViews);
		const groupedHeroViews = groupHeroByWidgetID(
			selectedWidgets,
			groupedViews.hero
		);
		const groupedSoloViews = groupSoloByWidgetID(
			selectedWidgets,
			groupedViews.solo
		);
		const concatViews = groupedHeroViews.concat(groupedSoloViews);
		const chartData = adaptViewLoadToChart(concatViews);
		const postProcess = postProcessDateRange(startDate, endDate, chartData.label, chartData.dataset);
		setViewLoadRatioGraphLabels(postProcess.label);
		setViewLoadRatioGraphData(postProcess.dataset);
	};

	//Function responsible for creating the View Duration chart data
	const showSoloHeroDuration = (startDate, endDate, durationData) => {
		const extractedDuration = extractByRelation(selectedProspect, durationData)
		const groupedDuration = groupByWidgetType(extractedDuration);
		const groupedHeroDuration = groupHeroByWidgetID(
			selectedWidgets,
			groupedDuration.hero
		);
		const groupedSoloDuration = groupSoloByWidgetID(
			selectedWidgets,
			groupedDuration.solo
		);
		const concatDuration = groupedHeroDuration.concat(groupedSoloDuration);
		// const concatDuration =
		// 	groupedHeroDuration[0].data.length !== 0
		// 		? groupedHeroDuration
		// 		: groupedSoloDuration;
		const dataset = [];
		concatDuration.forEach((it) => {
			const chartData = showDemoDurationGraph(startDate, endDate, it.data);
			const chartLabel = it.label;
			dataset.push({
				label: chartLabel,
				data: chartData,
				backgroundColor: "#1B85B6",
			});
		});
		setSoloHeroDemoDuration(dataset);
	};

	//Function responsible for creating the CTA Clicks chart data
	const showCTAGraph = (startDate, endDate, CTAData) => {
		const clicks = extractByDateRange(startDate, endDate, extractByRelation(selectedProspect, CTAData));
		const sortedClicks = sortByDate(clicks);
		const groupedClicks = groupHeroByWidgetID(selectedWidgets, sortedClicks);
		const chartData = adaptToChart(groupedClicks);
		const postProcess = postProcessDateRange(startDate, endDate, chartData.label, chartData.dataset);
		setClicksGraphLabels(postProcess.label);
		setClicksGraphData(postProcess.dataset);
	};

	//Function responsible for creating the View Duration chart data
	const showDemoDurationGraph = (startDate, endDate, durationEvents) => {
		const filteredEvents = extractByDateRange(
			startDate,
			endDate,
			extractByRelation(selectedProspect, durationEvents)
		);
		const durationData = [];
		for (let i = 0; i <= 9; i++) {
			durationData.push(0);
		}
		filteredEvents.forEach((it) => {
			const duration = JSON.parse(it.duration);
			const totalDuration = duration.total;

			if (!totalDuration) return;

			const accumulatedDuration = duration.played.reduce((prev, each) => {
				return prev + (JSON.parse(each)[1] - JSON.parse(each)[0]);
			}, 0);
			let durationPercent = Math.round(
				(accumulatedDuration / totalDuration) * 100
			);
			let index = -1;
			while (durationPercent > 0) {
				durationPercent -= 25;
				index += 1;
			}
			durationData[index <= 9 ? index : 9] += 1;
		});
		return durationData;
	};

	//Function responsible for creating the Sankey chart data
	const convertToSankey = (episodeData, analyticsData) => {
		const data = [];
		const column = [];
		let index = 0;
		episodeData.forEach((it, i) => {
			const newSlice = findEpisodeType(it, episodeData, analyticsData, i);
			const columnIndex = findColumnIndex(it, index, i);
			newSlice.forEach((it) => {
				data.push(it);
			});
			column.push(columnIndex[0]);
			index = columnIndex[1];
		});
		const convertToObject = column.reduce((result, currentObject) => {
			for (let key in currentObject) {
				if (currentObject.hasOwnProperty(key) && key !== "") {
					result[key] = currentObject[key];
				}
			}
			return result;
		}, {});
		convertToObject["Wrap up screen"] = index;
		return {
			data: data.filter((it) => it.flow !== 0 && it.from !== ""),
			column: convertToObject,
		};
	};

	//This function is responsible for the data used in CSV
	const extractCyaModalQuestions = (filteredEvents, episodeData) => {
		const filteredModalEvents = filteredEvents.filter(it => it.episode === "modal");
		const filteredEpisodeData = episodeData.filter(it => it.episode === "modal");
		const modalUnloadEvents = filteredModalEvents.filter(it => JSON.parse(it.event).type === "unload");
		const questionsAskedList = [];
		filteredEpisodeData.forEach(it => {
			const relevantEpisodeEvents = modalUnloadEvents.filter(el => JSON.parse(el.event).title.trim() === it.title.trim());
			if (relevantEpisodeEvents.length === 0) return
			let userInput = "";
			relevantEpisodeEvents.forEach(el => {
				const parsedEvent = JSON.parse(el.event);
				if (parsedEvent?.inputText) {
					userInput += (parsedEvent.inputText.trim() === "" ? "" : `${parsedEvent.inputText.trim()},`)
				}
			})
			const questionsAskedObject = {};
			questionsAskedObject["Modal Title"] = it.title.trim();
			questionsAskedObject["Prospect Questions"] = userInput;
			questionsAskedList.push(questionsAskedObject);
		})
		setCyoaModalQuestionsAsked(questionsAskedList)
	}

	//This fetches the date range from cookies if present and sets it to the state (user history)
	useEffect(() => {
		const date = localStorage.getItem("dateRangeWidget");
		if (date) {
			const parsedDate = JSON.parse(date);
			const startDate = new Date(parsedDate[0].startDate.split("T")[0]);
			const endDate = new Date(parsedDate[0].endDate.split("T")[0]);
			setDateRange([{startDate: startDate, endDate: endDate, key: parsedDate[0].key}]);
		}
	}, []);

	//This fetches the selected widget from cookies if present and sets it to the state (user history)
	useEffect(() => {
		setTimeout(() => {
			if (widgetOptions.length !== 0 && !selectedWidgets) {
				const localSelectedWidget = localStorage.getItem("selectedWidget");
				if (localSelectedWidget) {
					const parsedSelection = JSON.parse(localSelectedWidget);
					const filteredSelection = widgetOptions.filter(
						(it) =>
							it.id === parsedSelection.id && it.type === parsedSelection.type
					);
					if (filteredSelection.length !== 0) {
						setWidgets(filteredSelection[0], true);
					}
				}
			}
		}, 200);
	}, [widgetOptions]);

	//This fetches the selected prospect from cookies if present and sets it to the state (user history)
	useEffect(() => {
		setTimeout(() => {
			if (prospectOptions.length !== 0 && !selectedProspect) {
				const localSelectedProspect = localStorage.getItem("selectedProspectWidget");
				if (localSelectedProspect) {
					const parsedSelection = JSON.parse(localSelectedProspect);
					const filteredSelection = prospectOptions.filter(
						(it) =>
							it.label === parsedSelection.label
					);
					if (filteredSelection.length !== 0) {
						setProspect(filteredSelection[0], true);
					}
				}
			}
		}, 200);
	}, [prospectOptions]);

	//Hydrates the prospect dropdown
	useEffect(() => {
		if (!prospectIsFetching) {
			const options = [];
			prospectData?.payload.forEach((it, i) => {
				const filteredIds = prospectData?.payload.filter(el => el.email === it.email);
				const relationIds = filteredIds.map(el => {
					return el.relationshipId;
				})
				if (options.filter(el => el.label === it.email).length === 0)
					options.push({key: i, value: it.id, label: it.email, relationIds: relationIds});
			})
			options.push({key: uuid(), value: uuid(), label: "anonymous", relationIds: null})
			setProspectOptions(options);
		}
	}, [prospectIsFetching, prospectData])

	//Hydrates the widget dropdown
	useEffect(() => {
		if (!heroIsLoading && !soloIsLoading && !cyoaIsLoading) {
			const widgetList = [];
			let index = 0;
			if (heroData.payload.length !== 0) {
				heroData.payload.forEach((el) => {
					widgetList.push({
						key: index,
						value: index,
						id: el.id,
						label: el.name + " (Hero)",
						type: "hero",
						date_created: el.date_created,
					});
					index += 1;
				});
			}
			if (soloData.payload.length !== 0) {
				soloData.payload.forEach((el) => {
					widgetList.push({
						key: index,
						value: index,
						id: el.id,
						label: el.name + " (Solo)",
						type: "solo",
						date_created: el.date_created,
					});
					index += 1;
				});
			}
			if (cyoaData.payload.length !== 0) {
				cyoaData.payload.forEach((el) => {
					widgetList.push({
						key: index,
						value: index,
						id: el.id,
						label: el.name + " (CYOA)",
						type: "cyoa",
						date_created: el.date_created,
					});
					index += 1;
				});
			}
			setWidgetOptions(
				widgetList.sort((a, b) => {
					return new Date(b.date_created) - new Date(a.date_created);
				})
			);
		}
	}, [heroIsLoading, soloIsLoading, cyoaIsLoading]);

	//Hydrates the dropdowns embedded in chart panels
	useEffect(() => {
		if (selectedWidgets && selectedProspect) {
			if (selectedWidgets.type === "cyoa") {
				const id = selectedWidgets.id;
				const episodeData = JSON.parse(
					cyoaData.payload.filter((it) => it.id === id)[0].episodes
				);
				const demoOptions = [];
				const responseOptions = [];
				const pdfOptions = [];
				episodeData.forEach((it, i) => {
					if (it.episode === "demo") {
						const id = it.demo.value;
						const label = it.demo.label;
						if (demoOptions.filter((it) => it.label === label).length === 0) {
							demoOptions.push({key: i, value: id, label: label});
						}
					}
					if (it.episode === "question") {
						const label = it.question;
						const choices = it.choices;
						if (
							responseOptions.filter((it) => it.label === label).length === 0
						) {
							responseOptions.push({
								key: i,
								value: i,
								label: label,
								choices: choices,
							});
						}
					}
					if (it.episode === "pdf") {
						const label = it.pdf.title;
						if (pdfOptions.filter((it) => it.label === label).length === 0) {
							pdfOptions.push({
								key: i,
								value: i,
								label: label,
							});
						}
					}
					if (it.episode === "rule") {
						if (it.result.episode === "demo") {
							const id = it.result.demo.value;
							const label = it.result.demo.label;
							if (demoOptions.filter((it) => it.label === label).length === 0) {
								demoOptions.push({key: i, value: id, label: label});
							}
						}
						if (it.result.episode === "question") {
							const label = it.result.question;
							const choices = it.result.choices;
							if (
								responseOptions.filter((it) => it.label === label).length === 0
							) {
								responseOptions.push({
									key: i,
									value: i,
									label: label,
									choices: choices,
								});
							}
						}
						if (it.result.episode === "pdf") {
							const label = it.result.pdf.title;
							if (pdfOptions.filter((it) => it.label === label).length === 0) {
								pdfOptions.push({
									key: i,
									value: i,
									label: label,
								});
							}
						}
					}
				});
				setCyoaDemoOptions(demoOptions);
				setCyoaResponseOptions(responseOptions);
				setCyoaPdfOptions(pdfOptions);
			}
		}
	}, [selectedWidgets, selectedProspect]);

	//View Duration chart
	useEffect(() => {
		if (selectedCyoaDemo !== null) {
			const startDate = format(new Date(dateRange[0].startDate), "yyyy/MM/dd");
			const endDate = format(new Date(dateRange[0].endDate), "yyyy/MM/dd");
			const demoId = selectedCyoaDemo.value;
			const durationEvents = cyoaDemoDurationData.payload.filter(
				(it) => it.recording_id === demoId
			);
			const durationData = showDemoDurationGraph(
				startDate,
				endDate,
				durationEvents
			);
			setDemoDurationData(durationData);
		}
	}, [selectedCyoaDemo, sankeyData]);

	//PDF Engagement chart
	useEffect(() => {
		if (selectedCyoaPdf !== null) {
			const startDate = format(new Date(dateRange[0].startDate), "yyyy/MM/dd");
			const endDate = format(new Date(dateRange[0].endDate), "yyyy/MM/dd");
			const id = selectedWidgets.id;
			const concernedEvents = cyoaEventData.payload.filter(
				(it) => it.cyoa_id === id
			);
			const filteredEvents = extractByDateRange(
				startDate,
				endDate,
				concernedEvents
			);
			const pdfEvents = filteredEvents.filter(it => it.episode === "pdf")
			const pdfUnloadEvents = pdfEvents.filter(it => JSON.parse(it.event).type === "unload");
			const relevantEvents = pdfUnloadEvents.filter(it => JSON.parse(it.event).title.trim() === selectedCyoaPdf.label.trim());
			const totalTimeSpentPerPage = [];
			const totalPagesPerIndex = []
			const pdfLabels = [];
			const pdfData = []
			relevantEvents.forEach(it => {
				const parsedEvent = JSON.parse(it.event);
				const indices = Object.keys(parsedEvent.duration);
				indices.forEach(el => {
					if (totalPagesPerIndex[el] === undefined) {
						totalPagesPerIndex[el] = 1;
					} else {
						totalPagesPerIndex[el] += 1;
					}

					if (totalTimeSpentPerPage[el] === undefined) {
						totalTimeSpentPerPage[el] = parsedEvent.duration[el];
					} else {
						totalTimeSpentPerPage[el] += parsedEvent.duration[el];
					}
				})
			})
			totalTimeSpentPerPage.forEach((it,i) => {
				if (it === undefined) return
				const averageTime = (it/totalPagesPerIndex[i]).toFixed(2);
				pdfLabels.push(i);
				pdfData.push(averageTime);
			});
			setPdfEngagementLabels(pdfLabels);
			setPdfEngagementData(pdfData);
		}
	}, [selectedCyoaPdf, sankeyData]);

	//Responses Chart
	useEffect(() => {
		if (selectedCyoaResponse !== null) {
			const startDate = format(new Date(dateRange[0].startDate), "yyyy/MM/dd");
			const endDate = format(new Date(dateRange[0].endDate), "yyyy/MM/dd");
			const id = selectedWidgets.id;
			const concernedEvents = cyoaEventData.payload.filter(
				(it) => it.cyoa_id === id
			);
			const filteredEvents = extractByDateRange(
				startDate,
				endDate,
				concernedEvents
			);
			const questionEvents = filteredEvents.filter(
				(it) =>
					it.episode === "question" &&
					JSON.parse(it.event).type === "unload" &&
					JSON.parse(it.event).question === selectedCyoaResponse.label
			);
			showCyoaResponseGraph(questionEvents);
		}
	}, [selectedCyoaResponse, sankeyData]);

	useEffect(() => {
		try {
			if (selectedWidgets && selectedProspect) {
				const startDate = format(new Date(dateRange[0].startDate), "yyyy/MM/dd");
				const endDate = format(new Date(dateRange[0].endDate), "yyyy/MM/dd");

				sendAmplitudeEvent("AnalyticsShowClicked");

				if (viewData.payload.length !== 0) {
					showViewGraph(startDate, endDate, viewData.payload);
					showSoloHeroDuration(startDate, endDate, watchDurationData.payload);
					if (renderData.payload.length !== 0) {
						showViewLoadGraph(
							startDate,
							endDate,
							viewData.payload.concat(renderData.payload)
						);
					}
					if (CTAData.payload.length !== 0) {
						showCTAGraph(startDate, endDate, CTAData.payload);
					}
				}

				if (selectedWidgets.type === "cyoa") {
					const id = selectedWidgets.id;
					const episodeData = JSON.parse(
						cyoaData.payload.filter((it) => it.id === id)[0].episodes
					);
					const concernedEvents = cyoaEventData.payload.filter(
						(it) => it.cyoa_id === id
					);
					const filteredEvents = extractByDateRange(
						startDate,
						endDate,
						concernedEvents
					);
					const convertedSankeyData = convertToSankey(
						episodeData,
						filteredEvents
					);
					setSankeyData(convertedSankeyData.data);
					setSankeyColumn(convertedSankeyData.column);
					extractCyaModalQuestions(filteredEvents, episodeData);
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [
		selectedWidgets,
		selectedProspect,
		dateRange,
		watchDurationIsFetching,
		viewIsFetching,
		CTAIsFetching,
		cyoaEventIsFetching,
		cyoaDemoDurationIsFetching,
		renderIsFetching,
	]);

	//Function responsible for creating the responses chart data
	const showCyoaResponseGraph = (questionEvents) => {
		let labels = [];
		let data = [];
		selectedCyoaResponse.choices.map((it) => {
			labels.push(it);
		});
		questionEvents.forEach((it) => {
			const event = JSON.parse(it.event);
			const selectedChoice = event.selectedChoices;
			if (selectedChoice.length === 1) {
				const choiceIndex = labels.indexOf(selectedChoice[0]);
				if (data[choiceIndex] === undefined) {
					data[choiceIndex] = 1;
				} else {
					data[choiceIndex] += 1;
				}
			} else {
				const sortedChoice = selectedChoice.sort().reduce((prev, curr) => {
					return `${prev} ${curr}`;
				}, "");
				let choiceIndex = labels.indexOf(sortedChoice);
				if (choiceIndex === -1) {
					labels.push(sortedChoice);
				}
				choiceIndex = labels.indexOf(sortedChoice);
				if (data[choiceIndex] === undefined) {
					data[choiceIndex] = 1;
				} else {
					data[choiceIndex] += 1;
				}
			}
		});
		data = data.map((it, i) => {
			if (it === undefined) {
				labels[i] = null;
				return null;
			}
			return it;
		});
		setResponseLabels(labels.filter((it) => it !== null));
		setResponseData(data.filter((it) => it !== null));
	};

	if (
		heroIsLoading ||
		soloIsLoading ||
		cyoaIsLoading ||
		watchDurationIsFetching ||
		renderIsFetching ||
		viewIsFetching ||
		CTAIsFetching ||
		cyoaEventIsFetching ||
		cyoaDemoDurationIsFetching ||
		prospectIsFetching
	)
		return <PageSpinner text="Loading Analytics..."/>;

	if (
		heroIsError ||
		soloIsError ||
		cyoaIsError ||
		CTAIsError ||
		viewIsError ||
		renderIsError ||
		cyoaEventIsError ||
		cyoaDemoDurationIsError ||
		watchDurationIsError ||
		prospectIsError
	)
		return <div>Error occurred while fetching data</div>;

	return (
		<>
			<div className="flex flex-col gap-y-4 mt-[3rem]">
				<div className="flex flex-row gap-x-[2rem]">
					<div className="flex flex-col gap-y-[0.5rem] w-[19.81rem]">
						<p className="font-semibold ml-[0.7rem]">{`Select Prospect`}</p>
						<Select
							className="w-full h-[2.5rem]"
							styles={embedSelect}
							value={selectedProspect}
							options={prospectOptions}
							onChange={(e) => setProspect(e)}
							placeholder={`Select prospect`}
							autofocus
						/>
					</div>
					<div className="flex flex-col gap-y-[0.5rem] w-[19.81rem]">
						<p className="font-semibold ml-[0.7rem]">{`Select ${EMBED}`}</p>
						<Select
							className="w-full h-[2.5rem]"
							styles={embedSelect}
							value={selectedWidgets}
							options={widgetOptions}
							onChange={(e) => setWidgets(e)}
							placeholder={`Select ${EMBED.toLowerCase()}`}
						/>
					</div>
					<div className="flex flex-col gap-y-[0.5rem] w-[19.81rem]">
						<p className="font-semibold ml-[0.7rem]">Select Date</p>
						<DateRangePickerComp
							dateRange={dateRange}
							setDateRange={(e) => {
								localStorage.setItem("dateRangeWidget", JSON.stringify(e));
								setDateRange(e);
							}}
						/>
					</div>
				</div>
			</div>
			<div className="w-full mt-[3.375rem]">
				<CyaAnalytics
					selectedWidgets={selectedWidgets}
					selectedProspect={selectedProspect}
					cyoaDemoOptions={cyoaDemoOptions}
					sankeyData={sankeyData}
					cyoaData={cyoaData}
					cyoaResponseOptions={cyoaResponseOptions}
					demoDurationData={demoDurationData}
					demoDurationLabels={demoDurationLabels}
					responseData={responseData}
					responseLabels={responseLabels}
					pdfEngagementData={pdfEngagementData}
					pdfEngagementLabels={pdfEngagementLabels}
					sankeyColumn={sankeyColumn}
					selectedCyoaDemo={selectedCyoaDemo}
					selectedCyoaResponse={selectedCyoaResponse}
					sendAmplitudeEvent={sendAmplitudeEvent}
					cyoaModalQuestionsAsked={cyoaModalQuestionsAsked}
					setSelectedCyoaDemo={setSelectedCyoaDemo}
					setSelectedCyoaResponse={setSelectedCyoaResponse}
					selectedCyoaPdf={selectedCyoaPdf}
					setSelectedCyoaPdf={setSelectedCyoaPdf}
					cyoaPdfOptions={cyoaPdfOptions}
				/>
				<SoloAnalytics
					demoDurationLabels={demoDurationLabels}
					selectedWidgets={selectedWidgets}
					selectedProspect={selectedProspect}
					clicksGraphLabels={clicksGraphLabels}
					clicksGraphData={clicksGraphData}
					viewsGraphLabels={viewsGraphLabels}
					soloHeroDemoDuration={soloHeroDemoDuration}
					viewsGraphData={viewsGraphData}
					viewLoadRatioGraphLabels={viewLoadRatioGraphLabels}
					viewLoadRatioGraphData={viewLoadRatioGraphData}
				/>
			</div>
		</>
	);
};

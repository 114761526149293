import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const HeroTagTypes = {
	HERO: "hero",
	SINGLE_HERO: "single-hero",
};

export const heroApiSlice = createApi({
	reducerPath: "api/hero",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().sessionData.token;
			if (token) {
				headers.set("token", token);
			}
			return headers;
		},
	}),
	tagTypes: Object.values(HeroTagTypes),
	endpoints: (builder) => ({
		getHero: builder.query({
			query: (initialPost) => "/hero/" + initialPost.companyId + `?limit=${initialPost.limit}&pageNo=${initialPost.pageNo.length==0?1:initialPost.pageNo}`,
			providesTags: [HeroTagTypes.HERO],
		}),
		getHeroById: builder.query({
			query: (initialPost) => "/hero/id/" + initialPost.id,
			providesTags: [HeroTagTypes.SINGLE_HERO],
		}),
		createHero: builder.mutation({
			query: (initialPost) => ({
				url: "/hero",
				method: "POST",
				body: initialPost.fd,
			}),
			invalidatesTags: [HeroTagTypes.HERO],
		}),
		createHeroCopy: builder.mutation({
			query: (initialPost) => ({
				url: "/hero/copy",
				method: "POST",
				body: initialPost,
			}),
			invalidatesTags: [HeroTagTypes.HERO],
		}),
		updateHero: builder.mutation({
			query: (initialPost) => ({
				url: "/hero/" + initialPost.heroId,
				method: "PUT",
				body: initialPost.fd,
			}),
			invalidatesTags: [HeroTagTypes.HERO, HeroTagTypes.SINGLE_HERO],
		}),
		deleteHero: builder.mutation({
			query: (initialPost) => ({
				url: "/hero/" + initialPost.heroId,
				method: "DELETE",
			}),
			invalidatesTags: [HeroTagTypes.HERO],
		}),
	}),
});

export const {
	useGetHeroQuery,
	useGetHeroByIdQuery,
	useCreateHeroMutation,
	useCreateHeroCopyMutation,
	useUpdateHeroMutation,
	useDeleteHeroMutation,
} = heroApiSlice;

import React from "react";
import { StyledRadio } from "../../../../../custom/StyledRadio";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import { embedSelect } from "../../../../../../utils/reactSelectStyles";
import { MaterialSwitch } from "../../../../../custom/MaterialSwitch";

export const CYAVerificationCard = ({
	verificationContent,
	setVerificationContent,
}) => {
	return (
		<div
			className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px]`}
		>
			<div
				className={
					"flex items-center justify-start h-[2.38rem] bg-transparent border-[#DEE8ED] border-b"
				}
			>
				<p className="text-[#255CA4] text-[18px] leading-[18px] mx-[3.938rem] ">
					Customize contents of verification prompt here
				</p>
			</div>
			<div
				className={`flex flex-col rounded-bl-[10px] rounded-br-[10px] bg-[#F9FBFC]`}
			>
				<div className="flex flex-col gap-y-[8px] my-[20px]">
					<div className={"flex flex-col space-y-[18px] mx-[3.7rem]"}>
						<div className="flex flex-col space-y-[18px]">
							<input
								type="text"
								className={
									"flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] text-[#222222]"
								}
								onChange={(e) => {
									setVerificationContent((state) => ({
										...state,
										title: e.target.value,
									}));
								}}
								value={verificationContent.title}
								placeholder="Title"
							/>
							<textarea
								placeholder="Description - 200 characters or less"
								maxLength={200}
								onChange={(e) => {
									setVerificationContent((state) => ({
										...state,
										description: e.target.value,
									}));
								}}
								value={verificationContent.description}
								className={
									"flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] h-[4.5rem] text-[#222222]"
								}
							/>
							<input
								type="text"
								className={
									"flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] text-[#222222]"
								}
								onChange={(e) => {
									setVerificationContent((state) => ({
										...state,
										placeholder: e.target.value,
									}));
								}}
								value={verificationContent.placeholder}
								placeholder="Placeholder"
							/>
							<div className="flex flex-col gap-y-2 bg-[#FFFFFF] rounded-lg w-[35.06rem] py-2 px-4">
								<p className="font-normal text-sm text-[#222222]">
									Consent for Zeros Privacy Policy
								</p>
								<p className="font-normal text-sm text-[#222222] opacity-80">
									By pressing Submit, I understand that Zeros will process my
									personal information in accordance with their
									<span className="text-[#0071F2]"> Privacy Policy</span>. I may
									withdraw my consent through unsubscribe links at any time.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

import loadingIcon from "../../icons/loading-icon.svg";
export const Spinner = ({ text = "Loading..." }) => {
	return (
		<div className="flex flex-column gap-y-8 justify-center items-center">
			{/* <div className="animate-spin rounded-full h-52 w-52 border-b-2 border-gray-900" />
      <p className="fixed">{text}</p> */}
			<img src={loadingIcon} alt={text} />
		</div>
	);
};

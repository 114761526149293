import { format } from "date-fns";

export const extractByRelation = (selectedProspect, list) => {
	let filteredList = []
	if (selectedProspect?.relationIds !== null) {
		selectedProspect?.relationIds?.map(it => {
			filteredList = filteredList.concat(list.filter(el => el.relationship_id === it));
		})
	} else {
		filteredList = list.filter(it => it.relationship_id === null)
	}
	return filteredList;
}

export const extractByDateRange = (startDate, endDate, list) => {
  const extractedList = [];
  list.forEach((it) => {
    const objDate = format(new Date(it.timestamp), "yyyy/MM/dd");
    if (objDate >= startDate && objDate <= endDate) {
      extractedList.push(it);
    }
  });
  return extractedList;
};

export const sortByDate = (list) => {
  return list.sort((a, b) => {
    const dateA = new Date(a.timestamp);
    const dateB = new Date(b.timestamp);
    return dateA - dateB;
  });
};

export const groupByWidgetType = (list) => {
  const heroData = [];
  const soloData = [];
  list.forEach((it) => {
    if (it.hero_id !== null) {
      heroData.push(it);
    } else {
      soloData.push(it);
    }
  });
  return { hero: heroData, solo: soloData };
};

export const groupHeroByWidgetID = (selectedWidgets, list) => {
  // const selectedHeroWidgets = selectedWidgets.filter(
  //   (it) => it.type === "hero"
  // );
  // const groupById = [];
  // selectedHeroWidgets.forEach((it) => {
  //   groupById.push({
  //     label: it.label,
  //     data: list.filter((el) => el.hero_id === it.id),
  //   });
  // });
	if (selectedWidgets.type !== "hero") return [];
  const groupById = [];
  groupById.push({
        label: selectedWidgets.label,
        data: list.filter((el) => el.hero_id === selectedWidgets.id),
  })
  return groupById;
};

export const groupSoloByWidgetID = (selectedWidgets, list) => {
  // const selectedSoloWidgets = selectedWidgets.filter(
  //   (it) => it.type === "solo"
  // );
  // const groupById = [];
  // selectedSoloWidgets.forEach((it) => {
  //   groupById.push({
  //     label: it.label,
  //     data: list.filter((el) => el.solo_id === it.id),
  //   });
  // });
	if (selectedWidgets.type !== "solo") return [];
  const groupById = [];
  groupById.push({
    label: selectedWidgets.label,
    data: list.filter((el) => el.solo_id === selectedWidgets.id),
  })
  return groupById;
};

export const groupByDate = (list) => {
  const groups = list.reduce((groups, data) => {
    const date = data.timestamp.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(data);
    return groups;
  }, {});

  return Object.keys(groups).map((date) => {
    return {
      date,
      data: groups[date],
    };
  });
};

export const makeDataset = (entry) => {
  const groupDate = groupByDate(entry.data);
  const data = [];
  const label = [];
  const color = "#1B85B6";
  groupDate.forEach((it) => {
    label.push(format(new Date(it.date), "MMM d yyyy"));
    data.push({ x: format(new Date(it.date), "MMM d yyyy"), y: it.data.length });
  });
  return {
    label: label,
    data: {
      label: entry.label,
      fill: false,
      lineTension: 0.2,
      borderColor: color,
      backgroundColor: color,
      pointBackgroundColor: color,
      pointRadius: 4,
      pointHitRadius: 10,
      data: data,
    },
  };
};

export const adaptToChart = (data) => {
  const dataset = [];
  const labelArray = [];
  data.forEach((it) => {
    const chartData = makeDataset(it);
    labelArray.push(chartData.label);
    dataset.push(chartData.data);
  });
  let concatLabelArray = [];
  labelArray.forEach((it) => {
    concatLabelArray = concatLabelArray.concat(it);
  });
  concatLabelArray = [...new Set(concatLabelArray)].sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  });

  return { label: concatLabelArray, dataset: dataset };
};

export const makeViewLoadDataset = (entry) => {
	const groupDate = groupByDate(entry.data);
	const data = [];
	const label = [];
	const color = "#1B85B6";
	groupDate.forEach((it) => {
		const renderEvents = it.data.filter(el => el.type === "render");
		const playEvents = it.data.filter(el => el.type === "play");

		if (renderEvents.length === 0) return;

		const ratio = (playEvents.length/renderEvents.length).toFixed(3);
		label.push(format(new Date(it.date), "MMM d yyyy"));
		data.push({ x: format(new Date(it.date), "MMM d yyyy"), y: (ratio * 100).toFixed(3) });
	});
	return {
		label: label,
		data: {
			label: entry.label,
			fill: false,
			lineTension: 0.2,
			borderColor: color,
			backgroundColor: color,
			pointBackgroundColor: color,
			pointRadius: 4,
			pointHitRadius: 10,
			data: data,
		},
	};
}

export const adaptViewLoadToChart = (data) => {
	const dataset = [];
	const labelArray = [];
	data.forEach((it) => {
		const chartData = makeViewLoadDataset(it);
		labelArray.push(chartData.label);
		dataset.push(chartData.data);
	});
	let concatLabelArray = [];
	labelArray.forEach((it) => {
		concatLabelArray = concatLabelArray.concat(it);
	});
	concatLabelArray = [...new Set(concatLabelArray)].sort((a, b) => {
		const dateA = new Date(a);
		const dateB = new Date(b);
		return dateA - dateB;
	});

	return { label: concatLabelArray, dataset: dataset };
}

// const getDatesInRange = (startDate, endDate) => {
// 	const date = new Date(startDate);
// 	const formattedEndDate = new Date(endDate);
// 	const dates = [];
// 	const difference = differenceInDays(formattedEndDate, date);
// 	for (let i = 1; i <= difference - 1; i++) {
// 		dates.push(format(new Date(addDays(date,i)), "MMM d yyyy"));
// 	}
// 	return dates;
// }
//
// const postProcessDateRange = (startDate, endDate, labels, dataset) => {
// 	const formattedStartDate = format(new Date(startDate), "MMM d yyyy");
// 	const formattedEndDate = format(new Date(endDate), "MMM d yyyy");
// 	const oldLabels = [...labels];
// 	const oldData = [...dataset[0].data]
// 	const newLabels = [];
// 	const newData = [];
//  if (labels.length === 0) return {label: labels, dataset: dataset}
//
// 	if (labels.indexOf(formattedStartDate) === -1) {
// 		oldLabels.unshift(formattedStartDate);
// 		oldData.unshift({x: formattedStartDate, y: 0});
// 	}
//
// 	if (labels.indexOf(formattedEndDate) === -1) {
// 		oldLabels.push(formattedEndDate);
// 		oldData.push({ x: formattedEndDate, y: 0 });
// 	}
//
// 	oldLabels.forEach((it, i) => {
// 		if (i === 0) {
// 			newLabels.push(it);
// 			newData.push(oldData[i]);
// 			return
// 		}
// 		const missingDates = getDatesInRange(oldLabels[i-1], it);
// 		missingDates.forEach(it => {
// 			newLabels.push(it);
// 			newData.push({ x: it, y: 0 });
// 		});
// 		newLabels.push(it);
// 		newData.push(oldData[i]);
// 	})
//
// 	const newLabelsArray = [...new Set(newLabels)].sort((a, b) => {
// 		const dateA = new Date(a);
// 		const dateB = new Date(b);
// 		return dateA - dateB;
// 	});
//
// 	const newDataset = [...dataset];
// 	newDataset[0].data = newData
//
// 	return {label: newLabelsArray, dataset: newDataset}
// }

export const postProcessDateRange = (startDate, endDate, labels, dataset) => {
	const formattedStartDate = format(new Date(startDate), "MMM d yyyy");
	const formattedEndDate = format(new Date(endDate), "MMM d yyyy");
	const currentLabels = [...labels];
	const currentData = (dataset[0]?.data) ? [...dataset[0].data] : [];

	if (labels.length === 0) return {label: labels, dataset: dataset}

	if (labels.indexOf(formattedStartDate) === -1) {
		currentLabels.unshift(formattedStartDate);
		currentData.unshift({x: formattedStartDate, y: 0});
	}

	if (labels.indexOf(formattedEndDate) === -1) {
		currentLabels.push(formattedEndDate);
		currentData.push({ x: formattedEndDate, y: 0 });
	}

	const newLabelsArray = [...new Set(currentLabels)].sort((a, b) => {
		const dateA = new Date(a);
		const dateB = new Date(b);
		return dateA - dateB;
	});

	const newDataset = [...dataset];
	newDataset[0].data = currentData

	return {label: newLabelsArray, dataset: newDataset}
}

export const getRandomColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

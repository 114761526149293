import {useParams} from "react-router-dom";
import {useGetContactListQuery, useUpdateContactListMutation} from "../../../features/api/contactsApiSlice";
import {PageSpinner} from "../../top-level/PageSpinner";
import React, {useEffect, useState} from "react";
import {PAGE} from "../../../data/constants";
import {getBackgroundColor} from "../../../utils/avatar";
import ViewContainer from "../../top-level/DemoViewContainer";
import {toast} from "react-toastify";
import {toastError} from "../../custom-toast/toasts";

export const ContactListView = () => {
	const {id} = useParams();
	const [contactList, setContactList] = useState();
	const [title, setTitle] = useState("");
	const [identifiers, setIndentifiers] = useState({});
	const {
		data: contactListData,
		isLoading: contactListIsLoading,
		isError: contactListIsError
	} = useGetContactListQuery({id: id})

	const [updateContactList] = useUpdateContactListMutation();

	useEffect(() => {
		if (!contactListIsLoading) {
			if (contactListData?.payload) {
				setTitle(contactListData?.payload[0]?.title ?? "");
				try {
					setContactList(JSON.parse(contactListData?.payload[0]?.contact_bank));
					setIndentifiers(JSON.parse(contactListData?.payload[0]?.contact_bank).identifiers);
				} catch (e) {
					console.log(e);
				}
			}
		}
	}, [contactListData, contactListIsLoading])

	const updateList = async () => {
		const copyIdentifiers = JSON.parse(JSON.stringify(identifiers));
		Object.keys(copyIdentifiers).forEach((it) => copyIdentifiers[it] === "" && delete copyIdentifiers[it]);
		const data = {
			...contactList,
			identifiers: copyIdentifiers
		}
		const status = await updateContactList(
			{
				id: id,
				name: title,
				contactBank: JSON.stringify(data)
			}
		).unwrap();
		if (status.status === "error") {
			return toastError("Error updating list");
		}
	}

	if (contactListIsLoading)
		return <PageSpinner text="Loading..."/>;

	if (contactListIsError)
		return <div>Error occurred while fetching data</div>;

	return (
		<ViewContainer>
			<div className="flex flex-col justify-center items-center">
				<div className="w-[69rem]">
					<div className="sticky top-0 flex flex-row justify-between py-4 bg-white z-50">
						<div className="flex flex-col w-3/5 mb-4">
							<input
								type="text"
								className="px-1.5 py-0.5 font-normal leading-5 text-xl font-medium text-[#222222] border border-white focus:rounded-sm focus:border-zeros-blue"
								value={title}
								placeholder={`Title for this ${PAGE.toLowerCase()}`}
								onChange={(e) => setTitle(e.target.value)}
							/>
							<div className="flex flex-row mx-3 mt-1 gap-x-2 items-center">
								<div className="flex justify-center items-center gap-x-2">
									<div
										className="flex justify-center items-center w-6 h-6 rounded-full text-white"
										style={{
											backgroundColor: getBackgroundColor(
												contactListData?.payload[0]?.salesrep_id
											),
										}}
									>
										{contactListData?.payload[0]?.name.trim()[0]}
									</div>
									<p className="text-base font-normal text-[#888888]">
										{contactListData?.payload[0]?.name.trim()}
									</p>
								</div>
								<div className="w-1 h-1 rounded-full bg-[#686868]"/>
								<p className="text-base font-normal text-[#888888]">
									{new Date().toLocaleDateString(
										"en-us",
										{
											year: "numeric",
											month: "long",
											day: "numeric",
										}
									)}
								</p>
							</div>
						</div>
						<div className="flex mr-[8px]">
							<button
								className={`border w-[4.75rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA]`}
								type="button"
								onClick={() => {
									updateList();
								}}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="w-[69rem]">
				<p className="mt-[32px] ml-[12px] text-black text-[16px] leading-[16px]">
					Specify identifiers for any columns whose values you want to insert in placeholders while sending in bulk.
				</p>
				<div
					className="mt-[27px] flex items-center justify-center p-[12px] rounded-[8px] border border-[#D9D9D9] bg-[#F8F8F8] overflow-x-auto">
					<table className="whitespace-nowrap table-fixed block overflow-auto h-[85vh]">
						<thead>
						<tr>
							{
								(contactList?.headers?.map((element, index) => {
									return (
										<th key={index}
												className="text-left px-[12px] py-[24px]">
											<input type="text"
														 className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[185px]"
														 value={identifiers[element.trim()] ?? ""}
														 onChange={(e) => {
															 setIndentifiers((state) => {
																 return {
																	 ...state,
																	 [element.trim()]: e.target.value
																 };
															 })
														 }}
														 placeholder="add identifier (optional)"/>
										</th>
									)
								}))
							}
						</tr>
						<tr className={(!contactList?.showHeaders) ? "hidden" : ""}>
							{
								(contactList?.headers?.map((element, index) => {
									return (
										<th key={index}
												className="text-left px-[12px] py-[8px] bg-transparent text-[#7D7D7D] text-[13px] leading-[13px] font-normal border-t border-[#D9D9D9]">
											{element}
										</th>
									)
								}))
							}
						</tr>
						</thead>
						<tbody>
						{
							(contactList?.bank?.map((element, rowIndex) => {
								return (
									<tr key={rowIndex} className="text-left">
										{
											contactList?.headers?.map((entry, colIndex) => {
												return (
													<td key={colIndex}
															className={`text-left px-[12px] py-[24px] bg-transparent text-[#7D7D7D] text-[13px] leading-[13px] ${(!contactList?.showHeaders && rowIndex === 0) ? "" : "border-t border-[#D9D9D9]"}`}>
														{element[entry]}
													</td>
												)
											})
										}
									</tr>
								)
							}))
						}
						</tbody>
					</table>
				</div>
			</div>
		</ViewContainer>
	)
}

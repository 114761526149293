import ViewContainer from "../top-level/ViewContainer";
import TopBar from "../top-level/TopBar";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useFilePicker } from "use-file-picker";
import Modal from "../modal/Modal";
import StyledButton from "../top-level/StyledButton";
import { PageSpinner } from "../top-level/PageSpinner";
import { toast } from "react-toastify";
import {
	useGetLogoQuery,
	useGetCompanyStyleTemplateQuery,
	useInsertCompanyLogoImageMutation,
	useInsertCompanyLogoURLMutation,
	useUpdateCompanySettingsMutation,
	useGetEmailsBlockListQuery,
} from "../../features/api/settingsApiSlice";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import TagsInput from "./TagsInput/TagsInput";
import { FONTS, CORP_DOMAINS } from "../../data/constants";
import { useDispatch } from "react-redux";
import { setWebsiteStyle } from "../../features/state/user-settings/userSettingSlice";
import {toastError, toastSuccess} from "../custom-toast/toasts";

export const Settings = () => {
	const dispatch = useDispatch();

	const [bgColor, setBgColor] = useState("#FFF");
	const [textColor, setTextColor] = useState("#000");
	const [headerColor, setHeaderColor] = useState("#FFF");
	const [buttonColor, setButtonColor] = useState("#1E41AF");
	const [buttonHoverColor, setButtonHoverColor] = useState("#1E3B8A");
	const [font, setFont] = useState();
	const [isOpenImageURL, setIsOpenImageURL] = useState(false);
	const [imageURL, setImageURL] = useState("");
	const [imageData, setImageData] = useState("");
	const [recentMethod, setRecentMethod] = useState("");
	const [showCompanyName, toggleShowCompanyName] = useState(false);
	const [borderColor, setBorderColor] = useState("#FFF");
	const [corpEmails, setCorpEmails] = useState([...CORP_DOMAINS]);

	const companyId = useSelector((state) => state.sessionData.companyId);
	const styleTemplate = useGetCompanyStyleTemplateQuery({
		companyId: companyId,
	});
	const companyLogo = useGetLogoQuery({ companyId: companyId });
	const [updateSettings, updateSettingsResult] =
		useUpdateCompanySettingsMutation();
	const [insertCompanyLogoImage, insertCompanyLogoImageResult] =
		useInsertCompanyLogoImageMutation();
	const [insertCompanyLogoURL, insertCompanyLogoURLResult] =
		useInsertCompanyLogoURLMutation();
	const getEmailsBlockList = useGetEmailsBlockListQuery({ companyId });

	const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
		readAs: "DataURL",
		accept: "image/*",
		limitFilesConfig: { max: 1 },
		maxFileSize: 16, // in megabytes
	});

	const isImage = (file) => {
		return file.includes("image");
	};

	const handleSave = async () => {
		if (!font || font === "") {
			toastError("Please select a font");
			return;
		}
		const style = {
			textColor: textColor,
			bgColor: bgColor,
			buttonBgHoverColor: buttonHoverColor,
			buttonBgColor: buttonColor,
			headerColor: headerColor,
			borderColor: borderColor,
			fontName: font,
			showCompanyName: showCompanyName,
		};
		const status = await updateSettings({
			companyId: companyId,
			style: JSON.stringify(style),
			email_block_list: JSON.stringify(corpEmails),
		}).unwrap();
		if (status.status === "success") {
			dispatch(
				setWebsiteStyle({
					websiteStyle: JSON.stringify(style),
				})
			);
			toastSuccess("Settings Updated");
			return true;
		} else {
			toastError("Error Encountered While Updating Style");
			return false;
		}
	};

	const saveLogoUrl = async () => {
		const status = await insertCompanyLogoURL({
			companyId: companyId,
			url: imageURL,
		}).unwrap();
		if (status.status === "success") {
			toastSuccess("Logo Updated");
		} else {
			toastError("Error Encountered While Updating Logo");
		}
	};

	const saveLogoImage = async () => {
		if (isImage(filesContent[0].content)) {
			const status = await insertCompanyLogoImage({
				companyId: companyId,
				image: filesContent[0].content,
			}).unwrap();
			if (status.status === "success") {
				toastSuccess("Logo Updated");
			} else {
				toastError("Error Encountered While Adding/Updating Logo Image");
			}
		}
	};

	const handlePreview = async () => {
		const status = await handleSave();
		const encryptedId =
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDEsIm5hbWUiOiJVc2VyIiwiZW1haWwiOiJ6ZXJvc0BwcmV2aWV3LmNvbSIsInBhZ2VJZCI6Nywic2FsZXNyZXBJZCI6OSwiY29tcGFueUlkIjoxNywic3RhbXAiOjE2NDc0MzIxMDYxMzcsImlhdCI6MTY0NzQzMjEwNn0.agGHhfVAjDoh4YsrJ8u61M2WG6kzKMCfDiZNAX1NR64";
		if (status) {
			window.open(
				`${process.env.REACT_APP_CLIENT_PROSPECT}/${encryptedId}/preview/7/${companyId}/demo`
			);
		}
	};

	const handleFontChange = (e) => {
		setFont(e.target.value);
	};

	useEffect(() => {
		if (!styleTemplate.isLoading) {
			if (styleTemplate.data.status === "success") {
				if (styleTemplate.data.payload.length !== 0) {
					const payload = styleTemplate.data.payload[0];
					if (payload.website_style !== "") {
						const style = JSON.parse(payload.website_style);
						setTextColor(style.textColor);
						setBorderColor(style.borderColor);
						toggleShowCompanyName(style.showCompanyName);
						setBgColor(style.bgColor);
						setButtonColor(style.buttonBgColor);
						setButtonHoverColor(style.buttonBgHoverColor);
						setHeaderColor(style.headerColor);
						setFont(style.fontName);
					}
				}
			} else {
				toastError("Error Fetching Styles");
			}
		}
	}, [styleTemplate.data]);

	useEffect(() => {
		if (!companyLogo.isLoading) {
			if (companyLogo.data.status === "success") {
				if (companyLogo.data.payload.length !== 0) {
					if (
						companyLogo.data.payload[0].logo_url !== "" &&
						companyLogo.data.payload[0].logo_url !== null
					) {
						setImageURL(companyLogo.data.payload[0].logo_url);
					}
					if (
						companyLogo.data.payload[0].logo_image !== "" &&
						companyLogo.data.payload[0].logo_image !== null
					) {
						setImageData(companyLogo.data.payload[0].logo_image);
					}
					if (
						companyLogo.data.payload[0].recent_method !== "" &&
						companyLogo.data.payload[0].recent_method !== null
					) {
						setRecentMethod(companyLogo.data.payload[0].recent_method);
					}
				}
			} else {
				toastError("Error Fetching Logo");
			}
		}
	}, [companyLogo.data]);

	useEffect(() => {
		if (filesContent.length !== 0) {
			saveLogoImage();
		}
	}, [filesContent]);

	useEffect(() => {
		if (!getEmailsBlockList.isLoading) {
			if (getEmailsBlockList.data.status === "success") {
				if (getEmailsBlockList.data.payload.length !== 0) {
					if (getEmailsBlockList.data.payload[0].email_block_list !== null) {
						setCorpEmails(
							JSON.parse(getEmailsBlockList.data.payload[0].email_block_list)
						);
					}
				}
			}
		}
	}, [getEmailsBlockList.data]);

	if (styleTemplate.isLoading || companyLogo.isLoading) {
		return <PageSpinner text="Loading Settings..." />;
	}

	if (
		updateSettingsResult.isLoading ||
		insertCompanyLogoURLResult.isLoading ||
		insertCompanyLogoImageResult.isLoading
	) {
		return <PageSpinner text="Saving Settings..." />;
	}

	if (styleTemplate.error || companyLogo.error) {
		toastError("Failed to load existing settings");
	}

	if (loading) {
		return <PageSpinner text="Loading Image..." />;
	}

	if (errors.length) {
		toastError("Failed to load image");
	}

	return (
		<ViewContainer>
			<TopBar
				heading="Settings"
				description="Customize the look and feel of prospect client"
			></TopBar>
			<Modal open={isOpenImageURL} onClose={() => setIsOpenImageURL(false)}>
				<form>
					<div className="mb-3" controlId="formBasicEmail">
						<label>Link:</label>
						<br />
						<input
							className="w-full rounded px-1 py-2 mx-2 my-3 border"
							type="id"
							placeholder="Enter the Logo Image URL"
							value={imageURL}
							onChange={(e) => setImageURL(e.target.value)}
						/>
					</div>
					<StyledButton
						type="primary"
						onClick={(e) => {
							e.preventDefault();
							saveLogoUrl();
						}}
					>
						Upload
					</StyledButton>
				</form>
			</Modal>
			<div>
				<label
					htmlFor="show-company-name"
					className="font-semibold text-lg mr-2"
				>
					Show company name
				</label>
				<input
					type="checkbox"
					checked={showCompanyName}
					onChange={(e) => {
						toggleShowCompanyName(!showCompanyName);
					}}
					name="show-company-name"
					id="show-company-name"
				/>
			</div>
			<div className="flex flex-col mt-4 gap-y-1">
				<p className="font-bold">Logo</p>
				<div className="flex gap-x-12">
					<div className="flex flex-row items-center gap-x-4">
						<StyledButton
							className="h-12 w-32 inline-block"
							type="secondary"
							onClick={() => setIsOpenImageURL(true)}
						>
							Insert URL
						</StyledButton>
						<img
							className={
								recentMethod === "url" && imageURL !== "" ? "w-6 h-6" : "hidden"
							}
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Eo_circle_light-blue_checkmark.svg/1200px-Eo_circle_light-blue_checkmark.svg.png"
							alt="published"
						/>
					</div>
					<div className="flex flex-row items-center gap-x-4">
						<StyledButton
							className="h-12 w-32 inline-block"
							type="link"
							onClick={() => openFileSelector()}
						>
							Upload Image
						</StyledButton>
						<img
							className={
								recentMethod === "image" && imageData !== ""
									? "w-6 h-6"
									: "hidden"
							}
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Eo_circle_light-blue_checkmark.svg/1200px-Eo_circle_light-blue_checkmark.svg.png"
							alt="published"
						/>
					</div>
				</div>
				<p className="text-md text-red-500 font-semibold my-3">
					Note: Logo height should be a maximum of 80px
				</p>
			</div>
			<div>
				<h1 className="font-bold text-2xl mb-4">Domain Blocklist</h1>
				<TagsInput corpEmails={corpEmails} setCorpEmails={setCorpEmails} />
			</div>
			<div className="flex flex-row gap-x-8 my-5">
				<div className="flex flex-col justify-between">
					<h1 className="font-bold text-2xl mb-4">Select your font</h1>
					<div className="flex flex-col justify-start">
						<p>Set the font for Pages and Widgets.</p>
						<p className="text-gray-600">
							For widgets, you can override the <br />
							font in their embed code if they <br />
							are not listed in the dropdown.
						</p>
					</div>
				</div>
				<div className="flex flex-col justify-between w-[25%]">
					<select
						className="shadow appearance-none border h-10 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline"
						id="font"
						as="select"
						name="font"
						placeholder="Select a font"
						value={font ?? ""}
						onChange={(e) => handleFontChange(e)}
					>
						<option value="">Select a Font</option>
						{FONTS.map((it) => (
							<option key={it} value={it.split("(")[0].trim()}>
								{it}
							</option>
						))}
					</select>
					{font && (
						<div className="flex flex-col justify-start">
							<p>Here is what your chosen font looks like.</p>
							<p className="text-gray-600" style={{ fontFamily: font }}>
								Lorem ipsum dolor sit amet vitae.
								<br />
								Aenean feugiat, velit vitae mcorper <br />
								dolor nunc pharetra dolor ratwes
							</p>
						</div>
					)}
				</div>
			</div>
			<div className="flex gap-x-8">
				<div className="flex flex-col mt-4 gap-y-1">
					<p className="font-bold">Background Color</p>
					<SketchPicker
						disableAlpha={true}
						color={bgColor}
						onChange={(color) => setBgColor(color.hex)}
					/>
				</div>
				<div className="flex flex-col mt-4 gap-y-1">
					<p className="font-bold">Text Color</p>
					<SketchPicker
						disableAlpha={true}
						color={textColor}
						onChange={(color) => setTextColor(color.hex)}
					/>
				</div>
				<div className="flex flex-col mt-4 gap-y-1">
					<p className="font-bold">Header Color</p>
					<SketchPicker
						disableAlpha={true}
						color={headerColor}
						onChange={(color) => setHeaderColor(color.hex)}
					/>
				</div>
			</div>
			<div className="flex gap-x-8">
				<div className="flex flex-col mt-4 gap-y-1">
					<p className="font-bold">Button Color</p>
					<SketchPicker
						disableAlpha={true}
						color={buttonColor}
						onChange={(color) => setButtonColor(color.hex)}
					/>
				</div>
				<div className="flex flex-col mt-4 gap-y-1">
					<p className="font-bold">Button Color (Hover)</p>
					<SketchPicker
						disableAlpha={true}
						color={buttonHoverColor}
						onChange={(color) => setButtonHoverColor(color.hex)}
					/>
				</div>
				<div className="flex flex-col mt-4 gap-y-1">
					<p className="font-bold">Border Color</p>
					<SketchPicker
						disableAlpha={true}
						color={borderColor}
						onChange={(color) => setBorderColor(color.hex)}
					/>
				</div>
			</div>
			<div className="flex flex-col mt-4 gap-y-2">
				<div className="flex gap-x-4">
					<StyledButton
						className="h-12 w-24 inline-block"
						type="primary"
						onClick={() => {
							handleSave();
						}}
					>
						Save
					</StyledButton>
					<StyledButton
						className="h-12 w-24 inline-block"
						type="primary"
						onClick={() => {
							handlePreview();
						}}
					>
						Preview
					</StyledButton>
				</div>
				<Link
					to="/feature/questionnaire"
					className="flex justify-center items-center h-12 w-24 rounded font-medium text-black border-2 hover:underline"
				>
					Cancel
				</Link>
			</div>
		</ViewContainer>
	);
};

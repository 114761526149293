import React from "react";
import { BLINKER_PACK } from "../../../data/constants";
import "./BlinkersPack.scss";
export const BlinkersPack = ({ selected, onClick }) => {
	console.log(selected, "reached pack");
	return (
		<div className="flex flex-wrap w-[360px] p-2 absolute left-0 mt-3 z-10 border rounded-lg bg-white shadow-md">
			{Object.keys(BLINKER_PACK).map((blinker, idx) => (
				<button
					key={BLINKER_PACK[blinker]}
					className={
						blinker.toLowerCase() === selected.toLowerCase()
							? "flex items-center p-3 w-1/3 border border-1 border-[#D9D9D9] rounded bg-[#FAFAFA]"
							: "flex items-center p-3 w-1/3 border border-1 border-white rounded hover:border hover:border-[#D9D9D9]"
					}
					onClick={(e) => onClick(e, blinker, idx + 1)}
				>
					<div className="flex items-center justify-start gap-x-3">
						<p className="text-sm whitespace-nowrap">Style {idx + 1}</p>
						<p className={BLINKER_PACK[blinker]} />
					</div>
				</button>
			))}
		</div>
	);
};

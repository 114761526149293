import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const SoloTagTypes = {
	SOLO: "solo",
	SINGLE_SOLO: "single-solo",
};

export const soloApiSlice = createApi({
	reducerPath: "api/solo",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().sessionData.token;
			if (token) {
				headers.set("token", token);
			}
			return headers;
		},
	}),
	tagTypes: Object.values(SoloTagTypes),
	endpoints: (builder) => ({
		getSolo: builder.query({
			query: (initialPost) => "/solo/" + initialPost.companyId + `?limit=${initialPost.limit}&pageNo=${initialPost.pageNo.length==0?1:initialPost.pageNo}`,
			providesTags: [SoloTagTypes.SOLO],
		}),
		getSoloById: builder.query({
			query: (initialPost) => "/solo/id/" + initialPost.id,
			providesTags: [SoloTagTypes.SINGLE_SOLO],
		}),
		createSolo: builder.mutation({
			query: (initialPost) => ({
				url: "/solo",
				method: "POST",
				body: initialPost.fd,
			}),
			invalidatesTags: [SoloTagTypes.SOLO],
		}),
		createSoloCopy: builder.mutation({
			query: (initialPost) => ({
				url: "/solo/copy",
				method: "POST",
				body: initialPost,
			}),
			invalidatesTags: [SoloTagTypes.SOLO],
		}),
		updateSolo: builder.mutation({
			query: (initialPost) => ({
				url: "/solo/" + initialPost.soloId,
				method: "PUT",
				body: initialPost.fd,
			}),
			invalidatesTags: [SoloTagTypes.SOLO, SoloTagTypes.SINGLE_SOLO],
		}),
		deleteSolo: builder.mutation({
			query: (initialPost) => ({
				url: "/solo/" + initialPost.soloId,
				method: "DELETE",
			}),
			invalidatesTags: [SoloTagTypes.SOLO],
		}),
	}),
});

export const {
	useGetSoloQuery,
	useGetSoloByIdQuery,
	useCreateSoloMutation,
	useCreateSoloCopyMutation,
	useUpdateSoloMutation,
	useDeleteSoloMutation,
} = soloApiSlice;

import React, { useState, useRef } from "react";
import StyledButton from "./StyledButton";
import arrowupicon from "../../icons/arrowup-icon.svg";
import arrowdownicon from "../../icons/arrowdown-icon.svg";
import { ClickOutside } from "../../features/hooks/ClickOutside";

const DropdownButton = ({ title, options, onSelectHandler, loading }) => {
	const [isOpenDropdown, setIsOpenDropdown] = useState(false);
	const wrapperRef = useRef("menu");
	ClickOutside(wrapperRef, () => {
		setIsOpenDropdown(false);
	});
	return (
		<div className="relative" ref={wrapperRef}>
			<StyledButton
				className="w-[10.81rem] h-[2.75rem] rounded-[0.375rem] flex flex-row justify-center items-center"
				type="primary"
				onClick={() => setIsOpenDropdown(!isOpenDropdown)}
			>
				<div className="flex items-center justify-around gap-x-[1rem]">
					{loading ? (
						<div className="flex flex-column gap-y-8 justify-center items-center justify-center">
							<div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900" />
						</div>
					) : (
						<p>{title}</p>
					)}
					{isOpenDropdown ? (
						<img src={arrowupicon} alt="up" />
					) : (
						<img src={arrowdownicon} alt="down" />
					)}
				</div>
			</StyledButton>
			{isOpenDropdown && (
				<div className="bg-white min-w-max w-full absolute border-[#D9D9D9] rounded-b-lg shadow">
					{options.map((option) => (
						<div
							// {...option}
							key={option.key}
							value={option.value}
							className="flex justify-around cursor:pointer border-b-2 last:border-0 p-2 last:hover:rounded-b-lg hover:bg-[#F8F8F8] cursor-pointer"
							onClick={() => {
								setIsOpenDropdown(false);
								onSelectHandler(option.value);
							}}
						>
							{option.icon && (
								<img src={option.icon} alt={`${option.value}-icon`} />
							)}
							{option.label}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default DropdownButton;

import React, {useState} from "react";
import {QuestionCard} from "./QuestionCard";

export const Questions = ({questionList, setQuestionList}) => {

	const addQuestion = () => {
		if (!questionList) return setQuestionList([{ id: 1, data: {type: "checkbox"}}]);
		const qList = [...questionList];
		const sortedQList = [...qList];
		sortedQList.sort((a, b) => a.id - b.id);
		const id =
			sortedQList.length !== 0
				? sortedQList[sortedQList.length - 1].id + 1
				: 1;
		qList.push({ id: id, data: {type: "checkbox"}});
		setQuestionList(qList);
	}

	const updateQuestionList = (id, mode, data) => {
		let qList = [];
		switch (mode) {
			case "choice":
				qList = [...questionList];
				qList.forEach(it => {
					if (it.id === id) {
						it.data.choices = data;
					}
				})
				return setQuestionList(qList);
			case "name":
				qList = [...questionList];
				qList.forEach(it => {
					if (it.id === id) {
						it.data.name = data;
					}
				})
				return setQuestionList(qList);
			case "type":
				qList = [...questionList];
				qList.forEach(it => {
					if (it.id === id) {
						it.data.type = data;
					}
				})
				return setQuestionList(qList);
		}
	}

	const deleteQuestion = (id) => {
		const filteredQList = questionList.filter(it => it.id !== id)
		setQuestionList(filteredQList);
	}

	return (
		<div className="flex flex-col gap-y-[32px] my-[32px]">
			{
				questionList?.map(it => {
					return (
						<div key={it.id}>
							<QuestionCard
								index={it.id}
								fetchedQuestion={it.data}
								updateQuestionList={updateQuestionList}
								deleteEpisode={deleteQuestion}
							/>
						</div>
					)
				})
			}
			<div className={"flex items-center justify-center"} onClick={() => addQuestion()}>
				<button className="border border-[#63A4FA] bg-[#F1F7FF] text-[#1D60B5] text-[14px] leading-[14px] w-[130px] h-[32px] rounded-[10px]">
					+ Add Question
				</button>
			</div>
		</div>
	);
}

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { matchPath } from "react-router-dom";

import HeroEditor from "./components/HeroEditor";
import SneakpeekEditor from "./components/SneakpeekEditor";
import SoloEditor from "./components/SoloEditor";
import { CYAEditor } from "./components/CYAEditor";

const Editor = () => {
	const [isNew, setIsNew] = useState();
	const [id, setId] = useState();
	const [editor, setEditor] = useState();
	let selectedEditor = null;
	const location = useLocation();
	const params = useParams();

	useEffect(() => {
		const newHeroMatch = matchPath(location.pathname, {
			path: "/feature/embeds/hero",
			exact: true,
		});
		const editHeroMatch = matchPath(location.pathname, {
			path: "/feature/embeds/hero/:id",
			exact: true,
		});
		const newSoloMatch = matchPath(location.pathname, {
			path: "/feature/embeds/solo",
			exact: true,
		});
		const editSoloMatch = matchPath(location.pathname, {
			path: "/feature/embeds/solo/:id",
			exact: true,
		});
		const newSneakpeekMatch = matchPath(location.pathname, {
			path: "/feature/embeds/sneakpeek",
			exact: true,
		});
		const editSneakpeekMatch = matchPath(location.pathname, {
			path: "/feature/embeds/sneakpeek/:id",
			exact: true,
		});
		const newCYOAMatch = matchPath(location.pathname, {
			path: "/feature/embeds/cyoa",
			exact: true,
		});
		const editCYOAMatch = matchPath(location.pathname, {
			path: "/feature/embeds/cyoa/:id",
			exact: true,
		});
		if (newHeroMatch) {
			setEditor("NEW_HERO");
			setIsNew(true);
		} else if (editHeroMatch) {
			setEditor("EDIT_HERO");
			setIsNew(false);
			setId(params.id);
		} else if (newSoloMatch) {
			setEditor("NEW_SOLO");
			setIsNew(true);
		} else if (editSoloMatch) {
			setEditor("EDIT_SOLO");
			setIsNew(false);
			setId(params.id);
		} else if (newSneakpeekMatch) {
			setEditor("NEW_SNEAKPEEK");
			setIsNew(true);
		} else if (editSneakpeekMatch) {
			setEditor("EDIT_SNEAKPEEK");
			setIsNew(false);
			setId(params.id);
		} else if (newCYOAMatch) {
			setEditor("NEW_CYOA");
			setIsNew(true);
		} else if (editCYOAMatch) {
			setEditor("EDIT_CYOA");
			setIsNew(false);
			setId(params.id);
		}
	}, []);

	switch (editor) {
		case "NEW_HERO":
			selectedEditor = (
				<HeroEditor id={id} isNew={isNew} setId={setId} setIsNew={setIsNew} />
			);
			break;
		case "EDIT_HERO":
			selectedEditor = <HeroEditor id={id} isNew={isNew} />;
			break;
		case "NEW_SOLO":
			selectedEditor = (
				<SoloEditor id={id} isNew={isNew} setId={setId} setIsNew={setIsNew} />
			);
			break;
		case "EDIT_SOLO":
			selectedEditor = <SoloEditor id={id} isNew={isNew} />;
			break;
		case "NEW_SNEAKPEEK":
			selectedEditor = (
				<SneakpeekEditor
					id={id}
					isNew={isNew}
					setId={setId}
					setIsNew={setIsNew}
				/>
			);
			break;
		case "EDIT_SNEAKPEEK":
			selectedEditor = <SneakpeekEditor id={id} isNew={isNew} />;
			break;
		case "NEW_CYOA":
			selectedEditor = (
				<CYAEditor id={id} isNew={isNew} setId={setId} setIsNew={setIsNew} />
			);
			break;
		case "EDIT_CYOA":
			selectedEditor = <CYAEditor id={id} isNew={isNew} />;
			break;
		default:
			console.log("URL not found", editor);
	}
	return selectedEditor;
};

export default Editor;

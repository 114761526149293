import {ErrorMessage, Form, Formik, useField} from "formik";
import zerosLogo from "../../icons/zeros-logo.png";
import linkedInLogo from "../../icons/linkedIn-logo.svg";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import {useSignUpMutation} from "../../features/api/loginApiSlice";
import {toastError, toastSuccess} from "../custom-toast/toasts";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useLinkedIn} from "react-linkedin-login-oauth2";
import {usePostAuthMutation} from "../../features/api/linkedInApiSlice";

export const SignUp = () => {
	const [signUp] = useSignUpMutation();
	const history = useHistory();
	const [consent, setConsent] = useState(false);
	const [authCode, setAuthCode] = useState("");
	const { linkedInLogin } = useLinkedIn({
		clientId: process.env.REACT_APP_LINKEDIN_CLIENTID ?? "",
		scope: `r_liteprofile r_emailaddress`,
		redirectUri: `${window.location.origin}/linkedin`,
		onSuccess: (code) => {
			// setAuthCode(code);
		},
		onError: (error) => {
			// console.log(error)
			// toastError("Unable to access LinkedIn");
		},
	});

	useEffect(() => {
		const getAuthToken = () => {
			const authToken = localStorage.getItem("linkedInAuthCode");
			if (authToken) {
				if (authToken === "") {
					localStorage.removeItem("linkedInAuthCode");
					return toastError("Unable to access LinkedIn");
				} else {
					setAuthCode(authToken);
					localStorage.removeItem("linkedInAuthCode");
				}
			}
		}
		window.addEventListener('storage', getAuthToken);
		return () => {
			window.removeEventListener('storage', getAuthToken);
		};
	})

	const [postAuth] = usePostAuthMutation();

	const initialValues = {
		fullName: "",
		companyName: "",
		email: "",
		password: "",
		confirmPassword: "",
	};

	const logInSchema = Yup.object().shape({
		fullName: Yup.string().required("Name is required"),
		companyName: Yup.string().required("Company name is required"),
		email: Yup.string().email().required("Email is required"),
		password: Yup.string()
			.required("Password is required")
			.min(4, "Password is too short - should be more than 4 letters"),
		confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Password must match")
	});

	const createLinkedInSignUp = async (companyName) => {
		if (companyName === "")
			return window.alert("Please enter company name");

		const status = await postAuth({
			authCode: authCode,
			clientId: process.env.REACT_APP_LINKEDIN_CLIENTID,
			companyName: companyName,
			redirectUri: `${window.location.origin}/linkedin`,
		}).unwrap();

		if (status.status === "success") {
			toastSuccess("Account created successfully");
			history.push("/login")
		} else if (status.status === "error") {
			if (status.message === "Unable to sign up") {
				toastError("Unable to create account");
			} else if (status.message === "Account exists") {
				toastError("Account already exists");
			} else if (status.message === "Email is invalid") {
				toastError("Invalid or blocked email");
			} else {
				toastError("Unable to connect to database");
			}
		}
	};

	const createUserAccount = async (values) => {
		if (!consent) return toastError("Please accept Privacy Policy and Terms of Service");
		const data = {
			email: values.email,
			password: values.password,
			fullName: values.fullName,
			companyName: values.companyName,
			method: "email"
		}

		const status = await signUp(data).unwrap();
		if (status.status === "success") {
			toastSuccess("Account created successfully");
			history.push("/login")
		} else if (status.status === "error") {
			if (status.message === "Unable to sign up") {
				toastError("Unable to create account");
			} else if (status.message === "Account exists") {
				toastError("Account already exists");
			} else if (status.message === "Email is invalid") {
				toastError("Invalid or blocked email");
			} else {
				toastError("Unable to connect to database");
			}
		}

	}

	const Input = ({name, ...props}) => {
		const [field, meta] = useField(name);
		return (
			<div>
				<input
					className={`shadow appearance-none ${
						(meta.error && meta.touched) //|| hasLoggedIn
							? "border-2 border-[#B25353]"
							: "border border-[#2A343E]"
					} rounded-[8px] w-full py-[16px] px-[12px] text-[#FAFAFA] leading-tight focus:outline-none focus:shadow-outline bg-[#2A343E]`}
					{...field}
					{...props}
				/>
				<ErrorMessage
					name={field.name}
					component="div"
					className="text-red-500 text-xs"
				/>
			</div>
		);
	};

	return (
		<div className="h-screen">
			<div
				style={{height: "100%", width: "100%"}}
				className="flex justify-center items-center bg-[#0A1B2B]"
			>
				<div className="flex flex-col flex-grow bg-[#0A1B2B] space-y-[24px] items-center justify-center">
					<div className="flex flex-col justify-center items-center gap-y-[34px] mx-auto">
						<img
							src={zerosLogo}
							alt="zeros logo"
							className="w-[70px] h-[70px]"
						/>
						<p className="text-[28px] leading-[28px] font-semibold text-[#EDEDED]">
							Sign up for Zeros
						</p>
						<div className="flex flex-col flex-grow bg-[#094433] items-center justify-center px-[12px] py-[10px] rounded-[8px] text-white font-[500]">
							We won’t be sending any newsletters to your mailbox.
						</div>
					</div>
					<Formik
						initialValues={initialValues}
						validationSchema={logInSchema}
						onSubmit={(values) => {
							createUserAccount(values);
						}}
					>
						{(formik) => {
							const {isValid, dirty} = formik;
							const classSubmit =
								"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
							return (
								<div className="w-[400px] mx-auto">
									<Form
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												createUserAccount(formik.values);
											}
										}}
									>
										{
											(authCode === "") ?
												<>
													<div className="mb-4">
														<Input
															id="fullName"
															type="text"
															name="fullName"
															placeholder="Full name *"
														/>
													</div>
													<div className="mb-4">
														<Input
															id="companyName"
															type="text"
															name="companyName"
															placeholder="Company name *"
														/>
													</div>
													<div className="mb-4">
														<Input
															id="email"
															type="email"
															name="email"
															placeholder="Add your work email *"
														/>
													</div>
													<div className="mb-4">
														<Input
															id="password"
															type="password"
															name="password"
															placeholder="Password *"
														/>
													</div>
													<div className="flex flex-col gap-y-[8px] mb-6">
														<Input
															id="confirmPassword"
															type="password"
															name="confirmPassword"
															placeholder="Re-enter password *"
														/>
													</div>
													<div className={`flex flex-row items-start gap-x-[10px] mb-4`}>
														<input
															type="checkbox"
															className="w-[18px] h-[18px] rounded"
															checked={consent}
															onChange={(e) => {
																setConsent((state) => !state);
															}}
														/>
														<p className="text-[16px] leading-[24px] text-[#7D7D7D]">
															I have read and agree to the <a href="https://www.zeroshq.com/privacy-policy" target="_blank"
																															rel="noreferrer" className={`hover:underline text-[#1A85F8]`}>Privacy
															Policy</a> and <a href="https://www.zeroshq.com/terms-of-service" target="_blank"
																								rel="noreferrer" className={`hover:underline text-[#1A85F8]`}>Terms of
															Service</a>
														</p>
													</div>
													<div className="flex flex-col gap-y-[24px]">
														<button
															className={`h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA]`}
															type="submit"
															disabled={!(dirty && isValid)}
														>
															Create account
														</button>
														<div className={`flex flex-row items-center justify-center gap-x-[16px]`}>
															<div className={`border-2 border-[#2A343E] w-[170px]`}/>
															<p className={`text-[20px] leading-[20px] font-semibold text-[#B9B9B9]`}>OR</p>
															<div className={`border-2 border-[#2A343E] w-[170px]`}/>
														</div>
														<button
															className={`h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-transparent hover:bg-[#2A343E] border-2 border-[#2A343E]`}
															onClick={linkedInLogin}
														>
															<div className={`flex flex-row gap-x-[16px] justify-center items-center`}>
																<img src={linkedInLogo} alt="linkedIn Logo" className={`w-[30px] h-[30px]`}/>
																<p>Sign up with LinkedIn</p>
															</div>
														</button>
														<div
															className={`flex flex-row text-[18px] leading-[18px] font-[600] justify-center items-center gap-x-2`}>
															<p className={`text-[#B5B5B5]`}>
																Already have an account?
															</p>
															<a href={`${process.env.REACT_APP_CLIENT_SALES}`} className={`text-[#1A85F8] hover:underline`}>
																Log In
															</a>
														</div>
													</div>
												</>
												:
												<div className={`flex flex-col gap-y-[24px]`}>
													<div>
														<Input
															id="companyName"
															type="text"
															name="companyName"
															placeholder="Company name *"
														/>
													</div>
													<button
														className={`w-full h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA]`}
														onClick={() =>
															createLinkedInSignUp(
																formik.values
																	.companyName,
															)
														}
													>
														Continue
													</button>
												</div>
										}
									</Form>
								</div>
							);
						}}
					</Formik>
				</div>
			</div>
		</div>
	);
}

import React from "react";
import Modal from "react-modal";

import closeCrossIcon from "../../../../../../../icons/closecross-icon.svg";
import infoCircleIcon from "../../../../../../../icons/info-circle-icon.svg";
import { customStyles } from "../../../../../../../utils/modalStyles";

const UploadModal = ({ open, setOpen, onClick }) => {
	return (
		<Modal
			isOpen={open}
			onRequestClose={() => {
				console.log("DO NOTHING");
			}}
			style={customStyles}
		>
			<div className="flex flex-col px-[40px] py-[36px]">
				<div className="flex justify-end items-center">
					<button onClick={() => setOpen(false)}>
						<img
							src={closeCrossIcon}
							alt="close"
							className="w-[34px] h-[34px]"
						/>
					</button>
				</div>
				<div className="flex flex-col gap-y-[10px]">
					<div className="flex flex-row gap-x-[1rem] items-center">
						<img
							src={infoCircleIcon}
							alt="help"
							className="w-[30px] h-[30px]"
						/>
						<p className="font-medium text-[18px] leading-[18px] text-[#222222]">
							Saving content...
						</p>
					</div>
					<div className="flex flex-col ml-[46px] w-[480px]">
						<p className="text-[15px] leading-[22.5px] text-[#222222]">
							Please wait for the content to be uploaded. It may take some time.
						</p>
					</div>
				</div>
				<div className="flex flex-row justify-end items-center mt-[36px] gap-x-[18px] ml-[26rem]">
					<button
						className="w-[8.56rem] h-[2.5rem] flex border items-center justify-center rounded-md text-[#222222] hover:bg-[#E6E9EF] border-[#C5C7D0]"
						onClick={() => {
							onClick();
						}}
					>
						Cancel Upload
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default UploadModal;

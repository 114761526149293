import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const PreviewTagTypes = {
	PREVIEW: "preview",
};

export const previewApiSlice = createApi({
	reducerPath: "api/preview",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().sessionData.token;
			if (token) {
				headers.set("token", token);
			}
			return headers;
		},
	}),
	tagTypes: Object.values(PreviewTagTypes),
	endpoints: (builder) => ({
		sendPreviewDump: builder.mutation({
			query: (initialPost) => ({
				url: "/preview",
				method: "POST",
				body: {
					dump: initialPost.dump,
					date_created: new Date().toISOString().slice(0, 19).replace("T", " "),
				},
			}),
			invalidatesTags: [PreviewTagTypes.PREVIEW],
		}),
	}),
});

export const { useSendPreviewDumpMutation } = previewApiSlice;

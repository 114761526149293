import React from "react";
import { Route, Switch } from "react-router-dom";

import Editor from "./components/editor/Editor";
import EmbedBrowserView from "./components/EmbedBrowserView";
import { EmbedViewContainer } from "./EmbedViewContainer";

export const Embed = () => {
  return (
    <EmbedViewContainer>
      <Switch>
        <Route exact path={`/feature/embeds/sneakpeek`} component={Editor} />
        <Route
          exact
          path={`/feature/embeds/sneakpeek/:id`}
          component={Editor}
        />
        <Route exact path={`/feature/embeds/cyoa`} component={Editor} />
        <Route exact path={`/feature/embeds/cyoa/:id`} component={Editor} />
        <Route exact path={`/feature/embeds/solo`} component={Editor} />
        <Route exact path={`/feature/embeds/solo/:id`} component={Editor} />
        <Route exact path={`/feature/embeds/hero`} component={Editor} />
        <Route exact path={`/feature/embeds/hero/:id`} component={Editor} />
        <Route path={`/feature/embeds`} component={EmbedBrowserView} />
      </Switch>
    </EmbedViewContainer>
  );
};

import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useResetPasswordMutation, useSendResetCodeMutation} from "../../features/api/loginApiSlice";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {toastError, toastLoading} from "../custom-toast/toasts";

export const ResetPassword = () => {
    const randomString = (length, chars) => {
        let result = '';
        for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        return result;
    }

    const [resetCode] = useSendResetCodeMutation();
    const [resetPassword] = useResetPasswordMutation();
    const [code] = useState(randomString(5, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'))
    const [codeSent, setCodeSent] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        }
    }, [success])

    const initialValues = {
        email: "",
    };

    const initialCodeValues = {
        code: "",
    }

    const initialPasswordValues = {
        password: "",
        confirmPassword: "",
    }

    const codeSchema = Yup.object().shape({
        code: Yup.string().required("Reset code is required")
    });

    const logInSchema = Yup.object().shape({
        email: Yup.string().email().required("Email is required"),
    });

    const passwordSchema = Yup.object().shape({
        password: Yup.string()
            .required("Password is required")
            .min(12, "Password is too short - should be more than 11 letters"),
        confirmPassword: Yup.string()
            .required("Password is required")
            .min(12, "Password is too short - should be more than 11 letters"),
    })

    const authenticateEmail = async (values) => {
        const id = toastLoading("Sending Email...");
        const status = await resetCode({email: values.email, code: code}).unwrap();
        if (status.status === "success") {
            setCodeSent(true);
            setEmail(values.email);
            toast.dismiss(id);
        } else {
            toast.dismiss(id);
            if (status.message === "email not found") {
                toastError("Email not found");
            } else {
                toastError("Error sending email");
            }
        }
    }

    const authenticateCode = (values) => {
        if (values.code === code) {
            setAuthenticated(true);
        } else {
            toastError("Reset code not recognised");
        }
    }

    const authenticatePassword = async (values) => {
        if (values.password !== values.confirmPassword) {
            return toastError("Passwords do not match");
        }
        const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        if (values.password.match(regex)) {
            toastLoading("Updating Password");
            const status = await resetPassword({ email: email, password: values.password, code: code }).unwrap();
            if (status.status === "success") {
                toast.dismiss();
                setSuccess(true);
            } else {
                toast.dismiss();
                return toastError("Error updating password");
            }
        } else {
            return toastError("Password too easy");
        }
    }

    const ResetEmail = () => {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={logInSchema}
                onSubmit={(values) => authenticateEmail(values)}
            >
                {(formik) => {
                    const {isValid, dirty} = formik;
                    const classSubmit =
                        "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
                    return (
                        <div className="w-full max-w-xs mx-auto">
                            <Form className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div className="flex my-4 text-lg justify-center items-center">
                                    Let's get you into your account
                                </div>
                                <div className="flex my-4 text-md items-center">
                                    Please enter your email to get started:
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="email"
                                    >
                                        Email
                                    </label>
                                    <Field
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="email"
                                        type="email"
                                        name="email"
                                        placeholder="Enter email"
                                    />
                                    <ErrorMessage
                                        name="email"
                                        component="span"
                                        className="text-red-500 text-xs italic"
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <button
                                        className={
                                            dirty && isValid
                                                ? classSubmit
                                                : `bg-gray-300 py-2 px-4 rounded disabled cursor-not-allowed`
                                        }
                                        type="submit"
                                        disabled={!(dirty && isValid)}
                                    >
                                        Send Email
                                    </button>
                                </div>
                            </Form>
                            <p className="text-center font-semibold text-gray-500 text-xs">
                                &copy;{new Date().getFullYear()} Zeros Inc. All rights reserved.
                            </p>
                        </div>
                    );
                }}
            </Formik>
        )
    }

    const ResetCode = () => {
        return (
            <Formik
                initialValues={initialCodeValues}
                validationSchema={codeSchema}
                onSubmit={(values) => authenticateCode(values)}
            >
                {(formik) => {
                    const {isValid, dirty} = formik;
                    const classSubmit =
                        "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
                    return (
                        <div className="w-full max-w-xs mx-auto">
                            <Form className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div className="flex my-4 text-lg justify-center items-center">
                                    A reset code has been sent to your email
                                </div>
                                <div className="flex my-4 text-md items-center">
                                    Please enter reset code:
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="code"
                                    >
                                        Reset Code
                                    </label>
                                    <Field
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="code"
                                        type="code"
                                        name="code"
                                        placeholder="Enter reset code"
                                    />
                                    <ErrorMessage
                                        name="code"
                                        component="span"
                                        className="text-red-500 text-xs italic"
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <button
                                        className={
                                            dirty && isValid
                                                ? classSubmit
                                                : `bg-gray-300 py-2 px-4 rounded disabled cursor-not-allowed`
                                        }
                                        type="submit"
                                        disabled={!(dirty && isValid)}
                                    >
                                        Authenticate
                                    </button>
                                </div>
                            </Form>
                            <p className="text-center font-semibold text-gray-500 text-xs">
                                &copy;{new Date().getFullYear()} Zeros Inc. All rights reserved.
                            </p>
                        </div>
                    );
                }}
            </Formik>
        )
    }

    const ResetPassword = () => {
        return (
            <Formik
                initialValues={initialPasswordValues}
                validationSchema={passwordSchema}
                onSubmit={(values) => authenticatePassword(values)}
            >
                {(formik) => {
                    const {isValid, dirty} = formik;
                    const classSubmit =
                        "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
                    return (
                        <div className="w-full max-w-xs mx-auto">
                            <Form className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                <div className="flex my-4 text-lg justify-center items-center">
                                    Enter your new password
                                </div>
                                <div className="flex mt-4 mb-2 text-md items-center">
                                    The password must obey the following stipulations:
                                </div>
                                <div className="flex mt-2 mb-4 text-sm items-center">
                                    <ul>
                                        <li>
                                            Must have at least 12 characters
                                        </li>
                                        <li>
                                            Must contain at least 1 lowercase character
                                        </li>
                                        <li>
                                            Must contain at least 1 uppercase character
                                        </li>
                                        <li>
                                            Must contain at least 1 numeric digit
                                        </li>
                                        <li>
                                            Must contain at least 1 special character
                                        </li>
                                    </ul>
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="password"
                                    >
                                        Password
                                    </label>
                                    <Field
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="password"
                                        type="password"
                                        name="password"
                                        placeholder="Enter password"
                                    />
                                    <ErrorMessage
                                        name="password"
                                        component="span"
                                        className="text-red-500 text-xs italic"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="confirmPassword"
                                    >
                                        Confirm Password
                                    </label>
                                    <Field
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="confirmPassword"
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                    />
                                    <ErrorMessage
                                        name="confirmPassword"
                                        component="span"
                                        className="text-red-500 text-xs italic"
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <button
                                        className={
                                            dirty && isValid
                                                ? classSubmit
                                                : `bg-gray-300 py-2 px-4 rounded disabled cursor-not-allowed`
                                        }
                                        type="submit"
                                        disabled={!(dirty && isValid)}
                                    >
                                        Update Password
                                    </button>
                                </div>
                            </Form>
                            <p className="text-center font-semibold text-gray-500 text-xs">
                                &copy;{new Date().getFullYear()} Zeros Inc. All rights reserved.
                            </p>
                        </div>
                    );
                }}
            </Formik>
        )
    }

    const ResetSuccess = () => {
        return (
            <div className="w-full max-w-xs mx-auto">
                <div className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="flex flex-grow justify-center items-center">
                        <p className="text-lg">
                            Password updated successfully. <br/> This page will be refreshed in 5 seconds.
                        </p>
                    </div>
                </div>
                <p className="text-center font-semibold text-gray-500 text-xs">
                    &copy;{new Date().getFullYear()} Zeros Inc. All rights reserved.
                </p>
            </div>
        )
    }

    return (
        <div className="h-screen">
            {/*<Navbar/>*/}
            <div style={{height: "80%"}} className="flex justify-center items-center bg-white">
                <div className="flex flex-col flex-grow-1 bg-white space-y-6 justify-center w-1/3">
                    <div className="mt-20 mx-auto">
                        <p className="font-sans text-5xl font-bold text-blue-500">Reset</p>
                    </div>
                    {
                        !codeSent && <ResetEmail/>
                    }
                    {
                        codeSent && !authenticated && <ResetCode/>
                    }
                    {
                        authenticated && !success && <ResetPassword/>
                    }
                    {
                        success && <ResetSuccess/>
                    }
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect, useRef, useCallback } from "react";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Form, Field, ErrorMessage, Formik } from "formik";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useFilePicker } from "use-file-picker";
import PublishModal from "./PublishModal";
import PostPublishModal from "./PostPublishModal";
import PrePublishModal from "./PrePublishModal";
import playLaunchStyleImg from "../../../../../../icons/play-launch-style-img.svg";
import playLaunchStyleMini from "../../../../../../icons/play-launch-style-mini.svg";
import hotspotLaunchStyleImg from "../../../../../../icons/hotspot-launch-style-img.svg";
import closeCrossIcon from "../../../../../../icons/closecross-icon.svg";
import embedPlusCircle from "../../../../../../icons/embed-plus-icon.svg";
import infoIcon from "../../../../../../icons/info-icon.svg";
import previewIcon from "../../../../../../icons/preview-icon.svg";

import arrowupIcon from "../../../../../../icons/arrowup-icon.svg";
import arrowdownIcon from "../../../../../../icons/arrowdown-icon.svg";
import { BlinkersPack } from "../../../../../top-level/blinker-pack/BlinkersPack";
import "../../../../../top-level/blinker-pack/BlinkersPack.scss";
import {
	BLINKER_PACK,
	DEMO,
	EMBED,
	EMBED_CTA_TYPES,
} from "../../../../../../data/constants";
import ReactTooltip from "react-tooltip";
import { MaterialSwitch } from "../../../../../custom/MaterialSwitch";
import { StyledRadio } from "../../../../../custom/StyledRadio";
import { getBackgroundColor } from "../../../../../../utils/avatar";
import { getStateFromCookies } from "redux-cookies-middleware";
import userSettingReducer, {
	setSaveBeforePublishPrompt_Solo,
} from "../../../../../../features/state/user-settings/userSettingSlice";

import { paths } from "../../../../../../features/cookies-paths/paths";
import { useSelector } from "react-redux";
import { useSendPreviewDumpMutation } from "../../../../../../features/api/previewApiSlice";
import usePickerClickOutside from "../../../../../../features/hooks/usePickerClickOutside";
import "../../../../Embed.css";
import { SoloHeroRestrictedRedirectCard } from "./SoloHeroRestrictedRedirectCard";
import { toastError, toastLoading } from "../../../../../custom-toast/toasts";
import { SoloHeroVerificationCard } from "./SoloHeroVerificationCard";
import { Gating } from "./Gating";

export const SoloForm = ({
	isNew,
	data,
	sellerName,
	sellerId,
	recDataList,
	setThumbnailBlob,
	thumbnailBlobUrl,
	setThumbnailBlobUrl,
	setIconBlob,
	iconBlobUrl,
	setIconBlobUrl,
	websiteStyle,
	...formik
}) => {
	const { isValid } = formik;
	const saveBeforePublishPrompt = getStateFromCookies(userSettingReducer, paths)
		.userSetting.saveBeforePublishPrompt_Solo;
	const [openPublishPopUp, setOpenPublishPopUp] = useState(false);
	const [openSaveBeforePublish, setOpenSaveBeforePublish] = useState(false);
	const [openSaveAfterPublish, setOpenSaveAfterPublish] = useState(false);
	const [neverShowPrompt, setNeverShowPrompt] = useState(false);
	const [sendPreviewDump, {}] = useSendPreviewDumpMutation();
	const companyId = useSelector((state) => state.sessionData.companyId);
	const flags = useSelector((state) => state.userSetting.flags);

	const [displayTriangleColorPicker, setDisplayTriangleColorPicker] =
		useState(false);
	const [displayCircleColorPicker, setDisplayCircleColorPicker] =
		useState(false);
	const [displayHoverColorPicker, setDisplayHoverColorPicker] = useState(false);
	const [displayHotspotColorPicker, setDisplayHotspotColorPicker] =
		useState(false);
	const [displayBlinkerStylePicker, setDisplayBlinkerStylePicker] =
		useState(false);
	const [displayPlayStylePicker, setDisplayPlayStylePicker] = useState(false);

	const popoverTrianglePicker = useRef();
	const popoverCirclePicker = useRef();
	const popoverHoverPicker = useRef();
	const popoverHotspotPicker = useRef();
	const popoverBlinkerStylePicker = useRef();
	const popoverPlayStylePicker = useRef();

	const closeTrianglePicker = useCallback(
		() => setDisplayTriangleColorPicker(false),
		[]
	);
	const closeCirclePicker = useCallback(
		() => setDisplayCircleColorPicker(false),
		[]
	);
	const closeHoverPicker = useCallback(
		() => setDisplayHoverColorPicker(false),
		[]
	);
	const closeHotspotPicker = useCallback(
		() => setDisplayHotspotColorPicker(false),
		[]
	);
	const closeBlinkerStylePicker = useCallback(
		() => setDisplayBlinkerStylePicker(false),
		[]
	);
	const closePlayStylePicker = useCallback(
		() => setDisplayPlayStylePicker(false),
		[]
	);

	useEffect(() => {
		(() => formik.validateForm())();
	}, []);

	const [
		openThumbnailFileSelector,
		{
			filesContent: thumbnailFilesContent,
			loading: thumbnailLoading,
			errors: thumbnailErrors,
			clear: thumbnailClear,
		},
	] = useFilePicker({
		multiple: false,
		readAs: "DataURL",
		accept: [".jpg", ".jpeg", ".png"],
		limitFilesConfig: { min: 1, max: 1 },
		// minFileSize: 1, // in megabytes
		// maxFileSize: 1,
		// readFilesContent: false, // ignores file content
	});

	useEffect(() => {
		if (thumbnailErrors.length > 0) {
			toastError("Error uploading thumbnail");
			return;
		}
		if (thumbnailFilesContent.length > 0) {
			if (
				hasExtension(thumbnailFilesContent[0].name, [".jpg", ".jpeg", ".png"])
			) {
				fetch(thumbnailFilesContent[0].content).then((res) => {
					res.blob().then((newBlob) => {
						setThumbnailBlob(newBlob);
						setThumbnailBlobUrl(URL.createObjectURL(newBlob));
						formik.setFieldValue("show_custom_thumbnail", "1");
					});
				});
			} else {
				thumbnailClear();
				return toastError("Invalid file extension");
			}
		}
	}, [thumbnailFilesContent]);

	const [
		openIconFileSelector,
		{
			filesContent: iconFilesContent,
			loading: iconLoading,
			errors: iconErrors,
			clear: iconClear,
		},
	] = useFilePicker({
		multiple: false,
		readAs: "DataURL",
		accept: [".jpg", ".jpeg", ".png", ".svg"],
		limitFilesConfig: { min: 1, max: 1 },
		// minFileSize: 1, // in megabytes
		// maxFileSize: 1,
		// readFilesContent: false, // ignores file content
	});

	useEffect(() => {
		if (iconErrors.length > 0) {
			toastError("Error uploading thumbnail");
			return;
		}
		if (iconFilesContent.length > 0) {
			if (
				hasExtension(iconFilesContent[0].name, [
					".jpg",
					".jpeg",
					".png",
					".svg",
				])
			) {
				fetch(iconFilesContent[0].content).then((res) => {
					res.blob().then((newBlob) => {
						setIconBlob(newBlob);
						setIconBlobUrl(URL.createObjectURL(newBlob));
						formik.setFieldValue("blinker_style", "CUSTOM");
						setDisplayBlinkerStylePicker(false);
						setDisplayPlayStylePicker(false);
					});
				});
			} else {
				iconClear();
				return toastError("Invalid file extension");
			}
		}
	}, [iconFilesContent]);

	const hasExtension = (name, exts) => {
		return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
			name
		);
	};

	const publish = () => {
		formik.setFieldValue("live", "1");
		formik.submitForm();
	};

	const preview = async () => {
		if (formik.values.name === "" || formik.values.demo === "") {
			toastError("Incomplete Data");
			return;
		}
		const selectedRecording = recDataList.find(
			(item) => item.id === +formik.values.demo
		);
		const values = JSON.parse(JSON.stringify(formik.values));
		values.salesrepId = sellerId;
		values.companyId = companyId;
		values.location = selectedRecording.location;
		values.thumbnail = selectedRecording.thumbnail;
		values.preview = selectedRecording.preview;
		values.coordinates = selectedRecording.coordinates;
		values.flags = JSON.parse(flags);
		const toast_id = toastLoading();

		const status = await sendPreviewDump({
			dump: JSON.stringify(values),
		}).unwrap();
		if (status.status === "success") {
			toast.dismiss(toast_id);
			const newLink = `${
				process.env.REACT_APP_CLIENT_PROSPECT
			}/preview/solo?uuid=${uuid()}&data_id=${uuid()}-${
				status.payload
			}&template=${companyId}`;
			window.open(newLink, "_blank").focus();
		} else {
			toast.dismiss(toast_id);
			toastError("Unable to preview widget");
		}
	};

	const trianglePickerClickHandler = () => {
		setDisplayTriangleColorPicker((state) => !state);
	};
	const circlePickerClickHandler = () => {
		setDisplayCircleColorPicker((state) => !state);
	};
	const hoverPickerClickHandler = () => {
		setDisplayHoverColorPicker((state) => !state);
	};
	const hotspotPickerClickHandler = () => {
		setDisplayHotspotColorPicker((state) => !state);
	};
	const blinkerStylePickerClickHandler = () => {
		setDisplayBlinkerStylePicker((state) => !state);
	};
	const playStylePickerClickHandler = () => {
		setDisplayPlayStylePicker((state) => !state);
	};

	usePickerClickOutside(popoverTrianglePicker, closeTrianglePicker);
	usePickerClickOutside(popoverCirclePicker, closeCirclePicker);
	usePickerClickOutside(popoverHoverPicker, closeHoverPicker);
	usePickerClickOutside(popoverHotspotPicker, closeHotspotPicker);
	usePickerClickOutside(popoverBlinkerStylePicker, closeBlinkerStylePicker);
	usePickerClickOutside(popoverPlayStylePicker, closePlayStylePicker);

	const classSubmit =
		"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
	return (
		<div className="w-[69rem]">
			<Form className="bg-white pb-8 mb-4 w-full">
				<div className="sticky top-0 flex flex-row justify-between py-4 bg-white z-50">
					<div className="flex flex-col w-3/5 mb-4">
						<Field
							className="px-1.5 py-0.5 font-normal leading-5 text-xl font-medium text-[#222222] border border-white hover:border hover:rounded-sm hover:border-zeros-blue focus:rounded-sm focus:border-zeros-blue"
							id="name"
							type="input"
							name="name"
							placeholder={`Title for this ${EMBED.toLowerCase()}`}
						/>
						<ErrorMessage
							name="name"
							component="span"
							className="text-red-500 text-xs italic"
						/>

						{!isNew ? (
							<div className="flex flex-row mx-3 mt-1 gap-x-2 items-center">
								<div className="flex justify-center items-center gap-x-2">
									<div
										className="flex justify-center items-center w-6 h-6 rounded-full text-white"
										style={{
											backgroundColor: getBackgroundColor(
												data.payload[0].salesrep_id
											),
										}}
									>
										{data.payload[0].salesrep_name.trim()[0]}
									</div>
									<p className="text-base font-normal text-[#888888]">
										{data.payload[0].salesrep_name.trim()}
									</p>
								</div>
								<div className="w-1 h-1 rounded-full bg-[#686868]" />
								<p className="text-base font-normal text-[#888888]">
									{new Date(data.payload[0].date_created).toLocaleDateString(
										"en-us",
										{
											year: "numeric",
											month: "long",
											day: "numeric",
										}
									)}
								</p>
							</div>
						) : (
							<div className="flex flex-row mx-3 mt-1 gap-x-2 items-center">
								<div className="flex justify-center items-center gap-x-2">
									<div
										className="flex justify-center items-center w-6 h-6 rounded-full text-white"
										style={{
											backgroundColor: getBackgroundColor(sellerId),
										}}
									>
										{sellerName.trim()[0]}
									</div>
									<p className="text-base font-normal text-[#888888]">
										{sellerName.trim()}
									</p>
								</div>
								<div className="w-1 h-1 rounded-full bg-[#686868]" />
								<p className="text-base font-normal text-[#888888]">
									{new Date().toLocaleDateString("en-us", {
										year: "numeric",
										month: "long",
										day: "numeric",
									})}
								</p>
							</div>
						)}
					</div>
					<div className="flex gap-x-[18px]">
						<button
							className={
								!isNew
									? `border w-[44px] h-[44px] rounded-md mt-1 font-medium bg-[#FFFFFF] hover:bg-[#E6E9EF] flex items-center justify-center`
									: `border w-[44px] h-[44px] rounded-md mt-1 font-medium bg-[#E8E8E8] flex items-center justify-center disabled cursor-not-allowed`
							}
							type="button"
							onClick={() => preview()}
							disabled={isNew}
						>
							<img
								src={previewIcon}
								alt="preview"
								className="w-[24px] h-[24px]"
							/>
						</button>
						{formik.values.live === "0" ? (
							<button
								className={
									(formik.values.live === "0" || isNew) && isValid
										? `border border-[#63A4FA] w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-[#1D60B5] bg-[#FFFFFF] hover:bg-[#CCE5FF]`
										: `border w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium bg-[#E8E8E8] text-[#6F6F6F] rounded disabled cursor-not-allowed`
								}
								onClick={() => setOpenPublishPopUp(true)}
								type="button"
								disabled={!isValid || formik.values.live === "1"}
							>
								Publish
							</button>
						) : (
							<button
								className={`w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 bg-[#E8E8E8] disabled cursor-not-allowed`}
								// onClick={() => publish()}
								type="button"
								disabled={true}
							>
								<div className="flex flex-row justify-center items-center gap-x-[8px]">
									<div className="w-[14px] h-[14px] bg-[#4CB051] rounded-full" />
									<p className="text-[16px] leading-[16px] text-[#6F6F6F]">
										Published
									</p>
								</div>
							</button>
						)}
						<button
							className={
								isValid
									? `border w-[4.75rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA]`
									: `border w-[4.75rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium bg-[#E8E8E8] text-[#6F6F6F] rounded disabled cursor-not-allowed`
							}
							type="button"
							onClick={() => {
								formik.values.live === "1"
									? setOpenSaveAfterPublish(true)
									: saveBeforePublishPrompt ||
									  saveBeforePublishPrompt === undefined
									? setOpenSaveBeforePublish(true)
									: formik.submitForm();
							}}
							disabled={!isValid}
						>
							Save
						</button>
					</div>
				</div>
				<Tabs>
					<TabList className="flex items-center w-full">
						<Tab
							className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
							selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
						>
							Content
						</Tab>
						<Tab
							className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
							selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
						>
							Settings
						</Tab>
					</TabList>
					<TabPanel>
						<div className="flex flex-col">
							<div className="flex flex-row gap-x-24 justify-start items-center m-12">
								<label className="text-base font-medium" htmlFor="demo">
									{`${DEMO}`}
								</label>
								<Field
									className="p-0 pl-2 w-[19.81rem] h-[2.38rem] border border-[#C5C7D0] rounded"
									id="demo"
									as="select"
									name="demo"
									placeholder="Select a demo"
								>
									<option disabled value="">
										Select a {`${DEMO.toLowerCase()}`}
									</option>
									{recDataList.map((it) => (
										<option key={it.id} value={it.id} className="truncate">
											{it.title}
										</option>
									))}
								</Field>
								<ErrorMessage
									name="demo"
									component="span"
									className="text-red-500 text-xs italic"
								/>
							</div>
							<div
								className={
									formik.values.is_gated === "1"
										? "flex flex-col mt-[2rem]"
										: "hidden"
								}
							>
								<SoloHeroVerificationCard
									verificationContent={formik.values.verification_content}
									setVerificationContent={formik.setFieldValue}
								/>
							</div>
							<div
								className={
									formik.values.redirect_to_restricted_domain === "1" &&
									formik.values.is_gated === "1"
										? ""
										: "hidden"
								}
							>
								<SoloHeroRestrictedRedirectCard
									restricted_redirect={formik.values.restricted_redirect}
									setFormikValue={formik.setFieldValue}
									demoOptions={recDataList.map((it, index) => {
										return {
											key: index,
											value: it.id,
											label: it.title,
											type: "demo",
											coordinates: it.coordinates,
											thumbnail: it.thumbnail,
											location: it.location,
											preview: it.preview,
										};
									})}
								/>
							</div>
						</div>
					</TabPanel>
					<TabPanel>
						<div className="flex flex-col justify-start">
							<ReactTooltip
								effect="solid"
								place="right"
								multiline={true}
								className="max-w-[350px] font-inter text-xs leading-[22px]"
							/>
							<div className="mt-6 ml-6">
								<p className="text-base font-medium text-[#222222]">General</p>
								<div className="flex flex-col my-6 mx-5 gap-y-5">
									<div className="flex items-center gap-x-4">
										<MaterialSwitch
											color="primary"
											className="mx-2"
											id="is_muted"
											checked={formik.values.is_muted === "1"}
											name="is_muted"
											onChange={(event, checked) => {
												formik.setFieldValue("is_muted", checked ? "1" : "0");
											}}
										/>
										<div className="flex gap-x-2">
											<label
												className="text-sm font-normal text-[#222222]"
												htmlFor="is_muted"
											>
												{`${DEMO}`} is muted upon launch
											</label>
											<img
												src={infoIcon}
												alt="info"
												data-tip="The audio is muted at the time of launch. The user can turn it on if the video player controls are visible. You can toggle the setting below."
											/>
										</div>
									</div>
									<Gating formik={formik} />
									<div className="flex items-center gap-x-4">
										<MaterialSwitch
											color="primary"
											className="mx-2"
											id="show_controls"
											checked={formik.values.show_controls === "1"}
											name="show_controls"
											onChange={(event, checked) => {
												formik.setFieldValue(
													"show_controls",
													checked ? "1" : "0"
												);
											}}
										/>
										<div className="flex gap-x-2">
											<label
												className="text-sm font-normal text-[#222222]"
												htmlFor="show_controls"
											>
												Show video player controls
											</label>
											<img
												src={infoIcon}
												alt="info"
												data-tip="When toggled on, the video player controls will be visible at the time of launch."
											/>
										</div>
									</div>
								</div>
							</div>
							<hr className="border border-[#d9d9d9]" />
							<div className="mt-6 ml-6">
								<p className="text-base font-medium text-[#222222]">
									Teaser style
								</p>
								<div className="flex flex-col my-6 mx-5 gap-y-5">
									<div className="flex items-center gap-x-4">
										<StyledRadio
											onClick={() => {
												formik.setFieldValue("is_sneak", "0");
												formik.setFieldValue("is_cta", "0");
												formik.setFieldValue("show_preview", "0");
											}}
											checked={
												formik.values.is_sneak === "0" &&
												formik.values.is_cta === "0" &&
												formik.values.show_preview === "0"
											}
											id="show_preview"
											name="show_preview"
											value="0"
										/>
										<div className="flex flex-col gap-y-1 w-[17.31rem]">
											<p className="font-normal text-sm text-[#222222]">
												Thumbnail
											</p>
											<p className="font-normal text-[#666666] text-[13px]">
												A static image represents this embed. The preview will
												only show it after it has been saved.
											</p>
										</div>
									</div>
									{formik.values.show_preview === "0" &&
										formik.values.is_sneak === "0" &&
										formik.values.is_cta === "0" && (
											<div className="flex flex-row mx-14 gap-x-10">
												<div className="flex flex-col justify-center items-center">
													{formik.values.demo ? (
														<div
															className={
																formik.values.show_custom_thumbnail === "1"
																	? "w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
																	: "w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
															}
															onClick={() => {
																formik.setFieldValue(
																	"show_custom_thumbnail",
																	"0"
																);
															}}
														>
															<img
																className="w-full h-full object-cover rounded-xl"
																src={`${process.env.REACT_APP_CDN}${
																	recDataList.find(
																		(demo) => demo.id === +formik.values.demo
																	)?.thumbnail
																}`}
																alt="Default thumbnail"
															/>
														</div>
													) : (
														<div
															className={
																formik.values.show_custom_thumbnail === "1"
																	? "w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
																	: "w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
															}
															onClick={() => {
																formik.setFieldValue(
																	"show_custom_thumbnail",
																	"0"
																);
															}}
														></div>
													)}
													<div className="flex flex-row gap-x-1 justify-center items-center">
														<StyledRadio
															onClick={() => {
																formik.setFieldValue(
																	"show_custom_thumbnail",
																	"0"
																);
															}}
															checked={
																formik.values.show_custom_thumbnail === "0"
															}
															id="show_custom_thumbnail"
															name="show_custom_thumbnail"
														/>
														<p className="font-normal text-sm text-[#222222]">
															Default
														</p>
													</div>
												</div>
												<div className="flex flex-col justify-centeer items-center">
													{thumbnailBlobUrl ? (
														<div
															className={
																formik.values.show_custom_thumbnail === "0"
																	? "relative w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
																	: "relative w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
															}
															onClick={() => {
																formik.setFieldValue(
																	"show_custom_thumbnail",
																	"1"
																);
															}}
														>
															<img
																className="relative w-full h-full object-cover rounded-xl"
																src={thumbnailBlobUrl}
																alt="Default thumbnail"
															/>
															<img
																className="absolute -right-4 -top-4 w-[34px] h-[34px]"
																src={closeCrossIcon}
																alt="close"
																onClick={(e) => {
																	e.stopPropagation();
																	formik.setFieldValue(
																		"show_custom_thumbnail",
																		"0"
																	);
																	formik.setFieldValue("custom_thumbnail", "");
																	setThumbnailBlobUrl(undefined);
																}}
															/>
														</div>
													) : formik.values.custom_thumbnail ? (
														<div
															className={
																formik.values.show_custom_thumbnail === "0"
																	? "relative w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
																	: "relative w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
															}
															onClick={() => {
																formik.setFieldValue(
																	"show_custom_thumbnail",
																	"1"
																);
															}}
														>
															<img
																className="relative w-full h-full object-cover rounded-xl"
																src={`${process.env.REACT_APP_CDN}${formik.values.custom_thumbnail}`}
																alt="Custom thumbnail"
															/>
															<img
																className="absolute -right-4 -top-4 w-[34px] h-[34px]"
																src={closeCrossIcon}
																alt="close"
																onClick={(e) => {
																	e.stopPropagation();
																	formik.setFieldValue(
																		"show_custom_thumbnail",
																		"0"
																	);
																	formik.setFieldValue("custom_thumbnail", "");
																}}
															/>
														</div>
													) : (
														<div
															className={
																formik.values.show_custom_thumbnail === "0"
																	? "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
																	: "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
															}
															onClick={() => {
																openThumbnailFileSelector();
															}}
														>
															<img
																className="w-[36px] h-[36px]"
																src={embedPlusCircle}
																alt="No thumbnail"
															/>
															<p className="text-center text-[#514C4C] text-xs mx-4 mt-2">
																(Try to match the thumbnail to the video's
																dimensions to avoid cropping)
															</p>
														</div>
													)}
													<div className="flex flex-row gap-x-1 justify-center items-center">
														<StyledRadio
															onClick={() => {
																if (
																	!thumbnailBlobUrl &&
																	formik.values.custom_thumbnail === ""
																) {
																	openThumbnailFileSelector();
																} else {
																	formik.setFieldValue(
																		"show_custom_thumbnail",
																		"1"
																	);
																}
															}}
															checked={
																formik.values.show_custom_thumbnail === "1"
															}
															id="show_custom_thumbnail"
															name="show_custom_thumbnail"
														/>
														<p className="font-normal text-sm text-[#222222]">
															Custom
														</p>
													</div>
												</div>
											</div>
										)}
									{recDataList.find((demo) => demo.id === +formik.values.demo)
										?.type === "default" && (
										<div className="flex items-center gap-x-4">
											<StyledRadio
												onClick={() => {
													formik.setFieldValue("is_sneak", "0");
													formik.setFieldValue("is_cta", "0");
													formik.setFieldValue("show_preview", "1");
												}}
												checked={
													formik.values.is_sneak === "0" &&
													formik.values.is_cta === "0" &&
													formik.values.show_preview === "1"
												}
												id="show_preview"
												name="show_preview"
												value="1"
											/>

											<div className="flex flex-col gap-y-1 w-[17.31rem]">
												<p className="font-normal text-sm text-[#222222]">
													Animation
												</p>
												<p className="font-normal text-[#666666] text-[13px]">
													A short animation represents the embed. Check the
													preview to see how it looks.
												</p>
											</div>
										</div>
									)}
									<div className="flex items-center gap-x-4">
										<StyledRadio
											onClick={() => {
												formik.setFieldValue("show_preview", "0");
												formik.setFieldValue("is_sneak", "1");
												formik.setFieldValue("is_cta", "0");
											}}
											checked={
												formik.values.show_preview === "0" &&
												formik.values.is_cta === "0" &&
												formik.values.is_sneak === "1"
											}
											id="is_sneak"
											name="is_sneak"
										/>
										<div className="flex flex-col gap-y-1 w-[17.31rem]">
											<p className="font-normal text-sm text-[#222222]">
												Hotspot
											</p>
											<p className="font-normal text-[#666666] text-[13px]">
												There is no image or animation, just a blinking hotspot
												that launches the player upon click.
											</p>
										</div>
									</div>
									<div className="flex items-center gap-x-4">
										<StyledRadio
											onClick={() => {
												formik.setFieldValue("show_preview", "0");
												formik.setFieldValue("is_sneak", "0");
												formik.setFieldValue("is_cta", "1");
											}}
											checked={
												formik.values.show_preview === "0" &&
												formik.values.is_sneak === "0" &&
												formik.values.is_cta === "1"
											}
											id="is_cta"
											name="is_cta"
										/>
										<div className="flex flex-col gap-y-1 w-[17.31rem]">
											<p className="font-normal text-sm text-[#222222]">CTA</p>
											<p className="font-normal text-[#666666] text-[13px]">
												There is no image or animation, just a CTA that launches
												the embed upon click. Styling comes from global
												settings.
											</p>
										</div>
									</div>
									{formik.values.show_preview === "0" &&
										formik.values.is_sneak === "0" &&
										formik.values.is_cta === "1" && (
											<>
												<div className="flex mx-14 gap-x-2 items-center">
													<Field
														className="p-0 pl-2 w-[17.31rem] h-[2.25rem] border border-[#C5C7D0] rounded"
														id="cta_text"
														type="input"
														name="cta_text"
														placeholder="Enter text for this CTA"
													/>
													<ErrorMessage
														className="mx-1 text-xs text-red-800"
														name="cta_text"
														component="div"
													/>
												</div>
												<div className="flex flex-row mx-14 gap-x-10">
													<div className="flex flex-col justify-center items-center">
														<div
															className={
																formik.values.cta_type !== EMBED_CTA_TYPES.LINK
																	? "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
																	: "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
															}
															onClick={() => {
																formik.setFieldValue(
																	"cta_type",
																	EMBED_CTA_TYPES.LINK
																);
															}}
														>
															<p
																className="text-center mx-4 mt-2"
																style={{
																	font: "initial",
																	// fontFamily: "Nunito Sans",
																	padding: "10px 24px",
																	fontStyle: "normal",
																	fontWeight: 700,
																	fontSize: "20px",
																	// lineHeight: "160%",
																	minWidth: "115px",
																	// height: "50px",
																	justifyContent: "right",
																	backgroundColor: "#FFFFFF",
																	color: websiteStyle.buttonBgColor,
																	cursor: "pointer",
																	borderRadius: "4px",
																	border: "none",
																}}
															>
																{formik.values.cta_text}
															</p>
														</div>
														<div className="flex flex-row gap-x-1 justify-center items-center">
															<StyledRadio
																onClick={() => {
																	formik.setFieldValue(
																		"cta_type",
																		EMBED_CTA_TYPES.LINK
																	);
																}}
																checked={
																	formik.values.cta_type ===
																	EMBED_CTA_TYPES.LINK
																}
																id="cta_type"
																name="cta_type"
															/>
															<p className="font-normal text-sm text-[#222222]">
																Link
															</p>
														</div>
													</div>
													<div className="flex flex-col justify-centeer items-center">
														<div
															className={
																formik.values.cta_type !==
																EMBED_CTA_TYPES.BUTTON
																	? "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
																	: "relative flex flex-col justify-center items-center w-[234px] h-[132px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
															}
															onClick={() => {
																formik.setFieldValue(
																	"cta_type",
																	EMBED_CTA_TYPES.BUTTON
																);
															}}
														>
															<p
																className={`text-center mx-4 mt-2 hover:bg-[${websiteStyle.buttonBgHoverColor}]`}
																style={{
																	font: "initial",
																	fontFamily: "Nunito Sans",
																	padding: "10px 24px",
																	fontStyle: "normal",
																	fontWeight: 700,
																	fontSize: "20px",
																	// lineHeight: "160%",
																	minWidth: "115px",
																	// height: "50px",
																	justifyContent: "right",
																	backgroundColor: websiteStyle.buttonBgColor,
																	color: websiteStyle.bgColor,
																	cursor: "pointer",
																	borderRadius: "4px",
																	border: "none",
																}}
															>
																{formik.values.cta_text}
															</p>
														</div>
														<div className="flex flex-row gap-x-1 justify-center items-center">
															<StyledRadio
																onClick={() => {
																	formik.setFieldValue(
																		"cta_type",
																		EMBED_CTA_TYPES.BUTTON
																	);
																}}
																checked={
																	formik.values.cta_type ===
																	EMBED_CTA_TYPES.BUTTON
																}
																id="cta_type"
																name="cta_type"
															/>
															<p className="font-normal text-sm text-[#222222]">
																Button
															</p>
														</div>
													</div>
												</div>
											</>
										)}
								</div>
							</div>
							<hr className="border border-[#d9d9d9]" />
							{formik.values.is_cta !== "1" && (
								<div className="mt-6 ml-6">
									<p className="text-base font-medium text-[#222222]">
										Launch button
									</p>
									<div className="my-6 mx-5">
										<p className="text-sm font-medium text-[#222222]">Style</p>
										<div className="flex flex-row justify-start items-center my-4 mx-12 gap-x-10">
											<button
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setDisplayBlinkerStylePicker(false);
													playStylePickerClickHandler();
												}}
												className={
													formik.values.blinker_style === "PLAY"
														? "relative flex justify-center items-center border-2 border-[#1A73E8] w-[110px] h-[70px] rounded-xl"
														: "relative flex justify-center items-center border border-[#d9d9d9] w-[110px] h-[70px] rounded-xl"
												}
											>
												<div
													className="flex justify-center items-center gap-x-3"
													ref={popoverPlayStylePicker}
												>
													<div className="relative">
														<img
															className={
																formik.values.blinker_size === "SMALL"
																	? ""
																	: "hidden"
															}
															src={playLaunchStyleMini}
															alt="play button"
														/>
														<img
															className={
																formik.values.blinker_size === "LARGE"
																	? ""
																	: "hidden"
															}
															src={playLaunchStyleImg}
															alt="play button"
														/>
													</div>
													<div>
														{displayPlayStylePicker ? (
															<img
																className="relative svg-embed-icon-gray"
																src={arrowupIcon}
																alt="up"
															/>
														) : (
															<img
																className="relative svg-embed-icon-gray"
																src={arrowdownIcon}
																alt="down"
															/>
														)}
													</div>
												</div>
												<div>
													{displayPlayStylePicker && (
														<div className="flex flex-wrap w-[286px] h-[100px] p-2 absolute left-0 mt-3 z-10 border rounded-lg bg-white shadow-md">
															<button
																className={
																	"small" ===
																	formik.values.blinker_size.toLowerCase()
																		? "flex items-center justify-center p-3 w-1/2 border border-1 border-[#D9D9D9] rounded bg-[#FAFAFA]"
																		: "flex items-center justify-center p-3 w-1/2 border border-1 border-white rounded hover:border hover:border-[#D9D9D9]"
																}
																onClick={() => {
																	formik.setFieldValue("blinker_size", "SMALL");
																	formik.setFieldValue("blinker_style", "PLAY");
																}}
															>
																<div className="flex items-center justify-start gap-x-3">
																	<p className="text-sm whitespace-nowrap">
																		Small
																	</p>
																	<img
																		src={playLaunchStyleMini}
																		alt="play button"
																	/>
																</div>
															</button>
															<button
																className={
																	"large" ===
																	formik.values.blinker_size.toLowerCase()
																		? "flex items-center justify-center p-3 w-1/2 border border-1 border-[#D9D9D9] rounded bg-[#FAFAFA]"
																		: "flex items-center justify-center p-3 w-1/2 border border-1 border-white rounded hover:border hover:border-[#D9D9D9]"
																}
																onClick={() => {
																	formik.setFieldValue("blinker_size", "LARGE");
																	formik.setFieldValue("blinker_style", "PLAY");
																}}
															>
																<div className="flex items-center justify-start gap-x-3">
																	<p className="text-sm whitespace-nowrap">
																		Large
																	</p>
																	<img
																		src={playLaunchStyleImg}
																		alt="play button"
																	/>
																</div>
															</button>
														</div>
													)}
												</div>
											</button>
											<div className="flex flex-col justify-centeer items-center">
												{iconBlobUrl ? (
													<div
														className={
															formik.values.blinker_style === "CUSTOM"
																? "relative flex justify-center items-center border-2 hover:cursor-pointer border-[#1A73E8] w-[110px] h-[70px] rounded-xl"
																: "relative flex justify-center items-center border hover:cursor-pointer border-[#d9d9d9] w-[110px] h-[70px] rounded-xl"
														}
														onClick={(e) => {
															formik.setFieldValue("blinker_style", "CUSTOM");
															e.preventDefault();
															e.stopPropagation();
															setDisplayBlinkerStylePicker(false);
															setDisplayPlayStylePicker(false);
														}}
													>
														<img
															className="relative object-contain rounded-xl w-[32px] h-[32px]"
															src={iconBlobUrl}
															alt="Custom icon"
														/>
														<img
															className="absolute -right-4 -top-4 w-[34px] h-[34px]"
															src={closeCrossIcon}
															alt="close"
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																setDisplayBlinkerStylePicker(false);
																setDisplayPlayStylePicker(false);
																formik.setFieldValue("blinker_style", "PLAY");
																formik.setFieldValue("custom_blinker", "");
																setIconBlobUrl(undefined);
															}}
														/>
													</div>
												) : formik.values.custom_blinker ? (
													<div
														className={
															formik.values.blinker_style !== "CUSTOM"
																? "relative flex justify-center items-center border hover:cursor-pointer border-[#d9d9d9] w-[110px] h-[70px] rounded-xl"
																: "relative flex justify-center items-center border-2 hover:cursor-pointer border-[#1A73E8] w-[110px] h-[70px] rounded-xl"
														}
														onClick={() => {
															formik.setFieldValue("blinker_style", "CUSTOM");
														}}
													>
														<img
															className="relative object-contain rounded-xl w-[32px] h-[32px]"
															src={`${process.env.REACT_APP_CDN}${formik.values.custom_blinker}`}
															alt="Custom blinker"
														/>
														<img
															className="absolute -right-4 -top-4 w-[34px] h-[34px]"
															src={closeCrossIcon}
															alt="close"
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																setDisplayBlinkerStylePicker(false);
																setDisplayPlayStylePicker(false);
																formik.setFieldValue("blinker", "PLAY");
																formik.setFieldValue("custom_blinker", "");
															}}
														/>
													</div>
												) : (
													<div
														className={
															formik.values.blinker_style !== "CUSTOM"
																? "relative flex flex-col justify-center items-center w-[110px] h-[70px] hover:cursor-pointer border border-[#d9d9d9] rounded-xl"
																: "relative flex flex-col justify-center items-center w-[110px] h-[70px] hover:cursor-pointer border-2 border-[#1A73E8] rounded-xl"
														}
														onClick={() => {
															openIconFileSelector();
														}}
													>
														<img
															className="w-[32px] h-[32px]"
															src={embedPlusCircle}
															alt="No icon"
														/>
														<p className="text-center text-[#514C4C] text-xs mx-4 mt-2">
															(Add icon)
														</p>
													</div>
												)}
											</div>
											<button
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setDisplayPlayStylePicker(false);
													blinkerStylePickerClickHandler();
												}}
												className={
													formik.values.blinker_style !== "PLAY" &&
													formik.values.blinker_style !== "CUSTOM"
														? "relative px-3 border-2 border-[#1A73E8] w-[110px] h-[70px] rounded-xl"
														: "relative px-3 border border-[#d9d9d9] w-[110px] h-[70px] rounded-xl"
												}
											>
												<div
													className="flex justify-center items-center gap-x-3"
													ref={popoverBlinkerStylePicker}
												>
													<div
														className={`relative ${
															formik.values.blinker_style !== "PLAY" &&
															formik.values.blinker_style !== "CUSTOM"
																? BLINKER_PACK[
																		formik.values.blinker_style.toUpperCase()
																  ]
																: BLINKER_PACK["DARK"]
														} ${
															formik.values.is_sneak === "0"
																? formik.values.blinker_style.toUpperCase() ===
																  "SLACK"
																	? "scale-1"
																	: "scale-150"
																: formik.values.blinker_style.toUpperCase() ===
																  "SLACK"
																? "scale-[55%]"
																: "scale-75"
														}`}
													/>
													<div>
														{displayBlinkerStylePicker ? (
															<img
																className="relative svg-embed-icon-gray"
																src={arrowupIcon}
																alt="up"
															/>
														) : (
															<img
																className="relative svg-embed-icon-gray"
																src={arrowdownIcon}
																alt="down"
															/>
														)}
													</div>
												</div>
												{displayBlinkerStylePicker && (
													<BlinkersPack
														selected={formik.values.blinker_style}
														onClick={(e, type) => {
															formik.setFieldValue("blinker_style", type);
														}}
													/>
												)}
											</button>
										</div>
									</div>
									{formik.values.blinker_style !== "CUSTOM" && (
										<div className="my-6 mx-5">
											<p className="text-sm font-medium text-[#222222]">
												Colors
											</p>
											{formik.values.blinker_style === "PLAY" ? (
												<div className="flex justify-start items-center my-4 mx-12 gap-x-5">
													<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
														<p className="text-sm font-medium text-[#222222]">
															Circle - Default
														</p>
														<button
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																setDisplayTriangleColorPicker(false);
																setDisplayHoverColorPicker(false);
																circlePickerClickHandler();
															}}
															className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D]"
														>
															<div className="flex justify-between items-center h-[1.88rem]">
																<p className="mx-3 text-sm font-normal text-[#222222]">
																	{formik.values.launch_circle_color}
																</p>
																<div className="mx-1">
																	<div className="border rounded">
																		<div
																			className="w-[1.06rem] h-[1.06rem]"
																			style={{
																				background:
																					formik.values.launch_circle_color,
																			}}
																		/>
																	</div>
																	{displayCircleColorPicker && (
																		<div
																			className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
																			ref={popoverCirclePicker}
																			onClick={(e) => {
																				e.preventDefault();
																				e.stopPropagation();
																			}}
																		>
																			<HexColorPicker
																				color={
																					formik.values.launch_circle_color
																				}
																				onChange={(color) => {
																					formik.setFieldValue(
																						"launch_circle_color",
																						color
																					);
																				}}
																			/>
																			<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																				<label>HEX</label>
																				<HexColorInput
																					className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																					color={
																						formik.values.launch_circle_color
																					}
																					onChange={(color) => {
																						formik.setFieldValue(
																							"launch_circle_color",
																							color
																						);
																					}}
																					placeholder="Type hex value of color"
																					prefixed
																					alpha={false}
																				/>
																			</div>
																		</div>
																	)}
																</div>
															</div>
														</button>
													</div>
													<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
														<p className="text-sm font-medium text-[#222222]">
															Circle - Hover
														</p>
														<button
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																setDisplayTriangleColorPicker(false);
																setDisplayCircleColorPicker(false);
																hoverPickerClickHandler();
															}}
															className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D]"
														>
															<div className="flex justify-between items-center h-[1.88rem]">
																<p className="mx-3 text-sm font-normal text-[#222222]">
																	{formik.values.launch_hover_color}
																</p>
																<div className="mx-1">
																	<div className="border rounded">
																		<div
																			className="w-[1.06rem] h-[1.06rem]"
																			style={{
																				background:
																					formik.values.launch_hover_color,
																			}}
																		/>
																	</div>
																	{displayHoverColorPicker && (
																		<div
																			className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
																			ref={popoverHoverPicker}
																			onClick={(e) => {
																				e.preventDefault();
																				e.stopPropagation();
																			}}
																		>
																			<HexColorPicker
																				color={formik.values.launch_hover_color}
																				onChange={(color) => {
																					formik.setFieldValue(
																						"launch_hover_color",
																						color
																					);
																				}}
																			/>
																			<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																				<label>HEX</label>
																				<HexColorInput
																					className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																					color={
																						formik.values.launch_hover_color
																					}
																					onChange={(color) => {
																						formik.setFieldValue(
																							"launch_hover_color",
																							color
																						);
																					}}
																					placeholder="Type hex value of color"
																					prefixed
																					alpha={false}
																				/>
																			</div>
																		</div>
																	)}
																</div>
															</div>
														</button>
													</div>
													<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
														<p className="text-sm font-medium text-[#222222]">
															Triangle
														</p>
														<button
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																setDisplayCircleColorPicker(false);
																setDisplayHoverColorPicker(false);
																trianglePickerClickHandler();
															}}
															className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D]"
														>
															<div className="flex justify-between items-center h-[1.88rem]">
																<p className="mx-3 text-sm font-normal text-[#222222]">
																	{formik.values.launch_triangle_color}
																</p>
																<div className="mx-1">
																	<div className="border rounded">
																		<div
																			className="w-[1.06rem] h-[1.06rem]"
																			style={{
																				background:
																					formik.values.launch_triangle_color,
																			}}
																		/>
																	</div>
																	{displayTriangleColorPicker && (
																		<div
																			className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
																			ref={popoverTrianglePicker}
																			onClick={(e) => {
																				e.preventDefault();
																				e.stopPropagation();
																			}}
																		>
																			<HexColorPicker
																				color={
																					formik.values.launch_triangle_color
																				}
																				onChange={(color) => {
																					formik.setFieldValue(
																						"launch_triangle_color",
																						color
																					);
																				}}
																			/>
																			<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																				<label>HEX</label>
																				<HexColorInput
																					className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																					color={
																						formik.values.launch_triangle_color
																					}
																					onChange={(color) => {
																						formik.setFieldValue(
																							"launch_triangle_color",
																							color
																						);
																					}}
																					placeholder="Type hex value of color"
																					prefixed
																					alpha={false}
																				/>
																			</div>
																		</div>
																	)}
																</div>
															</div>
														</button>
													</div>
												</div>
											) : (
												<div className="flex justify-start items-center my-4 mx-12 gap-x-5">
													<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
														<p className="text-sm font-medium text-[#222222]">
															Hotspot color
														</p>
														<button
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																hotspotPickerClickHandler();
															}}
															className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D]"
														>
															<div className="flex justify-between items-center h-[1.88rem]">
																<p className="mx-3 text-sm font-normal text-[#222222]">
																	{formik.values.launch_hotspot_color}
																</p>
																<div className="mx-1">
																	<div className="border rounded">
																		<div
																			className="w-[1.06rem] h-[1.06rem]"
																			style={{
																				background:
																					formik.values.launch_hotspot_color,
																			}}
																		/>
																	</div>
																	{displayHotspotColorPicker && (
																		<div
																			className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
																			ref={popoverHotspotPicker}
																			onClick={(e) => {
																				e.preventDefault();
																				e.stopPropagation();
																			}}
																		>
																			<HexColorPicker
																				color={
																					formik.values.launch_hotspot_color
																				}
																				onChange={(color) => {
																					formik.setFieldValue(
																						"launch_hotspot_color",
																						color
																					);
																				}}
																			/>
																			<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																				<label>HEX</label>
																				<HexColorInput
																					className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																					color={
																						formik.values.launch_hotspot_color
																					}
																					onChange={(color) => {
																						formik.setFieldValue(
																							"launch_hotspot_color",
																							color
																						);
																					}}
																					placeholder="Type hex value of color"
																					prefixed
																					alpha={false}
																				/>
																			</div>
																		</div>
																	)}
																</div>
															</div>
														</button>
													</div>
												</div>
											)}
										</div>
									)}
								</div>
							)}
						</div>
					</TabPanel>
				</Tabs>
			</Form>
			<PublishModal
				open={openPublishPopUp}
				setOpen={setOpenPublishPopUp}
				onSubmit={publish}
			/>
			<PostPublishModal
				open={openSaveAfterPublish}
				setOpen={setOpenSaveAfterPublish}
				onSubmit={() => {
					formik.submitForm();
				}}
			/>
			<PrePublishModal
				open={openSaveBeforePublish}
				setOpen={setOpenSaveBeforePublish}
				neverShowPrompt={neverShowPrompt}
				setNeverShowPrompt={setNeverShowPrompt}
				onSubmit={() => {
					formik.submitForm();
				}}
				reducer={setSaveBeforePublishPrompt_Solo}
			/>
		</div>
	);
};

export default SoloForm;

import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import React, {useState} from "react";
import { embedSelect } from "../../../utils/reactSelectStyles";
import crossIcon from "../../../icons/cross-icon.svg";
import greenInfoBulbIcon from "../../../icons/green-info-bulb-icon.svg"
import { MaterialSwitch } from "../../custom/MaterialSwitch";
import { CYAVerificationCard } from "../../embed/components/editor/components/CYAComponents/CYAVerificationCard";

export const PageLayout = ({
	headline,
	setHeadline,
	welcomeMessage,
	setWelcomeMessage,
	welcomeDemo,
	setWelcomeDemo,
	demoOptions,
	demoMessage,
	setDemoMessage,
	demoType,
	setDemoType,
	standardDemoVideos,
	standardDemoOptions,
	handleStandardDemoChange,
	handleDeleteStandardDemoField,
	handleAddNewStandardDemoField,
	contactFields,
	handleFieldNameChange,
	fieldTypeOptions,
	handleTypeChange,
	handleRequiredChange,
	handleDeleteContactField,
	handleAddNewField,
	contactInfoMessage,
	setContactInfoMessage,
	actionMessage,
	setActionMessage,
	buttonLabel,
	setButtonLabel,
	redirectURL,
	setRedirectURL,
	verifyIdentity,
	verificationContent,
	setVerificationContent,
}) => {
	const [hideInfoBubble, setHideInfoBubble] = useState(false);

	return (
		<>
			<div className="flex flex-col gap-y-[32px] mt-[32px]">
				<div
					className={!hideInfoBubble ? `flex flex-col bg-[#CFFFF1] rounded-[8px] py-[10px] pl-[63px] pr-[31px]` : "hidden"}
				>
					<div className={`flex flex-row items-start justify-between`}>
						<div className={`flex flex-row items-start gap-x-[16px]`}>
							<img src={greenInfoBulbIcon} alt="info"/>
							<p className={`text-[#0F7053] text-[15px] leading-[26px]`}>
								<span className={`text-[#0F7053] text-[18px] leading-[34px] font-semibold`}>
									Placeholders
								</span>
								<br/>
								Use placeholders to personalize pages for recipients in bulk. Add them to a text field, then replace them with columns from a <br/>
								contact list at the time of generating Page URLs.<br/>
								Example: Hi {`<Full name>`}. For a limited time, we are offering {`<discount_rate>`}. <br/>
								All placeholders are case sensitive.
							</p>
						</div>
						<button className={`flex justify-center items-center`} onClick={ () => { setHideInfoBubble(true) } }>
							<img src={crossIcon} alt="close" className={`h-[24px] w-[24px] text-[#7D7D7D]`}/>
						</button>
					</div>
				</div>
				<div
					className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px]`}
				>
					<div
						className={
							"flex items-center justify-start h-[2.38rem] bg-transparent border-[#DEE8ED] border-b gap-x-[10px]"
						}
					>
						<p className="text-[#255CA4] text-[18px] leading-[18px] ml-[3.938rem]">
							Welcome
						</p>
						<div className="w-1 h-1 rounded-full bg-[#686868]" />
						<p className="text-[#7D7D7D] text-[14px] leading-[14px]">
							Start them off with a short overview of your company
						</p>
					</div>
					<div
						className={`flex flex-row bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px] py-[20px]`}
					>
						<div className="flex flex-col gap-y-[18px] ml-[50px]">
							<input
								type="text"
								className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem]"
								value={headline}
								onChange={(e) => setHeadline(e.target.value)}
								placeholder="Enter a headline"
							/>
							<textarea
								value={welcomeMessage}
								placeholder="Description - 400 characters or less"
								maxLength={400}
								onChange={(e) => setWelcomeMessage(e.target.value)}
								className="flex text-[15px] leading-[22px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] h-[4.5rem]"
							/>
							<Select
								className="w-80"
								value={welcomeDemo === undefined ? demoOptions[0] : welcomeDemo}
								styles={embedSelect}
								components={makeAnimated()}
								options={demoOptions}
								placeholder="Select Demo"
								onChange={(e) => setWelcomeDemo(e)}
								isSearchable
							/>
						</div>
					</div>
				</div>
				{demoType === "customized" ? (
					<div
						className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px]`}
					>
						<div
							className={
								"flex items-center justify-start h-[2.38rem] bg-transparent border-[#DEE8ED] border-b gap-x-[10px]"
							}
						>
							<p className="text-[#255CA4] text-[18px] leading-[18px] ml-[3.938rem]">
								Q&A dialogue with demos
							</p>
							<div className="w-1 h-1 rounded-full bg-[#686868]" />
							<p className="text-[#7D7D7D] text-[14px] leading-[14px]">
								Create a dialog with your audience. Set questions and rule based
								logic in the tabs above for serving content.
							</p>
						</div>
						<div
							className={`flex flex-row bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px] py-[20px]`}
						>
							<div className="flex flex-col gap-y-[18px] ml-[50px]">
								<input
									type="text"
									className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem]"
									value={demoMessage}
									onChange={(e) => setDemoMessage(e.target.value)}
									placeholder="Enter headline that goes above the dialogue"
								/>
							</div>
						</div>
					</div>
				) : (
					<div
						className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px]`}
					>
						<div
							className={
								"flex items-center justify-start h-[2.38rem] bg-transparent border-[#DEE8ED] border-b gap-x-[10px]"
							}
						>
							<p className="text-[#255CA4] text-[18px] leading-[18px] ml-[3.938rem]">
								Collection
							</p>
							<div className="w-1 h-1 rounded-full bg-[#686868]" />
							<p className="text-[#7D7D7D] text-[14px] leading-[14px]">
								Ask questions to discover their needs and present customized
								demos tailored to their needs
							</p>
						</div>
						<div
							className={`flex flex-row bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px] py-[20px]`}
						>
							<div className="flex flex-col gap-y-[6px] ml-[50px]">
								<input
									type="text"
									className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem]"
									value={demoMessage}
									onChange={(e) => setDemoMessage(e.target.value)}
									placeholder="Enter Message"
								/>
								<div className="flex flex-col ml-[12px] gap-x-[18px]">
									<div className="flex flex-col ml-[-12px]">
										<div>
											{standardDemoVideos.map((video, i) => (
												<div key={i} className="flex items-center my-3">
													<Select
														className="w-80"
														styles={embedSelect}
														value={standardDemoVideos[i]}
														components={makeAnimated()}
														options={standardDemoOptions}
														onChange={(e) => handleStandardDemoChange(e, i)}
														placeholder="Select Type"
														isSearchable
													/>
													<button
														className="w-8 h-8 ml-3"
														onClick={() => handleDeleteStandardDemoField(i)}
													>
														<img
															src={crossIcon}
															alt="close"
															className="w-5 h-5"
														/>
													</button>
												</div>
											))}
										</div>
										<div className="flex ml-[16px]">
											<button
												className="text-[#1D60B5] text-[14px] hover:underline"
												onClick={handleAddNewStandardDemoField}
											>
												+ Add more demo
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				<div
					className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px]`}
				>
					<div
						className={
							"flex items-center justify-start h-[2.38rem] bg-transparent border-[#DEE8ED] border-b gap-x-[10px]"
						}
					>
						<p className="text-[#255CA4] text-[18px] leading-[18px] ml-[3.938rem]">
							Contact Information
						</p>
						<div className="w-1 h-1 rounded-full bg-[#686868]" />
						<p className="text-[#7D7D7D] text-[14px] leading-[14px]">
							Prompt the user to enter their contact information
						</p>
					</div>
					<div
						className={`flex flex-row bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px] py-[20px]`}
					>
						<div className="flex flex-col gap-y-[18px] ml-[50px]">
							<textarea
								value={contactInfoMessage}
								placeholder="Description - 250 characters or less"
								maxLength={250}
								onChange={(e) => setContactInfoMessage(e.target.value)}
								className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] h-[4.5rem]"
							/>
							<div className="flex flex-col gap-y-[8px] ml-[-8px]">
								<table>
									<thead>
										<tr>
											{["", "", "", ""].map((it, ind) => (
												<th key={ind} className="p-2 border-0 font-bold">
													{it}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{contactFields.map((element, i) => {
											return (
												<tr key={i}>
													<td className="p-2">
														<input
															type="text"
															className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[272px] h-[38px]"
															placeholder={"Enter Field Name"}
															value={contactFields[i].fieldName}
															onChange={(e) =>
																handleFieldNameChange(e.target.value, i)
															}
														/>
													</td>
													<td className="p-2">
														<Select
															className="w-[272px]"
															value={fieldTypeOptions.filter(
																(it) => it.value === contactFields[i].type
															)}
															styles={embedSelect}
															components={makeAnimated()}
															options={fieldTypeOptions}
															placeholder="Select Type"
															onChange={(e) => handleTypeChange(e.value, i)}
															isSearchable
														/>
													</td>
													<td className="pt-2 pb-2 pl-2 pr-[40px] align-middle text-center">
														<div className="flex flex-row items-center">
															<MaterialSwitch
																color={"primary"}
																size={"small"}
																checked={contactFields[i].required}
																onClick={() => {
																	handleRequiredChange(i);
																}}
															/>
															<p
																className={`font-light text-[#222222] text-[13px] leading-[13px] ml-[12px]`}
															>
																Required
															</p>
														</div>
													</td>
													<td className="p-2 align-middle text-center">
														<button
															className="w-8 h-8"
															onClick={() => handleDeleteContactField(i)}
														>
															<img
																src={crossIcon}
																alt="close"
																className="w-5 h-5"
															/>
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
								<div className="flex flex-row ml-[24px] items-center">
									<button
										className="text-[#1D60B5] text-[14px] hover:underline"
										onClick={handleAddNewField}
									>
										+ Add a new field
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px]`}
				>
					<div
						className={
							"flex items-center justify-start h-[2.38rem] bg-transparent border-[#DEE8ED] border-b gap-x-[10px]"
						}
					>
						<p className="text-[#255CA4] text-[18px] leading-[18px] ml-[3.938rem]">
							Action
						</p>
						<div className="w-1 h-1 rounded-full bg-[#686868]" />
						<p className="text-[#7D7D7D] text-[14px] leading-[14px]">
							Prompt the user to take a desired action
						</p>
					</div>
					<div
						className={`flex flex-row bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px] py-[20px]`}
					>
						<div className="flex flex-col gap-y-[18px] ml-[50px]">
							<textarea
								value={actionMessage}
								placeholder="Message - 100 characters or less"
								maxLength={100}
								onChange={(e) => setActionMessage(e.target.value)}
								className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] h-[4.5rem]"
							/>
							<input
								type="text"
								className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem]"
								value={buttonLabel}
								onChange={(e) => setButtonLabel(e.target.value)}
								placeholder="Button Label"
							/>
							<input
								type="text"
								className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem]"
								value={redirectURL}
								onChange={(e) => setRedirectURL(e.target.value)}
								placeholder="Destination URL - opens in a new tab"
							/>
						</div>
					</div>
				</div>
				<div className={verifyIdentity ? "flex flex-col" : "hidden"}>
					<CYAVerificationCard
						verificationContent={verificationContent}
						setVerificationContent={setVerificationContent}
					/>
				</div>
			</div>
		</>
	);
};

/**
 * Extracts the placeholders present in the given string - Time Complexity O(n)
 * @param {string} field - The string user entered in the form field
 * @returns {Array} An array containing all the extracted placeholders from single field
 */
const getPlaceholders = (field) => {
	const results = [];
	let words = field.split(" ");
	for (const word of words) {
		if (word.includes("<") && word.includes(">")) results.push(word.replace("<", "").replace(">",""));
	}
	return results;
};

/**
 * Extracts placeholders from all fields and returns a compiled array - Time Complexity O(n^2)
 * @param {string[]} theFields - The string array comprised of text in all fields
 * @returns {Array} An array containing all the extracted placeholders from all fields
 */
export const extractPlaceholders = (...theFields) => {
	let extracted = [];
	try {
		for (const field of theFields) {
			if (field) {
				getPlaceholders(field).forEach(it => {
					if ( !extracted.includes(it) && it !== "prospect_email" && it !== "prospect_name" ) {
						extracted.push(it);
					}
				})
			}
		}
	} catch (error) {
		throw error;
	}
	return extracted;
};

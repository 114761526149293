import React from "react";
import ReactDom from "react-dom";

import closecrossicon from "../../icons/closecross-icon.svg";

const MODAL_STYLES = {
	width: "690px",
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	backgroundColor: "#FFF",
	padding: "38px 36px",
	zIndex: 1000,
	border: "1px solid #D9D9D9",
	borderRadius: "8px",
	boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
};

const OVERLAY_STYLES = {
	position: "fixed",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	backgroundColor: "rgba(0, 0, 0, .32)",
	backdropFilter: "blur(5px)",
	zIndex: 1000,
};

function Modal({ open, children, onClose, hideModal }) {
	if (!open) return null;
	return ReactDom.createPortal(
		<div className={hideModal !== undefined && hideModal ? "hidden" : ""}>
			<div style={OVERLAY_STYLES} />
			<div style={MODAL_STYLES}>
				<div className="flex flex-row-reverse">
					<button onClick={onClose}>
						<img
							src={closecrossicon}
							alt="Close"
							className="w-[2.6rem] h-[2.6rem]"
						/>
					</button>
				</div>
				{children}
			</div>
		</div>,
		document.getElementById("portal")
	);
}

export default Modal;

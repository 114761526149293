import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const DashboardTagTypes = {};

export const dashboardApiSlice = createApi({
	reducerPath: "api/dashboard",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().sessionData.token
			if (token) {
				headers.set('token', token)
			}
			return headers
		}
	}),
	tagTypes: Object.values(DashboardTagTypes),
	endpoints: builder => ({
		sendConsent: builder.mutation({
			query: initialPost => ({
				url: "/consent",
				method: "POST",
				body: {
					salesrepId: initialPost.salesRepId,
					companyId: initialPost.companyId,
					consent: initialPost.consent,
					timestamp: initialPost.timestamp
				},
			}),
			invalidatesTags: [],
		}),
		updateFlag: builder.mutation({
			query: initialPost => ({
				url: "/flag",
				method: "PUT",
				body: {
					salesrepId: initialPost.salesRepId,
					companyId: initialPost.companyId,
					config: initialPost.config,
				},
			}),
			invalidatesTags: [],
		}),
	})
})

export const { useSendConsentMutation, useUpdateFlagMutation } = dashboardApiSlice;

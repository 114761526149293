import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import cyaDeleteIcon from "../../../../../../icons/cya-delete-icon.svg";
import {MaterialSwitch} from "../../../../../custom/MaterialSwitch";
import {StyledRadio} from "../../../../../custom/StyledRadio";
import dragHandle from "../../../../../../icons/drag-handle.svg"
import uuid from "react-uuid";

export const CYAModalCard = forwardRef(({
																					index,
																					questionOptions,
																					answerOptions,
																					showIndex,
																					isLive,
																					fetchedModal,
																					deleteEpisode,
																				}, ref) => {
	const [modalTitle, setModalTitle] = useState("");
	const [modalDescription, setModalDescription] = useState("");
	const [showInputBox, setShowInputBox] = useState(true);
	const [inputBoxPlaceHolder, setInputBoxPlaceHolder] = useState("");
	const [showFirstCTA, setShowFirstCTA] = useState(true);
	const [showSecondCTA, setShowSecondCTA] = useState(true);
	const [firstCTALabel, setFirstCTALabel] = useState("");
	const [secondCTALabel, setSecondCTALabel] = useState("");
	const [firstCTAAction, setFirstCTAAction] = useState("next");
	const [secondCTAAction, setSecondCTAAction] = useState("next");
	const [firstCTARedirect, setFirstCTARedirect] = useState("");
	const [secondCTARedirect, setSecondCTARedirect] = useState("");
	const [uniqueId, setUniqueId] = useState(uuid());

	useImperativeHandle(ref, () => ({
		sendData() {
			return sendModalData();
		},
	}));

	useEffect(() => {
		if (fetchedModal !== undefined && fetchedModal !== null) {
			setModalTitle(fetchedModal.title);
			setModalDescription(fetchedModal.description);
			setShowInputBox(fetchedModal.showInputBox);
			setInputBoxPlaceHolder(fetchedModal.inputPlaceHolder);
			if (fetchedModal.firstCTA !== undefined) {
				setShowFirstCTA(fetchedModal.firstCTA.show);
				setFirstCTALabel(fetchedModal.firstCTA.label);
				setFirstCTAAction(fetchedModal.firstCTA.action);
				setFirstCTARedirect(fetchedModal.firstCTA.redirect);
			}
			if (fetchedModal.secondCTA !== undefined) {
				setShowSecondCTA(fetchedModal.secondCTA.show);
				setSecondCTALabel(fetchedModal.secondCTA.label);
				setSecondCTAAction(fetchedModal.secondCTA.action);
				setSecondCTARedirect(fetchedModal.secondCTA.redirect);
			}
			if (fetchedModal.uniqueId !== undefined) {
				setUniqueId(fetchedModal.uniqueId);
			}
		}
	}, [fetchedModal])

	const sendModalData = () => {
		return {
			title: modalTitle,
			description: modalDescription,
			showInputBox: showInputBox,
			inputPlaceHolder: inputBoxPlaceHolder,
			firstCTA: {
				show: showFirstCTA,
				type: "button",
				label: firstCTALabel,
				action: firstCTAAction,
				redirect: firstCTARedirect
			},
			secondCTA: {
				show: showSecondCTA,
				type: "link",
				label: secondCTALabel,
				action: secondCTAAction,
				redirect: secondCTARedirect
			},
			episode: "modal",
			index: index,
			uniqueId: uniqueId
		}
	}

	return (
		<div className={showIndex ? `flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px] mt-[2rem]` : "flex"}>
			<div className={showIndex ? "flex items-center justify-between h-[2.38rem] bg-transparent border-[#DEE8ED] border-b" : "hidden"}>
				<p className="text-[#255CA4] text-[18px] leading-[18px] mx-[3.938rem]">
					Show Modal
				</p>
				<button className="flex items-center justify-center mr-[2.063rem]" onClick={() => deleteEpisode(index)}>
					<img src={cyaDeleteIcon} alt="delete" className="w-5 h-5"/>
				</button>
			</div>
			<div className={`${showIndex ? "bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px]" : "bg-white"}`}>
				<div className="flex flex-row">
					<div className={showIndex ? "flex items-center w-[50px]" : "hidden"}>
						{!isLive ? <img src={dragHandle} alt="drag" className="ml-[9px]"/> : null}
					</div>
					<div className={showIndex ? "flex flex-col my-[1.25rem]" : "flex flex-col"}>
						<div className="flex flex-col space-y-[18px]">
							<input type="text"
										 className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem]"
										 value={modalTitle}
										 onChange={(e) => setModalTitle(e.target.value)} placeholder="Title"/>
							<textarea value={modalDescription} placeholder="Description - 400 characters or less"
												maxLength={400}
												onChange={(e) => setModalDescription(e.target.value)}
												className="flex text-[15px] leading-[22px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] h-[4.5rem]"/>
						</div>
						<div className="flex flex-col mt-[24px] space-y-[24px]">
							<div className="flex flex-col gap-y-[8px]">
								<div className="flex flex-row items-center gap-x-[0.5rem]">
									<MaterialSwitch color={"primary"} checked={showInputBox}
																	onClick={() => setShowInputBox(!showInputBox)}/>
									<p
										className={`font-light ${!showInputBox ? "text-[#7D7D7D]" : "text-[#222222]"} text-[15px] ml-[12px]`}>
										Add input field
									</p>
								</div>
								<input type="text"
											 className={`flex text-[15px] leading-[15px] px-[12px] ${!showInputBox ? "text-[#7E7E7E]" : ""} rounded-[4px] border border-[#BFCACF] w-[32.19rem] ml-[60px]`}
											 value={inputBoxPlaceHolder}
											 disabled={!showInputBox}
											 onChange={(e) => setInputBoxPlaceHolder(e.target.value)} placeholder="Placeholder"
								/>
							</div>
							<div className="flex flex-col gap-y-[8px]">
								<div className="flex flex-row items-center gap-x-[0.5rem]">
									<MaterialSwitch color={"primary"} checked={showFirstCTA}
																	onClick={() => setShowFirstCTA(!showFirstCTA)}/>
									<p
										className={`font-light ${!showFirstCTA ? "text-[#7D7D7D]" : "text-[#222222]"} text-[15px] ml-[12px]`}>
										Add primary button
									</p>
								</div>
								<input type="text"
											 className={`flex text-[15px] leading-[15px] px-[12px] ${!showFirstCTA ? "text-[#7E7E7E]" : ""} rounded-[4px] border border-[#BFCACF] w-[19.2rem] ml-[60px]`}
											 value={firstCTALabel}
											 onChange={(e) => setFirstCTALabel(e.target.value)} placeholder="Button label"/>
								<label className="ml-[48px]">
									<StyledRadio name="CTA1"
															 className="ml-[60px] mr-[1.5rem] w-[18px] h-[18px] border-2 border-[#C5C5C7]"
															 checked={showFirstCTA ? firstCTAAction === "next" : false}
															 onClick={() => setFirstCTAAction("next")}
															 disabled={!showFirstCTA}
									/>
									<span className={`font-light ${!showFirstCTA ? "text-[#7D7D7D]" : "text-[#222222]"} text-[14px]`}>
										Next Card
									</span>
								</label>
								<div className="flex flex-row items-center gap-x-[8px]">
									<label className="ml-[48px]">
										<StyledRadio name="CTA1"
																 className="ml-[60px] mr-[1.5rem] w-[18px] h-[18px] border-2 border-[#C5C5C7]"
																 checked={showFirstCTA ? firstCTAAction === "redirect" : false}
																 onClick={() => setFirstCTAAction("redirect")}
																 disabled={!showFirstCTA}
										/>
										<span className={`font-light ${!showFirstCTA ? "text-[#7D7D7D]" : "text-[#222222]"} text-[14px]`}>
										URL
									</span>
									</label>
									<input type="text"
												 className={`flex text-[15px] leading-[15px] px-[12px]  ${!showFirstCTA ? "text-[#7E7E7E]" : ""} rounded-[4px] border border-[#BFCACF] w-[14.56rem]`}
												 value={firstCTARedirect}
												 placeholder="Enter URL (opens in a new tab)"
												 disabled={!showFirstCTA}
												 onChange={(e) => setFirstCTARedirect(e.target.value)}/>
								</div>
							</div>
							<div className="flex flex-col gap-y-[8px]">
								<div className="flex flex-row items-center gap-x-[0.5rem]">
									<MaterialSwitch color={"primary"} checked={showSecondCTA}
																	onClick={() => setShowSecondCTA(!showSecondCTA)}/>
									<p
										className={`font-light ${!showSecondCTA ? "text-[#7D7D7D]" : "text-[#222222]"} text-[15px] ml-[12px]`}>
										Add secondary link
									</p>
								</div>
								<input type="text"
											 className={`flex text-[15px] leading-[15px] px-[12px] ${!showSecondCTA ? "text-[#7E7E7E]" : ""} rounded-[4px] border border-[#BFCACF] w-[19.2rem] ml-[60px]`}
											 value={secondCTALabel}
											 onChange={(e) => setSecondCTALabel(e.target.value)} placeholder="Link label"
											 disabled={!showSecondCTA}
								/>
								<label className="ml-[48px]">
									<StyledRadio name="CTA2"
															 className="ml-[60px] mr-[1.5rem] w-[18px] h-[18px] border-2 border-[#C5C5C7]"
															 checked={(showSecondCTA) ? secondCTAAction === "next" : false}
															 onClick={() => setSecondCTAAction("next")}
															 disabled={!showSecondCTA}
									/>
									<span className={`font-light ${!showSecondCTA ? "text-[#7D7D7D]" : "text-[#222222]"} text-[14px]`}>
										Next Card
									</span>
								</label>
								<div className="flex flex-row items-center gap-x-[8px]">
									<label className="ml-[48px]">
										<StyledRadio name="CTA2"
																 className="ml-[60px] mr-[1.5rem] w-[18px] h-[18px] border-2 border-[#C5C5C7]"
																 checked={(showSecondCTA) ? secondCTAAction === "redirect" : false}
																 onClick={() => setSecondCTAAction("redirect")}
																 disabled={!showSecondCTA}
										/>
										<span className={`font-light ${!showSecondCTA ? "text-[#7D7D7D]" : "text-[#222222]"} text-[14px]`}>
										URL
									</span>
									</label>
									<input type="text"
												 className={`flex text-[15px] ${!showSecondCTA ? "text-[#7E7E7E]" : ""} leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[14.56rem]`}
												 value={secondCTARedirect}
												 placeholder="Enter URL (opens in a new tab)"
												 onChange={(e) => setSecondCTARedirect(e.target.value)}
												 disabled={!showSecondCTA}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
})

import { Redirect, useHistory } from "react-router-dom";
import {
	useCheckTokenQuery,
	useLoginMutation,
} from "../../features/api/loginApiSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import sessionDataReducer, {
	setSessionData,
} from "../../features/state/session/sessionDataSlice";
import {
	setQuestionLibrary,
	setWebsiteStyle,
	setFlags,
} from "../../features/state/user-settings/userSettingSlice";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { amplitude } from "../amplitude/Amplitude";
import zerosLogo from "../../icons/zeros-logo.png";
import forgotPasswordIcon from "../../icons/forgot-password-icon.svg";
import helpIcon from "../../icons/help-icon.svg";
import alertCircleIcon from "../../icons/alert-circle-icon.svg";
import {toastError, toastLoading} from "../custom-toast/toasts";
import linkedInLogo from "../../icons/linkedIn-logo.svg";
import {useLinkedIn} from "react-linkedin-login-oauth2";
import {usePostLoginMutation} from "../../features/api/linkedInApiSlice";

export const Login = () => {
	const [login, {}] = useLoginMutation();
	const [cookieData, setCookieData] = useState(null);
	const [cookieToken, setCookieToken] = useState(null);
	const history = useHistory();
	const dispatch = useDispatch();
	const { data, isLoading } = useCheckTokenQuery({ token: cookieToken });
	const [hasLoggedIn, setHasLoggedIn] = useState(false);
	const [postLogin] = usePostLoginMutation();

	useEffect(() => {
		const getAuthToken = () => {
			const authToken = localStorage.getItem("linkedInAuthCode");
			if (authToken) {
				if (authToken === "") {
					localStorage.removeItem("linkedInAuthCode");
					return toastError("Unable to access LinkedIn");
				} else {
					loginViaLinkedIn(authToken);
					localStorage.removeItem("linkedInAuthCode");
				}
			}
		}
		window.addEventListener('storage', getAuthToken);
		return () => {
			window.removeEventListener('storage', getAuthToken);
		};
	})

	// useEffect(() => {
	//   const sessionData = getStateFromCookies(
	//     sessionDataReducer,
	//     paths
	//   ).sessionData;
	//   const isNull =
	//     sessionData.token === null ||
	//     sessionData.companyId === null ||
	//     sessionData.sellerId === null;
	//   if (!isNull) {
	//     setCookieData(sessionData);
	//     setCookieToken(sessionData.token);
	//   }
	// }, []);
	//
	// useEffect(() => {
	//   if (!isLoading) {
	//     if (data.status === "success") {
	//       toastSuccess("Token Authenticated");
	//       dispatch(
	//         setSessionData({
	//           sellerId: cookieData.sellerId,
	//           companyId: cookieData.companyId,
	//           token: cookieData.token,
	//         })
	//       );
	//     }
	//   }
	// }, [data]);

	const loginViaLinkedIn = async (authCode) => {
		try {
			toast.dismiss();
			const alert = toastLoading("Logging In...");
			try {
				const sessionData = await postLogin({
					authCode: authCode,
					clientId: process.env.REACT_APP_LINKEDIN_CLIENTID,
					redirectUri: `${window.location.origin}/linkedin`,
				}).unwrap();
				if (sessionData.status === "success") {
					const data = {
						sellerId: sessionData.payload.id,
						companyId: sessionData.payload.companyId,
						token: sessionData.payload.token,
						name: sessionData.payload.name,
						companyName: sessionData.payload.companyName,
						userName: sessionData.payload.userName,
					};

					toast.dismiss(alert);
					dispatch(setSessionData(data));
					dispatch(
						setQuestionLibrary({
							questionLibraryLink: sessionData.payload.companyQuestionLibraryLink,
						})
					);
					dispatch(
						setFlags({
							flags: sessionData.payload.flags,
						})
					);
					dispatch(
						setWebsiteStyle({
							websiteStyle: sessionData.payload.companyWebsiteStyle,
						})
					);
					history.push("/");
				} else {
					toast.dismiss(alert);
					setHasLoggedIn(true);
				}
			} catch (err) {
				console.log(err);
				toast.dismiss(alert);
				toastError("Couldn't connect to database");
			}
		} catch (err) {
			console.log(err);
			window.alert("Unable to login");
		}
	};

	const { linkedInLogin } = useLinkedIn({
		clientId: process.env.REACT_APP_LINKEDIN_CLIENTID ?? "",
		scope: `r_liteprofile r_emailaddress`,
		redirectUri: `${
			typeof window === "object" && window.location.origin
		}/linkedin`,
		onSuccess: (code) => {
			// loginViaLinkedIn(code);
		},
		onError: (error) => {
			// toastError("Unable to access LinkedIn");
		},
	});

	const initialValues = {
		email: "",
		password: "",
	};

	const logInSchema = Yup.object().shape({
		email: Yup.string().email().required("Email is required"),
		password: Yup.string()
			.required("Password is required")
			.min(4, "Password is too short - should be more than 4 letters"),
	});

	const authenticateLogin = async (values) => {
		toast.dismiss();
		const alert = toastLoading("Logging In...");
		try {
			const sessionData = await login({
				username: values.email,
				password: values.password,
			}).unwrap();
			if (sessionData.status === "success") {
				const data = {
					sellerId: sessionData.payload.id,
					companyId: sessionData.payload.companyId,
					token: sessionData.payload.token,
					name: sessionData.payload.name,
					companyName: sessionData.payload.companyName,
					userName: sessionData.payload.userName,
				};

				toast.dismiss(alert);
				dispatch(setSessionData(data));
				dispatch(
					setQuestionLibrary({
						questionLibraryLink: sessionData.payload.companyQuestionLibraryLink,
					})
				);
				dispatch(
					setFlags({
						flags: sessionData.payload.flags,
					})
				);
				dispatch(
					setWebsiteStyle({
						websiteStyle: sessionData.payload.companyWebsiteStyle,
					})
				);
				history.push("/");
			} else {
				toast.dismiss(alert);
				setHasLoggedIn(true);
				// toastError("Failed to find user");
			}
		} catch (err) {
			console.log(err);
			toast.dismiss(alert);
			toastError("Couldn't connect to database");
		}
	};

	const passwordResetScreen = () => {
		history.push("./reset");
	};

	const signUpScreen = () => {
		history.push("./signup");
	};

	const Input = ({ name, ...props }) => {
		const [field, meta] = useField(name);
		return (
			<div>
				<input
					className={`shadow appearance-none ${
						(meta.error && meta.touched) || hasLoggedIn
							? "border-2 border-[#B25353]"
							: "border border-[#2A343E]"
					} rounded-[8px] w-full py-[16px] px-[12px] text-[#FAFAFA] leading-tight focus:outline-none focus:shadow-outline bg-[#2A343E]`}
					{...field}
					{...props}
				/>
				<ErrorMessage
					name={field.name}
					component="div"
					className="text-red-500 text-xs"
				/>
			</div>
		);
	};

	return (
		<div className="h-screen">
			<div
				style={{ height: "100%", width: "100%" }}
				className="flex justify-center items-center bg-[#0A1B2B]"
			>
				<div className="flex flex-col flex-grow bg-[#0A1B2B] space-y-[40px] items-center justify-center">
					<div className="flex flex-col justify-center items-center gap-y-[34px] mx-auto">
						<img
							src={zerosLogo}
							alt="zeros logo"
							className="w-[70px] h-[70px]"
						/>
						<p className="text-[28px] leading-[28px] font-semibold text-[#EDEDED]">
							Login to Zeros
						</p>
					</div>
					<Formik
						initialValues={initialValues}
						validationSchema={logInSchema}
						onSubmit={(values) => authenticateLogin(values)}
					>
						{(formik) => {
							const { isValid, dirty } = formik;
							const classSubmit =
								"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
							return (
								<div className="w-[400px] mx-auto">
									<Form
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												authenticateLogin(formik.values);
											}
										}}
									>
										<div className="mb-4">
											<Input
												id="email"
												type="email"
												name="email"
												placeholder="Enter email"
											/>
										</div>
										<div className="flex flex-col gap-y-[8px] mb-6">
											<Input
												id="password"
												type="password"
												name="password"
												placeholder="Enter password"
											/>
											{hasLoggedIn ? (
												<div className="flex flex-row gap-x-[12px] items-center">
													<img
														src={alertCircleIcon}
														alt="alert icon"
														className="w-[20px] h-[20px]"
													/>
													<p className="text-[#FAFAFA] leading-[14px] text-[14px] font-[500]">
														Either email or password don't match
													</p>
												</div>
											) : null}
										</div>
										<div className="flex flex-col gap-y-[18px]">
											<div className="flex flex-row items-center gap-x-[8px]">
												<img
													src={forgotPasswordIcon}
													alt="forgot password"
													className="w-[18px] h-[18px]"
												/>
												<button
													className={
														"inline-block align-baseline font-semibold text-[16px] leading-[16px] text-[#7D7D7D] hover:text-[#EDEDED]"
													}
													onClick={() => passwordResetScreen()}
												>
													Forgot Password?
												</button>
											</div>
											<div className="flex flex-row items-center gap-x-[8px]">
												<img
													src={helpIcon}
													alt="forgot password"
													className="w-[18px] h-[18px]"
												/>
												<button
													className={
														"inline-block align-baseline font-semibold text-[16px] leading-[16px] text-[#7D7D7D] hover:text-[#EDEDED]"
													}
													onClick={(e) => {
														e.preventDefault()
														window.open("https://www.zeroshq.com", "_blank");

													}}
												>
													Having trouble? Contact us
												</button>
											</div>
											<button
												className={`h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA]`}
												type="submit"
												disabled={!(dirty && isValid)}
											>
												Log In
											</button>
											<div className={`flex flex-row items-center justify-center gap-x-[16px]`}>
												<div className={`border-2 border-[#2A343E] w-[170px]`}/>
												<p className={`text-[20px] leading-[20px] font-semibold text-[#B9B9B9]`}>OR</p>
												<div className={`border-2 border-[#2A343E] w-[170px]`}/>
											</div>
											<button
												className={`h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-transparent hover:bg-[#2A343E] border-2 border-[#2A343E]`}
												onClick={(e) => {
													e.preventDefault();
													linkedInLogin();
												}}
											>
												<div className={`flex flex-row gap-x-[16px] justify-center items-center`}>
													<img src={linkedInLogo} alt="linkedIn Logo" className={`w-[30px] h-[30px]`}/>
													<p>Login with LinkedIn</p>
												</div>
											</button>
											<div className={`flex flex-row text-[18px] leading-[18px] font-[600] justify-center items-center gap-x-2`}>
												<p className={`text-[#B5B5B5]`}>
													Don't have an account?
												</p>
												<button className={`text-[#1A85F8] hover:underline`} onClick={() => signUpScreen()}>
													Sign Up
												</button>
											</div>
										</div>
									</Form>
								</div>
							);
						}}
					</Formik>
				</div>
			</div>
		</div>
	);
};

"use-strict";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { toast } from "react-toastify";
import usePickerClickOutside from "../../../../../../../features/hooks/usePickerClickOutside";
import DefaultField from "./DefaultField";
import rectangleblackicon from "../../../../../../../icons/rectangle-black-icon.svg";
import dividericon from "../../../../../../../icons/divider-icon.svg";
import minusicon from "../../../../../../../icons/minus-icon.svg";
import "../../../../../../top-level/blinker-pack/BlinkersPack.scss";
import { DEMO_CONTROLS } from "../../../../../../../data/constants";
import hotspotPlayIcon from "../../../../../../../icons/hotspot-play-icon.svg";
import { toastError } from "../../../../../../custom-toast/toasts";

const RectangleEditor = ({
	idx,
	appearAt,
	selectedBorderColor,
	saveDefaultBorderColor,
	onSelectBorderColor,
	defaultBorderColor,
	selectedCaptionColor,
	onSelectCaptionColor,
	defaultCaptionColor,
	saveDefaultCaptionColor,
	saveDefaultBackgroundColor,
	selectedBackgroundColor,
	onSelectBackgroundColor,
	defaultBackgroundColor,
	selectedRectangleStyle,
	onSelectRectangleStyle,
	caption,
	onChangeCaption,
	rectangleDeleteHandler,
	rectangleTimeStampHandler,
	rectangleSeekHandler,
	totalDuration,
	onThumbnail,
	thumbnailRef,
}) => {
	const [tempBorderSelectedColor, setTempBorderSelectedColor] = useState();
	const [displayBorderColorPicker, setDisplayBorderColorPicker] =
		useState(false);
	const [tempBackgoundSelectedColor, setTempBackgroundSelectedColor] =
		useState();
	const [tempCaptionSelectedColor, setTempCaptionSelectedColor] = useState();
	const [displayCaptionColorPicker, setDisplayCaptionColorPicker] =
		useState(false);
	const [displayRectangleStylePicker, setDisplayRectangleStylePicker] =
		useState(false);
	// const [captionColor, setCaptionColor] = useState("#D9D9D9");
	const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] =
		useState(false);
	// const [backgroundColor, setBackgroundColor] = useState("#D9D9D9");
	const [checked, setChecked] = useState(false);
	const [expand, setExpand] = useState(false);
	const [captionValue, setCaptionValue] = useState("");
	const [rectangleStyle, setRectangleStyle] = useState("dark");
	const [isValid, setIsValid] = useState(true);
	const [styleNumber, setStyleNumber] = useState(6);
	const [editTimestamp, setEditTimestamp] = useState(false);

	const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(
		appearAt < 3600
			? new Date(appearAt * 1000).toISOString().substring(14, 23)
			: new Date(appearAt * 1000).toISOString().substring(11, 19)
	);
	const timestampInputRef = useRef();

	const popoverCaptionPicker = useRef();
	const popoverBackgroundPicker = useRef();
	const popoverBorderPicker = useRef();
	const popoverStylePicker = useRef();

	const closeBackgroundPicker = useCallback(
		() => setDisplayBackgroundColorPicker(false),
		[]
	);
	const closeCaptionPicker = useCallback(
		() => setDisplayCaptionColorPicker(false),
		[]
	);
	const closeBorderPicker = useCallback(
		() => setDisplayBorderColorPicker(false),
		[]
	);
	const closeStylePicker = useCallback(
		() => setDisplayRectangleStylePicker(false),
		[]
	);

	usePickerClickOutside(popoverBackgroundPicker, closeBackgroundPicker);
	usePickerClickOutside(popoverCaptionPicker, closeCaptionPicker);
	usePickerClickOutside(popoverBorderPicker, closeBorderPicker);
	usePickerClickOutside(popoverStylePicker, closeStylePicker);

	useEffect(() => {
		setCaptionValue(caption && caption.length > 0 ? caption : "");
		// setRectangleStyle(selectedRectangleStyle ?? "dark");
		// if (selectedRectangleStyle) {
		// 	const computedStyleNumber = Object.keys(BLINKER_PACK).findIndex(
		// 		(style) => style.toUpperCase() === selectedRectangleStyle.toUpperCase()
		// 	);
		// 	setStyleNumber(computedStyleNumber < 0 ? "6" : computedStyleNumber + 1);
		// }
	}, []);

	const onInputChangeHandler = (e) => {
		if (e.target.value.length <= 250) {
			setCaptionValue(e.target.value);
			onChangeCaption(idx, e.target.value.trim(), DEMO_CONTROLS.RECTANGLE);
			!isValid && setIsValid(true);
		} else {
			setCaptionValue(e.target.value);
			setIsValid(false);
		}
	};

	const handleDefault = (e) => {
		setChecked(e.target.checked);
		console.log(e);
	};

	const captionPickerClickHandler = () => {
		setDisplayCaptionColorPicker((state) => !state);
	};
	const backgroundPickerClickHandler = () => {
		setDisplayBackgroundColorPicker((state) => !state);
	};
	const borderPickerClickHandler = () => {
		setDisplayBorderColorPicker((state) => !state);
	};
	const rectangleStylePickerClickHandler = () => {
		setDisplayRectangleStylePicker((state) => !state);
	};

	const editTimestampInRectangle = (value) => {
		const regex = new RegExp(
			`^(([0]?[0-5][0-9]|[0-9]):([0-5][0-9])[.]([0-9][0-9][0-9]))$`,
			"g"
		);
		if (regex.test(value)) {
			if (totalDuration) {
				const split = value.split(":");
				const parsedMins = parseInt(split[0]) * 60;
				const parsedSeconds = parseFloat(split[1]);
				const newTimestamp = parsedMins + parsedSeconds;
				if (totalDuration > newTimestamp) {
					rectangleTimeStampHandler(idx, newTimestamp);
				} else {
					timestampInputRef.current.value = lastUpdatedTimestamp;
					toastError("Timestamp exceeds video duration");
				}
			}
		} else {
			timestampInputRef.current.value = lastUpdatedTimestamp;
			toastError("Invalid Timestamp");
		}
	};

	return (
		<>
			<div
				type="button"
				onClick={() => {
					setExpand((state) => !state);
					rectangleSeekHandler(appearAt);
					setDisplayBackgroundColorPicker(false);
					setDisplayCaptionColorPicker(false);
					setDisplayBorderColorPicker(false);
					setDisplayRectangleStylePicker(false);
				}}
				onMouseEnter={() => {
					setEditTimestamp(true);
				}}
				onMouseLeave={() => {
					if (!expand) {
						setEditTimestamp(false);
					}
				}}
				style={{ border: "1px solid white" }}
				className={`flex px-7 py-5 flex-row w-full border border-white hover:border-[#F8F8F8] hover:bg-[#F8F8F8] ${
					expand ? "bg-[#F8F8F8]" : ""
				} rounded-lg`}
			>
				<div className="flex w-full justify-between">
					<div className="flex flex-row w-full items-start">
						<div className="flex justify-center items-center">
							<button
								className={`border-1 m-1 rounded-md flex justify-center items-center w-[2.5rem] h-[1.5rem]`}
								type="button"
								onClick={(e) => {
									rectangleSeekHandler(appearAt);
									if (expand) e.stopPropagation();
								}}
							>
								<img
									src={!editTimestamp ? rectangleblackicon : hotspotPlayIcon}
									alt="Rectangle"
									className={
										editTimestamp
											? "w-[1.5rem] h-[1.5rem]"
											: "w-[2.5rem] h-[2.5rem]"
									}
								/>
							</button>
						</div>
						<img src={dividericon} alt="|" className="w-[2rem] h-[2rem]" />
						<div className="flex w-[4.75rem] h-[1.25rem] justify-center items-center rounded-[8px] text-sm text-[#0B4CA3] mx-2 mt-2">
							{!editTimestamp ? (
								appearAt < 3600 ? (
									new Date(appearAt * 1000).toISOString().substring(14, 23)
								) : (
									new Date(appearAt * 1000).toISOString().substring(11, 19)
								)
							) : (
								<input
									placeholder={"mm:ss.sss"}
									name="timestamp"
									className="w-[5rem] h-[2.25rem] p-3 text-sm text-[#222222] border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D] text-center m-0 p-0"
									maxLength={9}
									ref={timestampInputRef}
									defaultValue={
										appearAt < 3600
											? new Date(appearAt * 1000)
													.toISOString()
													.substring(14, 23)
											: new Date(appearAt * 1000)
													.toISOString()
													.substring(11, 19)
									}
									// disabled={totalDuration === null}
									onKeyPress={(e) => {
										if (e.code === "Enter" || e.code === "NumpadEnter") {
											e.preventDefault();
											editTimestampInRectangle(e.target.value);
										}
									}}
									onClick={(e) => {
										if (onThumbnail) {
											thumbnailRef.current.click();
										}
										if (expand) e.stopPropagation();
										setDisplayBackgroundColorPicker(false);
										setDisplayCaptionColorPicker(false);
										setDisplayBorderColorPicker(false);
										setDisplayRectangleStylePicker(false);
									}}
									onBlur={(e) => {
										editTimestampInRectangle(e.target.value);
									}}
								/>
							)}
						</div>
						<img src={dividericon} alt="|" className="w-[2rem] h-[2rem]" />
						<div className="flex flex-col justify-center items-start mx-2 gap-y-3">
							<input
								className="w-[45rem] h-[2.25rem] p-3 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
								value={captionValue}
								onKeyPress={(e) => {
									if (e.code === "Enter" || e.code === "NumpadEnter")
										e.preventDefault();
								}}
								onChange={onInputChangeHandler}
								onClick={(e) => {
									if (expand) e.stopPropagation();
									setDisplayBackgroundColorPicker(false);
									setDisplayCaptionColorPicker(false);
									setDisplayBorderColorPicker(false);
									setDisplayRectangleStylePicker(false);
								}}
								name="caption"
								placeholder="Enter a caption"
							/>
							{expand && (
								<div className="flex justify-around items-center gap-x-4">
									<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
										<p className="text-sm font-medium text-[#222222]">
											Caption Text
										</p>
										<button
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setDisplayBackgroundColorPicker(false);
												setDisplayBorderColorPicker(false);
												setDisplayRectangleStylePicker(false);
												captionPickerClickHandler();
											}}
											className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D] bg-white"
										>
											<div className="flex justify-between items-center h-[1.88rem]">
												<p className="mx-3 text-sm font-normal text-[#222222]">
													{selectedCaptionColor ?? defaultCaptionColor}
												</p>
												<div className="mx-1">
													<div className="border rounded">
														<div
															className="w-[1.06rem] h-[1.06rem]"
															style={{
																background:
																	selectedCaptionColor ?? defaultCaptionColor,
															}}
														/>
													</div>
													{displayCaptionColorPicker && (
														<div
															className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
															ref={popoverCaptionPicker}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
															}}
														>
															<HexColorPicker
																color={
																	selectedCaptionColor ?? defaultCaptionColor
																}
																onChange={(color) =>
																	onSelectCaptionColor(
																		idx,
																		color,
																		DEMO_CONTROLS.RECTANGLE
																	)
																}
															/>
															<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																<label>HEX</label>
																<HexColorInput
																	className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																	color={
																		selectedCaptionColor ?? defaultCaptionColor
																	}
																	onChange={(color) => {
																		onSelectCaptionColor(
																			idx,
																			color,
																			DEMO_CONTROLS.RECTANGLE
																		);
																		setTempCaptionSelectedColor(color);
																	}}
																	placeholder="Type hex value of color"
																	prefixed
																	alpha={false}
																/>
															</div>
															<DefaultField
																currentColor={
																	tempCaptionSelectedColor ??
																	selectedCaptionColor ??
																	defaultCaptionColor
																}
																defaultColor={defaultCaptionColor}
																onChange={saveDefaultCaptionColor}
															/>
														</div>
													)}
												</div>
											</div>
										</button>
									</div>
									<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
										<p className="text-sm font-medium text-[#222222]">
											Caption Background
										</p>
										<button
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setDisplayCaptionColorPicker(false);
												setDisplayBorderColorPicker(false);
												setDisplayRectangleStylePicker(false);
												backgroundPickerClickHandler();
											}}
											className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D] bg-white"
										>
											<div className="flex justify-between items-center h-[1.88rem]">
												<p className="mx-3 text-sm font-normal text-[#222222]">
													{selectedBackgroundColor ?? defaultBackgroundColor}
												</p>
												<div className="mx-1">
													<div className="border rounded">
														<div
															className="w-[1.06rem] h-[1.06rem]"
															style={{
																background:
																	selectedBackgroundColor ??
																	defaultBackgroundColor,
															}}
														/>
													</div>
													{displayBackgroundColorPicker && (
														<div
															className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
															ref={popoverBackgroundPicker}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
															}}
														>
															<HexColorPicker
																color={
																	selectedBackgroundColor ??
																	defaultBackgroundColor
																}
																onChange={(color) => {
																	onSelectBackgroundColor(idx, color);
																	setTempBackgroundSelectedColor(color);
																}}
															/>
															<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																<label>HEX</label>
																<HexColorInput
																	className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																	color={
																		selectedBackgroundColor ??
																		defaultBackgroundColor
																	}
																	onChange={(color) =>
																		onSelectBackgroundColor(idx, color)
																	}
																	placeholder="Type hex value of color"
																	prefixed
																	alpha={false}
																/>
															</div>
															<DefaultField
																currentColor={
																	tempBackgoundSelectedColor ??
																	selectedBackgroundColor ??
																	defaultBackgroundColor
																}
																defaultColor={defaultBackgroundColor}
																onChange={saveDefaultBackgroundColor}
															/>
														</div>
													)}
												</div>
											</div>
										</button>
									</div>
									<div className="relative flex flex-col items-start w-[9.94rem] gap-y-1">
										<p className="text-sm font-medium text-[#222222]">Border</p>
										<button
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setDisplayBackgroundColorPicker(false);
												setDisplayCaptionColorPicker(false);
												setDisplayRectangleStylePicker(false);
												borderPickerClickHandler();
											}}
											className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D] bg-white"
										>
											<div className="flex justify-between items-center h-[1.88rem]">
												<p className="mx-3 text-sm font-normal text-[#222222]">
													{selectedBorderColor ?? defaultBorderColor}
												</p>
												<div className="mx-1">
													<div className="border rounded">
														<div
															className="w-[1.06rem] h-[1.06rem]"
															style={{
																background:
																	selectedBorderColor ?? defaultBorderColor,
															}}
														/>
													</div>
													{displayBorderColorPicker && (
														<div
															className="absolute right-0 z-10 border rounded-md bg-white shadow-md"
															ref={popoverBorderPicker}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
															}}
														>
															<HexColorPicker
																color={
																	selectedBorderColor ?? defaultBorderColor
																}
																onChange={(color) => {
																	onSelectBorderColor(idx, color);
																	setTempBorderSelectedColor(color);
																}}
															/>
															<div className="flex items-center justify-center gap-x-2 mx-2 my-2">
																<label>HEX</label>
																<HexColorInput
																	className="w-full px-1 text-sm border rounded-md border-[#D9D9D9] focus:border-[#7D7D7D]"
																	color={
																		selectedBorderColor ?? defaultBorderColor
																	}
																	onChange={(color) =>
																		onSelectBorderColor(idx, color)
																	}
																	placeholder="Type hex value of color"
																	prefixed
																	alpha={false}
																/>
															</div>
															<DefaultField
																currentColor={
																	tempBackgoundSelectedColor ??
																	selectedBorderColor ??
																	defaultBorderColor
																}
																defaultColor={defaultBorderColor}
																onChange={saveDefaultBorderColor}
															/>
														</div>
													)}
												</div>
											</div>
										</button>
									</div>
									{/* <div
										className="relative flex flex-col items-start w-[9.94rem] gap-y-1"
										ref={popoverStylePicker}
									>
										<p className="text-sm font-medium text-[#222222]">
											Rectangle style
										</p>
										<button
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setDisplayBackgroundColorPicker(false);
												setDisplayCaptionColorPicker(false);
												setDisplayBorderColorPicker(false);
												rectangleStylePickerClickHandler();
											}}
											className="w-full border border-[#D9D9D9] rounded-md border focus:border-[#7D7D7D]"
										>
											<div className="flex justify-between items-center h-[1.88rem]">
												<p className="relative mx-3 text-sm font-normal text-[#222222]">
													Style {styleNumber}
												</p>
												<div className="mx-1">
													<p
														className={`${
															BLINKER_PACK[rectangleStyle.toUpperCase()]
														} ${
															+styleNumber === 1
																? "scale-[35%]"
																: +styleNumber === 5
																? "scale-[55%]"
																: "scale-75"
														}`}
													/>
													{displayRectangleStylePicker && (
														<BlinkersPack
															selected={rectangleStyle}
															onClick={(e, type, styleNumber = 6) => {
																setRectangleStyle(type);
																setStyleNumber(styleNumber);
																onSelectRectangleStyle(idx, type);
															}}
														/>
													)}
												</div>
											</div>
										</button>
									</div> */}
								</div>
							)}
						</div>
					</div>
					<div className="flex justify-center items-start">
						<button
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setDisplayCaptionColorPicker(false);
								setDisplayBackgroundColorPicker(false);
								setDisplayBorderColorPicker(false);
								setDisplayRectangleStylePicker(false);
								rectangleDeleteHandler(idx);
							}}
							className={`border-1 m-1 rounded-md`}
							type="button"
						>
							<img
								src={minusicon}
								alt="Rectangle"
								className="min-w-[1.5rem] min-h-[1.5rem]"
							/>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default RectangleEditor;

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import {
	useDeleteHeroMutation,
	useCreateHeroCopyMutation,
} from "../../../features/api/heroApiSlice";
import {
	useDeleteSoloMutation,
	useCreateSoloCopyMutation,
} from "../../../features/api/soloApiSlice";
import {
	useDeleteSneakpeekMutation,
	useCreateSneakpeekCopyMutation,
} from "../../../features/api/sneakpeekApiSlice";
import {
	useDeleteCyoaMutation,
	useCreateCyoaCopyMutation,
} from "../../../features/api/cyoaApiSlice";
import Modal from "react-modal";
import TopBar from "../../top-level/TopBar";
import plusCircle from "../../../icons/plus-circle.svg";
import externalLink from "../../../icons/external-link.svg";
import "../Embed.css";
import { EmbedBrowserCardContainer } from "./editor/EmbedBrowserCardContainer";
import cyoaImg from "../../../icons/cyoa-img.svg";
import soloImg from "../../../icons/solo-img.svg";
import heroImg from "../../../icons/hero-img.svg";
import StyledButton from "../../top-level/StyledButton";
import { DEMO, EMBED } from "../../../data/constants";
import { customStyles } from "../../../utils/modalStyles";
import { toastError, toastLoading } from "../../custom-toast/toasts";
import { amplitude } from "../../amplitude/Amplitude";

const EmbedBrowser = (widgets) => {
	// const companyId = useSelector((state) => state.sessionData.companyId);
	// const salesRepId = useSelector((state) => state.sessionData.sellerId);

	const [deleteHero] = useDeleteHeroMutation();
	const [deleteSolo] = useDeleteSoloMutation();
	const [deleteCyoa] = useDeleteCyoaMutation();
	const [copySolo] = useCreateSoloCopyMutation();
	const [copyHero] = useCreateHeroCopyMutation();
	const [copyCyoa] = useCreateCyoaCopyMutation();
	const history = useHistory();

	const [openWidgetSelectionPopUp, setOpenWidgetSelectionPopUp] =
		useState(false);
	const [selectedEmbed, setSelectedEmbed] = useState("cyoa");

	const copyWidget = (type, widget) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="flex flex-col w-[30rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<p className="mt-[2.25rem] text-[18px] font-medium">
							{`Would you like to copy this ${EMBED.toLowerCase()}?`}
						</p>
						<p className="mt-[1.5rem] text-[14px] leading-[14px]">
							This will create a new copy
						</p>
						<div className="flex flex-row justify-between mt-[3.375rem]">
							<button
								onClick={onClose}
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Cancel
							</button>
							<button
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
								onClick={async () => {
									const widgetData = {
										...widget,
										date_created: new Date()
											.toISOString()
											.slice(0, 19)
											.replace("T", " "),
										date_modified: new Date()
											.toISOString()
											.slice(0, 19)
											.replace("T", " "),
										name: `${widget.name} - Copy`,
									};
									const status = await eval(`copy${type}(widgetData).unwrap()`);
									if (status.status === "success") {
										// toastSuccess(`${EMBED} Copied`);
										onClose();
									} else {
										toastError(`Error copying ${EMBED.toLowerCase()}`);
										onClose();
									}
								}}
							>
								Copy
							</button>
						</div>
					</div>
				);
			},
		});
	};
	useEffect(()=>{
		if(widgets.solos.length ==0 + widgets.heros.length ==0 + widgets.cyoa.length ==50){
			widgets.setLimit(75) ;
			return
		}
		if(widgets.solos.length ==0 || widgets.heros.length ==0 || widgets.cyoa.length ==0 ){
			widgets.setLimit(50)
			return
		}	

	})
	const soloDeleteHandler = (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="flex flex-col w-[30rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<p className="mt-[2.25rem] text-[18px] font-medium">
							{`Are you sure you want to delete this ${EMBED.toLowerCase()}?`}
						</p>
						<p className="mt-[1.5rem] text-[14px] leading-[14px]">
							Once deleted cannot be recovered
						</p>
						<div className="flex flex-row justify-between mt-[3.375rem]">
							<button
								onClick={onClose}
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Cancel
							</button>
							<button
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#EDC8C5] font-roboto text-[16px] leading-[24px] border border-[#D83A52] text-[#D83A52] rounded-[0.25rem]"
								onClick={async () => {
									const popup = toastLoading("Delete in Progress");
									try {
										const deletion = await deleteSolo({ soloId: id }).unwrap();
										if (deletion.status === "success") {
											toast.dismiss(popup);
											// toastSuccess(`Solo ${EMBED} Deleted!`);
											onClose();
										} else {
											toast.dismiss(popup);
											toastError(
												`Failed to delete solo ${EMBED.toLowerCase()}`
											);
											onClose();
										}
									} catch (err) {
										toastError("Couldn't connect to database");
										onClose();
									}
								}}
							>
								Delete
							</button>
						</div>
					</div>
				);
			},
		});
	};

	const heroDeleteHandler = (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="flex flex-col w-[30rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<p className="mt-[2.25rem] text-[18px] font-medium">
							{`Are you sure you want to delete this ${EMBED.toLowerCase()}?`}
						</p>
						<p className="mt-[1.5rem] text-[14px] leading-[14px]">
							Once deleted cannot be recovered
						</p>
						<div className="flex flex-row justify-between mt-[3.375rem]">
							<button
								onClick={onClose}
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Cancel
							</button>
							<button
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#EDC8C5] font-roboto text-[16px] leading-[24px] border border-[#D83A52] text-[#D83A52] rounded-[0.25rem]"
								onClick={async () => {
									const popup = toastLoading("Delete in Progress");
									try {
										const deletion = await deleteHero({
											heroId: id,
										}).unwrap();
										if (deletion.status === "success") {
											toast.dismiss(popup);
											// toastSuccess(`Hero ${EMBED} Deleted!`);
											onClose();
										} else {
											toast.dismiss(popup);
											toastError(
												`Failed to delete hero ${EMBED.toLowerCase()}`
											);
											onClose();
										}
									} catch (err) {
										toastError("Couldn't connect to database");
										onClose();
									}
								}}
							>
								Delete
							</button>
						</div>
					</div>
				);
			},
		});
	};

	const cyoaDeleteHandler = (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="flex flex-col w-[30rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<p className="mt-[2.25rem] text-[18px] font-medium">
							{`Are you sure you want to delete this ${EMBED.toLowerCase()}?`}
						</p>
						<p className="mt-[1.5rem] text-[14px] leading-[14px]">
							Once deleted cannot be recovered
						</p>
						<div className="flex flex-row justify-between mt-[3.375rem]">
							<button
								onClick={onClose}
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Cancel
							</button>
							<button
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#EDC8C5] font-roboto text-[16px] leading-[24px] border border-[#D83A52] text-[#D83A52] rounded-[0.25rem]"
								onClick={async () => {
									const popup = toastLoading("Delete in Progress");
									try {
										const deletion = await deleteCyoa({
											cyoaId: id,
										}).unwrap();
										if (deletion.status === "success") {
											toast.dismiss(popup);
											// toastSuccess(`Cyoa ${EMBED} Deleted!`);
											onClose();
										} else {
											toast.dismiss(popup);
											toastError(
												`Failed to delete cyoa ${EMBED.toLowerCase()}`
											);
											onClose();
										}
									} catch (err) {
										toastError("Couldn't connect to database");
										onClose();
									}
								}}
							>
								Delete
							</button>
						</div>
					</div>
				);
			},
		});
	};

	return (
		<div className="flex flex-col justify-center items-center">
			<ReactTooltip effect="solid" />
			<div className="sticky top-0 flex flex-row justify-between bg-white py-4 w-[69rem] z-10">
				<div className="grow">
					<div className="flex flex-col">
						<p className="font-medium text-3xl">{`${EMBED}s`}</p>
						<div className="flex flex-row gap-x-2 justify-center items-center">
							<p className="text-base font-normal">
								Create and manage embedded widgets.
							</p>
							<div className="w-72 flex flex-row justify-start gap-x-1 text-[#0058BD] cursor-pointer hover:underline">
								<a
									href="https://go.zeroshq.com/Terms-of-Service"
									target={`_blank`}
									className="font-normal text-[13px] leading-[20px]"
									onClick={() =>
										amplitude.getInstance().logEvent("SampleGuideClicked", {
											timestamp: `${new Date().toUTCString()}`,
										})
									}
								>
									See more examples
								</a>

								<img
									src={externalLink}
									alt="externalLink"
									style={{
										filter:
											"invert(21%) sepia(98%) saturate(1902%) hue-rotate(199deg) brightness(90%) contrast(105%)",
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="flex grow">
					<div className="items-center justify-center">
						<div className="flex flex-row items-center gap-x-6 mt-3">
							<div>
							<button
										className="flex items-center h-[2.75rem] rounded-[0.375rem] text-white bg-[#0071F2] hover:bg-[#0351AA]"
										onClick={() => setOpenWidgetSelectionPopUp(true)}
									>
										<div className="flex flex-row mx-5 gap-x-[0.5rem]">
											<img
												src={plusCircle}
												alt="plusCircle"
												// className="w-[1.48rem] h-[1.48rem]"
											/>
											<p className="font-semibold text-base">
												Create {`${EMBED}`}
											</p>
										</div>
									</button>
							</div>
						</div>
						{
							// widgets.solos &&   widgets.heros &&widgets.cyoa &&
							// (
							// 	(!(widgets.solos.length < 25 && widgets.heros.length < 25 && widgets.cyoa.length < 25) && widgets.pageNO == 1)
							// 	||
							// 	((widgets.solos.length == 25 && widgets.heros.length == 25 && widgets.cyoa.length == 25) || widgets.pageNo != 1)
							// )
							// &&
							<div style={{
								paddingTop: "12px", display: "flex",
								alignItems: "center",
								gap: "16px",
							}}>
								<div
									onClick={widgets.moveToPrevPage}
									style={{
										cursor: "pointer",

										strokeWidth: "1.4px",
										stroke: widgets.pageNo == 1 ? "#A9A9A9" : "#A9A9A9",
										width: "28px",
										height: "28px"
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
										<path d="M16.625 19.25L11.375 14L16.625 8.75" stroke="#5F5F5F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
										<rect x="0.7" y="0.7" width="26.6" height="26.6" rx="3.96667" stroke={widgets.pageNo == 1 ? "#A9A9A9" : "#5BA7FE"} stroke-width="1.4" />
									</svg>
								</div>
								{/* <div style={{
									width: "40px",
									height: "28px",
									padding: "6px 5px",
									flexDirection: "column",
									justifyContent: "space-between",
									alignItems: "center",
								}}> */}
								<input type="text"
									style={{
										textAlign: "center",
										borderRadius: "5px",
										border: "2px solid #5BA7FE",
										outline: "none",
										width: "40px",
										height: "28px",
										padding: "6px 5px",
										flexDirection: "column",
										justifyContent: "space-between",
										alignItems: "center",
									}}
									value={widgets.pageNo}
									onChange={(event) => {
									
										if(isNaN(+(event.target.value))){
											return 
										}else{
											widgets.setPageNo(event.target.value)
										}
										// if (!isNaN(+(event.target.value))) {
										// 	setPageNo(event.target.value)

										// }
									}}
								></input>
								{/* <p>{pageNo}</p> */}
								{/* </div> */}
								<div

									onClick={widgets.moveToNextPage}
									style={{
										pointerEvents: widgets.solos.length < 25 && widgets.heros.length < 25 && widgets.cyoa.length < 25,
										cursor: "pointer",
										strokeWidth: "1.4px",
										stroke: "#A9A9A9",
										width: "28px",
										height: "28px"
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
										<path d="M11.375 19.25L16.625 14L11.375 8.75" stroke="#5F5F5F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
										<rect x="0.7" y="0.7" width="26.6" height="26.6" rx="3.96667" stroke={widgets.solos.length < 25 && widgets.heros.length < 25 && widgets.cyoa.length < 25 ? "#A9A9A9" : "#5BA7FE"} stroke-width="1.4" />
									</svg>
								</div>

							</div>
						}
				
					</div>
				</div>

			</div>
			<div className="flex justify-center mt-[3rem]">
				<div className="flex flex-col justify-center">
					<div className="flex flex-row justify-center">
						<EmbedBrowserCardContainer
							solo={widgets.solos}
							hero={widgets.heros}
							cyoa={widgets.cyoa}
							copyWidget={copyWidget}
							deleteCyoa={cyoaDeleteHandler}
							deleteHero={heroDeleteHandler}
							deleteSolo={soloDeleteHandler}
						/>
					</div>
				</div>
			</div>
			<Modal
				isOpen={openWidgetSelectionPopUp}
				onRequestClose={() => {
					setOpenWidgetSelectionPopUp(false);
				}}
				style={customStyles}
			>
				<div className="flex flex-col mx-[80px] my-[48px] gap-y-[48px]">
					<div className="flex flex-col justify-center items-center">
						<p className="font-medium text-[28px]">
							Select the type of {`${EMBED.toLowerCase()}`} to continue
						</p>
					</div>

					<div
						className="flex flex-row justify-center items-center gap-x-[32px]"
						onClick={() => setSelectedEmbed("cyoa")}
					>
						<div className="flex flex-col justify-center items-center rounded-full w-6 h-6 border-2 border-[#2B71CE]">
							<div
								className={
									selectedEmbed === "cyoa"
										? "w-[0.875rem] h-[0.875rem] rounded-full bg-[#2B71CE]"
										: "hidden"
								}
							/>
						</div>
						<div>
							<img
								src={cyoaImg}
								alt=""
								className={`w-[11.75rem] h-[9rem] rounded-[0.81rem] ${
									selectedEmbed === "cyoa" ? "border-2 border-zeros-blue" : ""
								}`}
							/>
						</div>
						<div className="flex flex-col gap-y-[0.5rem] ml-[0.875rem]">
							<p className="font-medium text-[24px]">Choose Your Adventure</p>
							<p className="font-normal text-[13px] text-[#7D7D7D] w-[20rem]">
								Take the prospect through a Choose Your Adenture™ journey. Probe
								their needs, then wow them with an ‘aha’ moment based on their
								answers.
							</p>
							<div className="flex flex-row justify-start gap-x-1 text-[#0058BD] cursor-pointer hover:underline">
								<a
									href="https://go.zeroshq.com/Terms-of-Service"
									target={`_blank`}
									className="font-normal text-[13px] leading-[20px]"
									onClick={() =>
										amplitude.getInstance().logEvent("SampleGuideClicked", {
											timestamp: `${new Date().toUTCString()}`,
										})
									}
								>
									Examples of Choose Your Adventure™ journeys
								</a>

								<img
									src={externalLink}
									alt="externalLink"
									style={{
										filter:
											"invert(21%) sepia(98%) saturate(1902%) hue-rotate(199deg) brightness(90%) contrast(105%)",
									}}
									// className="w-[1.48rem] h-[1.48rem]"
								/>
							</div>
						</div>
					</div>

					<div
						className="flex flex-row justify-center items-center gap-x-6"
						onClick={() => setSelectedEmbed("solo")}
					>
						<div className="flex flex-col justify-center items-center rounded-full w-6 h-6 border-2 border-[#2B71CE]">
							<div
								className={
									selectedEmbed === "solo"
										? "w-[0.875rem] h-[0.875rem] rounded-full bg-[#2B71CE]"
										: "hidden"
								}
							/>
						</div>
						<div>
							<img
								src={soloImg}
								alt=""
								className={`w-[11.75rem] h-[9rem] rounded-[0.81rem] ${
									selectedEmbed === "solo" ? "border-2 border-zeros-blue" : ""
								}`}
							/>
						</div>
						<div className="flex flex-col gap-y-[0.5rem] ml-[0.875rem]">
							<p className="font-medium text-[24px]">Solo {`${EMBED}`}</p>
							<p className="font-normal text-[13px] text-[#7D7D7D] w-[20rem]">
								Embed a single demo. This is best suited for scenarios like
								introductions or where a focused story needs to be told to the
								prospects.
							</p>
						</div>
					</div>

					<div
						className="flex flex-row justify-center items-center gap-x-6"
						onClick={() => setSelectedEmbed("hero")}
					>
						<div className="flex flex-col justify-center items-center rounded-full w-6 h-6 border-2 border-[#2B71CE]">
							<div
								className={
									selectedEmbed === "hero"
										? "w-[0.875rem] h-[0.875rem] rounded-full bg-[#2B71CE]"
										: "hidden"
								}
							/>
						</div>
						<div>
							<img
								src={heroImg}
								alt=""
								className={`w-[11.75rem] h-[9rem] rounded-[0.81rem] ${
									selectedEmbed === "hero" ? "border-2 border-zeros-blue" : ""
								}`}
							/>
						</div>
						<div className="flex flex-col gap-y-[0.5rem] ml-[0.875rem]">
							<p className="font-medium text-[24px]">Hero {`${EMBED}`}</p>
							<p className="font-normal text-[13px] text-[#7D7D7D] w-[20rem]">
								Emphasize the visual narrative with a description next to the
								embed. Change the text or the demo as many times and it goes
								live instantly.
							</p>
						</div>
					</div>

					<div className="flex flex-row-reverse gap-x-[2rem] items-center">
						<StyledButton
							type="primary"
							className="flex justify-center items-center w-[6.63rem] h-[2.75rem] bg-[#0071F2] text-white text-[16px] font-semibold rounded-[0.25rem]"
							onClick={() => {
								setOpenWidgetSelectionPopUp(false);
								history.push(`/feature/embeds/${selectedEmbed}`);
							}}
						>
							Continue
						</StyledButton>
						<button
							className="hover:underline"
							onClick={() => setOpenWidgetSelectionPopUp(false)}
						>
							Cancel
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};
export default EmbedBrowser;

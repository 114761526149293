import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const SneakpeekTagTypes = {
  SNEAKPEEK: "sneakpeek",
  SINGLE_SNEAKPEEK: "single-sneakpeek",
};

export const sneakpeekApiSlice = createApi({
  reducerPath: "api/sneakpeek",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER}/web`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().sessionData.token;
      if (token) {
        headers.set("token", token);
      }
      return headers;
    },
  }),
  tagTypes: Object.values(SneakpeekTagTypes),
  endpoints: (builder) => ({
    getSneakpeek: builder.query({
      query: (initialPost) => "/sneakpeek/" + initialPost.companyId,
      providesTags: [SneakpeekTagTypes.SNEAKPEEK],
    }),
    getSneakpeekById: builder.query({
      query: (initialPost) => "/sneakpeek/id/" + initialPost.id,
      providesTags: [SneakpeekTagTypes.SINGLE_SNEAKPEEK],
    }),
    createSneakpeek: builder.mutation({
      query: (initialPost) => ({
        url: "/sneakpeek",
        method: "POST",
        body: initialPost,
      }),
      invalidatesTags: [SneakpeekTagTypes.SNEAKPEEK],
    }),
    createSneakpeekCopy: builder.mutation({
      query: (initialPost) => ({
        url: "/sneakpeek/copy",
        method: "POST",
        body: initialPost,
      }),
      invalidatesTags: [SneakpeekTagTypes.SNEAKPEEK],
    }),
    updateSneakpeek: builder.mutation({
      query: (initialPost) => ({
        url: "/sneakpeek/" + initialPost.sneakpeekId,
        method: "PUT",
        body: {
          name: initialPost.name,
          recording_id: initialPost.recordingId,
          is_muted: initialPost.is_muted,
          is_gated: initialPost.is_gated,
          show_controls: initialPost.show_controls,
          date_modified: initialPost.date_modified,
        },
      }),
      invalidatesTags: [
        SneakpeekTagTypes.SNEAKPEEK,
        SneakpeekTagTypes.SINGLE_SNEAKPEEK,
      ],
    }),
    deleteSneakpeek: builder.mutation({
      query: (initialPost) => ({
        url: "/sneakpeek/" + initialPost.sneakpeekId,
        method: "DELETE",
      }),
      invalidatesTags: [
        SneakpeekTagTypes.SNEAKPEEK,
        SneakpeekTagTypes.SINGLE_SNEAKPEEK,
      ],
    }),
  }),
});

export const {
  useGetSneakpeekQuery,
  useGetSneakpeekByIdQuery,
  useCreateSneakpeekMutation,
  useCreateSneakpeekCopyMutation,
  useUpdateSneakpeekMutation,
  useDeleteSneakpeekMutation,
} = sneakpeekApiSlice;

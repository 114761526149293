import ViewContainer from "../../top-level/ViewContainer";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { PAGE } from "../../../data/constants";
import { getBackgroundColor } from "../../../utils/avatar";
import previewIcon from "../../../icons/preview-icon.svg";
import PublishModal from "../../embed/components/editor/components/components/PublishModal";
import PostPublishModal from "../../embed/components/editor/components/components/PostPublishModal";
import PrePublishModal from "../../embed/components/editor/components/components/PrePublishModal";
import userSettingReducer, {
	setSaveBeforePublishPrompt_Page,
} from "../../../features/state/user-settings/userSettingSlice";
import React, { useEffect, useState } from "react";
import {
	useGetPageTitleQuery,
	useUpdatePageTitleMutation,
	useUpdatePageVisibilityMutation,
} from "../../../features/api/pagesApiSlice";
import { PageSpinner } from "../../top-level/PageSpinner";
import { toast } from "react-toastify";
import { getStateFromCookies } from "redux-cookies-middleware";
import { paths } from "../../../features/cookies-paths/paths";
import { useSelector } from "react-redux";
import {
	useGetPageStyleTemplateQuery,
	useUpdatePageStyleMutation,
} from "../../../features/api/settingsApiSlice";
import { useHistory } from "react-router-dom";
import StyledButton from "../../top-level/StyledButton";
import Select from "react-select";
import { embedSelect } from "../../../utils/reactSelectStyles";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import { useGetHeroQuery } from "../../../features/api/heroApiSlice";
import { useGetSoloQuery } from "../../../features/api/soloApiSlice";
import { useGetCyoaQuery } from "../../../features/api/cyoaApiSlice";
import uuid from "react-uuid";
import { extractPlaceholders } from "../../../utils/extractPlaceholders";
import {
	toastError,
	toastLoading,
	toastSuccess,
} from "../../custom-toast/toasts";
import { Settings } from "./Settings";
import { CYAVerificationCard } from "../../embed/components/editor/components/CYAComponents/CYAVerificationCard";
import greenInfoBulbIcon from "../../../icons/green-info-bulb-icon.svg";
import crossIcon from "../../../icons/cross-icon.svg";

export const PageEditEmbed = ({ id, live, type }) => {
	const flags = useSelector((state) => JSON.parse(state.userSetting.flags));
	const [pageTitle, setPageTitle] = useState("");
	const fetchTitle = useGetPageTitleQuery({ pageId: id });
	const fetchPageTemplate = useGetPageStyleTemplateQuery({ pageId: id });

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [selectedEmbed, setSelectedEmbed] = useState(null);
	const [widgetOptions, setWidgetOptions] = useState([]);
	const [verifyIdentity, setVerifyIdentity] = useState(flags.gating.checked);
	const [customVerification, setCustomVerification] = useState(
		flags.gating.page.verify.checked
	);
	const [isOutbound, setIsOutbound] = useState(
		flags.gating.page.outbound.checked
	);
	const [isRequired, setIsRequired] = useState(
		flags.gating.page.required.checked
	);
	const [checkBlocklist, setCheckBlocklist] = useState(
		flags.gating.page.verify.blocklist.checked
	);
	const [checkDomain, setCheckDomain] = useState(
		flags.gating.page.verify.domain.checked
	);
	const [checkIntended, setCheckIntended] = useState(
		flags.gating.page.verify.intended.checked
	);
	const [checkTwoFactor, setCheckTwoFactor] = useState(
		flags.gating.page.verify.twofactor.checked
	);
	const [verificationContent, setVerificationContent] = useState({
		title: "Enter your email address to continue",
		description: "Enter your email address below to access the content",
		placeholder: "",
	});

	const saveBeforePublishPrompt = getStateFromCookies(userSettingReducer, paths)
		.userSetting.saveBeforePublishPrompt_Page;
	const [openPublishPopUp, setOpenPublishPopUp] = useState(false);
	const [openSaveAfterPublish, setOpenSaveAfterPublish] = useState(false);
	const [openSaveBeforePublish, setOpenSaveBeforePublish] = useState(false);
	const [neverShowPrompt, setNeverShowPrompt] = useState(false);
	const [hideInfoBubble, setHideInfoBubble] = useState(false);

	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);

	const {
		data: heroData,
		isFetching: heroIsFetching,
		isError: heroIsError,
	} = useGetHeroQuery({
		companyId: companyId,
		limit:10000,
		pageNo:1
	});
	const {
		data: soloData,
		isFetching: soloIsFetching,
		isError: soloIsError,
	} = useGetSoloQuery({
		companyId: companyId,
		limit:10000,
		pageNo:1
	});
	const {
		data: cyoaData,
		isFetching: cyoaIsFetching,
		isError: cyoaIsError,
	} = useGetCyoaQuery({
		companyId: companyId,
		limit:10000,
		pageNo:1
	});
	const [updateStyle] = useUpdatePageStyleMutation();
	const [updatePageTitle] = useUpdatePageTitleMutation();
	const [goLive] = useUpdatePageVisibilityMutation();
	const history = useHistory();

	useEffect(() => {
		if (!fetchTitle.isLoading) {
			if (fetchTitle.data !== null) {
				setPageTitle(fetchTitle.data.payload[0].title);
			}
		}
	}, [fetchTitle]);

	useEffect(() => {
		if (!heroIsFetching && !soloIsFetching && !cyoaIsFetching) {
			const widgetList = [];
			if (heroData.payload.length !== 0) {
				heroData.payload.forEach((el) => {
					widgetList.push({
						key: uuid(),
						value: uuid(),
						id: el.id,
						label: el.name,
						type: "hero",
						date_created: el.date_created,
					});
				});
			}
			if (soloData.payload.length !== 0) {
				soloData.payload.forEach((el) => {
					widgetList.push({
						key: uuid(),
						value: uuid(),
						id: el.id,
						label: el.name,
						type: "solo",
						date_created: el.date_created,
					});
				});
			}
			if (cyoaData.payload.length !== 0) {
				cyoaData.payload.forEach((el) => {
					widgetList.push({
						key: uuid(),
						value: uuid(),
						id: el.id,
						label: el.name,
						type: "cyoa",
						date_created: el.date_created,
					});
				});
			}
			setWidgetOptions(
				widgetList.sort((a, b) => {
					return new Date(b.date_created) - new Date(a.date_created);
				})
			);
		}
	}, [heroIsFetching, soloIsFetching, cyoaIsFetching]);

	useEffect(() => {
		if (!fetchPageTemplate.isFetching && widgetOptions.length !== 0) {
			if (fetchPageTemplate.data.payload[0]?.page_style === undefined) return;
			const template = JSON.parse(fetchPageTemplate.data.payload[0].page_style);
			const parsedVerificationContent = template.verificationContent;
			setVerifyIdentity(template.settings?.verifyIdentity);
			setCustomVerification(template.settings?.customVerification);
			setIsOutbound(template.settings?.isOutbound);
			setIsRequired(template.settings?.isRequired);
			setCheckBlocklist(template.settings?.checkBlocklist);
			setCheckDomain(template.settings?.checkDomain);
			setCheckIntended(template.settings?.checkIntended);
			setCheckTwoFactor(template.settings?.checkTwoFactor);

			if (parsedVerificationContent) {
				setVerificationContent(parsedVerificationContent);
			}
			const parseEmbed = template?.embed;
			let demo = [];
			if (Array.isArray(parseEmbed)) {
				demo = widgetOptions.filter(
					(it) => it.id === parseEmbed[0]?.id && it.type === parseEmbed[0]?.type
				);
			} else {
				demo = widgetOptions.filter(
					(it) => it.id === parseEmbed?.id && it.type === parseEmbed?.type
				);
			}
			setTitle(template?.title);
			setDescription(template?.description);
			if (demo.length !== 0) {
				setSelectedEmbed(demo);
			}
		}
	}, [fetchPageTemplate.isFetching, widgetOptions]);

	if (
		fetchTitle.isFetching ||
		soloIsFetching ||
		heroIsFetching ||
		cyoaIsFetching ||
		fetchPageTemplate.isFetching
	) {
		return <PageSpinner text="Loading Page..." />;
	}

	if (
		fetchTitle.isError ||
		soloIsError ||
		heroIsError ||
		cyoaIsError ||
		fetchPageTemplate.isError
	) {
		toastError("Couldn't retrieve data. Refresh page.");
	}

	const savePageSetting = async () => {
		if (pageTitle === "") {
			return toastError("Page Title is empty");
		} else if (title === "" || description === "") {
			return toastError("Please add a title and description");
		} else if (selectedEmbed === null) {
			return toastError("Please select an embed");
		}

		const toastId = toastLoading();
		try {
			const pageTitleStatus = await updatePageTitle({
				pageId: id,
				title: pageTitle,
			}).unwrap();
			const pageData = {
				embed: selectedEmbed,
				title: title,
				description: description,
				settings: {
					verifyIdentity,
					customVerification,
					isOutbound,
					isRequired,
					checkBlocklist,
					checkDomain,
					checkIntended,
					checkTwoFactor,
				},
				verificationContent,
			};
			const status = await updateStyle({
				pageId: id,
				style: JSON.stringify(pageData),
				placeholders: JSON.stringify(extractPlaceholders(title, description)),
			}).unwrap();
			if (status.status === "success") {
				toast.dismiss(toastId);
				toastSuccess("Page Updated");
				return true;
			} else {
				toast.dismiss(toastId);
				toastError("Error Encountered While Updating Page");
				return false;
			}
		} catch (error) {
			toastError("Error Encountered While Updating Page");
		}
	};

	const handlePreview = async () => {
		const status = await savePageSetting();
		const encryptedId =
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDEsIm5hbWUiOiJVc2VyIiwiZW1haWwiOiJ6ZXJvc0BwcmV2aWV3LmNvbSIsInBhZ2VJZCI6Nywic2FsZXNyZXBJZCI6OSwiY29tcGFueUlkIjoxNywic3RhbXAiOjE2NDc0MzIxMDYxMzcsImlhdCI6MTY0NzQzMjEwNn0.agGHhfVAjDoh4YsrJ8u61M2WG6kzKMCfDiZNAX1NR64";
		if (status) {
			window.open(
				`${process.env.REACT_APP_CLIENT_PROSPECT}/${encryptedId}/preview/${id}/${companyId}/demo`
			);
		}
	};

	const handlePublishPage = async () => {
		await savePageSetting();
		const status = await goLive({
			pageId: id,
			companyId: companyId,
			live: parseInt(live) !== 0,
		}).unwrap();
		if (status.status === "success") {
			// toastSuccess("Page Visibility Updated");
			history.push("..");
		} else if (status.status === "error" && status.payload === null) {
			toastError("Create Page and Company Settings Before Publishing!");
		} else {
			toastError("Error Encountered While Publishing Page");
		}
	};

	return (
		<ViewContainer>
			<div className="flex flex-col justify-center items-center">
				<div className="w-[69rem]">
					<div className="sticky top-0 flex flex-row justify-between py-4 bg-white z-50">
						<div className="flex flex-col w-3/5 mb-4">
							<input
								type="text"
								className="px-1.5 py-0.5 font-normal leading-5 text-xl font-medium text-[#222222] border border-white focus:rounded-sm focus:border-zeros-blue"
								value={pageTitle}
								placeholder={`Title for this ${PAGE.toLowerCase()}`}
								onChange={(e) => setPageTitle(e.target.value)}
							/>
							<div className="flex flex-row mx-3 mt-1 gap-x-2 items-center">
								<div className="flex justify-center items-center gap-x-2">
									<div
										className="flex justify-center items-center w-6 h-6 rounded-full text-white"
										style={{
											backgroundColor: getBackgroundColor(
												fetchTitle.data.payload[0].owner_id
											),
										}}
									>
										{fetchTitle.data.payload[0].name.trim()[0]}
									</div>
									<p className="text-base font-normal text-[#888888]">
										{fetchTitle.data.payload[0].name.trim()}
									</p>
								</div>
								<div className="w-1 h-1 rounded-full bg-[#686868]" />
								<p className="text-base font-normal text-[#888888]">
									{new Date().toLocaleDateString("en-us", {
										year: "numeric",
										month: "long",
										day: "numeric",
									})}
								</p>
							</div>
						</div>
						<div className="flex gap-x-[18px]">
							<button
								className={`border w-[44px] h-[44px] rounded-md mt-1 font-medium bg-[#FFFFFF] hover:bg-[#E6E9EF] flex items-center justify-center`}
								type="button"
								onClick={() => handlePreview()}
								// disabled={isNew}
							>
								<img
									src={previewIcon}
									alt="preview"
									className="w-[24px] h-[24px]"
								/>
							</button>
							{live === "0" ? (
								<button
									className={
										live === "0"
											? `border border-[#63A4FA] w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-[#1D60B5] bg-[#FFFFFF] hover:bg-[#CCE5FF]`
											: `border w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium bg-[#E8E8E8] text-[#6F6F6F] rounded disabled cursor-not-allowed`
									}
									onClick={() => setOpenPublishPopUp(true)}
									// type="button"
									disabled={live === "1"}
								>
									Publish
								</button>
							) : (
								<button
									className={`w-[7.56rem] h-[2.75rem] rounded-md mt-1 px-2.5 bg-[#E8E8E8] disabled cursor-not-allowed`}
									// onClick={() => publish()}
									type="button"
									disabled={true}
								>
									<div className="flex flex-row justify-center items-center gap-x-[8px]">
										<div className="w-[14px] h-[14px] bg-[#4CB051] rounded-full" />
										<p className="text-[16px] leading-[16px] text-[#6F6F6F]">
											Published
										</p>
									</div>
								</button>
							)}
							<button
								className={`border w-[4.75rem] h-[2.75rem] rounded-md mt-1 px-2.5 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA]`}
								type="button"
								onClick={() => {
									live === "1"
										? setOpenSaveAfterPublish(true)
										: saveBeforePublishPrompt ||
										  saveBeforePublishPrompt === undefined
										? setOpenSaveBeforePublish(true)
										: savePageSetting();
								}}
							>
								Save
							</button>
						</div>
					</div>
					<Tabs forceRenderTabPanel={true}>
						<TabList className="flex items-center w-full">
							<Tab
								className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
								selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
							>
								Layout
							</Tab>
							<Tab
								className="flex justify-center w-full font-medium text-lg text-[#7D7D7D] hover:text-[#2262B6] pb-4 cursor-pointer border-b border-b-[#D9D9D9]"
								selectedClassName="border-b-[2px] border-b-[#5290E0] text-[#2262B6]"
							>
								Settings
							</Tab>
						</TabList>
						<TabPanel>
							<div
								className={
									!hideInfoBubble
										? `flex flex-col bg-[#CFFFF1] rounded-[8px] py-[10px] pl-[63px] pr-[31px] my-[20px]`
										: "hidden"
								}
							>
								<div className={`flex flex-row items-start justify-between`}>
									<div className={`flex flex-row items-start gap-x-[16px]`}>
										<img src={greenInfoBulbIcon} alt="info" />
										<p className={`text-[#0F7053] text-[13px] leading-[22px]`}>
											<span
												className={`text-[#0F7053] text-[13px] leading-[22px] font-semibold`}
											>
												Placeholders
											</span>
											<br />
											Use placeholders to personalize pages for recipients in
											bulk. Add them to a text field, then replace them with
											columns from a <br />
											contact list at the time of generating Page URLs.
											<br />
											Example: Hi {`<Full name>`}. For a limited time, we are
											offering {`<discount_rate>`}. <br />
											All placeholders are case sensitive.
										</p>
									</div>
									<button
										className={`flex justify-center items-center`}
										onClick={() => {
											setHideInfoBubble(true);
										}}
									>
										<img
											src={crossIcon}
											alt="close"
											className={`h-[24px] w-[24px] text-[#7D7D7D]`}
										/>
									</button>
								</div>
							</div>
							<div
								className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px] my-[20px]`}
							>
								<div
									className={
										"flex items-center justify-start h-[2.38rem] bg-transparent border-[#DEE8ED] border-b gap-x-[10px]"
									}
								>
									<p className="text-[#255CA4] text-[18px] leading-[18px] ml-[3.938rem]">
										Welcome
									</p>
									<div className="w-1 h-1 rounded-full bg-[#686868]" />
									<p className="text-[#7D7D7D] text-[14px] leading-[14px]">
										Start them off with a short overview of your company
									</p>
								</div>
								<div
									className={`flex flex-row bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px] py-[20px]`}
								>
									<div className="flex flex-col gap-y-[18px] ml-[50px]">
										<input
											type="text"
											className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem]"
											value={title}
											onChange={(e) => setTitle(e.target.value)}
											placeholder="Enter a headline"
										/>
										<textarea
											value={description}
											placeholder="Description - 400 characters or less"
											maxLength={400}
											onChange={(e) => setDescription(e.target.value)}
											className="flex text-[15px] leading-[22px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[35.06rem] h-[4.5rem]"
										/>
										<Select
											className="w-80"
											value={selectedEmbed}
											styles={embedSelect}
											components={makeAnimated()}
											options={widgetOptions}
											placeholder="Select Embed"
											onChange={(e) => setSelectedEmbed(e)}
											isSearchable
										/>
									</div>
								</div>
							</div>
							<div className={verifyIdentity ? "flex flex-col" : "hidden"}>
								<CYAVerificationCard
									verificationContent={verificationContent}
									setVerificationContent={setVerificationContent}
								/>
							</div>
						</TabPanel>
						<TabPanel>
							<Settings
								verifyIdentity={verifyIdentity}
								setVerifyIdentity={setVerifyIdentity}
								customVerification={customVerification}
								setCustomVerification={setCustomVerification}
								isOutbound={isOutbound}
								setIsOutbound={setIsOutbound}
								isRequired={isRequired}
								setIsRequired={setIsRequired}
								checkBlocklist={checkBlocklist}
								setCheckBlocklist={setCheckBlocklist}
								checkDomain={checkDomain}
								setCheckDomain={setCheckDomain}
								checkIntended={checkIntended}
								setCheckIntended={setCheckIntended}
								checkTwoFactor={checkTwoFactor}
								setCheckTwoFactor={setCheckTwoFactor}
							/>
						</TabPanel>
					</Tabs>
					<PublishModal
						open={openPublishPopUp}
						setOpen={setOpenPublishPopUp}
						onSubmit={handlePublishPage}
					/>
					<PostPublishModal
						open={openSaveAfterPublish}
						setOpen={setOpenSaveAfterPublish}
						onSubmit={() => {
							savePageSetting();
						}}
					/>
					<PrePublishModal
						open={openSaveBeforePublish}
						setOpen={setOpenSaveBeforePublish}
						neverShowPrompt={neverShowPrompt}
						setNeverShowPrompt={setNeverShowPrompt}
						onSubmit={() => {
							savePageSetting();
						}}
						reducer={setSaveBeforePublishPrompt_Page}
					/>
				</div>
			</div>
		</ViewContainer>
	);
};

import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import Dashboard from "./components/dashboard/Dashboard";
import {Login} from "./components/login/Login";
import {useSelector} from "react-redux";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {ResetPassword} from "./components/login/ResetPassword";
import {SignUp} from "./components/login/SignUp";
import toastCloseIcon from "./icons/toast-close-icon.svg"
import {MigrateLogin} from "./components/login/MigrateLogin";
import {LinkedInCallBack} from "./components/linkedin/LinkedIn";

function App() {
	const CloseButton = ({ closeToast }) => (
		<i onClick={closeToast} className="mt-[14px] mr-[14px]">
			<img src={toastCloseIcon} alt="close" className={"w-[20px] h-[20px]"}/>
		</i>
	);


	// Sentry.init({
  //       dsn: "https://41a1a3ef59a14afe901c70a5acfd47d2@o1262621.ingest.sentry.io/6445114",
  //       integrations: [new BrowserTracing()],
	//
  //       // Set tracesSampleRate to 1.0 to capture 100%
  //       // of transactions for performance monitoring.
  //       // We recommend adjusting this value in production
  //       tracesSampleRate: 1.0,
  //   });

    return (
        <>
						<Switch>
							<Route exact path="/linkedin" component={LinkedInCallBack} />
							<Route exact path="/signup" component={SignUp} />
							<Route exact path="/reset" component={ResetPassword} />
							<Route exact path="/login" component={Login} />
							<Route exact path="/" component={Dashboard} />
							<Route exact path="/matrix" component={MigrateLogin} />
							<Route path="/feature/*" component={Dashboard} />
						</Switch>
            <ToastContainer
                position="bottom-center"
								// icon={false}
								style={{width: "410px"}}
								closeButton={<CloseButton/>}
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                // pauseOnFocusLoss
                draggable
                pauseOnHover/>
        </>
    );
}


export default App;

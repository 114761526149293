import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

export const RelationTagTypes = {
	RELATIONSHIP: "relationship",
};

export const relationshipApiSlice = createApi({
	reducerPath: "api/relationships",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_SERVER}/web`,
		prepareHeaders: (headers, {getState}) => {
			const token = getState().sessionData.token
			if (token) {
				headers.set('token', token)
			}
			return headers
		}
	}),
	tagTypes: Object.values(RelationTagTypes),
	endpoints: builder => ({
		getRelationships: builder.query({
			query: (initialPost) => "salesrep/" + initialPost.salesRepId + "/relationship?page_id=" + initialPost.pageId,
			providesTags: [RelationTagTypes.RELATIONSHIP],
		}),
		createRelationship: builder.mutation({
			query: initialPost => ({
				url: "/relationship",
				method: "POST",
				body: {
					salesrep_id: initialPost.salesRepId,
					prospect_id: initialPost.prospectId,
					company_id: initialPost.companyId,
					page_id: initialPost.pageId,
					engaged: initialPost.engaged,
					encrypted_id: initialPost.encryptedId,
					date_created: initialPost.dateCreated
				},
			}),
			invalidatesTags: [RelationTagTypes.RELATIONSHIP],
		}),
		deleteRelationship: builder.mutation({
			query: initialPost => ({
				url: "/relationship/" + initialPost.relationshipId,
				method: "DELETE",
			}),
			invalidatesTags: [RelationTagTypes.RELATIONSHIP],
		}),
	}),
});

export const {
	useGetRelationshipsQuery,
	useCreateRelationshipMutation,
	useDeleteRelationshipMutation
} = relationshipApiSlice;

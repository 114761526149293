import './TagsInput.css';

const TagsInput = ({ corpEmails, setCorpEmails }) => {

  const removeCorpEmail = indexToRemove => {
    setCorpEmails([...corpEmails.filter((_, index) => index !== indexToRemove)]);
  };
  const addCorpEmail = event => {
    if (event.target.value !== "") {
      if(event.target.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
        if (!corpEmails.includes(event.target.value)) {
          console.log("valid");
          setCorpEmails([...corpEmails, event.target.value.replace(/(^,)|(,$)/g, "")]);
          event.target.value = "";
        }
      } else {
        console.log('invalid');
      }
    }
  };
  return (
    <div>
      <div className="tags-input">
        <ul id="tags">
          {corpEmails && corpEmails.map((corpEmail, index) => (
            <li key={index} className="tag">
              <span className='tag-title'>{corpEmail}</span>
              <span className='tag-close-icon'
                    onClick={() => removeCorpEmail(index)}
              >
							x
						</span>
            </li>
          ))}
        </ul>
        <input
          className="border-0 focus:outline-none focus:border-none"
          type="text"
          onKeyUp={event => event.key === "Enter" ? addCorpEmail(event) : null}
          placeholder="Press enter to add emails"
        />
      </div>
    </div>
  );
};

export default TagsInput;
import {useFilePicker} from "use-file-picker";
import TopBar from "../top-level/TopBar";
import {CONTACT} from "../../data/constants";
import arrowUpIcon from "../../icons/arrow-up-icon.svg";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {hasExtension} from "../../utils/uploadFiles";
import {PageSpinner} from "../top-level/PageSpinner";
import {
	useCreateContactListMutation,
	useDeleteContactListMutation,
	useGetAllContactListsQuery
} from "../../features/api/contactsApiSlice";
import {useSelector} from "react-redux";
import {getBackgroundColor} from "../../utils/avatar";
import {Route, Switch, useHistory} from "react-router-dom";
import {ContactListView} from "./components/ContactListView";
import deleteIcon from "../../icons/delete-icon.svg";
import ReactTooltip from "react-tooltip";
import {confirmAlert} from "react-confirm-alert";
import {customStyles} from "../../utils/modalStyles";
import Modal from "react-modal";
import closeCrossIcon from "../../icons/closecross-icon.svg";
import crossIcon from "../../icons/cross-icon.svg";
import greenCheckIcon from "../../icons/green-check-icon.svg"
import {Spinner} from "../top-level/Spinner";
import Papa from "papaparse";
import {toastError, toastLoading} from "../custom-toast/toasts";

const UploadContactList = ({openUploadModal, setOpenUploadModal}) => {
	const [openFileSelector, {filesContent, loading, errors, plainFiles, clear}] = useFilePicker({
		accept: '.csv',
		readAs: 'Text',
		limitFilesConfig: {max: 1},
	});
	const [stepNumber, setStepNumber] = useState(1);
	const [listName, setListName] = useState("");
	const [uploadedFile, setUploadedFile] = useState(null);
	const [showHeaders, setShowHeaders] = useState(true);
	const [sheetData, setSheetData] = useState(null);

	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);

	const [createContactList, {}] = useCreateContactListMutation();

	useEffect(async () => {
		if (errors.length > 0) {
			toastError("Error uploading file");
			return;
		}
		if (plainFiles.length > 0 && errors.length <= 0) {
			if (
				hasExtension(plainFiles[0].name, [
					".csv"
				])
			) {
				Papa.parse(plainFiles[0], {
					header: true,
					skipEmptyLines: true,
					complete: async function (results) {
						if (results.errors.length !== 0) {
							toastError("Errors detected in the file");
							return
						}
						setSheetData(results);
						setUploadedFile(plainFiles[0]);
						setListName(plainFiles[0].name.replace(".csv", ""));
					},
				});
			} else {
				clear();
				return toastError("Invalid file extension");
			}
		}
	}, [plainFiles]);

	const upload = async () => {
		const contactBank = {
			headers: sheetData?.meta?.fields,
			bank: sheetData?.data,
			showHeaders: showHeaders,
			identifiers: {}
		}
		const data = {
			companyId: companyId,
			salesRepId: salesRepId,
			name: listName,
			contactBank: JSON.stringify(contactBank),
			dateCreated: new Date()
				.toISOString()
				.slice(0, 19)
				.replace("T", " "),
		}
		const status = await createContactList(data).unwrap();
		if (status.status === "error") {
			toast.dismiss();
			toastError("Error uploading file");
		} else {
			resetUploadModal();
			setOpenUploadModal(false);
		}
	}

	const nextStep = () => {
		if (listName === "") {
			toastError("Enter a name for the file");
			return
		}
		if (!uploadedFile) {
			toastError("Upload a file");
			return
		}

		setStepNumber(2);
	}

	const resetUploadModal = () => {
		setStepNumber(1);
		setListName("");
		setUploadedFile(null);
		setShowHeaders(true);
		setSheetData(null);
		clear();
	}

	return (
		<Modal
			isOpen={openUploadModal}
			style={customStyles}
		>
			<div className="flex flex-col px-[40px] py-[36px]">
				<div className="flex justify-between items-center pb-[16px] border-b border-[#D9D9D9]">
					<p className={`text-[#222222] text-[20px] leading-[20px]`}>
						{`Step ${stepNumber} of 2`}
					</p>
					<button onClick={() => {
						setOpenUploadModal(false);
						resetUploadModal();
					}}>
						<img
							src={closeCrossIcon}
							alt="close"
							className="w-[34px] h-[34px]"
						/>
					</button>
				</div>
				<div className={`my-[16px] gap-y-[8px] flex flex-col`}>
					<p className={`text-[#222222] text-[24px] leading-[24px]`}>
						{(stepNumber === 1) ? "Upload Contact List" : "Verify upload"}
					</p>
					<p className={`text-[#222222] text-[16px] leading-[16px]`}>
						{(stepNumber === 1) ? "Select a csv file to import contacts" : "Verify if the list was uploaded correctly"}
					</p>
				</div>
				{
					(stepNumber === 1) ?
						<>
							<div className={`my-[16px] gap-y-[32px] justify-center flex flex-col`}>
								<input type="text"
											 className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[520px]"
											 value={listName}
											 onChange={(e) => {
												 setListName(e.target.value);
											 }}
											 placeholder="Enter a name"/>
								<div className="flex items-center justify-center">
									<div className="bg-[#F8F8F8] px-[40px] py-[24px] rounded-[5px]">
										{
											loading ?
												<Spinner/>
												:
												<div className="flex flex-row gap-x-[8px]">
													<p className="text-[#222222] text-[16px] leading-[16px]">
														Upload a file (only CSV)
													</p>
													<button
														className="flex items-center text-[#0071F2] underline hover:text-[#0351AA] text-[15px] leading-[15px]"
														onClick={
															() => openFileSelector()
														}>
														Browse Files
													</button>
												</div>
										}
									</div>
								</div>
								<div
									className={(uploadedFile) ? `flex flex-row px-[16px] py-[8px] justify-between items-center rounded-[8px] shadow-xl` : `hidden`}>
									<div className="flex flex-row items-center gap-x-[16px]">
										<img src={greenCheckIcon} alt="uploaded" className="w-[24px] h-[24px]"/>
										<p className="text-[#7D7D7D] text-[15px] leading-[15px] font-medium">
											{uploadedFile?.name}
										</p>
									</div>
									<button className="flex items-center justify-center mx-[18px]" onClick={() => {
										setUploadedFile(null);
										clear();
									}}>
										<img src={crossIcon} alt="delete" className={"w-[20px] h-[20px]"}/>
									</button>
								</div>
							</div>
							<div className="flex justify-end items-center mt-[28px] mb-[4px]">
								<button
									className={`text-white bg-[#0071F2] hover:bg-[#0351AA] font-[600] text-[16px] leading-[16px] border rounded-[4px] py-[10px] px-[20px]`}
									onClick={() => {
										nextStep();
									}}
								>
									Continue
								</button>
							</div>
						</>
						:
						<>
							<div className="flex flex-col gap-y-[24px] my-[24px]">
								<div
									className="flex items-center justify-center rounded-[8px] border border-[#D9D9D9] bg-[#F8F8F8] overflow-x-auto">
									<table
										className={`whitespace-nowrap table-fixed block overflow-auto h-[30vh] w-[700px] m-[12px] table-fixed`}>
										<thead className={(!showHeaders) ? "hidden" : ""}>
										{
											(sheetData?.meta?.fields?.map((element, index) => {
												return (
													<th key={index}
															className="text-left px-[12px] py-[8px] bg-transparent text-[#7D7D7D] text-[13px] leading-[13px] font-normal">
														{element}
													</th>
												)
											}))
										}
										</thead>
										<tbody>
										{
											(sheetData?.data?.map((element, rowIndex) => {
												return (
													<tr key={rowIndex} className="text-left">
														{
															sheetData?.meta?.fields?.map((entry, colIndex) => {
																return (
																	<td key={colIndex}
																			className={`text-left px-[12px] py-[24px] bg-transparent text-[#7D7D7D] text-[13px] leading-[13px] ${(!showHeaders && rowIndex === 0) ? "" : "border-t border-[#D9D9D9]"}`}>
																		{element[entry]}
																	</td>
																)
															})
														}
													</tr>
												)
											}))
										}
										</tbody>
									</table>
								</div>
								<div className="flex flex-row gap-x-[1rem] items-center">
									<input
										type="checkbox"
										className="w-[18px] h-[18px] text-[#5B5B5B] rounded"
										checked={showHeaders}
										onChange={(e) => {
											setShowHeaders((state) => !state);
										}}
									/>
									<p className="text-[16px] text-[#5B5B5B]">
										The first row is for header
									</p>
								</div>
							</div>
							<div className="flex flex-row gap-x-[34px] justify-end items-center mb-[4px]">
								<button className={"text-[#7D7D7D] text-[16px] leading-[16px] font-medium"} onClick={
									() => {
										resetUploadModal();
										setOpenUploadModal(false);
									}
								}>
									Cancel
								</button>
								<button
									className={`text-white bg-[#0071F2] hover:bg-[#0351AA] font-[600] text-[16px] leading-[16px] border rounded-[4px] py-[10px] px-[20px]`}
									onClick={() => {
										upload();
									}}
								>
									Start the upload
								</button>
							</div>
						</>
				}
			</div>
		</Modal>
	)
}

const ContactList = () => {
	const [contactLists, setContactLists] = useState([]);
	const [openUploadModal, setOpenUploadModal] = useState(false);

	const [randomID] = useState(String(Math.random()));
	const history = useHistory();

	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);

	const {
		data: contactListData,
		isLoading: contactListIsLoading,
		isError: contactListIsError
	} = useGetAllContactListsQuery({companyId: companyId});
	const [deleteContactList, {}] = useDeleteContactListMutation();


	useEffect(() => {
		if (!contactListIsLoading) {
			if (contactListData?.payload) {
				setContactLists(contactListData.payload);
			}
		}
	}, [contactListData, contactListIsLoading])

	const handleDeleteContactList = async (id) => {
		confirmAlert({
			customUI: ({onClose}) => {
				return (
					<div className="flex flex-col w-[30rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<p className="mt-[2.25rem] text-[18px] font-medium">
							{`Are you sure you want to delete this link?`}
						</p>
						<p className="mt-[1.5rem] text-[14px] leading-[14px]">
							Once deleted cannot be recovered
						</p>
						<div className="flex flex-row justify-between mt-[3.375rem]">
							<button
								onClick={onClose}
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Cancel
							</button>
							<button
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#EDC8C5] font-roboto text-[16px] leading-[24px] border border-[#D83A52] text-[#D83A52] rounded-[0.25rem]"
								onClick={async () => {
									const popup = toastLoading("Delete in Progress");
									try {
										const status = await deleteContactList({id: id}).unwrap();
										if (status.status === "success") {
											toast.dismiss(popup);
											onClose();
										} else {
											toast.dismiss(popup);
											toastError("Error deleting contact list");
											onClose();
										}
									} catch (err) {
										toastError("Couldn't connect to database");
										onClose();
									}
								}}
							>
								Delete
							</button>
						</div>
					</div>
				);
			},
		});
	}

	if (contactListIsLoading)
		return <PageSpinner text="Loading..."/>;

	if (contactListIsError)
		return <div>Error occurred while fetching data</div>;

	return (
		<>
			<ReactTooltip effect="solid" place="bottom" id={randomID} html={true}/>
			<div className="flex flex-col justify-center items-center">
				<div className="sticky top-0 flex flex-row justify-between bg-white py-4 w-[69rem] z-10">
					<div className="grow">
						<TopBar
							heading={`${CONTACT}s`}
							description="Create contact lists here"
						/>
					</div>
					<div className="flex grow">
						<div className="flex items-center justify-center">
							<div className="flex flex-row items-center gap-x-6">
								<div>
									<div>
										<button
											className="flex items-center rounded-[0.375rem] text-white bg-[#0071F2] hover:bg-[#0351AA]"
											onClick={() => setOpenUploadModal(true)}
										>
											<div className="flex flex-row items-center mx-[20px] my-[13px] gap-x-[0.5rem]">
												<img
													src={arrowUpIcon}
													alt="upload"
													className="w-[22px] h-[22px]"
												/>
												<p className="font-semibold text-base">
													Upload
												</p>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					<div className="w-[69rem]">
						<div className="mt-[48px]">
							<table className="mt-2 w-full">
								<thead>
								<tr className="text-left">
									{["Title", "No of Rows", "No of Identifiers", "Owner", "Created On", "Action"].map(
										(it, index) => (
											<th key={index}
													className="pl-[40px] py-[10px] bg-[#F8F8F8] text-[#4444444] font-bold text-[15px] leading-[15px]">
												{it}
											</th>
										)
									)}
								</tr>
								</thead>
								<tbody>
								{contactLists.length !== 0 &&
								contactLists.map((element, index) => {
									return (
										<tr className="hover:drop-shadow-lg"
												key={index}
												onClick={() => {
													history.push(
														`/feature/contact/view/${element.id}/`
													);
												}}
										>
											<td
												className={`pl-[40px] py-[23px] text-[14px] leading-[14px] text-[#222222] w-[25%] ${
													(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
												}`}
											>
												{element.title.trim().length !== 0
													? element.title
													: "N/A"}
											</td>
											<td
												className={`text-center py-[23px] text-[14px] leading-[14px] text-[#222222] w-[15%] ${
													(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
												}`}
											>
												{JSON.parse(element.contact_bank).bank.length}
											</td>
											<td
												className={`text-center pl-[20px] py-[23px] text-[14px] leading-[14px] text-[#222222] w-[15%] ${
													(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
												}`}
											>
												{Object.keys(JSON.parse(element.contact_bank).identifiers).length}
											</td>
											<td
												className={`pl-[40px] py-[23px] ${
													(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
												}`}
											>
												<div className="flex flex-grow justify-start items-center ml-[8px]">
													<div
														className="flex justify-center items-center text-white w-[2rem] h-[2rem] rounded-full"
														style={{
															backgroundColor: getBackgroundColor(
																element.salesrep_id
															),
														}}
													>
														{element.name[0]}
													</div>
												</div>
											</td>
											<td
												className={`text-center py-[23px] text-[14px] leading-[14px] text-[#7D7D7D] ${
													(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
												}`}
											>
												{new Date(element.date_created).toLocaleDateString(
													"en-us",
													{
														year: "numeric",
														month: "long",
														day: "numeric",
													}
												)}
											</td>
											<td className={`px-[40px] py-[23px] ${
												(index + 1) % 2 === 0 ? "bg-[#F9FAFC]" : "bg-white"
											}`}>
												<div
													className="flex flex-row justify-start items-center"
													data-tip="Delete"
													data-for={randomID}
												>
													<button
														className="no-underline text-black"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															handleDeleteContactList(element.id);
														}}
													>
														<img
															src={deleteIcon}
															alt="delete"
															className="w-[20px] h-[20px] ml-[12px]"
														/>
													</button>
												</div>
											</td>
										</tr>
									);
								})}
								</tbody>
							</table>
						</div>
					</div>
				}
			</div>
			<UploadContactList openUploadModal={openUploadModal} setOpenUploadModal={setOpenUploadModal}/>
		</>
	)
}

export const Contact = () => {
	return (
		<Switch>
			<Route
				exact
				path={`/feature/contact/view/:id`}
				component={ContactListView}
			/>
			<Route path={`/feature/contact/`} component={ContactList}/>
		</Switch>
	)
}

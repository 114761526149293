import { createSlice } from "@reduxjs/toolkit";

const userSettingSlice = createSlice({
	name: "user/setting",
	initialState: {
		questionLibraryLink: "",
		websiteStyle: "",
		flags: "",
		demoRecordingPrompt: true,
		saveBeforePublishPrompt_Cyoa: true,
		saveBeforePublishPrompt_Solo: true,
		saveBeforePublishPrompt_Hero: true,
		saveBeforePublishPrompt_Page: true,
	},
	reducers: {
		setWebsiteStyle: (state, action) => {
			state.websiteStyle = action.payload.websiteStyle;
		},
		setQuestionLibrary: (state, action) => {
			state.questionLibraryLink = action.payload.questionLibraryLink;
		},
		setFlags: (state, action) => {
			state.flags = action.payload.flags;
		},
		setRecordingPrompt: (state, action) => {
			state.demoRecordingPrompt = action.payload.demoRecordingPrompt;
		},
		setSaveBeforePublishPrompt_Cyoa: (state, action) => {
			state.saveBeforePublishPrompt_Cyoa =
				action.payload.saveBeforePublishPrompt;
		},
		setSaveBeforePublishPrompt_Solo: (state, action) => {
			state.saveBeforePublishPrompt_Solo =
				action.payload.saveBeforePublishPrompt;
		},
		setSaveBeforePublishPrompt_Hero: (state, action) => {
			state.saveBeforePublishPrompt_Hero =
				action.payload.saveBeforePublishPrompt;
		},
		setSaveBeforePublishPrompt_Page: (state, action) => {
			state.saveBeforePublishPrompt_Page =
				action.payload.saveBeforePublishPrompt;
		},
	},
});

const { actions, reducer } = userSettingSlice;
export const {
	setWebsiteStyle,
	setQuestionLibrary,
	setFlags,
	setRecordingPrompt,
	setSaveBeforePublishPrompt_Cyoa,
	setSaveBeforePublishPrompt_Solo,
	setSaveBeforePublishPrompt_Hero,
	setSaveBeforePublishPrompt_Page,
} = actions;
export default reducer;

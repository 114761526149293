import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import cyaDeleteIcon from "../../../../../../icons/cya-delete-icon.svg";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {flatReactSelectStyle} from "../../../../../../utils/styleObjects";
import {embedSelect} from "../../../../../../utils/reactSelectStyles";
import dragHandle from "../../../../../../icons/drag-handle.svg";
import uuid from 'react-uuid';
import ReactTooltip from "react-tooltip";

export const CYAPdfCard = forwardRef(({index, showIndex, fetchedPdf, deleteEpisode, isLive}, ref) => {
	const [title, setTitle] = useState("");
	const [pdfLink, setPdfLink] = useState("");
	const [uniqueId, setUniqueId] = useState(uuid());

	useImperativeHandle(ref, () => ({
		sendData() {
			return {pdf: {title: title, link: pdfLink}, episode: "pdf", uniqueId: uniqueId, index: index};
		},
	}));

	useEffect(() => {
		if (fetchedPdf !== undefined && fetchedPdf !== null) {
			if (fetchedPdf.episode === "pdf") {
				setTitle(fetchedPdf.pdf.title);
				setPdfLink(fetchedPdf.pdf.link);
			}
		}
	}, [fetchedPdf]);


	return (
		<>
			<ReactTooltip
				effect="solid"
				place="bottom"
				id={uniqueId}
				html={true}
				className="max-w-[350px] font-inter text-xs leading-[22px]"
			/>
			<div className={showIndex ? `flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px] mt-[2rem]` : "flex"}>
				<div className={showIndex ? "flex items-center justify-between h-[2.38rem] bg-transparent border-[#DEE8ED] border-b" : "hidden"}>
					<p className="text-[#255CA4] text-[18px] leading-[18px] mx-[3.938rem]">
						Show PDF
					</p>
					<button className="flex items-center justify-center mr-[2.063rem]" onClick={() => deleteEpisode(index)}>
						<img src={cyaDeleteIcon} alt="delete" className="w-5 h-5"/>
					</button>
				</div>
				<div className={`flex flex-row ${showIndex ? "bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px]" : "bg-white"}`}>
					<div className={showIndex ? "flex items-center w-[50px]" : "hidden"}>
						{!isLive ? <img src={dragHandle} alt="drag" className="ml-[9px]"/> : null}
					</div>
					<div>
						<div className={showIndex ? "flex flex-col gap-y-[18px] items-center my-[1.25rem]" : "flex flex-col gap-y-[18px]"}>
							<input type="text"
										 className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[19.75rem]"
										 value={title}
										 onChange={(e) => setTitle(e.target.value)} placeholder="Enter a label to be used in analytics *"/>
							<input type="text"
										 className="flex text-[15px] leading-[15px] px-[12px] rounded-[4px] border border-[#BFCACF] w-[19.75rem]"
										 value={pdfLink}
										 onChange={(e) => setPdfLink(e.target.value)} placeholder="Enter the URL for this PDF *"/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
});

import {CYARuleConditionSingle} from "./CYARuleConditionSingle";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {CYARuleConditionGroup} from "./CYARuleConditionGroup";
import {CYAQuestionCard} from "./CYAQuestionCard";
import {CYADemoCard} from "./CYADemoCard";
import cyaDeleteIcon from "../../../../../../icons/cya-delete-icon.svg";
import {embedSelect} from "../../../../../../utils/reactSelectStyles";
import dragHandle from "../../../../../../icons/drag-handle.svg";
import uuid from "react-uuid";
import {CYAModalCard} from "./CYAModalCard";
import {CYAPdfCard} from "./CYAPdfCard";

export const CYARuleCard = forwardRef(({
																				 index,
																				 questionList,
																				 answerList,
																				 demoOptions,
																				 rule,
																				 ruleToDelete,
																				 isPage,
																				 isLive
																			 }, ref) => {
	const [logicOperatorsList] = useState([
		{key: "0", value: "&&", label: "And"},
		{key: "1", value: "||", label: "Or"}
	]);
	const [episodeTypeList] = useState((!isPage) ? [
			{key: 0, value: "question", label: "Ask Question"},
			{key: 1, value: "demo", label: "Play Demo"},
			{key: 2, value: "modal", label: "Show Modal"},
			{key: 2, value: "pdf", label: "Show PDF"},
		]
		:
		[
			{key: 1, value: "demo", label: "Play Demo"},
		])
	const [conditionIdList, setConditionIdList] = useState([]);
	const [episodeType, setEpisodeType] = useState({key: 1, value: "demo", label: "Play Demo"});
	const [conditionList, setConditionList] = useState([]);
	const [conditionType, setConditionType] = useState([]);
	const [questionOptions, setQuestionOptions] = useState([]);
	const [answerOptions, setAnswerOptions] = useState({});
	const [logicOperator, setLogicOperator] = useState({key: "0", value: "&&", label: "And"});
	const [ruleResult, setRuleResult] = useState(undefined);
	const [uniqueId, setUniqueId] = useState(uuid());

	const ruleRef = useRef([]);
	const episodeRef = useRef();

	//Functions that can be called by parent
	useImperativeHandle(ref, () => ({
		sendData() {
			return returnRule();
		},
		sendModule() {
			return episodeRef.current.sendData();
		}
	}));

	useEffect(() => {
		const options = [];
		questionList.forEach((it, i) => {
			if (it.type === "modal") {
				options.push({key: i, value: it.data, label: `Input for the card titled "${it.data}"`, type: it.type, index: it.index});
			} else {
				options.push({key: i, value: it.data, label: it.data, type: it.type, index: it.index});
			}
		});
		setQuestionOptions(options);
	}, [questionList])

	useEffect(() => {
		const options = {};
		answerList.forEach((it, i) => {
			const data = [];
			it.answers.forEach((el, ind) => {
				data.push({key: ind, value: el, label: el});
			})
			options[it.index] = data;
		});
		setAnswerOptions(options);
	}, [answerList])

	useEffect(() => {
		if (rule !== undefined && rule !== null) {
			addCondition("single", rule.extra.length, rule.extra);
			setConditionList(rule.extra);
			setRuleResult(rule.result);
			setEpisodeType(episodeTypeList.filter(it => it.value === rule.result.episode)[0]);
			if (rule.uniqueId !== undefined) {
				setUniqueId(rule.uniqueId);
			}
		}
	}, [rule])

	const addCondition = (condition, batch = null, extra = null) => {
		if (batch === null) {
			const conditionList = [...conditionIdList];
			const type = [...conditionType];
			const id = conditionList.length !== 0 ? conditionList[conditionList.length - 1] + 1 : 0;
			conditionList.push(id);
			type.push((condition === "single") ? {id: id, type: "single"} : {id: id, type: "group"});
			setConditionIdList(conditionList);
			setConditionType(type);
		} else {
			const conditionList = [];
			const type = [];
			for (let i = 0; i < batch; i++) {
				const id = conditionList.length !== 0 ? conditionList[conditionList.length - 1] + 1 : 0;
				conditionList.push(id);
			}
			extra.forEach((it, i) => {
				if (it.variables === undefined) {
					type.push({id: i, type: "single"})
				} else {
					type.push({id: i, type: "group"})
				}
			})
			setConditionIdList(conditionList);
			setConditionType(type);
		}
	}

	const conditionToDelete = (id) => {
		const conditionList = conditionIdList.filter((it) => it !== id);
		const type = conditionType.filter((it) => it.id !== id);

		setConditionIdList(conditionList);
		setConditionType(type);
	}

	const returnRule = () => {
		const rules = [];
		let integrityFlag = true;

		conditionIdList.forEach((it) => {
			rules.push(ruleRef.current[it].sendCondition());
		});

		rules.forEach((it) => {
			if (it === null) {
				integrityFlag = false;
			}
		})

		if (!integrityFlag || rules.length === 0) {
			return null;
		}

		const ruleObject = {}
		const variables = []
		const extra = [];
		let condition = "";

		rules.forEach((it, i) => {
			if (it.type === "group") {
				const groupExtra = [];

				it.condition.variables.forEach((el) => {
					variables.push(el);
					groupExtra.push(el);
				})
				extra.push({logic: it.condition.logic, variables: groupExtra});

				if (i === 0) {
					condition += `(${it.condition.condition})`;
				} else {
					condition += ` ${it.condition.logic} (${it.condition.condition})`;
				}
			} else {
				variables.push(it.condition);
				extra.push(it.condition);

				if (i === 0) {
					condition += it.condition.expression;
				} else {
					condition += ` ${it.condition.logic} ${it.condition.expression}`;
				}
			}
		})

		ruleObject.variables = variables;
		ruleObject.condition = condition;
		ruleObject.result = episodeRef.current.sendData();
		ruleObject.extra = extra;
		ruleObject.episode = "rule";
		ruleObject.uniqueId = uniqueId;
		ruleObject.index = index;
		return ruleObject
	}

	return (
		<div className={`flex flex-col border-[#DEE8ED] bg-[#FFFFFF] border rounded-[10px] mt-[2rem]`}>
			<div className={"flex items-center justify-between h-[2.38rem] bg-transparent border-[#DEE8ED] border-b"}>
				<p className="text-[#255CA4] text-[18px] leading-[18px] mx-[3.938rem]">
					Rule
				</p>
				<button className="flex items-center justify-center mr-[2.063rem]" onClick={() => ruleToDelete(index)}>
					<img src={cyaDeleteIcon} alt="delete" className="w-5 h-5"/>
				</button>
			</div>
			<div className="bg-[#F9FBFC] rounded-bl-[10px] rounded-br-[10px]">
				<div className="flex flex-row">
					<div className="flex items-center w-[25px]">
						{!isLive ? <img src={dragHandle} alt="drag" className="ml-[9px]"/> : null}
					</div>
					<div className={"flex flex-col my-[1.25rem]"}>
						<div className={"flex flex-col justify-center space-y-[18px]"}>
							{
								conditionIdList.map((it) => {
									return (
										<div key={it} className="flex flex-row items-center">
											{(conditionType.filter((el) => el.id === it)[0].type === "single")
												?
												<CYARuleConditionSingle
													ref={(el) => (ruleRef.current[it] = el)}
													index={it}
													questionOptions={questionOptions}
													answerOptions={answerOptions}
													logicOperator={logicOperator}
													setLogicOperator={setLogicOperator}
													logicOperatorsList={logicOperatorsList}
													condition={conditionList[it]}
													conditionIdList={conditionIdList}
													conditionToDelete={conditionToDelete}
													conditionGroup={false}
												/>
												:
												<CYARuleConditionGroup
													ref={(el) => (ruleRef.current[it] = el)}
													index={it}
													questionOptions={questionOptions}
													answerOptions={answerOptions}
													logicOperator={logicOperator}
													setLogicOperator={setLogicOperator}
													logicOperatorsList={logicOperatorsList}
													ruleToDelete={conditionToDelete}
													condition={conditionList[it]}
													conditionIdList={conditionIdList}/>
											}
										</div>
									)
								})
							}
							<div className="flex flex-row gap-x-[24px] ml-[86px] mt-[18px] mb-[24px]">
								<button className="text-[#1D60B5] text-[14px] hover:underline"
												onClick={() => addCondition("single")}>
									+ Add Condition
								</button>
								<button className="text-[#1D60B5] text-[14px] hover:underline"
												onClick={() => addCondition("group")}>
									+ Add Condition Group
								</button>
							</div>
						</div>
						<div
							className="flex flex-col mt-[24px] py-[20px] gap-y-[18px] border border-[#BFCACF] rounded-[5px] w-[65.75rem] bg-white">
							<div className="flex flex-row items-center mx-[20px] gap-x-[42px]">
								<p className="text-[15px] text-black font-medium">
									Then
								</p>
								<Select
									className="w-[19.75rem]"
									value={episodeType}
									components={makeAnimated()}
									options={episodeTypeList}
									styles={embedSelect}
									placeholder="Select Option"
									onChange={(e) => setEpisodeType(e)}
									isSearchable
								/>
							</div>
							<div className="bg-white">
								{
									(episodeType.value === "question") ?
										<div className="flex items-center ml-[96px]">
											<CYAQuestionCard
												index={index}
												showIndex={false}
												ref={episodeRef}
												fetchedQuestion={ruleResult}
											/>
										</div>
										:
										false
								}
								{
									(episodeType.value === "demo") ?
										<div className="flex items-center ml-[96px]">
											<CYADemoCard
												index={index}
												showIndex={false}
												demoOptions={demoOptions}
												ref={episodeRef}
												fetchedDemo={ruleResult}
											/>
										</div>
										:
										false
								}
								{
									(episodeType.value === "modal") ?
										<div className="flex items-center ml-[96px]">
											<CYAModalCard
												index={index}
												ref={episodeRef}
												fetchedModal={ruleResult}
												showIndex={false}
											/>
										</div>
										:
										false
								}
								{
									(episodeType.value === "pdf") ?
										<div className="flex items-center ml-[96px]">
											<CYAPdfCard
												index={index}
												ref={episodeRef}
												fetchedPdf={ruleResult}
												showIndex={false}
											/>
										</div>
										:
										false
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
})

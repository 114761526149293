import React, { useState } from "react";
import { MaterialSwitch } from "../../../../../custom/MaterialSwitch";
import { StyledRadio } from "../../../../../custom/StyledRadio";
import infoIcon from "../../../../../../icons/info-icon.svg";
import { useSelector } from "react-redux";

export const CYAGating = ({
	verifyIdentity,
	setVerifyIdentity,
	isRequired,
	customVerification,
	setCustomVerification,
	checkBlocklist,
	setCheckBlocklist,
	redirectToRestrictedDomain,
	setRedirectToRestrictedDomain,
	checkDomain,
	setCheckDomain,
	checkTwoFactor,
	setCheckTwoFactor,
	setIsRequired,
	randomID,
}) => {
	const [hover, setHover] = useState(false);
	const flags = useSelector((state) => JSON.parse(state.userSetting.flags));
	const gating = flags.gating;
	const embed = flags.gating.embed;
	return gating.show ? (
		<div className="flex flex-col gap-y-[6px]">
			<div className="flex items-center gap-x-4">
				<MaterialSwitch
					color="primary"
					className="mx-2"
					checked={verifyIdentity}
					disabled={!gating.enable}
					name="is_gated"
					onChange={(event, checked) => {
						setVerifyIdentity(checked ? true : false);
					}}
				/>
				<div className="flex gap-x-2">
					<label
						className="text-sm font-normal text-[#222222]"
						htmlFor="is_gated"
					>
						Ask for email address in the beginning
					</label>
					<img
						src={infoIcon}
						alt="info"
						data-tip="When toggled on, the user has to enter their email address upon which they will receive a link in email to continue further."
						data-for={randomID}
					/>
				</div>
			</div>
			{verifyIdentity && (
				<div className="ml-[4.7rem] text-[#5B5B5B] text-sm">
					Customize the modal for asking the email address in the
					<span className="text-[#0071F2]"> Content </span>
					tab.
				</div>
			)}
			<div
				onMouseOver={() => setHover(true)}
				onMouseOut={() => setHover(false)}
				className={`w-[600px] ml-[4.7rem] rounded-lg ${
					!gating.edit && verifyIdentity && "relative border-8 border-[#0AE50A]"
				}`}
			>
				<div
					className={
						verifyIdentity
							? `flex flex-col justify-center items-start gap-y-2 bg-[#EDF5FE] rounded-lg px-4 py-2 ${
									!gating.edit && hover && "blur"
							  }`
							: "hidden"
					}
				>
					{embed.required.show && (
						<div className="flex flex-col">
							<div className="flex gap-x-2">
								<label
									className="text-sm font-normal text-[#222222]"
									htmlFor="is_required"
								>
									Required vs Optional
								</label>
								<img
									src={infoIcon}
									alt="info"
									data-tip="When toggled on, the user has to enter their email address upon which they will receive a link in email to continue further."
									data-for={randomID}
								/>
							</div>
							<div className="flex items-center gap-x-1">
								<StyledRadio
									onClick={() => {
										setIsRequired(true);
									}}
									checked={isRequired}
									disabled={!embed.required.enable}
									id="is_required"
									name="is_required"
								/>
								<p className="font-normal text-sm text-[#5B5B5B]">Required</p>
							</div>
							<div className="flex items-center gap-x-1">
								<StyledRadio
									onClick={() => {
										setIsRequired(false);
									}}
									checked={!isRequired}
									disabled={!embed.required.enable}
									id="is_required"
									name="is_required"
								/>
								<p className="font-normal text-sm text-[#5B5B5B]">Optional</p>
							</div>
						</div>
					)}
					{embed.verify.show && (
						<div className="flex flex-col">
							<div className="flex gap-x-2">
								<label
									className="text-sm font-normal text-[#222222]"
									htmlFor="is_verify"
								>
									Verify email address
								</label>
								<img
									src={infoIcon}
									alt="info"
									data-tip="When toggled on, the user has to enter their email address upon which they will receive a link in email to continue further."
									data-for={randomID}
								/>
							</div>
							<div className="flex items-center gap-x-1">
								<StyledRadio
									onClick={() => {
										setCustomVerification(true);
									}}
									checked={customVerification}
									disabled={!embed.verify.enable}
									id="custom_verification"
									name="custom_verification"
								/>
								<p className="font-normal text-sm text-[#5B5B5B]">Yes</p>
							</div>
							<div
								className={
									customVerification
										? "flex flex-col justify-center items-start gap-y-2 ml-[2.5rem] bg-[#FFFFFF] w-[500px] p-2 rounded-lg"
										: "hidden"
								}
							>
								{embed.verify.blocklist.show && (
									<div className="flex flex-row gap-x-4">
										<input
											type="checkbox"
											className="w-[18px] h-[18px] text-[#569EFE] rounded border-2 border-[#569EFE]"
											checked={checkBlocklist}
											disabled={!embed.verify.blocklist.enable}
											onChange={(e) => {
												setCheckBlocklist((state) => {
													if (state) setRedirectToRestrictedDomain(false);
													return !state;
												});
											}}
										/>
										<div className="flex gap-x-2">
											<label
												className="text-sm font-normal text-[#5B5B5B]"
												htmlFor="custom_verification"
											>
												Email domain must not be in the blocklist
											</label>
											<img
												src={infoIcon}
												alt="info"
												data-tip="When toggled on, the user has to enter their email address upon which they will receive a link in email to continue further."
												data-for={randomID}
											/>
										</div>
									</div>
								)}
								{embed.verify.blocklist.redirect.show && (
									<div className="flex flex-row gap-x-4 ml-[2rem]">
										<input
											type="checkbox"
											className="w-[18px] h-[18px] text-[#569EFE] rounded border-2 border-[#569EFE]"
											checked={redirectToRestrictedDomain}
											disabled={!embed.verify.blocklist.redirect.enable}
											onChange={(e) => {
												setRedirectToRestrictedDomain((state) => {
													if (!state) setCheckBlocklist(true);
													return !state;
												});
											}}
										/>
										<div className="flex-flex-col">
											<p className="text-sm font-normal text-[#5B5B5B]">
												Show special content to the users from blocked email
												domains
											</p>
											<p className="text-sm font-normal text-[#5B5B5B]">
												Set Restricted content in the{" "}
												<span className="text-[#0071F2]"> Content </span> tab
											</p>
										</div>
									</div>
								)}
								{embed.verify.domain.show && (
									<div className="flex flex-row gap-x-4">
										<input
											type="checkbox"
											className="w-[18px] h-[18px] text-[#569EFE] rounded border-2 border-[#569EFE]"
											checked={checkDomain}
											disabled={!embed.verify.domain.enable}
											onChange={(e) => {
												setCheckDomain((state) => {
													if (state) setCheckTwoFactor(false);
													return !state;
												});
											}}
										/>
										<div className="flex gap-x-2">
											<label
												className="text-sm font-normal text-[#5B5B5B]"
												htmlFor="custom_verification"
											>
												Email domain must be a real domain
											</label>
											<img
												src={infoIcon}
												alt="info"
												data-tip="When toggled on, the user has to enter their email address upon which they will receive a link in email to continue further."
												data-for={randomID}
											/>
										</div>
									</div>
								)}
								{embed.verify.twofactor.show && (
									<div className="flex flex-row gap-x-4">
										<input
											type="checkbox"
											className="w-[18px] h-[18px] text-[#569EFE] rounded border-2 border-[#569EFE]"
											checked={checkTwoFactor}
											disabled={!embed.verify.twofactor.enable}
											onChange={(e) => {
												setCheckTwoFactor((state) => {
													if (!state) setCheckDomain(true);
													return !state;
												});
											}}
										/>
										<div className="flex gap-x-2">
											<label
												className="text-sm font-normal text-[#5B5B5B]"
												htmlFor="custom_verification"
											>
												User must click on a link sent to their email address
											</label>
											<img
												src={infoIcon}
												alt="info"
												data-tip="When toggled on, the user has to enter their email address upon which they will receive a link in email to continue further."
												data-for={randomID}
											/>
										</div>
									</div>
								)}
							</div>
							<div className="flex items-center gap-x-1">
								<StyledRadio
									onClick={() => {
										setCustomVerification(false);
									}}
									checked={!customVerification}
									disabled={!embed.verify.enable}
									id="custom_verification"
									name="custom_verification"
								/>
								<p className="font-normal text-sm text-[#5B5B5B]">No</p>
							</div>
						</div>
					)}
				</div>
				<div
					onMouseOver={() => setHover(true)}
					onMouseOut={() => setHover(false)}
					className={
						!gating.edit && hover
							? "absolute top-1/3 mx-7 flex flex-col gap-y-4"
							: "hidden"
					}
				>
					<div className="flex gap-x-2 items-center">
						<svg
							width="22"
							height="24"
							viewBox="0 0 22 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.0833 1.16797L1.25 14.168H11L9.91667 22.8346L20.75 9.83463H11L12.0833 1.16797Z"
								stroke="#009EF7"
								stroke-width="2.16667"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<p className="font-semibold text-xl text-[#009EF6]">Upgrade</p>
					</div>
					<div className="flex flex-col gap-x-2.5 ">
						<p className="text-base font-medium text-[#444444]">
							Upgrade to the
							<span className="font-semibold text-[#222222]">
								{" "}
								Premium plan{" "}
							</span>
							to edit email settings.
						</p>
						<p className="text-sm font-medium text-[#444444]">
							You can still ask for email address with default settings in the
							Basic plan.
						</p>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useReactMediaRecorder } from "react-media-recorder";
import { getStateFromCookies } from "redux-cookies-middleware";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { paths } from "../../../features/cookies-paths/paths";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFilePicker } from "use-file-picker";
import ysFixWebmDuration from "fix-webm-duration";
import getBlobDuration from "get-blob-duration";
import { amplitude } from "../../amplitude/Amplitude";
import userSettingReducer, {
	setRecordingPrompt,
} from "../../../features/state/user-settings/userSettingSlice";
import Modal from "../../modal/Modal";
import TopBar from "../../top-level/TopBar";
import StyledButton from "../../top-level/StyledButton";
import { StyledRadio } from "../../custom/StyledRadio";
import DropdownButton from "../../top-level/DropdownButton";
import recordicon from "../../../icons/record-icon.svg";
import uploadicon from "../../../icons/upload-icon.svg";
import bulbicon from "../../../icons/bulb-icon.svg";
import pagelinkslanted from "../../../icons/page-link-slanted-icon.svg";
import { useGetRecordingQuery } from "../../../features/api/recordingsApiSlice";
import { useGetVideoQuery } from "../../../features/api/videoApiSlice";

import { PageSpinner } from "../../top-level/PageSpinner";
import DemoTable from "./DemoTable";
import { DEMO, DEMO_TYPES } from "../../../data/constants";
import { hasExtension } from "../../../utils/uploadFiles";
import { toastError } from "../../custom-toast/toasts";

const getIdFromUrl = (url) => {
	return url.match(/[-\w]{25,}/);
};

const DemoTableView = () => {
	const companyId = useSelector((state) => state.sessionData.companyId);
	const [limit, setLimit] = useState(40)
	const [pageNo, setPageNo] = useState(1)
	useEffect(() => {
		return () => {
			setPageNo(1)
		}
	}, [])
	const moveToPrevPage = (event) => {
		if (pageNo != 1 && pageNo > 1) {
			console.log("SETING PREVIOUS PAGE")
			setPageNo(pageNo - 1)
		}
	}
	const moveToNextPage = (event) => {
		if (videoData?.payload?.length < 40 && recordingData?.payload?.length < 40) {
			return
		}
		setPageNo(pageNo + 1)
	}

	const {
		data: recordingData,
		isLoading: recordingIsLoading,
		isError: recordingIsError,
		error: recordingError,
	} = useGetRecordingQuery({
		companyId: companyId,
		limit,
		pageNo
	});
	const {
		data: videoData,
		isLoading: videoIsLoading,
		isError: videoIsError,
		error: videoError,
	} = useGetVideoQuery({
		companyId: companyId,
		limit,
		pageNo
	});

	const demoRecordingPrompt = getStateFromCookies(userSettingReducer, paths)
		.userSetting.demoRecordingPrompt;

	const history = useHistory();
	const dispatch = useDispatch();
	const [openPrompt, setOpenPrompt] = useState(false);
	const [openURLPrompt, setURLPrompt] = useState(false);
	const [neverShowPrompt, setNeverShowPrompt] = useState(false);
	const [hasMicPermission, setHasMicPermission] = useState(false);

	const [openFileSelector, { filesContent, loading, errors, clear }] =
		useFilePicker({
			multiple: false,
			readAs: "DataURL",
			accept: [".mp4", ".m4v", ".mkv", ".avi", ".wmv"],
			limitFilesConfig: { min: 1, max: 1 },
			// minFileSize: 1, // in megabytes
			// maxFileSize: 1,
			// readFilesContent: false, // ignores file content
		});

	useEffect(() => {
		const setup = async () => {
			if (errors.length > 0) {
				toastError("Error uploading video");
				return;
			}
			if (filesContent.length > 0) {
				if (
					hasExtension(filesContent[0].name, [
						".mp4",
						".m4v",
						".mkv",
						".avi",
						".wmv",
					])
				) {
					const res = await fetch(filesContent[0].content);
					const blob = await res.blob();
					const url = URL.createObjectURL(blob);
					history.push("demos/recording", {
						mediaBlobUrl: url,
						blob: blob,
					});
				} else {
					clear();
					return toastError("Invalid file extension");
				}
			}
		};
		setup();
	}, [filesContent]);

	useEffect(() => {
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then(function (stream) {
				setHasMicPermission(true);
			})
			.catch(function (err) {
				setHasMicPermission(false);
			});
	}, []);

	const onStop = (blobUrl, blob) => {
		getBlobDuration(blob).then((duration) => {
			ysFixWebmDuration(blob, duration, (fixedBlob) => {
				history.push("demos/recording", {
					mediaBlobUrl: blobUrl,
					blob: fixedBlob,
				});
			});
		});
	};

	const {
		status,
		startRecording: startRecord,
		stopRecording: stopRecord,
		pauseRecording: pauseRecord,
		resumeRecording: resumeRecord,
		mediaBlobUrl,
	} = useReactMediaRecorder({
		screen: true,
		video: false,
		audio: true,
		blobPropertyBag: { type: "video/mp4" },
		onStop,
	});

	const startRecording = () => {
		startRecord();
	};
	const stopRecording = () => stopRecord();

	const confirmStartRecording = () => {
		if (demoRecordingPrompt === undefined || demoRecordingPrompt) {
			setOpenPrompt(true);
		} else {
			if (hasMicPermission) {
				startRecording();
			} else {
				toastError("Mic Permission Not Found");
			}
		}
	};

	const promptStartRecording = () => {
		if (hasMicPermission) {
			dispatch(setRecordingPrompt({ demoRecordingPrompt: !neverShowPrompt }));
			setOpenPrompt(false);
			startRecording();
		} else {
			toastError("Mic permissions not found");
		}
	};

	const createDemoSelectHandler = (value) => {
		switch (value) {
			case "record":
				confirmStartRecording();
				break;
			case "upload":
				openFileSelector();
				amplitude.getInstance().logEvent("UploadDemoClicked", {
					timestamp: new Date().toUTCString(),
				});
				break;
			case "url":
				setURLPrompt(true);
				break;
			default:
				console.log("Unknown option clicked");
				break;
		}
	};

	if (recordingIsLoading || videoIsLoading)
		return <PageSpinner text="Loading Demos..." />;
	return (
		<>
			<div className="sticky top-0 flex flex-row justify-between bg-white py-4 z-50">
				<div className="grow">
					<TopBar
						heading={`${DEMO}s`}
						description={`Create and manage product ${DEMO.toLowerCase()}s that will showcase your product to the prospect`}
					/>
				</div>
				<div className="flex grow">
					<div className="items-center justify-center">
						<div className="flex flex-row items-center gap-x-6 mt-3">
							<div>
								<DropdownButton
									title={`Create ${DEMO.toLowerCase()}`}
									options={[
										{
											key: 1,
											value: "record",
											label: `Record ${DEMO}`,
											icon: recordicon,
										},
										{
											key: 2,
											value: "upload",
											label: `Upload ${DEMO}`,
											icon: uploadicon,
										},
										{
											key: 3,
											value: "url",
											label: `Add ${DEMO} URL`,
											icon: pagelinkslanted,
										},
									]}
									onSelectHandler={(value) => createDemoSelectHandler(value)}
									loading={loading}
								/>
							</div>
						</div>
						{
							videoData && recordingData && videoData.payload && recordingData.payload &&
							(
								(!(videoData.payload.length < 40 && recordingData.payload.length < 40) && pageNo == 1)
								||
								((videoData.payload.length == 40 && recordingData.payload.length == 40) || pageNo != 1)
							)
							&&
							<div style={{
								paddingTop: "12px", display: "flex",
								alignItems: "center",
								gap: "16px",
							}}>
								<div
									onClick={moveToPrevPage}
									style={{
										cursor: "pointer",

										strokeWidth: "1.4px",
										stroke: pageNo == 1 ? "#A9A9A9" : "#A9A9A9",
										width: "28px",
										height: "28px"
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
										<path d="M16.625 19.25L11.375 14L16.625 8.75" stroke="#5F5F5F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
										<rect x="0.7" y="0.7" width="26.6" height="26.6" rx="3.96667" stroke={pageNo == 1 ? "#A9A9A9" : "#5BA7FE"} stroke-width="1.4" />
									</svg>
								</div>
								{/* <div style={{
									width: "40px",
									height: "28px",
									padding: "6px 5px",
									flexDirection: "column",
									justifyContent: "space-between",
									alignItems: "center",
								}}> */}
								<input type="text"
									style={{
										textAlign: "center",
										borderRadius: "5px",
										border: "2px solid #5BA7FE",
										outline: "none",
										width: "40px",
										height: "28px",
										padding: "6px 5px",
										flexDirection: "column",
										justifyContent: "space-between",
										alignItems: "center",
									}}
									value={pageNo}
									onChange={(event) => {
									
										if(isNaN(+(event.target.value))){
											return 
										}else{
											setPageNo(event.target.value)
										}
										// if (!isNaN(+(event.target.value))) {
										// 	setPageNo(event.target.value)

										// }
									}}
								></input>
								{/* <p>{pageNo}</p> */}
								{/* </div> */}
								<div

									onClick={moveToNextPage}
									style={{
										pointerEvents: videoData.payload.length < 40 && recordingData.payload.length < 40,
										cursor: "pointer",
										strokeWidth: "1.4px",
										stroke: "#A9A9A9",
										width: "28px",
										height: "28px"
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
										<path d="M11.375 19.25L16.625 14L11.375 8.75" stroke="#5F5F5F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
										<rect x="0.7" y="0.7" width="26.6" height="26.6" rx="3.96667" stroke={videoData.payload.length < 40 && recordingData.payload.length < 40 ? "#A9A9A9" : "#5BA7FE"} stroke-width="1.4" />
									</svg>
								</div>

							</div>
						}
					</div>
				</div>
			</div >
			{recordingIsError || videoIsError ? (
				<div>
					{JSON.stringify(recordingError ? recordingError : videoError)}
				</div>
			) : (
				<DemoTable
					videos={videoData.payload}
					recordings={recordingData.payload}
				/>
			)
			}
			<Modal
				open={openPrompt}
				onClose={() => {
					setOpenPrompt(false);
				}}
			>
				<div className="flex flex-col gap-y-4">
					<div className="flex flex-col gap-y-2">
						<p className="text-lg font-semibold">Before the camera rolls</p>
						<div className="flex flex-col my-4 gap-y-3">
							<p className="text-base">
								1. The next step will ask you to select the tab or window to be
								recorded.
							</p>
							<p className="text-base">
								2. As soon as you select one, you will be automatically taken to
								that tab or window.
							</p>
							<p className="text-base">
								3. Your screen and your audio will start getting recorded
								instantly.
							</p>
						</div>
					</div>
					<div>
						<div className="flex flex-row items-start justify-around bg-[#EDF5FE] p-1.5 border-[#EDF5FE] rounded-lg">
							<img
								src={bulbicon}
								alt="Bulb"
								className="w-[1.5rem] h-[1.5rem]"
							/>

							<p className="text-sm font-normal">
								<strong>Tip</strong>: Add a short pause in your narration
								wherever you plan to insert hotspots later on. During playback,
								the audio and video will pause wherever a hotspot is
								encountered.
							</p>
						</div>
					</div>
					<div className="flex flex-row mt-4 justify-between">
						<div className="flex flex-row gap-x-2 items-center">
							<input
								type="checkbox"
								id="recordingPrompt"
								name="recordingPrompt"
								checked={neverShowPrompt}
								onChange={(e) => setNeverShowPrompt(!neverShowPrompt)}
							/>
							<p className="ml-4 text-base">Don't show this message again</p>
						</div>
						<div>
							<StyledButton
								type="primary"
								onClick={() => {
									promptStartRecording();
								}}
							>
								Start Recording
							</StyledButton>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				open={openURLPrompt}
				onClose={() => {
					setURLPrompt(false);
				}}
			>
				<div className="flex flex-col gap-y-4">
					<div className="flex flex-col gap-y-2">
						<p className="text-2xl font-medium ml-3">Add Demo URL</p>
					</div>
					<div className="flex flex-row items-start">
						<Formik
							initialValues={{ type: DEMO_TYPES.PUBLIC, url: "" }}
							validationSchema={Yup.object().shape({
								type: Yup.string().required("Type is required"),
								url:
									Yup.string()
										.url("Should be a valid url")
										.required("URL is required") ||
									Yup.string()
										.matches(
											/[-\w]{25,}/,
											"Does not match a google drive sharable url"
										)
										.required("URL is required"),
							})}
							onSubmit={async (values, { setSubmitting, setFieldError }) => {
								// match regex for google drive url
								if (
									values.url.match(
										/https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?\?usp=sharing/g
									)
								) {
									const extractedId = getIdFromUrl(values.url);
									if (!extractedId) {
										setFieldError("url", "Incorrect URL format");
									}
									history.push("demos/recording", {
										mediaBlobUrl: `${process.env.REACT_APP_GDRIVE}${extractedId}`,
										type: DEMO_TYPES.GDRIVE,
									});
								} else {
									history.push("demos/recording", {
										mediaBlobUrl: `${values.url}`,
										type: DEMO_TYPES.PUBLIC,
									});
								}
							}}
						>
							{({ isSubmitting, values, setFieldValue, errors }) => (
								<Form className="w-full">
									{/* TODO - Copy change text */}
									<p className="text-[#737272] text-sm mt-1 mb-0 mx-3">
										Enter the URL of the video below. Make sure the access
										permissions are set appropriately.
									</p>
									<div className={`w-full my-6 mx-2.5`}>
										<Field
											type="url"
											name="url"
											className="w-[96%] border border-2 rounded-md border-[#D9D9D9]"
											placeholder="Enter URL"
										/>
										<ErrorMessage
											className="mx-1 text-xs text-red-800"
											name="url"
											component="div"
										/>
									</div>
									<div className="flex flex-row mx-3 justify-end">
										<div>
											<button
												className="border rounded-md p-3 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA] disabled:bg-[#D9D9D9] disabled:text-black disabled:cursor-not-allowed"
												disabled={!!errors.url || isSubmitting}
											>
												Generate
											</button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</Modal>
		</>
	);
};
export default DemoTableView;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {FormTagTypes} from "./formsApiSlice";

export const DemoTagTypes = {
    DEMO: "demo",
    ACCOUNTS: "accounts"
};

export const demoApiSlice = createApi({
    reducerPath: "api/demo",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_SERVER}/web`,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().sessionData.token
            if (token) {
                headers.set('token', token)
            }
            return headers
        }
    }),
    tagTypes: Object.values(DemoTagTypes),
    endpoints: builder => ({
        getDemo: builder.query({
            query: (initialPost) => "/demo/" + initialPost.salesRepId +  `?limit=${initialPost.limit??1000}&pageNo=${initialPost?.pageNo?.length==0?1:initialPost.pageNo??1}`,
            providesTags: [DemoTagTypes.DEMO],
        }),
        getAccounts: builder.query({
            query: (initialPost) => "/demo/account/" + initialPost.companyId,
            providesTags: [DemoTagTypes.ACCOUNTS],
        }),
        createDemo: builder.mutation({
            query: initialPost => ({
                url: "/demo",
                method: "POST",
                body: {
                    name: initialPost.name,
                    title: initialPost.title,
                    email: initialPost.email,
                    company: initialPost.company,
                    demoEmail: initialPost.demoEmail,
                    demoPassword: initialPost.demoPassword,
                    salesRepId: initialPost.salesRepId,
                    companyId: initialPost.companyId,
                },
            }),
            invalidatesTags: [DemoTagTypes.DEMO],
        }),
        deleteDemo: builder.mutation({
            query: initialPost => ({
                url: "/demo/" + initialPost.encryptedId,
                method: "DELETE",
            }),
            invalidatesTags: [DemoTagTypes.DEMO],
        }),
    })
})

export const { useGetDemoQuery, useGetAccountsQuery, useCreateDemoMutation, useDeleteDemoMutation } = demoApiSlice;
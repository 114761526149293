import {useLocation} from "react-router-dom";
import * as queryString from "querystring";
import {useDispatch} from "react-redux";

export const LinkedInCallBack = () => {
	const location = useLocation();
	const queryParams = queryString.parse(location.search);

	localStorage.setItem("linkedInAuthCode", (queryParams["?code"]) ? queryParams["?code"] : "");
	window.dispatchEvent(new Event("storage"));
	return window.close();
}

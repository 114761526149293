import React from "react";
import ReactDom from "react-dom";

const MODAL_STYLES = {
  width: "695px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  padding: "5px",
  zIndex: 1000,
  border: "2px solid #1A73E8",
  borderRadius: "20px",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, .16)",
  zIndex: 1000,
};

function ControlsModal({ open, children, onClose, hideModal }) {
  if (!open) return null;
  return ReactDom.createPortal(
    <div className={hideModal !== undefined && hideModal ? "hidden" : ""}>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES}>{children}</div>
    </div>,
    document.getElementById("portal")
  );
}

export default ControlsModal;

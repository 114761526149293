export const embedSelect = {
	placeholder: (provided) => ({
		...provided,
		color: "#737373",
	}),
	singleValue: (provided) => ({
		...provided,
		fontHeight: "14px"
	}),
	indicatorSeparator: () => null,
};

export const graphSelectionStyles = {
	control: (provided) => ({
		...provided,
		border: 0,
		boxShadow: "#FFF",
		borderRadius: "0px",
	}),
	singleValue: (provided) => ({
		...provided,
		color: "#255CA4",
		margin: 0,
		padding: 0,
	}),
	menu: (provided) => ({
		...provided,
	}),
	placeholder: (provided) => ({
		...provided,
		color: "#255CA4",
	}),
	indicatorSeparator: () => null,
};

export const logicSelect = {
	control: (provided) => ({
		...provided,
		boxShadow: "#FFF",
		borderRadius: "8px",
		backgroundColor: "#F1F7FF",
		borderColor: "#63A4FA",
	}),
	singleValue: (provided) => ({
		...provided,
		color: "#1D60B5",
		fontSize: "14px"
	}),
	menu: (provided) => ({
		...provided,
		borderRadius: "8px",
		fontSize: "14px",
		padding: "0px",
	}),
	indicatorSeparator: () => null,
	dropdownIndicator: (provided) => ({
		...provided,
		padding: "0px"
	})
}

export const operatorSelect = {
	placeholder: (provided) => ({
		...provided,
		color: "#737373",
	}),
	singleValue: (provided) => ({
		...provided,
		color: "#1D60B5",
		fontSize: "15px"
	}),
	indicatorSeparator: () => null,
};

export const bulkSelect = {
	placeholder: (provided) => ({
		...provided,
		color: "#737373",
	}),
	singleValue: (provided) => ({
		...provided,
		color: "#444444",
		fontSize: "15px"
	}),
	indicatorSeparator: () => null,
};

import React, { useRef, useState } from "react";
import StyledButton from "./StyledButton";
import ReactTooltip from "react-tooltip";
import { ClickOutside } from "../../features/hooks/ClickOutside";

const DropdownIcon = ({
	className,
	options,
	onSelectHandler,
	titleIcon,
	dataTip,
}) => {
	const [isOpenDropdown, setIsOpenDropdown] = useState(false);
	const wrapperRef = useRef("menu");
	ClickOutside(wrapperRef, () => {
		setIsOpenDropdown(false);
	});

	return (
		<div className="relative" ref={wrapperRef}>
			<ReactTooltip effect="solid" place="bottom" />
			<button
				className={className}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					setIsOpenDropdown(!isOpenDropdown);
				}}
			>
				<div className="flex items-center justify-around">
					<div data-tip={dataTip} className="flex items-center justify-center">
						<img
							className="min-w-[2rem] min-h-[2rem]"
							src={titleIcon}
							alt="icon"
						/>
					</div>
				</div>
			</button>
			{isOpenDropdown && (
				<div
					className="bg-white mx-1 min-w-max w-full absolute top-0 left-9 border border-[#D9D9D9] rounded-md z-10"
					style={{ boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)" }}
				>
					{options.map((option) => (
						<div
							key={option.key}
							className={`flex flex-row items-center py-2 px-5 cursor:pointer border-b-2 last:border-0 last:hover:rounded-b-md ${ option?.value === "embedPage" && !option?.element?.live ? "bg-[#E9E9EB]" : "bg-white hover:bg-[#F8F8F8]" } cursor-pointer gap-x-2`}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								if ( option?.value === "embedPage" && !option?.element?.live ) return
								onSelectHandler(option.value, option.element);
							}}
						>
							<div>
								{option.icon && (
									<img src={option.icon} alt={`${option.value}-icon`} className={"w-[18px] h-[18px]"} />
								)}
							</div>
							<div>
								<span className="text-sm">{option.label}</span>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default DropdownIcon;

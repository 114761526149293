export const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		border: "1px solid #D9D9D9",
		boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
		borderRadius: "8px",
		padding: "0px",
	},
	overlay: {
		zIndex: 50,
		background: "rgba(0, 0, 0, 0.32)",
		backdropFilter: "blur(5px)",
	},
};

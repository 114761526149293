import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Modal from "react-modal";
import {toast} from "react-toastify";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useGeneratePublicLinkMutation, useGeneratePublicWebsiteLinkMutation} from "../../../features/api/formsApiSlice";
import {useCreateRelationshipMutation} from "../../../features/api/relationshipApiSlice";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useGetPageRulesQuery} from "../../../features/api/pagesApiSlice";
import {PageSpinner} from "../../top-level/PageSpinner";
import {customStyles} from "../../../utils/modalStyles";
import closeCrossIcon from "../../../icons/closecross-icon.svg";
import {StyledRadio} from "../../custom/StyledRadio";
import Select from "react-select";
import makeAnimated from "react-select/animated/dist/react-select.esm";
import {bulkSelect, operatorSelect} from "../../../utils/reactSelectStyles";
import {useGetAllContactListsQuery} from "../../../features/api/contactsApiSlice";
import uuid from "react-uuid";
import {useGetPageStyleTemplateQuery} from "../../../features/api/settingsApiSlice";
import {toastError, toastLoading, toastSuccess} from "../../custom-toast/toasts";
import infoIcon from "../../../icons/info-icon.svg";
import copyIcon from "../../../icons/copy-icon.svg";
import bulkPremiumUpsellImage from "../../../assets/bulk-premium-upsell-image.svg"
import {Spinner} from "../../top-level/Spinner";
import ReactTooltip from "react-tooltip";

export const GeneratePageDialog = ({isOpen, setIsOpen, pageId, isLive}) => {
	const [sellerLink, setSellerLink] = useState("");
	const [websiteLink, setWebsiteLink] = useState("");
	// const [variables, setVariables] = useState([]);
	const [linkType, setLinkType] = useState("Seller");
	const [hideModal, setHideModal] = useState(false);
	const [bulkGenerate, setBulkGenerate] = useState(false);
	const [selectedContactList, setSelectedContactList] = useState(null);
	const [contactListOptions, setContactListOptions] = useState([]);
	const [placeholders, setPlaceholders] = useState([]);
	const [identifiers, setIdentifiers] = useState({});
	const [flags] = useState(JSON.parse(useSelector((state) => state.userSetting.flags)));
	const [randomID] = useState(String(Math.random()));

	const [creatingLinks, setCreatingLinks] = useState(false);

	const salesRepId = useSelector((state) => state.sessionData.sellerId);
	const companyId = useSelector((state) => state.sessionData.companyId);

	const {data, isLoading, isError} = useGetPageRulesQuery({salesRepId: salesRepId, companyId: companyId});
	const {
		data: contactListData,
		isLoading: contactListIsLoading,
		isError: contactListIsError
	} = useGetAllContactListsQuery({companyId: companyId});
	const {
		data: pageTemplateData,
		isFetching: pageTemplateIsFetching,
		isError: pageTemplateIsError
	} = useGetPageStyleTemplateQuery({ pageId: pageId });
	const [publicURL, {}] = useGeneratePublicLinkMutation();
	const [websiteURL] = useGeneratePublicWebsiteLinkMutation();
	const [createRelationship, {}] = useCreateRelationshipMutation();


	useEffect(() => {
		if (linkType === "Website") {
			(isLive === 1) ? websiteLinkGenerateHandler() : confirmDialogue()
		}
	}, [linkType])

	// useEffect(() => {
	// 	if (!isLoading) {
	// 		if (data.payload.length !== 0) {
	// 			const variableArray = JSON.parse(data.payload[0].variables);
	// 			setVariables(variableArray);
	// 		}
	// 	}
	// }, [data])

	useEffect(() => {
		if (!contactListIsLoading) {
			if (contactListData?.payload) {
				const list = contactListData?.payload.map(it => {
					return {
						key: uuid(),
						value: uuid(),
						label: it.title,
						data: it
					}
				});
				setContactListOptions(list);
			}
		}
	}, [contactListData, contactListIsLoading])

	useEffect(() => {
		if (!pageTemplateIsFetching) {
			if (pageTemplateData?.payload) {
				if (pageTemplateData?.payload[0]?.placeholders && flags.page.url.bulkGenerate.enable) {
					setPlaceholders(JSON.parse(pageTemplateData?.payload[0]?.placeholders));
				}
			}
		}
	}, [pageTemplateData, pageTemplateIsFetching, isOpen])

	if (isLoading || contactListIsLoading || pageTemplateIsFetching) {
		return <PageSpinner/>
	}

	const initialValues = {
		prospect_name: "",
		prospect_email: "",
	};

	const updateIdentifiers = (selectedContactList) => {
		setIdentifiers(JSON.parse((selectedContactList) ? selectedContactList?.data?.contact_bank : "[]")?.identifiers ?? {})
	}

	const confirmDialogue = (values) => {
		setHideModal(true);
		confirmAlert({
			customUI: ({onClose}) => {
				return (
					<div className="flex flex-col w-[30rem] h-[14.13rem] bg-white px-[2.25rem] rounded-[0.63rem] drop-shadow-2xl">
						<p className="mt-[2.25rem] text-[18px] font-medium">
							{`Page is not published`}
						</p>
						<p className="mt-[1.5rem] text-[14px] leading-[14px]">
							Do you want to proceed?
						</p>
						<div className="flex flex-row justify-between mt-[3.375rem]">
							<button
								onClick={() => {
									setHideModal(false);
									setLinkType("Seller");
									setIsOpen(false);
									onClose();
								}}
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
							>
								Cancel
							</button>
							<button
								className="flex justify-center items-center w-[5.06rem] h-[2.5rem] hover:bg-[#E6E9EF] font-roboto text-[16px] leading-[24px] border border-[#C5C7D0] rounded-[0.25rem]"
								onClick={
									async () => {
										setHideModal(false);
										if (linkType === "Website") {
											await websiteLinkGenerateHandler();
											onClose();
										} else {
											await linkValidationHandler(values);
											onClose();
										}
									}
								}
							>
								Continue
							</button>
						</div>
					</div>
				);
			},
		});
	}

	const validateIdentifiers = (values, identifiers) => {
		let status = true;
		if (Object.values(values).length === 0) return false;
		Object.values(values).forEach(it => {
			const cleanValue = it.replaceAll("{", "").replaceAll("}", "").trim();
			if (!Object.values(identifiers).includes(cleanValue)) status = false;
		})
		return status;
	}

	const validateIdentifierPattern = (values) => {
		let status = true;
		Object.values(values).forEach(it => {
			if (!it.includes("{{") || !it.includes("}}")) {
				status = false;
			}
		})
		return status;
	}

	const validateValues = (values, placeHolders) => {
		let status = true;
		placeHolders.forEach(it => {
			if (values[it.trim()] === undefined || values[it.trim()] === "") status = false;
		})
		return status;
	}

	const validateEmailAddress = (input) => {
		const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		return !!input.match(validRegex);
	}

	const linkValidationHandler = async (values) => {
		if (!validateValues(values, placeholders)) return toastError("Empty Fields Detected");
		if (bulkGenerate) {
			if (!selectedContactList) return toastError("Please select a contact list");
			if (!validateIdentifiers(values, identifiers) || !validateIdentifierPattern(values)) return toastError("Invalid Identifier Detected");
		}
		if (bulkGenerate) {
			await bulkLinkGenerateHandler(values, identifiers);
		} else {
			await linkGenerateHandler(values);
		}
	}

	const resetModal = () => {
		setSellerLink("");
		setLinkType("Seller");
		setBulkGenerate(false);
		setSelectedContactList(null);
		setPlaceholders([]);
		setIdentifiers({})
	}

	const getKeyByValue = (object, value) => {
		return Object.keys(object).find(key => object[key] === value);
	}

	const bulkLinkGenerateHandler = async (values, identifiers) => {
		const contactBank = JSON.parse(selectedContactList?.data?.contact_bank ?? "{}")?.bank ?? [];
		const valueKeys = Object.keys(values);
		const valueValues = Object.values(values);
		const identifierHeaders = {}
		let invalidFields = false;
		setCreatingLinks(true);
		valueKeys.forEach((it, i) => {
			identifierHeaders[it.trim()] = getKeyByValue(identifiers, valueValues[i].replaceAll("{", "").replaceAll("}", "").trim())
		})
		for await (const bank of contactBank) {
			if (!validateEmailAddress(bank[identifierHeaders["prospect_email"]])) {
				invalidFields = true;
			}
			const mappedIdentifiers = {
				prospect_email: bank[identifierHeaders["prospect_email"]],
				prospect_name: bank[identifierHeaders["prospect_name"]]
			}
			placeholders.map(it => {
				mappedIdentifiers[it.trim()] = bank[identifierHeaders[it.trim()]];
			})
			try {
				const data = {
					name: bank[identifierHeaders["prospect_name"]],
					email: bank[identifierHeaders["prospect_email"]],
					pageId: pageId,
					mappingData: JSON.stringify(mappedIdentifiers),
					salesRepId: salesRepId,
					companyId: companyId
				}
				const getUrl = await publicURL(data).unwrap();
				if (getUrl.status === "success") {
					try {
						const rel = await createRelationship({
							salesRepId: salesRepId,
							prospectId: getUrl.payload.id,
							companyId: companyId,
							pageId: pageId,
							engaged: "yes",
							dateCreated: new Date()
								.toISOString()
								.slice(0, 19)
								.replace("T", " "),
							encryptedId: getUrl.payload.token,
						}).unwrap();
						if (rel.status === "success") {
							// toast.dismiss(id);
							// toastSuccess("Link generated!");
							// setSellerLink(getUrl.payload.url);
						} else {
							// toast.dismiss(id);
							invalidFields = true;
							// toastError("Failed to generate seller link");
						}
					} catch (err) {
						invalidFields = true;
						// toastError("Failed to generate seller link");
					}
				} else {
					setCreatingLinks(false);
					invalidFields = true;
					toastError("Failed to generate seller link");
				}
			} catch (err) {
				setCreatingLinks(false);
				invalidFields = false;
				return toastError("Couldn't connect to database");
			}
		}
		if (invalidFields) {
			toastError("Invalid fields detected and dismissed");
		}
		setCreatingLinks(false);
		setIsOpen(false);
		resetModal();
	}

	const linkGenerateHandler = async (values) => {
		if (!validateEmailAddress(values.prospect_email)) return (toastError("Invalid Email Address"))
		setCreatingLinks(true);
		const mappedIdentifiers = {
			prospect_email: values.prospect_email,
			prospect_name: (values.prospect_name === "") ? values.prospect_email : values.prospect_name,
		}
		placeholders.map(it => {
			mappedIdentifiers[it.trim()] = values[it.trim()];
		})
		try {
			const data = {
				name: (values.prospect_name === "") ? values.prospect_email : values.prospect_name,
				email: values.prospect_email,
				pageId: pageId,
				mappingData: JSON.stringify(mappedIdentifiers),
				salesRepId: salesRepId,
				companyId: companyId
			}
			const getUrl = await publicURL(data).unwrap();
			if (getUrl.status === "success") {
				try {
					const rel = await createRelationship({
						salesRepId: salesRepId,
						prospectId: getUrl.payload.id,
						companyId: companyId,
						pageId: pageId,
						engaged: "yes",
						dateCreated: new Date()
							.toISOString()
							.slice(0, 19)
							.replace("T", " "),
						encryptedId: getUrl.payload.token,
					}).unwrap();
					if (rel.status === "success") {
						setCreatingLinks(false);
						// toastSuccess("Link generated!");
						setSellerLink(getUrl.payload.url);
					} else {
						setCreatingLinks(false);
						toastError("Failed to generate seller link");
					}
				} catch (err) {
					setCreatingLinks(false);
					toastError("Failed to generate seller link");
				}
			} else {
				setCreatingLinks(false);
				toastError("Failed to generate seller link");
			}
		} catch (err) {
			setCreatingLinks(false);
			toastError("Couldn't connect to database");
		}
	};

	const websiteLinkGenerateHandler = async () => {
		const id = toastLoading("Generating Link");
		try {
			const data = {
				pageId: pageId,
				companyId: companyId
			}
			const getUrl = await websiteURL(data).unwrap();
			if (getUrl.status === "success") {
				toast.dismiss(id);
				// toastSuccess("Link generated!");
				setWebsiteLink(getUrl.payload.url);
			} else {
				toast.dismiss(id);
				toastError("Failed to generate website link");
			}
		} catch (err) {
			toastError("Couldn't connect to database");
		}
	};

	const readOnlySellerLink = (
		<div className="flex flex-col gap-y-[8px] my-[20px] pt-[24px] border-t">
			<p className="text-[#222222] text-[16px] leading-[16px]">
				Share Outbound Link
			</p>
			<div className="flex flex-row gap-x-6">
				<input
					className="appearance-none border bg-[#F4F4F4] rounded-[5px] w-full py-2 px-3 text-[#656565] leading-[20px] font-inter focus:outline-none focus:shadow-outline"
					id="link"
					type="link"
					name="link"
					value={sellerLink}
					readOnly
				/>
				<button
					className="flex items-center justify-center border border-[#63A4FA] h-[40px] w-[140px] rounded-[0.375rem] text-[#0071F2] bg-[#F1F7FF] hover:bg-white ml-[-14px]"
					onClick={() => {
						navigator.clipboard.writeText(sellerLink);
						toastSuccess("Copied");
					}}
				>
					<p className="text-[15px] leading-[15px]">
						Copy Link
					</p>
				</button>
			</div>
		</div>
	);

	const readOnlyWebsiteLink = (
		<div className="flex flex-col gap-y-[18px]">
			<p className="text-[#222222] text-[16px] leading-[16px]">
				Share Inbound Link
			</p>
			<div className="flex flex-row gap-x-6">
				<input
					className="appearance-none border bg-[#F4F4F4] rounded-[5px] w-full py-2 px-3 text-[#656565] leading-[20px] font-inter focus:outline-none focus:shadow-outline"
					id="link"
					type="link"
					name="link"
					value={`${websiteLink}/${salesRepId}`}
					readOnly
				/>
				<button
					className="flex items-center justify-center border border-[#63A4FA] h-[40px] w-[140px] rounded-[0.375rem] text-[#0071F2] bg-[#F1F7FF] hover:bg-white ml-[-14px]"
					onClick={() => {
						navigator.clipboard.writeText(`${websiteLink}/${salesRepId}`);
						toastSuccess("Copied");
					}}
				>
					<p className="text-[15px] leading-[15px]">
						Copy Link
					</p>
				</button>
			</div>
		</div>
	);

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={() => {
				if (!creatingLinks) {
					resetModal();
					setIsOpen(false);
				}
			}}
			ariaHideApp={hideModal}
			style={customStyles}
		>
			<>
				{
					(creatingLinks) ?
						<div className={"p-24"}>
							<Spinner/>
						</div>
						:
						<div className="w-[601px] flex flex-col my-[36px] mx-[40px] overflow-y-auto h-[600px] 2xl:h-full">
							<ReactTooltip
								effect="solid"
								place="right"
								multiline={true}
								id={randomID}
								html={true}
								className="max-w-[350px] font-inter text-xs leading-[22px]"
							/>
							<div className="flex justify-between items-center pb-[20px] border-b border-[#D9D9D9]">
								<p className="text-[#222222] text-[24px] leading-[24px] font-[500]">
									Choose link type
								</p>
								<button onClick={() => {
									resetModal();
									setIsOpen(false);
								}}>
									<img
										src={closeCrossIcon}
										alt="close"
										className="w-[34px] h-[34px]"
									/>
								</button>
							</div>
							<div className="flex flex-col my-[24px] gap-y-[4px]">
								<div className="flex flex-row gap-x-[26px]">
									<label className="ml-[-12px]">
										<StyledRadio name="Link"
																 className="ml-[60px] mr-[1.5rem] w-[18px] h-[18px] border-2 border-[#C5C5C7]"
																 checked={linkType === "Seller"}
																 onClick={() => setLinkType("Seller")}
										/>
										<span className={`font-light text-[#222222] text-[14px]`}>
										Outbound Link
									</span>
									</label>
									<label>
										<StyledRadio name="Link"
																 className="ml-[60px] mr-[1.5rem] w-[18px] h-[18px] border-2 border-[#C5C5C7]"
																 checked={linkType === "Website"}
																 onClick={() => setLinkType("Website")}
										/>
										<span className={`font-light text-[#222222] text-[14px]`}>
										Inbound Link
									</span>
									</label>
								</div>
								<p className="text-[#737272] text-[13px] leading-[13px]">This creates a link that binds the
									Prospect
									to Sales Rep Account</p>
							</div>
							<div className={(linkType === "Seller" ? "flex flex-col gap-y-[8px]" : "hidden")}>
								<div
									className={`relative font-inter rounded-[0.5rem] ${!flags.page.url.bulkGenerate.enable ? "border-2 border-[#0AE50A]" : ""} font-normal text-xs group`}
								>
									<div className="flex flex-row justify-between items-center bg-[#F5FAFF] rounded-[5px] p-[10px]">
										<div className={"flex flex-row gap-x-[10px] items-center"}>
											<input
												type="checkbox"
												className="w-[18px] h-[18px] text-[#1A73E8] rounded"
												checked={bulkGenerate}
												onChange={(e) => {
													setBulkGenerate((state) => !state);
												}}
											/>
											<p className="text-[12px] text-[#222222]">
												Bulk generate URLs using this contact list
											</p>
										</div>
										<Select
											className="w-[280px] text-[#444444]"
											value={selectedContactList}
											components={makeAnimated()}
											options={contactListOptions}
											styles={bulkSelect}
											placeholder="Select Contact List"
											onChange={(e) => {
												setSelectedContactList(e);
												updateIdentifiers(e);
											}}
											isDisabled={!bulkGenerate}
											isSearchable
										/>
									</div>
									<div className={!flags.page.url.bulkGenerate.enable ? "absolute inset-0 z-10 bg-white text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300 rounded-[0.5rem]" : "hidden"}>
										<div className="flex flex-row gap-x-[1rem] items-center justify-center">
											<img src={bulkPremiumUpsellImage} alt="upgrade"/>
										</div>
									</div>
								</div>
								<div className={bulkGenerate && selectedContactList ? "flex flex-col p-[10px] gap-y-[10px] justify-center bg-[#FAFAFA] rounded-[5px]" : "hidden"}>
									<div className={"flex flex-col gap-y-[8px]"}>
										<p className={selectedContactList ? "text-[13px] text-[#444444] font-medium" : "hidden"}>
											This contact list supports the following identifiers:
										</p>
										<div className={"flex flex-row gap-x-[5px]"}>
											{Object.values(identifiers).map(it => {
												return (
													<div className={"text-[10px] text-[#3776C7] px-[12px] py-[5px] bg-[#F1F7FF] border border-[#92C1FF] rounded-[25px]"}>
														{it}
													</div>
												)
											})}
										</div>
									</div>
									<hr className="text-[#D9D9D9]"/>
									<div className={"flex flex-row gap-x-[8px] items-start"}>
										<img
											src={infoIcon}
											alt="info"
											className={"mt-[4px] w-[13px] h-[13px] svg-info-gray"}
										/>
										<p className="text-[10px] font-medium text-[#7D7D7D]">
											Enter the right identifiers below for each placeholder in the page content. <br/>
											Example: add {`{{identifier name}}`} in the input fields below
										</p>
									</div>
								</div>
								<Formik
									initialValues={initialValues}
									onSubmit={(values) => {
										(isLive === 1) ? linkValidationHandler(values) : confirmDialogue(values)
									}}
								>
									{(formik) => {
										const {isValid, dirty} = formik;
										const classSubmit =
											"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline";
										return (
											<div className="w-full">
												<Form className="bg-white">
													<div className="flex flex-col gap-y-[8px]">
														<div className={"flex flex-col py-[15px] px-[10px] rounded-[5px] bg-[#FAFAFA] gap-y-[12px]"}>
															<p className="text-[13px] text-[#444444] font-medium">
																Recipients Details
															</p>
															<div className="flex flex-col gap-y-[8px]">
																<p className={"text-[#444444] text-[12px]"}>
																	{`Name`}
																</p>
																<Field
																	className="px-3 py-2 w-full text-[12px] leading-[12px] text-[#7D7D7D] border rounded-[5px] border-[#C5C7D0]"
																	id="prospect_name"
																	type="input"
																	name="prospect_name"
																	placeholder={(bulkGenerate) ? "Add identifier for name" : "Enter name (optional)"}
																/>
																<ErrorMessage
																	name="prospect_email"
																	component="span"
																	className="text-red-500 text-xs italic"
																/>
															</div>
															<div className="flex flex-col gap-y-[8px]">
																<p className={"text-[#444444] text-[12px]"}>
																	{`Email address`}
																</p>
																<Field
																	className="px-3 py-2 w-full text-[12px] leading-[12px] text-[#7D7D7D] border rounded-[5px] border-[#C5C7D0]"
																	id="prospect_email"
																	type="input"
																	name="prospect_email"
																	placeholder={(bulkGenerate) ? "Add identifier for email address" : "Enter email address"}
																/>
																<ErrorMessage
																	name="prospect_email"
																	component="span"
																	className="text-red-500 text-xs italic"
																/>
															</div>
														</div>
														<div className={(placeholders.length !== 0) ? "flex flex-col gap-y-[12px] py-[15px] px-[10px] rounded-[5px] bg-[#FAFAFA]" : "hidden"}>
															<div className={"flex flex-row gap-x-[5px] items-center"}>
																<p className="text-[13px] text-[#444444] font-medium">
																	Other Placeholders
																</p>
																<div >
																	<img
																		src={infoIcon}
																		alt="info"
																		className={"w-[13px] h-[13px]"}
																		data-for={randomID}
																		data-tip={`Map these placeholders to an identifier`}
																	/>
																</div>
															</div>
															<div className="flex flex-col gap-y-[8px] max-h-[220px] overflow-y-auto">
																{
																	placeholders.map((it, i) => {
																		return (
																			<div key={i} className="flex flex-col gap-y-[8px]">
																				<p className={"text-[#444444] text-[12px]"}>
																					{`Placeholder ${it}`}
																				</p>
																				<Field
																					className="px-3 py-2 w-full text-[12px] leading-[12px] text-[#7D7D7D] border rounded-[5px] border-[#C5C7D0]"
																					id={it.trim()}
																					type="input"
																					name={it.trim()}
																					placeholder={(bulkGenerate) ? `Add identifier` : `Enter value`}
																				/>
																				<ErrorMessage
																					name={it.trim()}
																					component="span"
																					className="text-red-500 text-xs italic"
																				/>
																			</div>
																		)
																	})
																}
															</div>
														</div>
													</div>
													<div className="flex items-center justify-end mt-[30px]">
														<button
															className={`border rounded-md mt-1 font-medium text-white bg-[#0071F2] hover:bg-[#0351AA]`}
															type="submit"
														>
															<p className="my-[10px] mx-[20px] flex justify-center items-center">
																{(bulkGenerate) ? "Bulk Generate URLs" : "Generate URL"}
															</p>
														</button>
													</div>
												</Form>
											</div>
										);
									}}
								</Formik>
								{
									(sellerLink !== "") ? readOnlySellerLink : false
								}
							</div>
							<div className={(linkType === "Website") ? "" : "hidden"}>
								{readOnlyWebsiteLink}
							</div>
						</div>
				}
			</>
		</Modal>
	);
};
